<template>
  <div class="card-body p-1 justify-content-center" :id="plotId" :ref="'table'+plotId"
       style="height:calc(100% - 10px); width: 100%">
    <div class="p-5" style="height:calc(100%); width: 100%">
      <ejs-grid
        :id="plotId"
        :ref="'data_manager'+plotId"
        :allowSorting="true"
        width="100%"
        :height="tableRows.length<=0 ? '35px': '100%'"
        :locale="lang"
        :key="tableRows.length"
        :allowFiltering="true"
        :enableAdaptiveUI="true"
        :allowPaging="true"
        :enableRtl="lang == 'per'"
        :pageSettings="{ pageSize: 5,pageSizes: [5, 10, 20, 50] }"
        :dataSource="tableRows"
      >
        <e-columns>
          <e-column
            v-for="(item, index) in datas.fields"
            :key="index"
            :field="item.key"
            :width="120"
            :headerText="item.title"
            :allowSorting="item.allowSorting"
            :template="item.hasTemplate ? item.templateType : null"
          ></e-column>
          <template v-slot:photo="{ data }">
            <div>
              <img v-if="computePic(data)!=' - ' " :src="computePic(data)" alt="" class="img-fluid" width="40">
              <p v-else>{{ computePic(data) }}</p>
            </div>
          </template>
          <template v-slot:text="{ data }">
            <p>{{ computeText(data) }}</p>
          </template>
        </e-columns>
        <!--        <template v-slot:rowTemplate="{ data }">-->
        <!--          <tr :style="{'background-color': data.index==4  || data.index==15? 'blue' :'' +'!important'}">-->
        <!--            <td>{{ data }}</td>-->
        <!--          </tr>-->
        <!--        </template>-->
      </ejs-grid>
      <b-modal
        :id="'modal-setting-table'+plotId"
        size="xl"
        :title="dict.title[lang]"
        centered
        hide-header-close
        scrollable
        :ok-title="dict.ok[lang]"
        :cancel-title="dict.cancel[lang]"
        :dir="dict.dir[lang]"
        @ok="handleSubmit"
        @shown="initialModal"
        @cancel="resetAll"
        no-close-on-backdrop
        header-border-variant="primary"
        header-class="my-modal-header "
      >
        <div>
          <div class="p-3">
            <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
              <li
                v-for="(item, index) in tabs"
                class="mga-tab-nav-item"
                @click="activeTab = item"
                :key="index"
              >
                <a
                  class="mga-tab-nav-link text-dark fw-lighter"
                  :class="{ active: activeTab === item }"
                  :id="item"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="tab1"
                  aria-selected="true"
                >
                  {{ dict[item][lang] }}
                </a>
              </li>
            </ul>
          </div>
          <div class="px-5" v-if="activeTab == 'data'" :key="activeTab">
            <div class="d-flex gap-5 my-3">
              <ejs-radiobutton
                :checked="datas.tableStatus=='fix' ? true:false "
                :enableRtl="lang == 'per'"
                @change="statusChange($event)"
                :label="dict.fixTable[lang]"
                value="fix"
                name="fix"
              ></ejs-radiobutton>
              <ejs-radiobutton
                :checked="datas.tableStatus=='timeSeri' ? true:false "
                :enableRtl="lang == 'per'"
                @change="statusChange($event)"
                :label="dict.timeSeriTable[lang]"
                value="timeSeri"
                name="timeSeri"
              ></ejs-radiobutton>
            </div>

            <div class="d-flex align-items-center" v-if="datas.tableStatus=='timeSeri'" :key="datas.tableStatus">
              <ejs-textbox
                :placeholder="dict.pickData[lang]"
                :enableRtl="lang == 'per'"
                floatLabelType="Auto"
                v-model="datas.assignedData['name']"
                :showClearButton="true"
              ></ejs-textbox>
              <b-button @click="pickData" class="btn-sm" variant="primary"
              ><span class="e-icons e-edit-6"></span>
              </b-button>
            </div>
            <div class="col-12" v-if="datas.tableStatus=='fix'" :key="datas.tableStatus">
              <ejs-dropdownlist
                :placeholder="dict.pickClass[lang]"
                :dataSource="categoryList"
                @change="getCat($event)"
                v-model="datas.assignedData['id']"
                :enableRtl="lang === 'per'"
                :fields="{ value: 'value', text: 'text' }"
              ></ejs-dropdownlist>
            </div>

          </div>
          <div class="px-5" v-if="activeTab == 'setting'">
            <div class="card p-3 mt-3">
              <b-button
                variant="primary"
                size="sm"
                class="col-md-2 px-0"
                @click="addNewField"
              >{{ dict.newRow[lang] }}
                <span class="e-icons e-add"></span>
              </b-button>
              <div
                class="row my-3 border py-2"
                v-for="(item, key) in datas.fields"
                :key="key"
              >
                <p class="border-end mt-4 col-md-1 col-1">{{ key + 1 }}</p>
                <div class="col-md-3 col-12  col-12" :class="{'mt-4':items.length>0,'mt-2':items.length<=0}">
                  <ejs-dropdownlist
                    v-if="items.length>0"
                    :dataSource="items"
                    :showClearButton="true"
                    :enableRtl="lang === 'per'"
                    @change="rowValue($event,item,key)"
                    v-model="item.dataId"
                    :placeholder="dict.useAsRow[lang]"
                    :fields="{ value: 'value', text: 'text' , disabled: 'State'}"
                  ></ejs-dropdownlist>
                  <ejs-textbox
                    v-if="items.length<=0"
                    :placeholder="dict.fieldTitle[lang]"
                    :enableRtl="lang === 'per'"
                    floatLabelType="Auto"
                    @input="preventPersian($event,item,item.key)"
                    v-model="item.key"
                    :showClearButton="true"
                  ></ejs-textbox>
                </div>
                <div class="col-md-3 mt-2 col-12">
                  <ejs-textbox
                    :placeholder="dict.fieldTitle[lang]"
                    :enableRtl="lang === 'per'"
                    floatLabelType="Auto"
                    v-model="item.title"
                    :showClearButton="true"
                  ></ejs-textbox>
                </div>
                <!--            <div class="col-md-2 col-12 mt-4">-->
                <!--              <ejs-switch-->
                <!--                @change="setRowTemplate($event,item)"-->
                <!--                :enableRtl="lang == 'per'"-->
                <!--                :checked="false"-->
                <!--              />-->
                <!--              <span class="mx-2" style="font-size: 13px">{{ dict.useAsRow[lang] }}</span>-->
                <!--            </div>-->
                <div class="col-md-2 col-12 mt-4">
                  <ejs-switch
                    :enableRtl="lang == 'per'"
                    :checked="item.allowSorting"
                    v-model="item.allowSorting"
                  />
                  <span class="mx-2" style="font-size: 13px">{{
                      dict.allowSort[lang]
                    }}</span>
                </div>
                <div class="col-md-2 d-flex col-12 mt-2 gap-3">
                  <ejs-dropdownlist
                    :placeholder="dict.chooseDataPos[lang]"
                    :dataSource="tamplateSelection"
                    @change="templateActivation($event,item)"
                    v-model="item.templateType"
                    :enableRtl="lang === 'per'"
                    :fields="{ value: 'value', text: lang }"
                  ></ejs-dropdownlist>
                  <span v-if="item.hasTemplate" @click="addPhotoTemplate(item)"
                        class="e-icons e-export-jpg mt-3"></span>
                </div>
                <div class="col-md-1 mt-4">
                <span
                  @click="removeField(item.dataId)"
                  style="font-size: 17px"
                  class="e-icons e-delete-1 text-danger"
                ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <!--    <div class="col-md-6 row col-12">-->
      <!--      <div class="col-md-1" v-for="i in 10" :key="i">-->
      <!--        <img src="@/assets/images/cars/roll-off-trucks.webp" class="img-fluid" alt="">-->
      <!--      </div>-->
      <!--    </div>-->
      <b-modal
        :id="'modal-setPhoto-template'+plotId"
        size="lg"
        :title="dict.title[lang]"
        centered
        hide-header-close
        scrollable
        :ok-title="dict.ok[lang]"
        :cancel-title="dict.cancel[lang]"
        :dir="dict.dir[lang]"
        @ok="handleOk"
        no-close-on-backdrop
        header-border-variant="primary"
        header-class="my-modal-header"
      >
        <div class="card mt-2 mx-auto">
          <div class="border px-0" v-if="Object.keys(selectedItem).length>0">
            <ejs-toolbar :items="toolbarGenerator"></ejs-toolbar>
            <div class="row border-bottom border-top mx-0 py-1" v-for="(data,index) in selectedItem.values "
                 :key="index"
                 style="font-size: 13px">
              <div class="col-md-4 col-12 mt-3">
                <ejs-textbox
                  :placeholder="dict.enterValue[lang]"
                  :autocomplete="'off'"
                  :type="data.type"
                  floatLabelType="Auto"
                  :value="data.value"
                  @input="getValue($event,data,'value')"
                  :enableRtl="lang === 'per'"
                >
                </ejs-textbox>
                <!--              <ejs-dropdownlist-->
                <!--                v-if="data.type == 'Boolean'"-->
                <!--                :dataSource="booleanType"-->
                <!--                :value="data.value"-->
                <!--                floatLabelType="Auto"-->
                <!--                :enabled="true"-->
                <!--                :fields="{ text: lang, value: 'value' }"-->
                <!--                :placeholder="dict.value[lang]"-->
                <!--                :enableRtl="lang === 'per'"-->
                <!--                :required="data.required"-->
                <!--              ></ejs-dropdownlist>-->
              </div>
              <div class="col-md-4 col-12 pt-3" v-if="selectedItem.templateType=='text'">
                <ejs-textbox
                  :placeholder="dict.enterText[lang]"
                  :autocomplete="'off'"
                  floatLabelType="Auto"
                  :enableRtl="lang === 'per'"
                  @input="getValue($event,data,'text')"
                >
                </ejs-textbox>
              </div>
              <div class="col-md-2 col-12 pt-4" v-if="selectedItem.templateType=='photo'">
                <img v-if="data.pic!=''" @click="choosePhotoAsValue(data)" :src="data.pic" alt="" class="img-fluid">
                <span v-if="data.pic==''" @click="choosePhotoAsValue(data)" class="e-icons e-export-jpg mt-3"></span>
              </div>
              <div class="col-md-2 col-12 pt-4 mt-3 ml-auto">
                   <span
                     class="e-icons e-delete-1 text-danger"
                     style="font-size: 17px"
                     @click="removeRow(data.id)"
                   ></span>
              </div>
            </div>
            <ejs-pager
              style="border: unset!important;"
              :pageSize="5"
              v-model="curPage"
              :pageSizes="[5,10,20,50]"
              :locale="lang"
              :enableRtl="lang == 'per'"
              @click="getValValue"
              :totalRecordsCount="selectedItem.values.length"
            ></ejs-pager>
          </div>
        </div>
      </b-modal>
      <b-modal
        :id="'modal-gallery'+plotId"
        size="md"
        :title="dict.carsTitle[lang]"
        centered
        hide-header-close
        scrollable
        :ok-title="dict.ok[lang]"
        :cancel-title="dict.cancel[lang]"
        :dir="dict.dir[lang]"
        no-close-on-backdrop
        header-border-variant="primary"
        header-class="my-modal-header "
      >
        <div class="row col-12">
          <div class="col-md-3 my-3" v-for="(item,index) in objectsPhoto" @click="choosePhoto(item)" :key="index">
            <img :src="item.address" class="img-fluid" alt="">
          </div>
        </div>
      </b-modal>
      <data-picker-modal />
    </div>


  </div>
</template>

<script>
import DataPickerModal from "@/components/dataManagerModalDataPicker.vue";
import { Edit, ExcelExport, Page, Search, Sort, Toolbar } from "@syncfusion/ej2-vue-grids";
import { v4 } from "uuid";
import { ToolbarComponent } from "@syncfusion/ej2-vue-navigations";

export default {
  name: "widgetTable",
  components: { DataPickerModal, "ejs-toolbar": ToolbarComponent },
  props: {
    plotId: String,
    data: Object,
    editable: Boolean,
    resized: Number,
    setting: Boolean
  },
  data() {
    return {
      attr: {
        mapHeight: null,
        mapWidth: null
      },
      obj: {},
      updateIt: 0,
      curPage: 1,
      perPage: 5,
      watcherList: [],
      variables: [],
      dict: {
        newRow: { eng: "Add item", per: "افزودن آیتم" },
        fixTable: { eng: "fixed table", per: "جدول نرمال" },
        carsTitle: { eng: "pick photo", per: "انتخاب عکس" },
        pickClass: { eng: "pick class data", per: "انتخاب داده از نوع کلاس" },
        chooseDataPos: { eng: "show value", per: "نمایش مقدار" },
        timeSeriTable: {
          eng: "time seri table",
          per: "جدول با بروزرسانی لحظه ای"
        },
        showInTable: { eng: "show as column", per: "نمایش در جدول" },
        colChoose: {
          eng: "choose data as columns",
          per: "انتخاب داده بعنوان ستون"
        },
        useAsRow: {
          eng: "choose value as row",
          per: "انتخاب مقدار بعنوان یک ردیف"
        },
        allowSort: { eng: "sort by", per: "مرتب کردن بر اساس" },
        dir: { eng: "ltr", per: "rtl" },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        title: { eng: "setting", per: "تنظیمات" },
        fieldTitle: { eng: "title column", per: "نام ستون" },
        data: { eng: "data", per: "داده" },
        setting: { eng: "setting", per: "تنظیمات" },
        pickData: { per: "انتخاب داده", eng: "Pick Data" },
        enterText: { per: "وارد کردن متن", eng: "Enter text" },
        enterValue: { per: "وارد کردن مقدار", eng: "Enter value" },
        value: { per: "مقدار داده", eng: "data value" }
      },
      tabs: ["data", "setting"],
      activeTab: "data",
      foundedItem: "",
      categoryList: [],
      tamplateSelection: [
        { per: "مقدار اصلی متغیر", eng: "default variable", value: "default" },
        { per: "جایگذاری عکس", eng: "add photo", value: "photo" },
        { per: "جایگذاری متن", eng: "add text", value: "text" }

      ],
      tableRows: [],
      datas: {
        fields: [],
        assignedData: {
          dataType: "",
          id: "",
          name: "",
          type: ""
        },
        rowTemplate: {
          enable: false,
          item: {}
        },
        propertyList: [],
        tableStatus: "fix",
        categoryDatas: []
      },
      selectedCols: [],
      items: [],
      selectedItemToCondition: [],
      objectsPhoto: [
        { name: "fire Trcuk1", address: require("@/assets/images/cars/fireTrcuk1.png") },
        { name: "fire Trcuk2", address: require("@/assets/images/cars/fireTrcuk2.png") },
        { name: "fire Trcuk3", address: require("@/assets/images/cars/fireTrcuk3.png") },
        { name: "fire Trcuk4", address: require("@/assets/images/cars/fireTrcuk4.png") },
        { name: "ambulanc", address: require("@/assets/images/cars/ambulanc.png") },
        { name: "kamiunet", address: require("@/assets/images/cars/kamiunet.png") },
        { name: "jaro_kesh", address: require("@/assets/images/cars/jaro_kesh.png") },
        { name: "pinki_peykan", address: require("@/assets/images/cars/pinki_peykan.png") },
        { name: "bus", address: require("@/assets/images/cars/bus.png") },
        { name: "siman", address: require("@/assets/images/cars/siman.png") },
        { name: "van", address: require("@/assets/images/cars/van.png") },
        { name: "neisan", address: require("@/assets/images/cars/neisan.png") },
        { name: "matiz", address: require("@/assets/images/cars/matiz.png") }
      ],
      selectedItem: {},
      tempFields: [],
      items2: [],
      reRender: 0,
      dataSelectedProps: [],
      dataFields: [{ key: "img", title: "image", hasTemplate: false }],
      selectionOptions: {
        type: "Multiple",
        persistSelection: false,
        checkboxOnly: true
      },
      editSettings: {
        allowEditing: false,
        allowAdding: true,
        allowDeleting: true,
        mode: "Dialog"
      }
    };
  },
  // { key: "name", title: "" }
  computed: {
    getDataSse() {
      return this.$store.state.panel.data;
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    child2parent() {
      return this.$store.state.chpge.child2child;
    },
    toolbarGenerator() {
      return [
        {
          text: this.dict.newRow[this.lang],
          id: "add_data",
          prefixIcon: "e-icons e-add",
          click: () => this.addRow()
        }
      ];
    }
  },
  watch: {
    resized(v) {
      this.resize();
    },
    getDataSse: {
      handler(arg) {
        if (this.plotId != null) {
          if (this.data.datas.tableStatus == "timeSeri" && arg[0].id == this.datas.assignedData.id) {
            let obj = {};
            arg[0].value.forEach(item => {
              obj[item.name] = item.value;
            });
            if (Object.keys(obj).length > 0) {
              this.tableRows.unshift(obj);
            }

          } else if (this.datas.tableStatus == "fix" && arg.length > 1) {
            let itemsList = [];

            arg.forEach((data) => {
              let row = {};
              data.value.forEach(x => {
                let founded = this.datas.propertyList.find(n => n.id == x.id);
                if (founded.name != undefined && x.value != undefined) {
                  row[founded.name] = x.value;
                }
              });
              itemsList.push(row);
              this.tableRows = itemsList;
            });
          }
        }

      }, deep: true
    },
    child2parent(newValue) {
      if (newValue.to == "widgetTable" + this.plotId) {
        if (Object.keys(newValue.item).length > 0) {
          this.getData(newValue.item.id);
          this.datas.assignedData = newValue.item;
        }
      }
    },
    "datas.tableStatus": {
      handler() {
        // this.datas.assignedData = {
        //   dataType: "",
        //   id: "",
        //   name: "",
        //   type: ""
        // };
        // this.datas.rowTemplate = {
        //   enable: false,
        //   item: {}
        // };
        // this.datas.propertyList = [];
        // this.items = [];
        // this.items2 = [];
        // this.datas.fields = [];
      }, deep: true
    },
    setting: {
      handler(val) {
        if (val)
          this.settingModal();
      }
    }
  },
  methods: {
    resize() {
      const divElement = this.$refs["table" + this.plotId];
      if (divElement && this.data != null) {
        this.attr.mapHeight = divElement.clientHeight - 58;
        this.attr.mapWidth = divElement.clientWidth - 16;
      }
      // setTimeout(() => {
      //   this.setupLeafletMap();
      // }, "1000");

    },
    removeField(id) {
      let founded = this.datas.fields.find(item => item.dataId == id);
      this.datas.fields.splice(this.datas.fields.indexOf(founded), 1);
      if (this.selectedCols.indexOf(id) >= 0) {
        this.selectedCols.splice(this.selectedCols.indexOf(id), 1);
      }
    },
    getValValue(e) {
      if ("pageSize" in e.newProp)
        this.valPerPage = e.newProp.pageSize;

      this.valCurPage = e.currentPage;
    },
    computePic(val) {
      let founded = this.datas.fields.find(item => item.key == val.column.field).values;
      if (founded.length > 0) {
        if (founded.find(item => val[val.column.field] == item.value) != undefined)
          return founded.find(item => val[val.column.field] == item.value).pic;
        else return " - ";
      } else if (founded.length <= 0) {
        return " - ";
      }
    },
    computeText(val) {
      let founded = this.datas.fields.find(item => item.key == val.column.field).values;
      if (founded.length > 0) {
        if (founded.find(item => val[val.column.field] == item.value) != undefined)
          return founded.find(item => val[val.column.field] == item.value).text;
        else {
          return val[val.column.field];
        }
      }

    },
    handleSubmit() {
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then(isValid => {
        if (isValid) {

          this.$store.dispatch("data/editDashboard", {
            _id: this.plotId,
            data: { attribute: this.attr, datas: this.datas }
          }).then(
            data => {
              this.$store.dispatch("chpge/child2parent", { task: "updateDash" });
              this.myDataTmp = JSON.parse(JSON.stringify({ attribute: this.attr, datas: this.datas }));
              this.resetAll();

              this.$nextTick(() => {
                this.$bvModal.hide(this.plotId);
              });
            },
            error => {
              this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              this.successful = false;
              // alert(this.message);
            }
          );
        }
      });
    },
    resetAll() {
      this.datas = {
        fields: [],
        assignedData: {
          dataType: "",
          id: "",
          name: "",
          type: ""
        },
        rowTemplate: {
          enable: false,
          item: {}
        },
        propertyList: [],
        tableStatus: "fix",
        categoryDatas: []
      };
    },
    getValue(value, item, name) {
      item[name] = value.value;
    },
    toolbarClick(args) {
      if (args.item.id.includes("add_data")) {
        this.addRow();
      }
    },
    handleOk(e) {
      e.preventDefault();
      this.$nextTick(() => {
        this.$bvModal.hide("modal-setPhoto-template" + this.plotId);
      });
    },
    settingModal() {
      this.selectedCols = [];
      if (this.data != null && this.datas.assignedData.id != "") {
        if (this.datas.tableStatus != "fix") {
          this.getData(this.datas.assignedData.id);
        } else {
          this.getCat({
            itemData: {
              value: this.datas.assignedData.id
            }
          });
        }
        this.datas.fields.forEach((item) => {
          this.selectedCols.push(item.dataId);
        });
      }


      this.$bvModal.show("modal-setting-table" + this.plotId);
    },
    pickData() {
      this.$store.dispatch("chpge/parent2child", {
        task: "pickedData",
        from: "widgetTable" + this.plotId,
        filter: {
          dataType: ["Object"],
          usedBy: [],
          parents: [],
          archive: null,
          perPage: 5000,
          page: 1,
          withChild: true
        }
      });
      this.$root.$emit("bv::show::modal", "modal-data-picker");
    },
    getData(id) {
      this.$store.dispatch("panel/getData", { id: [id] }).then((res) => {
        this.items = [];
        this.datas.propertyList = [];

        if (this.datas.tableStatus == "fix") {
          if (this.datas.assignedData.dataType == "freeArray") {
            if (res[0].value.length > 0) {
              this.datas.propertyList = res[0].value;
              Object.keys(res[0].value[0]).forEach(item => {
                this.items.push({ text: item, value: v4(), State: false });
              });
            }
          } else if (this.datas.assignedData.dataType == "Object") {
            this.datas.propertyList = res[0].value;
            res[0].value.forEach(item => {
              this.items.push({ text: item.name, value: item.id, State: false });
            });
          } else if (this.datas.assignedData.dataType == "freeObject") {
            if (typeof res[0].value != "string") {
              if (typeof res[0].value == "object" && Object.keys(res[0].value).length > 0)
                this.datas.propertyList = res[0].value;
              Object.keys(res[0].value).forEach(item => {
                this.items.push({ text: item, value: v4(), State: false });
              });
            }
          }
        } else {
          this.datas.propertyList = res[0].value;
          res[0].value.forEach(item => {
            this.items.push({ text: item.name, value: item.id, State: false });
          });
        }

      });
    },
    getVal(value, item) {
      if (this.datas.tableStatus == "timeSeri") {
        let values = [];
        if (this.datas.fields.length > 0) {
          this.datas.fields.forEach((item2) => {
            if (item2.dataId != value.itemData.value && !values.includes(value.itemData.value)) {
              values.push(value.itemData.value);
            }
          });
        } else {
          values = value.itemData.value;
        }
        this.datas.propertyList.forEach((item) => {
          if (item.id == value.itemData.value) {
            if (!this.datas.fields.some((field) => field.dataId === value.itemData.value)) {
              this.datas.fields.push({
                dataId: value.itemData.value,
                key: item.name,
                hasTemplate: false,
                allowSorting: false,
                title: "",
                type: item.type,
                templateType: "",
                values: []
              });
            }
            this.remainedItems(item.id);
          }
        });

      } else if (this.datas.tableStatus == "fix") {
        if (this.datas.fields.length <= 0) {
          this.datas.fields.push({
            dataId: value.itemData.value,
            key: this.items.find(y => y.value == value.itemData.value).text,
            hasTemplate: false,
            allowSorting: false,
            title: "",
            type: "String",
            templateType: "",
            values: []
          });
        } else {
          const existingDataIds = new Set(this.datas.fields.map(item => item.dataId));
          if (!existingDataIds.has(value.itemData.value)) {
            this.datas.fields.push({
              dataId: value.itemData.value,
              key: this.items.find(y => y.value == value.itemData.value).text,
              hasTemplate: false,
              allowSorting: false,
              title: "",
              type: "String",
              templateType: "",
              values: []
            });
          }

        }
      }
    },
    addNewField() {
      this.datas.fields.push({
        dataId: v4(),
        key: "",
        hasTemplate: false,
        allowSorting: false,
        title: "",
        type: "String",
        templateType: "",
        values: []
      });
    },
    preventPersian(event, item, name) {
      const filteredInput = event.value.replace(/[\u0600-\u06FF]/g, "");
      item["key"] = filteredInput;
      event.value = filteredInput;
    },
    rowValue(args, item, index) {
      if (args.value != null) {
        this.datas.fields[index].key = this.items.find(i => i.value == args.value).text;
        this.datas.fields[index].dataId = this.items.find(i => i.value == args.value).value;
        this.items.find(i => i.value == args.value).State = true;
      } else {
        this.items.find(i => i.value == args.previousItemData.value).State = false;
      }
    },
    remainedItems(item) {
      if (item) this.items2 = this.items.filter((x) => x.value != item);
      else this.items2 = this.items;
    },
    getCat(val) {
      this.items = [];
      this.datas.categoryDatas = val.itemData.list;
      this.$store.dispatch("data/getClassById", { id: val.itemData.value }).then(res => {
        this.datas.propertyList = res.value;
        this.datas.assignedData = {
          dataType: res.dataType,
          id: res._id,
          name: res.name,
          type: "data"
        };
        res.value.forEach((item) => {
          this.items.push({ text: item.name, value: item.id });
        });
      });
    },
    getAllCategories() {
      this.categoryList = [];
      this.$store.dispatch("data/getAllCategory").then((res) => {
        res.forEach((item) => {
          this.categoryList.push({
            text: item.name,
            value: item.value[0].type,
            list: item.value
          });
        });
      });
    },
    statusChange(e) {
      this.datas.tableStatus = e.value;
    },
    templateActivation(e, col) {
      let foundedItem = this.datas.fields.find(item => item.dataId == col.dataId);
      foundedItem.templateType = e.value;
      foundedItem.hasTemplate = e.value == "photo" || e.value == "text";

      if (e.value == "photo" || e.value == "text") {
        this.selectedItem = foundedItem;
      }
    },
    addPhotoTemplate(item) {
      this.selectedItem = item;

      if (this.selectedItem.templateType == "photo") {
        this.tempFields = [
          { key: "value", per: "مقدار", eng: "value", hasTemplate: true },
          { key: "pic", per: "عکس", eng: "photo", hasTemplate: true },
          { key: "modify", per: "", eng: "", hasTemplate: true }];
      } else if (this.selectedItem.templateType == "text") {
        this.tempFields = [
          { key: "value", per: "مقدار", eng: "value", hasTemplate: true },
          { key: "text", per: "متن", eng: "text", hasTemplate: true },
          { key: "modify", per: "", eng: "", hasTemplate: true }];
      }
      this.$bvModal.show("modal-setPhoto-template" + this.plotId);
    },
    choosePhoto(arg, index) {
      this.selectedItem.values.find(item => item.id == this.foundedItem).pic = arg.address;
      this.$nextTick(() => {
        this.$refs.value_datas.ej2Instances.refresh();
        this.reRender += 1;
      });
      this.$bvModal.hide("modal-gallery" + this.plotId);
    },
    choosePhotoAsValue(arg) {
      this.foundedItem = arg.id;
      this.$bvModal.show("modal-gallery" + this.plotId);
    },
    addRow() {
      if (this.selectedItem.templateType == "photo") {
        this.selectedItem.values.push({
          id: v4(),
          name: "", value: "", pic: ""
        });
      } else if (this.selectedItem.templateType == "text") {
        this.selectedItem.values.push({
          id: v4(),
          name: "", value: "", text: ""
        });
      }
    },
    removeRow(id) {
      let item = this.selectedItem.values.find(e => e.id == id);
      this.selectedItem.values.splice(this.selectedItem.values.indexOf(item), 1);
    },
    initialModal() {
      if (this.data != null) {
        this.datas = this.data["datas"];
        this.watcherList = [];
        if ("categoryDatas" in this.datas)
          this.variables = this.datas.categoryDatas;

        this.datas.fields.forEach(item => {
          if (item.values.length > 0) {
            item.values.forEach(x => {
              this.watcherList.push(x);
            });
          }
        });
      }
    }
  },
  mounted() {
    this.tableRows = [];
    this.getAllCategories();
    if (this.data != null) {
      setTimeout(() => {
        this.datas = this.data["datas"];
        this.watcherList = [];
        if (this.datas.tableStatus == "fix")
          this.variables = this.datas.categoryDatas;

        this.datas.fields.forEach(item => {
          if (item.values.length > 0) {
            item.values.forEach(x => {
              this.watcherList.push(x);
            });
          }
        });
      }, 20);

    } else {
      if (this.plotId != null) {
        this.datas = {
          fields: [],
          assignedData: {
            dataType: "",
            id: "",
            name: "",
            type: ""
          },
          rowTemplate: {
            enable: false,
            item: {}
          },
          propertyList: [],
          tableStatus: "fix",
          categoryDatas: []
        };
      }
    }
  },
  provide: {
    grid: [Page, Sort, Toolbar, Edit, Search, ExcelExport]
  }
};
</script>


<style>
.your-table-class {
  width: 100%;
  overflow: hidden;
}
</style>
