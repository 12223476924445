<template>
  <div>
    <canvas ref="gauge" style="height:100%; width:100%;"></canvas>
  </div>
</template>
<script>

import { LinearGauge } from "canvas-gauges";

export default {
  name: "linearGaugeForm",
  props: {
    gaugeOptions: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      options: {}
    };
  },
  methods: {
    selChart() {
      var options = {
        renderTo: this.$refs.gauge,
        width: 250,
        height: 250
      };

      Object.entries(this.options).forEach(key => {
        Object.entries(key[1]).forEach(opt => {
          options[opt[0]] = opt[1].default;
        });
      });
      this.gauge.update(options);

    }
  },
  mounted() {
    this.gauge = new LinearGauge({
      renderTo: this.$refs.gauge,
      width: 250,
      height: 250
    }).draw();
    this.options = this.gaugeOptions;
  },
  watch: {
    options: {
      handler(val) {
        if (Object.keys(val).length > 0)
          this.selChart();
      }, deep: true
    }
  }
};
</script>

<style scoped>

</style>