var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      Object.keys(_vm.holeUsage).length > 0 &&
      Object.keys(_vm.usedResources).length > 0 &&
      Object.keys(_vm.usage).length > 0
    )?_c('div',{staticClass:"row col-md-12"},_vm._l((_vm.mainResources.parameters),function(item,index){return _c('div',{key:index,staticClass:"col-12"},[(index != 'numberOfWorkbenches' && item.connections != 0)?_c('label',{staticClass:"pl-3 py-3 px-3",attrs:{"for":index}},[_vm._v(_vm._s(_vm.translation[index][_vm.lang]))]):_vm._e(),(index != 'numberOfWorkbenches' && item.connections != 0)?_c('div',{staticClass:"row py-3 px-0 mx-0"},[_c('div',{staticClass:"col-md-8 col-12 px-0 mx-0"},[_c('ejs-slider',{attrs:{"id":'default' + index,"min":0,"enableRtl":_vm.lang=='per',"max":_vm.showMax(index),"type":"MinRange","limits":{
              enabled: true,
              minStart:
                typeof _vm.usage[index] == 'object'
                  ? _vm.usage[index].connections
                  : _vm.usage[index],
              minEnd: _vm.showMax(index),
            },"tooltipChange":_vm.tChange,"tooltip":{ placement: "Before", isVisible: true, showOn: "Auto" },"value":_vm.usedResources[index].connections || _vm.usedResources[index],"ticks":{
              placement: 'Before',
              largeStep:_vm.computeLargeStep(index),
              smallStep: Math.floor(_vm.showMax(index) / 10) == 0
                  ? 1
                  : Math.floor(_vm.showMax(index) / 10),
              showSmallTicks: true,
            }},on:{"change":function($event){return _vm.getVal($event,index)}}})],1),_c('div',{staticClass:"progress my-3 col-md-4"},[_c('div',{staticClass:"progress-bar progress-bar-striped progress-bar-animated",style:({
              width:
                _vm.showPercent(
                  typeof _vm.usedResources[index] == 'object'
                    ? _vm.usedResources[index].connections
                    : _vm.usedResources[index],
                  typeof _vm.usage[index] == 'object'
                    ? _vm.usage[index].connections
                    : _vm.usage[index]
                ) + '%',
            }),attrs:{"role":"progressbar","aria-valuenow":typeof _vm.usedResources[index] == 'object'
                ? _vm.usedResources[index].connections
                : _vm.usedResources[index],"aria-valuemin":"0","aria-valuemax":_vm.showMax(index)}},[_vm._v(" "+_vm._s(_vm.showPercent( typeof _vm.usedResources[index] == "object" ? _vm.usedResources[index].connections : _vm.usedResources[index], typeof _vm.usage[index] == "object" ? _vm.usage[index].connections : _vm.usage[index] ) + "%")+" ")]),_c('div',{staticClass:"\n              progress-bar progress-bar-striped progress-bar-animated\n              bg-secondary\n            ",style:({
              width:
                _vm.showRemains(
                  typeof _vm.usedResources[index] == 'object'
                    ? _vm.usedResources[index].connections
                    : _vm.usedResources[index],
                  typeof _vm.usage[index] == 'object'
                    ? _vm.usage[index].connections
                    : _vm.usage[index]
                ) + '%',
            }),attrs:{"role":"progressbar","aria-valuenow":"30","aria-valuemin":"0","aria-valuemax":_vm.showMax(index)}},[_vm._v(" "+_vm._s(_vm.showRemains( typeof _vm.usedResources[index] == "object" ? _vm.usedResources[index].connections : _vm.usedResources[index], typeof _vm.usage[index] == "object" ? _vm.usage[index].connections : _vm.usage[index] ) + "%")+" ")])])]):_vm._e()])}),0):_vm._e(),(
      Object.keys(_vm.holeUsage).length <= 0 &&
      Object.keys(_vm.usedResources).length <= 0 &&
      Object.keys(_vm.usage).length <= 0
    )?_c('div',{staticClass:"p-5 text-center"},[_vm._v(" "+_vm._s(_vm.translation.licenceLoader[_vm.lang])+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }