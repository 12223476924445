<template>
  <div
    :dir="dict.dir[lang]"
    class="container-fluid p-0 bg-none"
    :class="lang === 'per' ? 'text-end' : 'text-start'"
    style="min-height: calc(100vh - 60px)"
  >
    <div class="p-3 m-0 bg-page-title d-flex">
      <div
        :class="
          dict.dir[lang] === 'rtl'
            ? 'page-title-icon-rtl'
            : 'page-title-icon-ltr'
        "
      >
        <img src="@/assets/icons/eve.png" alt="dashboard" style="width: 60px" />
      </div>

      <div class="d-flex align-items-center">
        <h5 class="mb-0">{{ dict.title[lang] }}</h5>
      </div>
    </div>

    <div
      class="row g-0 p-2"
      :class="lang === 'per' ? 'text-end' : 'text-start'"
      style="min-height: calc(100vh - 152px)"
    >
      <div class="col-lg-12 h-100">
        <div
          class="card h-100"
          style="
            background-color: rgba(255, 255, 255, 0.75) !important;
            min-height: calc(100vh - 168px);
          "
        >
          <div class="p-3">
            <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
              <li
                v-for="(item, index) in tabs"
                class="mga-tab-nav-item"
                @click="activeTab = item.name"
                :key="index"
              >
                <a
                  class="mga-tab-nav-link"
                  :class="{ active: activeTab === item.name }"
                  :id="item.name"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="tab1"
                  aria-selected="true"
                >
                  {{ dict[item.name][lang] }}
                </a>
              </li>
            </ul>
          </div>
          <div class="card-body h-100" style="overflow: hidden">
            <transition name="slide-fade" mode="out-in">
              <div class="tab-content h-100" :key="activeTab">
                <div v-if="activeTab === 'tab1'" class="h-100">
                  <b-container
                    fluid
                    class="yekan"
                    style="padding: 0 !important"
                  >
                    <ejs-grid
                      ref="grid_keyClock"
                      :width="'auto'"
                      :locale="lang"
                      :toolbar="['Search']"
                      :enableRtl="lang == 'per'"
                      :allowSorting="true"
                      :allowFiltering="true"
                      :editSettings="curTableOptions.editSettings"
                      :allowPaging="true"
                      :enableAdaptiveUI="true"
                      :pageSettings="{
                        pageSize: 5,
                        pageSizes: [5, 10, 20, 50],
                      }"
                      :selectionSettings="curTableOptions.selectionOptions"
                      :dataSource="curTableOptions.items"
                    >
                      <e-columns>
                        <e-column
                          v-for="(item, index) in curTableOptions.fields"
                          :key="index"
                          :width="200"
                          :field="item.key"
                          :headerText="item[lang]"
                          :template="item.hasTemplate ? item.key : null"
                        ></e-column>
                      </e-columns>
                      <template v-slot:active="{ data }">
                        {{ getIrDate(data.active.timestamp, lang) }}
                      </template>

                      <template v-slot:acknowledgment="{ data }">
                        <div
                          class="
                            d-flex
                            gap-4
                            align-items-center
                            justify-content-center
                          "
                        >
                          <button
                            v-permission="'event_acknowledge'"
                            v-if="!data.acknowledgment"
                            size="sm"
                            @click="acknowledge(data)"
                            class="btn btn-success"
                            style="width: 80%"
                            type="button"
                          >
                            {{ dict.acknowledge[lang] }}
                          </button>
                          <div v-else>
                            {{
                              getIrDate(
                                data.acknowledgment.acknowledgedAt,
                                lang
                              )
                            }}
                          </div>
                        </div>
                      </template>

                      <template v-slot:inactive="{ data }">
                        <div
                          class="
                            d-flex
                            gap-4
                            align-items-center
                            justify-content-center
                          "
                        >
                          <span
                            v-if="!data.inactive"
                            class="
                              badge
                              d-flex
                              align-items-center
                              rounded-pill
                              p-2
                              bg-danger
                              text-light
                              h-100
                            "
                            style="width: 80%"
                          >
                            <font-awesome-icon
                              icon="fas fa-circle-exclamation"
                              size="2x"
                            />
                            <div class="w-100">
                              {{ dict.active[lang] }}
                            </div>
                          </span>
                          <div v-else>
                            {{ getIrDate(data.inactive.timestamp, lang) }}
                          </div>
                        </div>
                      </template>
                    </ejs-grid>
                  </b-container>
                </div>
                <div
                  v-permission="'event_archive_list'"
                  v-if="activeTab === 'tab2'"
                  class="h-100"
                >
                  <b-container
                    fluid
                    class="yekan"
                    style="padding: 0 !important"
                  >
                    <ejs-grid
                      ref="arcTable"
                      :width="'auto'"
                      :toolbarClick="toolbarClick"
                      :toolbar="generateToolbar"
                      :locale="lang"
                      :enableRtl="lang == 'per'"
                      :allowSorting="true"
                      :allowFiltering="true"
                      :editSettings="curTableOptions.editSettings"
                      :allowPaging="false"
                      :enableAdaptiveUI="true"
                      :pageSettings="{
                        pageSize: 5,
                        pageSizes: [5, 10, 20, 50],
                      }"
                      :selectionSettings="curTableOptions.selectionOptions"
                      :dataSource="arcTableOptions.items"
                    >
                      <e-columns>
                        <e-column
                          v-for="(item, index) in arcTableOptions.fields"
                          :key="index"
                          :width="200"
                          :field="item.key"
                          :headerText="item[lang]"
                          :template="item.hasTemplate ? item.key : null"
                        ></e-column>
                      </e-columns>

                      <template v-slot:activeTimestamp="{ data }">
                        {{ getIrDate(data.activeTimestamp, lang) }}
                      </template>

                      <template v-slot:acknowledgedAt="{ data }">
                        {{ getIrDate(data.acknowledgedAt, lang) }}
                      </template>

                      <template v-slot:inactiveTimestamp="{ data }">
                        {{ getIrDate(data.inactiveTimestamp, lang) }}
                      </template>
                      <template v-slot:modify="{ data }">
                        <div
                          style="display: flex; justify-content: flex-end"
                          class="gap-4"
                        >
                          <!-- <b-button pill size="sm" variant="outline-success" @click="editAccount(row.index,$event)" class="mr-2" style="outline: none !important; border: none; box-shadow: none;">
                            <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                          </b-button> -->
                          <b-button
                            pill
                            size="sm"
                            variant="outline-danger"
                            @click="removeAccount(data.index, $event)"
                            class="mr-2"
                            style="
                              margin-inline: 10px;
                              outline: none !important;
                              border: none;
                              box-shadow: none;
                            "
                          >
                            <b-icon
                              icon="trash-fill"
                              aria-hidden="true"
                            ></b-icon>
                          </b-button>
                        </div>
                      </template>
                    </ejs-grid>
                    <ejs-pager
                      :pageSize="perPage"
                      :pageSizes="[5, 10, 20, 30]"
                      :locale="lang"
                      :enableRtl="lang == 'per'"
                      :totalRecordsCount="pages"
                      v-model="currentPage"
                      :currentPage="currentPage"
                      @click="getVal($event)"
                    ></ejs-pager>
                  </b-container>
                </div>
                <div v-if="activeTab == 'tab3'" class="h-100">
                  <b-container
                    fluid
                    class="yekan"
                    style="padding: 0 !important"
                  >
                    <ejs-grid
                      :width="'auto'"
                      :toolbar="toolbarEType"
                      :toolbarClick="toolbarClick"
                      :locale="lang"
                      :enableRtl="lang == 'per'"
                      :allowSorting="true"
                      :allowFiltering="true"
                      :editSettings="curTableOptions.editSettings"
                      :allowPaging="true"
                      :enableAdaptiveUI="true"
                      :pageSettings="{
                        pageSize: 5,
                        pageSizes: [5, 10, 20, 50],
                      }"
                      :selectionSettings="curTableOptions.selectionOptions"
                      :dataSource="eventList.items"
                    >
                      <e-columns>
                        <e-column
                          v-for="(item, index) in eventList.fields"
                          :key="index"
                          :width="150"
                          :field="item.key"
                          :headerText="item[lang]"
                          :template="item.hasTemplate ? item.key : null"
                        ></e-column>
                      </e-columns>
                      <template v-slot:modify="{ data }">
                        <div class="d-flex justify-content-between col-3 gap-4">
                          <span
                            v-permission="'event_type_edit'"
                            @click="editEventType(data)"
                            style="font-size: 17px"
                            class="e-icons e-edit-6"
                          ></span>
                          <span
                            @click="removeEventType(data._id)"
                            style="font-size: 17px"
                            v-permission="'event_type_remove'"
                            class="e-icons e-delete-1 text-danger"
                          ></span>
                        </div>
                      </template>
                    </ejs-grid>
                  </b-container>
                </div>
                <div v-if="activeTab === 'tab4'" class="h-100">
                  <b-container
                    fluid
                    class="yekan"
                    style="padding: 0 !important"
                  >
                    <ejs-grid
                      :width="'auto'"
                      :toolbar="toolbarERule"
                      :toolbarClick="toolbarClick"
                      :locale="lang"
                      :enableRtl="lang == 'per'"
                      :allowSorting="true"
                      :allowFiltering="true"
                      :editSettings="curTableOptions.editSettings"
                      :allowPaging="true"
                      :enableAdaptiveUI="true"
                      :pageSettings="{
                        pageSize: 5,
                        pageSizes: [5, 10, 20, 50],
                      }"
                      :selectionSettings="curTableOptions.selectionOptions"
                      :dataSource="eventRules.items"
                    >
                      <e-columns>
                        <e-column
                          v-for="(item, index) in eventRules.fields"
                          :key="index"
                          :width="150"
                          :field="item.key"
                          :headerText="item[lang]"
                          :template="item.hasTemplate ? item.key : null"
                        ></e-column>
                      </e-columns>
                      <template v-slot:modify="{ data }">
                        <div class="d-flex justify-content-between col-3 gap-4">
                          <span
                            @click="editEventRule(data)"
                            style="font-size: 17px"
                            class="e-icons e-edit-6"
                          ></span>
                          <span
                            @click="removeEventRule(data._id)"
                            style="font-size: 17px"
                            class="e-icons e-delete-1 text-danger"
                          ></span>
                        </div>
                      </template>
                    </ejs-grid>
                  </b-container>
                </div>
                <!--                <div v-if="activeTab === 'tab5'" class="h-100">-->
                <!--                  <div class="row">-->
                <!--                    <div class="col-md-6 col-12 my-2">-->
                <!--                      <ejs-dropdownlist-->
                <!--                        floatLabelType="Auto"-->
                <!--                        :dataSource="booleanList"-->
                <!--                        :value="eventSetting.clean"-->
                <!--                        :fields="{ text: lang, value: 'value' }"-->
                <!--                        :placeholder="dict.cleaner[lang]"-->
                <!--                        :required="true"-->
                <!--                        :enableRtl="lang === 'per'"-->
                <!--                        @change="-->
                <!--                          (val) => {-->
                <!--                            eventSetting.clean = val.value;-->
                <!--                          }-->
                <!--                        "-->
                <!--                      ></ejs-dropdownlist>-->
                <!--                    </div>-->
                <!--                    <div class="col-md-6 col-12 my-2">-->
                <!--                      <ejs-textbox-->
                <!--                        floatLabelType="Auto"-->
                <!--                        :value="eventSetting.speed"-->
                <!--                        :placeholder="dict.speed[lang]"-->
                <!--                        :required="true"-->
                <!--                        type="number"-->
                <!--                        :enableRtl="lang === 'per'"-->
                <!--                        @change="-->
                <!--                          (val) => {-->
                <!--                            eventSetting.speed = val.value;-->
                <!--                          }-->
                <!--                        "-->
                <!--                      ></ejs-textbox>-->
                <!--                    </div>-->
                <!--                    <div class="col-md-6 col-12 my-2">-->
                <!--                      <ejs-textbox-->
                <!--                        floatLabelType="Auto"-->
                <!--                        :value="eventSetting.duration"-->
                <!--                        :placeholder="dict.duration[lang]"-->
                <!--                        :required="true"-->
                <!--                        type="number"-->
                <!--                        :enableRtl="lang === 'per'"-->
                <!--                        @change="-->
                <!--                          (val) => {-->
                <!--                            eventSetting.duration = val.value;-->
                <!--                          }-->
                <!--                        "-->
                <!--                      ></ejs-textbox>-->
                <!--                    </div>-->
                <!--                    <div class="col-md-6 col-12 my-2">-->
                <!--                      <ejs-textbox-->
                <!--                        floatLabelType="Auto"-->
                <!--                        :value="eventSetting.max"-->
                <!--                        :placeholder="dict.max[lang]"-->
                <!--                        :required="true"-->
                <!--                        type="number"-->
                <!--                        :enableRtl="lang === 'per'"-->
                <!--                        @change="-->
                <!--                          (val) => {-->
                <!--                            eventSetting.max = val.value;-->
                <!--                          }-->
                <!--                        "-->
                <!--                      ></ejs-textbox>-->
                <!--                    </div>-->
                <!--                    -->
                <!--                    <div class="col-md-6 col-12 my-2">-->
                <!--                      <ejs-dropdownlist-->
                <!--                        floatLabelType="Auto"-->
                <!--                        :dataSource="positionsList"-->
                <!--                        :value="eventSetting.position"-->
                <!--                        :fields="{ text: lang, value: 'value' }"-->
                <!--                        :placeholder="dict.position[lang]"-->
                <!--                        :required="true"-->
                <!--                        :enableRtl="lang === 'per'"-->
                <!--                        @change="-->
                <!--                          (val) => {-->
                <!--                            eventSetting.position = val.value;-->
                <!--                          }-->
                <!--                        "-->
                <!--                      ></ejs-dropdownlist>-->
                <!--                    </div>-->
                <!--                    -->
                <!--                  </div>-->
                <!--                  <b-button @click="storeSetting" variant="primary"-->
                <!--                  >{{ dict.saveSetting[lang] }}-->
                <!--                  </b-button>-->
                <!--                </div>-->
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="eventAdder_modal"
      size="lg"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      hide-header-close
      no-close-on-backdrop
      :dir="dict.dir[lang]"
      content-class="shadow"
      @cancel="resetModal"
      @ok="handleOk"
      :title="dict[modalMode][lang]"
      @shown="initModal"
      :cancel-title="dict.cancel[lang]"
      :ok-title="dict.ok[lang]"
    >
      <div id="eventType_Section">
        <div class="my-4">
          <div
            :dir="dict.dir[lang]"
            class="row w-50 mx-auto border border-md"
            :style="{
              'border-radius':
                lang == 'eng' ? '15% 0% 0% 15%' : '0% 15% 15% 0%',
            }"
          >
            <div
              class="col-2 p-2"
              :style="{
                'border-radius':
                  lang == 'eng' ? '15% 0% 0% 15%' : '0% 15% 15% 0%',
                background: notificationColor,
              }"
            >
              <i class="mx-auto">
                <font-awesome-icon
                  v-if="eventType.details.type === 'warning'"
                  class="text-white"
                  size="3x"
                  icon="fas fa-exclamation-triangle"
                />
                <font-awesome-icon
                  v-else-if="eventType.details.type === 'alarm'"
                  class="text-white"
                  size="3x"
                  icon="fas fa-exclamation-circle"
                />
                <font-awesome-icon
                  v-else-if="eventType.details.type === 'notice'"
                  class="text-white"
                  size="3x"
                  icon="fas fa-check-circle"
                />
                <font-awesome-icon
                  v-else-if="eventType.details.type === 'info'"
                  class="text-white"
                  size="3x"
                  icon="fas fa-circle-exclamation"
                />
              </i>
            </div>
            <div
              class="col-10 d-flex justify-content-between py-1"
              :style="{
                'border-radius':
                  lang == 'eng' ? '15% 0% 0% 15%' : '0% 15% 15% 0%',
              }"
            >
              <div>
                <h6>{{ lang == "eng" ? "title" : "عنوان" }}</h6>
                <hr class="py-0 my-0 mb-2" />
                <span>{{
                    lang == "eng"
                      ? "this is alaram message.."
                      : "این یک هشدار است..."
                  }}</span>
              </div>
              <i class="notification-close">
                <font-awesome-icon icon="fas fa-xmark" />
              </i>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-12 my-2">
            <ejs-textbox
              floatLabelType="Auto"
              :value="eventType.name"
              :placeholder="dict.alertName[lang]"
              :required="true"
              :enableRtl="lang === 'per'"
              @change="
                (val) => {
                  eventType.name = val.value;
                }
              "
            ></ejs-textbox>
          </div>
          <div class="col-md-6 col-12 my-2">
            <ejs-multiselect
              floatLabelType="Auto"
              :dataSource="notifLists"
              :value="eventType.details.remindType"
              :fields="{ text: lang, value: 'value' }"
              :placeholder="dict.showNotif[lang]"
              :required="true"
              :enableRtl="lang === 'per'"
              @change="
                (val) => {
                  eventType.details.remindType = val.value;
                }
              "
            ></ejs-multiselect>
          </div>
          <div class="col-md-6 col-12 my-2">
            <ejs-dropdownlist
              floatLabelType="Auto"
              :dataSource="alarmTypeList"
              :value="eventType.details.type"
              :fields="{ text: lang, value: 'value' }"
              :placeholder="dict.colorType[lang]"
              :required="true"
              :enableRtl="lang === 'per'"
              @change="
                (val) => {
                  eventType.details.type = val.value;
                }
              "
            ></ejs-dropdownlist>
          </div>
          <div class="col-md-6 col-12 my-2">
            <ejs-dropdownlist
              floatLabelType="Auto"
              :dataSource="priorityList"
              :value="eventType.details.priority"
              :fields="{ text: lang, value: 'value' }"
              :placeholder="dict.priority[lang]"
              :required="true"
              :enableRtl="lang === 'per'"
              @change="(val) => {
                           eventType.details.priority = val.value;
                          }
                        "
            ></ejs-dropdownlist>
          </div>
          <!--          <div class="col-md-6 col-12 my-2">-->
          <!--            <ejs-dropdownlist-->
          <!--              floatLabelType="Auto"-->
          <!--              :dataSource="booleanList"-->
          <!--              :value="eventType.details.clean"-->
          <!--              :fields="{ text: lang, value: 'value' }"-->
          <!--              :placeholder="dict.cleaner[lang]"-->
          <!--              :required="true"-->
          <!--              :enableRtl="lang === 'per'"-->
          <!--              @change="(val) => {-->
          <!--                           eventType.details.clean = val.value;-->
          <!--                          }-->
          <!--                        "-->
          <!--            ></ejs-dropdownlist>-->
          <!--          </div>-->
          <!--          <div class="col-md-6 col-12 my-2">-->
          <!--            <ejs-textbox-->
          <!--              floatLabelType="Auto"-->
          <!--              :value="eventType.details.speed"-->
          <!--              :placeholder="dict.speed[lang]"-->
          <!--              :required="true"-->
          <!--              type="number"-->
          <!--              :enableRtl="lang === 'per'"-->
          <!--              @change="-->
          <!--                          (val) => {-->
          <!--                            eventType.details.speed = val.value;-->
          <!--                          }-->
          <!--                        "-->
          <!--            ></ejs-textbox>-->
          <!--          </div>-->
          <!--          <div class="col-md-6 col-12 my-2">-->
          <!--            <ejs-textbox-->
          <!--              floatLabelType="Auto"-->
          <!--              :value="eventType.details.duration"-->
          <!--              :placeholder="dict.duration[lang]"-->
          <!--              :required="true"-->
          <!--              type="number"-->
          <!--              :enableRtl="lang === 'per'"-->
          <!--              @change="-->
          <!--                          (val) => {-->
          <!--                            eventType.details.duration = val.value;-->
          <!--                          }-->
          <!--                        "-->
          <!--            ></ejs-textbox>-->
          <!--          </div>-->
          <!--          <div class="col-md-6 col-12 my-2">-->
          <!--            <ejs-textbox-->
          <!--              floatLabelType="Auto"-->
          <!--              :value="eventType.details.max"-->
          <!--              :placeholder="dict.max[lang]"-->
          <!--              :required="true"-->
          <!--              type="number"-->
          <!--              :enableRtl="lang === 'per'"-->
          <!--              @change="-->
          <!--                          (val) => {-->
          <!--                            eventType.details.max = val.value;-->
          <!--                          }-->
          <!--                        "-->
          <!--            ></ejs-textbox>-->
          <!--          </div>-->
          <!--          <div class="col-md-6 col-12 my-2">-->
          <!--            <ejs-dropdownlist-->
          <!--              floatLabelType="Auto"-->
          <!--              :dataSource="booleanList"-->
          <!--              :value="eventType.details.clean"-->
          <!--              :fields="{ text: lang, value: 'value' }"-->
          <!--              :placeholder="dict.cleaner[lang]"-->
          <!--              :required="true"-->
          <!--              :enableRtl="lang === 'per'"-->
          <!--              @change="-->
          <!--                (val) => {-->
          <!--                  eventType.details.clean = val.value;-->
          <!--                }-->
          <!--              "-->
          <!--            ></ejs-dropdownlist>-->
          <!--          </div>-->
          <!--          <div class="col-md-6 col-12 my-2">-->
          <!--            <ejs-dropdownlist-->
          <!--              floatLabelType="Auto"-->
          <!--              :dataSource="positionsList"-->
          <!--              :value="eventType.details.position"-->
          <!--              :fields="{ text: lang, value: 'value' }"-->
          <!--              :placeholder="dict.position[lang]"-->
          <!--              :required="true"-->
          <!--              :enableRtl="lang === 'per'"-->
          <!--              @change="-->
          <!--                (val) => {-->
          <!--                  eventType.details.position = val.value;-->
          <!--                }-->
          <!--              "-->
          <!--            ></ejs-dropdownlist>-->
          <!--          </div>-->
          <!--          <div class="col-md-6 col-12 my-2">-->
          <!--            <ejs-textbox-->
          <!--              floatLabelType="Auto"-->
          <!--              :value="eventType.details.max"-->
          <!--              :placeholder="dict.max[lang]"-->
          <!--              :required="true"-->
          <!--              type="number"-->
          <!--              :enableRtl="lang === 'per'"-->
          <!--              @change="-->
          <!--                (val) => {-->
          <!--                  eventType.details.max = val.value;-->
          <!--                }-->
          <!--              "-->
          <!--            ></ejs-textbox>-->
          <!--          </div>-->
          <!--          <div class="col-md-6 col-12 my-2">-->
          <!--            <ejs-textbox-->
          <!--              floatLabelType="Auto"-->
          <!--              :value="eventType.details.speed"-->
          <!--              :placeholder="dict.speed[lang]"-->
          <!--              :required="true"-->
          <!--              type="number"-->
          <!--              :enableRtl="lang === 'per'"-->
          <!--              @change="-->
          <!--                (val) => {-->
          <!--                  eventType.details.speed = val.value;-->
          <!--                }-->
          <!--              "-->
          <!--            ></ejs-textbox>-->
          <!--          </div>-->
          <!--          <div class="col-md-6 col-12 my-2">-->
          <!--            <ejs-textbox-->
          <!--              floatLabelType="Auto"-->
          <!--              :value="eventType.details.duration"-->
          <!--              :placeholder="dict.duration[lang]"-->
          <!--              :required="true"-->
          <!--              type="number"-->
          <!--              :enableRtl="lang === 'per'"-->
          <!--              @change="-->
          <!--                (val) => {-->
          <!--                  eventType.details.duration = val.value;-->
          <!--                }-->
          <!--              "-->
          <!--            ></ejs-textbox>-->
          <!--          </div>-->
        </div>
      </div>
    </b-modal>

    <b-modal
      id="eventRules_Adder_modal"
      size="lg"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      hide-header-close
      no-close-on-backdrop
      :dir="dict.dir[lang]"
      content-class="shadow"
      @hidden="resetRuleModal"
      @cancel="resetRuleModal"
      @ok="handleEventRuleSubmit"
      :title="dict[modalMode][lang]"
      @shown="initialEventRule"
      :cancel-title="dict.cancel[lang]"
      :ok-title="dict.ok[lang]"
    >
      <div>
        <div class="row" id="event_rule_section">
          <div class="col-md-6 col-12 mt-3">
            <ejs-textbox
              :value="eventRule.name"
              :placeholder="dict.alertName[lang]"
              :required="true"
              :enableRtl="lang === 'per'"
              @change="
                (val) => {
                  eventRule.name = val.value;
                }
              "
            ></ejs-textbox>
          </div>
          <div class="col-md-6 col-12">
            <div class="d-flex align-items-center">
              <ejs-textbox
                :value="selectedData['name']"
                :placeholder="dict.pickData[lang]"
                :enableRtl="lang == 'per'"
                floatLabelType="Auto"
                :showClearButton="true"
                v-model="selectedData['name']"
              ></ejs-textbox>
              <b-button @click="pickData" class="btn-sm" variant="primary"
              ><span class="e-icons e-edit-6"></span>
              </b-button>
            </div>
          </div>
        </div>
        <alertEditorModal class="mt-3" ref="alert"></alertEditorModal>
      </div>
    </b-modal>
    <b-modal
      id="filterOptions"
      size="md"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      hide-header-close
      no-close-on-backdrop
      :dir="dict.dir[lang]"
      content-class="shadow"
      @hidden="resetFilter"
      @cancel="resetFilter"
      @ok="handleArchivedEvents(1, 5)"
      hide-header
      @shown="initialEventRule"
      :cancel-title="dict.cancel[lang]"
      :ok-title="dict.ok[lang]"
    >
      <div class="d-flex justify-content-between gap-3 my-3">
        <p>{{ dict.acknowledge[lang] }}</p>
        <ejs-datetimepicker
          :placeholder="dict.start[lang]"
          floatLabelType="Auto"
          v-model="filter.acknowledgedPeriod.start"
          :showClearButton="true"
          :enableRtl="lang == 'per'"
        ></ejs-datetimepicker>

        <ejs-datetimepicker
          :placeholder="dict.end[lang]"
          floatLabelType="Auto"
          v-model="filter.acknowledgedPeriod.end"
          :showClearButton="true"
          :enableRtl="lang == 'per'"
        ></ejs-datetimepicker>
      </div>
      <div class="d-flex justify-content-between gap-3 my-3">
        <p>{{ dict.active[lang] }}</p>
        <ejs-datetimepicker
          :placeholder="dict.start[lang]"
          floatLabelType="Auto"
          v-model="filter.activePeriod.start"
          :showClearButton="true"
          :enableRtl="lang == 'per'"
        ></ejs-datetimepicker>

        <ejs-datetimepicker
          :placeholder="dict.end[lang]"
          floatLabelType="Auto"
          v-model="filter.activePeriod.end"
          :showClearButton="true"
          :enableRtl="lang == 'per'"
        ></ejs-datetimepicker>
      </div>
      <div class="d-flex justify-content-between gap-3 my-3">
        <p>{{ dict.inactivedPeriod[lang] }}</p>
        <ejs-datetimepicker
          :placeholder="dict.start[lang]"
          floatLabelType="Auto"
          v-model="filter.inactivedPeriod.start"
          :showClearButton="true"
          :enableRtl="lang == 'per'"
        ></ejs-datetimepicker>

        <ejs-datetimepicker
          :placeholder="dict.end[lang]"
          floatLabelType="Auto"
          v-model="filter.inactivedPeriod.end"
          :showClearButton="true"
          :enableRtl="lang == 'per'"
        ></ejs-datetimepicker>
      </div>
      <div>
        <ejs-dropdownlist
          :dataSource="typeList"
          :value="filter.eventType"
          :fields="{ text: 'text', value: 'value' }"
          :placeholder="dict.typeAlert[lang]"
          :enableRtl="lang === 'per'"
          :required="true"
          @change="
            (val) => {
              filter.eventType = val.value;
            }
          "
        ></ejs-dropdownlist>
      </div>
    </b-modal>
    <dataPickerModal></dataPickerModal>
  </div>
</template>

<script>
import dataPickerModal from "@/components/setContModalDataPicker.vue";
import alertEditorModal from "@/components/dataManagerModalDataAlertEditor.vue";
import { FormValidator } from "@syncfusion/ej2-inputs";
import Vue from "vue";
import {
  Page,
  Sort,
  Toolbar,
  PdfExport,
  ExcelExport,
  Edit,
  Search
} from "@syncfusion/ej2-vue-grids";
import { mapGetters } from "vuex";
import { DialogUtility } from "@syncfusion/ej2-popups";

export default {
  components: { alertEditorModal, dataPickerModal },
  data() {
    return {
      typeList: [],
      perPage: 5,
      pages: 0,
      currentPage: 1,
      activeTab: "tab1",
      filter: {
        acknowledgedPeriod: {
          end: null,
          start: null
        },
        inactivedPeriod: {
          end: null,
          start: null
        },
        activePeriod: {
          end: null,
          start: null
        },
        eventType: null
      },
      tabs: [
        { name: "tab1", value: "tab1" },
        { name: "tab2", value: "tab2" },
        { name: "tab3", value: "tab3" },
        { name: "tab4", value: "tab4" }
        // { name: "tab5", value: "tab5" }
      ],
      curTableOptions: {
        items: [],
        fields: [
          {
            key: "active",
            per: "زمان فعال شدن",
            eng: "activation time",
            hasTemplate: true,
            sortDirection: "asc"
          },
          {
            key: "data.name",
            per: "مربوط به",
            eng: "Associated with",
            hasTemplate: false
          },
          { key: "message", per: "پیام", eng: "Message", hasTemplate: false },
          {
            key: "inactive",
            per: "زمان غیرفعال شدن",
            eng: "deactivation time",
            hasTemplate: true
          },
          {
            key: "acknowledgment",
            eng: "acknowledgment time",
            per: "زمان تایید",
            hasTemplate: true
          }
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "active",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: []
      },
      selectedData: {
        _id: "",
        name: ""
      },
      arcTableOptions: {
        items: [],
        fields: [
          {
            key: "activeTimestamp",
            eng: "activation time",
            per: "زمان فعال شدن",
            hasTemplate: true,
            sortDirection: "asc"
          },
          {
            key: "data.name",
            per: "مربوط به",
            eng: "Associated with",
            hasTemplate: false
          },
          { key: "message", per: "پیام", eng: "Message", hasTemplate: false },
          {
            key: "inactiveTimestamp",
            per: "زمان غیرفعال شدن",
            eng: "deactivation time",
            hasTemplate: true
          },
          {
            key: "acknowledgedAt",
            per: "زمان تایید",
            eng: "acknowledgment time",
            hasTemplate: true
          }
          // { key: "modify", label: "", hasTemplate: true }
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "active",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: []
      },
      eventList: {
        items: [],
        fields: [
          { key: "name", per: "نام", eng: "name", hasTemplate: false },
          { key: "modify", per: "", eng: "", hasTemplate: true }
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "active",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: []
      },
      eventRules: {
        items: [],
        fields: [
          { key: "name", per: "نام", eng: "name", hasTemplate: false },
          { key: "modify", eng: "", per: "", hasTemplate: true }
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "active",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: []
      },
      booleanList: [
        { per: "بسته شود", eng: "close it", value: true },
        { per: "بسته نشود", eng: "no close", value: false }
      ],
      priorityList: [
        { per: "خیلی کم", eng: "Very Low", value: 1 }, { per: "کم", eng: "Low", value: 2 }, {
          per: "متوسط",
          eng: "Medium",
          value: 3
        },
        { per: "زیاد", eng: "High", value: 4 },
        { per: "خیلی زیاد", eng: "Very High", value: 5 }
      ],
      dict: {
        refresh: { eng: "refresh datas", per: "به روزرسانی اطلاعات" },
        saveSetting: { eng: "submit", per: "ذخیره" },
        deleteConfirm: { eng: "Confirmation Delete", per: "تاییدیه حذف" },
        deleteConfirmContent: {
          eng: "Are you sure to remove the data ?",
          per: "آیا از حذف این رکورد اطمینان دارید ؟"
        },
        acknowledgedPeriod: {
          eng: "acknowledged Period",
          per: "بازه زمانی رویدادهای تایید شده"
        },
        inactivedPeriod: {
          eng: "inactived Period",
          per: "بازه زمانی رویدادهای غیرفعال شده"
        },
        activePeriod: {
          eng: "active Period",
          per: "بازه زمانی رویدادهای فعال شده"
        },
        typeAlert: { per: "نوع رویدادها", eng: "event types" },
        start: { per: "زمان شروع", eng: "start time" },
        end: { per: "زمان پایان", eng: "end time" },
        filter: { eng: "filter archives", per: "فیلتر کردن آرشیوها" },
        cancel: { eng: "Cancel", per: "لغو" },
        pickData: { per: "انتخاب داده", eng: "Pick Data" },
        newEventType: { eng: "New event type", per: "نوع رویداد جدید" },
        newEventRule: { eng: "New event rule", per: "رویداد جدید" },
        ok: { eng: "Ok", per: "قبول" },
        dir: { eng: "ltr", per: "rtl" },
        title: { eng: "event manager", per: "مدیریت رخدادها" },
        tab1: { eng: "current alarms", per: "خطاهای موجود" },
        tab2: { eng: "archived alarms", per: "خطاهای آرشیو شده" },
        tab3: { eng: "event types", per: "انواع رخدادها" },
        tab4: { eng: "event rules", per: "قوانین رخدادها" },
        tab5: { eng: "event settings", per: "تنظیمات رویدادها" },
        active: { eng: "active", per: "فعال" },
        acknowledge: { eng: "acknowledge", per: "تایید" },

        th_active: { eng: "activation time", per: "زمان فعال شدن" },
        th_source: { eng: "Associated with", per: "مربوط به" },
        th_message: { eng: "Message", per: "پیام" },
        th_deactive: { eng: "deactivation time", per: "زمان غیرفعال شدن" },
        th_acked: { eng: "acknowledgment time", per: "زمان تایید" },
        th_modify: { eng: "", per: "" },
        search: { eng: "Type to Search", per: "برای جستجو تایپ کنید" },
        clear: { eng: "clear", per: "حذف" },

        po_5: { eng: "5 rows", per: "5 ردیف" },
        po_10: { eng: "10 rows", per: "10 ردیف" },
        po_15: { eng: "15 rows", per: "15 ردیف" },
        po_100: { eng: "100 rows", per: "ردیف های بیشتر" },

        wbname: { eng: "workbench name", per: "نام میزکار" },
        wbowner: { eng: "workbench owner", per: "مالک میزکار" },
        wbresources: { eng: "workbench resources", per: "منابع میزکار" },

        regmsg: { eng: "Don't have an account?", per: "حساب کاربری ندارید؟" },
        register: { eng: "Register now", per: "ثبت نام" },
        errCaptcha: {
          eng: "invalid captcha",
          per: "کد امنیتی وارد شده اشتباه می باشد"
        },
        errUserPass1: {
          eng: "invalid username or password",
          per: "نام کاربری یا کلمه عبور اشتباه است"
        },
        errUserPass2: {
          eng: "check your keyboard language",
          per: "• وضعيت زبان(En/FA)سيستم خود را كنترل كنيد"
        },
        errUserPass3: {
          eng: "the password is caps sensitive",
          per: "• پسورد به حروف بزرگ و كوچك حساس است"
        },
        New: { per: "افزودن آیتم", eng: "Add Item" },
        Edit: { per: "ویرایش آیتم", eng: "Edit Item" },
        colorType: { per: "نوع آلارم", eng: "alarm type" },
        showNotif: { per: "نوع اعلان", eng: "notification type" },
        duration: { per: "مدت زمان نمایش اعلان (میلی ثانیه)", eng: "alert duration (ms)" },
        speed: { per: "سرعت نمایش اعلان", eng: "alert speed" },
        max: { per: "حداکثر تعداد نمایش اعلانات", eng: "max count event" },
        alertName: { per: "نام", eng: "name" },
        priority: { per: "سطح اولویت رویداد", eng: "event's priority" },
        cleaner: { per: "بستن اعلان با کلیک روی آن", eng: "close alarm when clicked on" },
        position: { per: "محل نمایش اعلان در صفحه", eng: "position of alarm" }
      },
      modalMode: "New",
      alarmTypeList: [
        { per: "یک مورد انتخاب کنید", eng: "Select One", value: "null" },
        { per: "هشدار", eng: "warning", value: "warning" },
        { per: "توجه", eng: "notice", value: "notice" },
        { per: "اطلاعیه", eng: "info", value: "info" },
        { per: "رخداد", eng: "alarm", value: "alarm" }
      ],
      positionsList: [
        { per: "یک مورد انتخاب کنید", eng: "Select One", value: "null" },
        { per: "پایین سمت راست", eng: "bottom right", value: "bottom right" },
        { per: "پایین سمت چپ", eng: "bottom left", value: "bottom left" },
        { per: "بالا سمت راست", eng: "top right", value: "top right" },
        { per: "بالا سمت چپ", eng: "top left", value: "top left" },
        { per: "بالا وسط صفحه", eng: "top center", value: "top center" },
        { per: "پایین وسط صفحه", eng: "bottom center", value: "top center" }
      ],
      notifLists: [
        { per: "یک مورد انتخاب کنید", eng: "Select One", value: "null" },
        {
          per: "نمایش در اعلانات",
          eng: "push notification",
          value: "notification"
        },
        { per: "ارسال پیامک", eng: "send sms", value: "sms" },
        { per: "ارسال ایمیل", eng: "send email", value: "email" },
        {
          per: "فقط نمایش در لیست رویدادها",
          eng: "only show in event manager",
          value: "event_manager"
        }
      ],
      eventRule: {
        data: null,
        name: "",
        dataType: "",
        details: {}
      },
      eventType: {
        name: null,
        details: {
          priority: null,
          type: null,
          duration: null,
          speed: null,
          max: 2,
          clean: false,
          position: "bottom right",
          remindType: []
        }
      },
      validator: null,
      renderItems: false,
      renderItem: 0,
      eventSetting: {
        type: null,
        duration: null,
        speed: null,
        max: 2,
        clean: false,
        position: "bottom right"
      }
    };
  },
  watch: {
    newEvents(v) {
      this.currentPage = 1;
      this.perPage = 5;
      this.handleArchivedEvents(1, 5);
    },
    activeTab(newValue, oldValue) {
      if (newValue === "tab1") this.handleCurrentEvents();
      else if (newValue === "tab2") {
        this.currentPage = 1;
        this.perPage = 5;
        this.handleArchivedEvents(1, 5);
      }
      // this.getContConf()
    },
    lang(newValue) {
      this.arcTableOptions.fields.forEach((field) => {
        field.label = this.dict["th_" + field.key][newValue];
      });
      this.curTableOptions.fields.forEach((field) => {
        field.label = this.dict["th_" + field.key][newValue];
      });
      this.arcTableOptions.pageOptions.forEach((field) => {
        field.text = this.dict["po_" + field.value][newValue];
      });
      this.curTableOptions.pageOptions.forEach((field) => {
        field.text = this.dict["po_" + field.value][newValue];
      });
    },
    child2parent(newValue, oldValue) {
      if (newValue.task === "pickData") {
        this.selectedData = newValue.item;
        this.eventRule.data = newValue.item._id;
        this.eventRule.dataType = newValue.item.dataType;
        this.$store.dispatch("chpge/parent2child", {
          task: "editAlert",
          type: "New",
          item: { dataType: this.eventRule.dataType }
        });
        this.$root.$emit("bv::show::modal", "modal-alert-editor");
      }
      if (newValue.task === "editAlert") {
        this.eventRule.details = newValue.item;
      }
    },
    workbench() {
      this.handleCurrentEvents();
      this.getAllEventTypes();
      this.getAllEventRules();
      this.$store.dispatch("data/sendDataForSSE", { id: [] });
    }
  },
  computed: {
    ...mapGetters(["screenWidth", "screenHeight"]),
    newEvents() {
      return this.$store.state.panel.event;
    },
    generateToolbar() {
      return [
        "Search",
        {
          text: this.dict.refresh[this.lang],
          id: "refresh_table_arc",
          prefixIcon: "e-icons e-refresh-2"
        }
        // {
        //   text: this.dict.filter[this.lang],
        //   id: "filtering",
        //   prefixIcon: "e-icons e-filter-3"
        // }
      ];
    },
    notificationColor: function() {
      var color = "#000000";
      if (this.eventType.details.type === "notice") color = "#42A85F";
      if (this.eventType.details.type === "warning") color = "#f48a06";
      if (this.eventType.details.type === "alarm") color = "#B82E24";
      if (this.eventType.details.type === "info") color = "#0d35d0";
      return color;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    workbench() {
      return this.$store.state.data.workbench.activeWorkbench;
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    userPermission() {
      return this.$store.state.data.user.permissions;
    },
    toolbarEType() {
      if (this.userPermission.includes("event_type_add")) {
        return [
          "Search",
          {
            text: this.dict.newEventType[this.lang],
            id: "new_type",
            prefixIcon: "e-icons e-add"
          },
          {
            text: this.dict.refresh[this.lang],
            id: "refresh_table",
            prefixIcon: "e-icons e-refresh-2"
          }

        ];
      } else {
        return ["Search",
          {
            text: this.dict.refresh[this.lang],
            id: "refresh_table",
            prefixIcon: "e-icons e-refresh-2"
          }
        ];
      }
    },
    toolbarERule() {
      if (this.userPermission.includes("event_rule_add")) {
        return [
          "Search",
          {
            text: this.dict.newEventRule[this.lang],
            id: "new_rule",
            prefixIcon: "e-icons e-add"
          },
          {
            text: this.dict.refresh[this.lang],
            id: "refresh_table",
            prefixIcon: "e-icons e-refresh-2"
          }
        ];
      } else {
        return ["Search",
          {
            text: this.dict.refresh[this.lang],
            id: "refresh_table",
            prefixIcon: "e-icons e-refresh-2"
          }
        ];
      }
    }
  },
  mounted() {
    this.handleCurrentEvents();
    this.getAllEventTypes();
    this.getAllEventRules();
    this.$store.dispatch("data/sendDataForSSE", { id: [] });
  },
  methods: {
    storeSetting() {
      this.$store.dispatch("data/saveEventSetting", { details: this.eventSetting });
    },
    getVal(e) {
      if (e.newProp["pageSize"]) this.perPage = e.newProp.pageSize;

      this.currentPage = e.currentPage;
      this.handleArchivedEvents(e.currentPage, this.perPage);
    },
    toolbarClick(args) {
      if (args.item.id == "new_type") {
        this.addNewEvent();
      } else if (args.item.id == "new_rule") {
        this.addNewEventRule();
      } else if (args.item.id == "filtering") {
        this.$bvModal.show("filterOptions");
      } else if (args.item.id == "refresh_table") {
        this.getAllEventRules();
        this.getAllEventTypes();
      } else if (args.item.id == "refresh_table_arc") {
        this.currentPage = 1;
        this.perPage = 5;
        this.handleArchivedEvents(1, 5);
      }
    },
    editEventType(item) {
      this.eventType = item;
      this.modalMode = "Edit";
      this.$bvModal.show("eventAdder_modal");
    },
    editEventRule(item) {
      this.eventRule = item;
      this.selectedData = item.selectedData;
      this.modalMode = "Edit";
      this.$bvModal.show("eventRules_Adder_modal");
    },
    removeEventType(id) {
      const DialogObj = DialogUtility.confirm({
        title: this.dict.deleteConfirm[this.lang],
        locale: this.lang,
        position: "center center",
        Enabletrl: this.lang == "per" ? true : false,
        content: this.dict.deleteConfirmContent[this.lang],
        okButton: {
          text: this.dict.ok[this.lang],
          click: () => {
            this.$store
              .dispatch("data/removeEventTypes", { eventType: id })
              .then(() => {
                this.getAllEventTypes();
              });
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }
    },
    removeEventRule(id) {
      const DialogObj = DialogUtility.confirm({
        title: this.dict.deleteConfirm[this.lang],
        locale: this.lang,
        position: "center center",
        Enabletrl: this.lang == "per" ? true : false,
        content: this.dict.deleteConfirmContent[this.lang],
        okButton: {
          text: this.dict.ok[this.lang],
          click: () => {
            this.$store
              .dispatch("data/removeEventRule", { eventRule: id })
              .then(() => {
                this.getAllEventRules();
              });
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }
    },
    addNewEvent() {
      this.modalMode = "New";
      this.$bvModal.show("eventAdder_modal");
    },
    addNewEventRule() {
      this.modalMode = "New";
      this.$bvModal.show("eventRules_Adder_modal");
    },
    resetModal() {
      this.eventType = {
        name: null,
        details: {
          priority: null,
          type: null,
          duration: null,
          speed: "",
          clean: false,
          remindType: []
        }
      };
      this.validator = null;
    },
    resetRuleModal() {
      this.eventRule = {
        data: null,
        name: "",
        dataType: "",
        details: {}
      };
      this.selectedData = { name: "", _id: "" };
      this.renderItems = false;
    },
    handleEventRuleSubmit($bv) {
      $bv.preventDefault();
      const isValidParams = new FormValidator("#event_rule_section").validate();
      const isValid = this.$refs.alert.handleSubmit();
      if (isValid && isValidParams) {
        if (this.modalMode == "Edit") {
          setTimeout(() => {
            this.updateEventRules();
          }, 100);
        } else {
          setTimeout(() => {
            this.$store
              .dispatch("data/createNewEventRule", {
                data: this.eventRule.data,
                name: this.eventRule.name,
                dataType: this.eventRule.dataType,
                details: this.eventRule.details,
                selectedData: this.selectedData
              })
              .then(() => {
                this.getAllEventRules();
                this.$nextTick(() => {
                  this.$bvModal.hide("eventRules_Adder_modal");
                });
              })
              .catch((error) => {
                // alert(
                //   (error.response &&
                //     error.response.data &&
                //     error.response.data.message) ||
                //   error.message ||
                //   error.toString()
                // );
              });
          }, 100);
        }
      }
    },
    handleOk($bv) {
      $bv.preventDefault();
      let isValid = new FormValidator("#eventType_Section").validate();
      if (isValid) {
        if (this.modalMode == "Edit") {
          this.updateEventsType();
        } else {
          this.$store
            .dispatch("data/createNewEventType", this.eventType)
            .then((res) => {
              this.getAllEventTypes();
              this.$nextTick(() => {
                this.$bvModal.hide("eventAdder_modal");
              });
            })
            .catch((error) => {
              alert(
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString()
              );
            });
        }
      }
    },
    initModal() {
      if (this.modalMode == "New") {
        this.eventType = {
          name: null,
          details: {
            type: null,
            duration: null,
            priority: null,
            speed: "",
            clean: false,
            remindType: []
          }
        };
      }
    },
    initialEventRule() {
      this.renderItems = true;
      if (this.modalMode == "Edit") {
        this.$store.dispatch("chpge/parent2child", {
          task: "editAlert",
          type: "Edit",
          item: this.eventRule
        });
      } else {
        this.eventRule = {
          data: null,
          name: "",
          dataType: "",
          details: {}
        };
      }
    },
    pickData() {
      let filterParents = [];
      this.$store.dispatch("chpge/parent2child", {
        task: "pickData",
        from: "getPostMap",
        filter: {
          dataType: ["!complex"],
          usedBy: [],
          parents: filterParents,
          archive: null,
          withChild: false,
          page: 1,
          perPage: 1000
        }
      });
      this.$root.$emit("bv::show::modal", "modal-pick-data");
    },
    getIrDate(idate, lang) {
      const date = new Date(idate);
      if (lang === "eng") return date;
      else if (lang === "per") {
        var time = this.formatDateTime(date, {
          hour: "numeric",
          minute: "numeric",
          second: "numeric"
        });
        var year = this.formatDateTime(date, { year: "numeric" });
        var month = this.formatDateTime(date, { month: "long" });
        var wday = this.formatDateTime(date, { weekday: "long" });
        var day = this.formatDateTime(date, { day: "numeric" });
        var str = `${wday} ${day} ${month} ${year} ساعت  ${time}`;
        return str;
      }
    },
    formatDateTime(date, option) {
      return new Intl.DateTimeFormat("fa-IR", option).format(date);
    },
    curTableOnFiltered(filteredItems) {
      this.curTableOptions.totalRows = filteredItems.length;
      this.curTableOptions.currentPage = 1;
    },
    arcTableOnFiltered(filteredItems) {
      this.arcTableOptions.totalRows = filteredItems.length;
      this.arcTableOptions.currentPage = 1;
    },
    handleCurrentEvents() {
      this.$store.dispatch("data/eventGetCur").then(
        (data) => {
          this.curTableOptions.items = data.data;
          this.curTableOptions.items.map((item) => {
            item._rowVariant = this.getVariant(item.type);
            return item;
          });
          this.curTableOptions.totalRows = this.curTableOptions.items.length;
          this.$refs.curTable.refresh();
          // console.log(JSON.stringify(this.accounts))
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    resetFilter() {
      this.filter = {
        startDate: null,
        endDate: null,
        eventType: null
      };
    },
    handleArchivedEvents(page, perPage) {
      this.$store
        .dispatch("data/eventGetArc", {
          page: page,
          perPage: perPage,
          endDate: this.filter.endDate != null ? this.filter.endDate : null,
          startDate:
            this.filter.startDate != null ? this.filter.startDate : null,
          eventType:
            this.filter.eventType != null ? this.filter.eventType : null
        })
        .then(
          (data) => {
            this.arcTableOptions.items = data.data.newmatches;
            this.pages = data.data.numberOfalldatas;
            this.arcTableOptions.items.map((item) => {
              item._rowVariant = this.getVariant(item.type);
              return item;
            });
            this.arcTableOptions.totalRows = this.arcTableOptions.items.length;
            this.$refs.arcTable.refresh();
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            // this.successful = false;
          }
        );
    },
    getVariant(status) {
      switch (status) {
        case "notice":
          return "t-notice";
        case "alarm":
          return "t-alarm";
        case "warning":
          return "t-warning";
      }
    },
    acknowledge(item) {
      this.$store
        .dispatch("data/eventAcknowledge", {
          eventId: item._id,
          info: { userId: this.$store.state.data.user.id }
        })
        .then(
          (data) => {
            this.handleCurrentEvents();
            // console.log(data.data)
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            // this.successful = false;
          }
        );
    },
    getAllEventTypes() {
      this.$store.dispatch("data/getAllEventTypes").then((res) => {
        this.typeList = [];
        this.eventList.items = res;
        this.eventList.items.forEach((item) => {
          this.typeList.push({ text: item.name, value: item._id });
        });
        this.eventList.totalRows = res.length;
      });
    },
    getAllEventRules() {
      this.$store.dispatch("data/getAllEventRules").then((res) => {
        this.eventRules.items = res;
        this.eventRules.totalRows = res.length;
      });
    },
    updateEventsType() {
      this.$store
        .dispatch("data/updateEventTypes", { updates: this.eventType })
        .then(() => {
          this.getAllEventTypes();
          this.$nextTick(() => {
            this.$bvModal.hide("eventAdder_modal");
          });
        })
        .catch((error) => {
          // alert(
          //   (error.response &&
          //     error.response.data &&
          //     error.response.data.message) ||
          //   error.message ||
          //   error.toString()
          // );
        });
    },
    updateEventRules() {
      let update = {
        data: this.eventRule.data,
        name: this.eventRule.name,
        dataType: this.eventRule.dataType,
        details: this.eventRule.details,
        selectedData: this.selectedData,
        _id: this.eventRule._id
      };
      this.$store
        .dispatch("data/updateEventRule", { updates: update })
        .then(() => {
          this.getAllEventTypes();
          this.getAllEventRules();
          this.$nextTick(() => {
            this.$bvModal.hide("eventRules_Adder_modal");
          });
        })
        .catch((error) => {
          // alert(
          //   (error.response &&
          //     error.response.data &&
          //     error.response.data.message) ||
          //   error.message ||
          //   error.toString()
          // );
        });
    }
  },
  provide: {
    grid: [Page, Sort, Toolbar, PdfExport, ExcelExport, Edit, Search]
  }
};
</script>

<style scoped src="@/assets/styles/theme.css"></style>
<style>
.table-t-notice {
  background-color: #58d68d;
}

.table-t-alarm {
  background-color: #ec7063;
}

.table-t-warning {
  background-color: #f1c40f;
}
</style>
<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: transform 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(-100%);
}
</style>
