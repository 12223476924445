<template>
  <div
    :dir="dict.dir[lang]"
    class="container-fluid p-0 bg-none"
    :class="lang === 'per' ? 'text-end' : 'text-start'"
    style="min-height: calc(100vh - 60px)"
  >
    <div class="p-3 m-0 bg-page-title d-flex">
      <div
        style="background: none!important;"
        :class="
          dict.dir[lang] === 'rtl'
            ? 'page-title-icon-rtl'
            : 'page-title-icon-ltr'
        "
      >
        <img
          src="@/assets/icons/data_manager-2.png"
          alt="dashboard"
          style="width: 60px"
        />
      </div>

      <div class="d-flex align-items-center">
        <h5 class="mb-0">{{ dict.title[lang] }}</h5>
      </div>
    </div>

    <div
      class="row g-0 p-2"
      :class="lang === 'per' ? 'text-end' : 'text-start'"
      style="min-height: calc(100vh - 152px)"
    >
      <div class="col-lg-12 h-100">
        <div
          class="card h-100"
          style="
            background-color: rgba(255, 255, 255, 0.75) !important;
            min-height: calc(100vh - 168px);
          "
        >
          <div class="p-3">
            <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
              <li
                v-for="(item, index) in tabs"
                class="mga-tab-nav-item"
                @click="activeTab = item.value"
                :key="index"
              >
                <a
                  class="mga-tab-nav-link"
                  :class="{ active: activeTab === item.value }"
                  :id="item.name"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="tab1"
                  aria-selected="true"
                >
                  {{ dict[item.value][lang] }}
                </a>
              </li>
            </ul>
          </div>
          <div class="card-body h-100" style="overflow: hidden">
            <transition name="slide-fade" mode="out-in">
              <div class="tab-content h-100" :key="activeTab">
                <div v-if="activeTab === 'tab1'" class="h-100">
                  <!--                  <button @click="sendDataSSE">click to send</button>-->
                  <!--                  <select v-model="theme" @change="loadStyles">-->
                  <!--                    <option value="material">Material Theme</option>-->
                  <!--                    <option value="fabric">Fabric Theme</option>-->
                  <!--                  </select>-->
                  <b-container fluid class="p-0">
                    <ejs-grid
                      ref="data_manager"
                      :width="'auto'"
                      :key="dataItems.length"
                      :loadingIndicator="{ indicatorType: 'Shimmer' }"
                      :allowSorting="true"
                      :locale="lang"
                      :excelQueryCellInfo="excelQueryCellInfo"
                      :allowExcelExport="true"
                      :allowFiltering="true"
                      :editSettings="dataTableOptions.editSettings"
                      :allowPdfExport="true"
                      :toolbar="toolbarGenerator"
                      :toolbarClick="toolbarClick"
                      :enableAdaptiveUI="true"
                      :actionBegin="actionBegin"
                      :allowPaging="false"
                      :rowRenderingMode="'Horizontal'"
                      :enableRtl="lang == 'per'"
                      :selectionSettings="dataTableOptions.selectionOptions"
                      :dataSource="dataItems"
                      :dataBound="dataBounded"
                    >
                      <e-columns>
                        <e-column width="50" type="checkbox"></e-column>
                        <e-column
                          v-for="(item, index) in dataFields"
                          :key="index"
                          :width="140"
                          :field="item.key"
                          :headerText="item[lang]"
                          :template="item.hasTemplate ? item.key : null"
                        ></e-column>
                      </e-columns>

                      <template v-slot:type="{ data }">
                        <div>
                          <p v-if="!isClass(data.type)">{{ translateDataType[data.type][lang] }}</p>
                          <p v-if="isClass(data.type)">class</p>
                        </div>
                      </template>

                      <template v-slot:modify="{ data }">
                        <div class="d-flex justify-content-between">

                          <span
                            style="font-size: 17px"
                            @click="editJsonItem('data', data)"
                            v-permission="'dataManager_edit'"
                            class="e-icons e-edit-6"></span>
                          <span
                            style="font-size: 17px"
                            @click="removeJsonItem(data)"
                            v-permission="'dataManager_remove'"
                            class="e-icons e-delete-1 text-danger"
                          ></span>
                        </div>
                      </template>
                      <template v-slot:generation="{ data }">
                        <div>
                          <span v-if="data.generation && data.generation.enable" class="e-icons e-check"></span>
                          <span v-else class="e-icons e-close"></span>
                        </div>
                      </template>

                      <template v-slot:value="{ data }">
                        <div>
                          <div>
                            <i v-if="data.type!='freeArray'&&data.type!='freeObject'"
                               class="reset mx-2"
                               @click="showVal(data._id, data.value,data.type,data)"
                            >
                              <font-awesome-icon icon="fas fa-equals" />
                            </i>
                          </div>
                        </div>
                      </template>

                      <template v-slot:archive="{ data }">
                        <div>
                          <span v-if="data.archive && data.archive.enable" class="e-icons e-check"></span>
                          <span v-else class="e-icons e-close"></span>
                        </div>
                      </template>

                      <template v-slot:alert="{ data }">
                        <div>
                          <span v-if="data.alert && data.alert.enable" class="e-icons e-check"></span>
                          <span v-else class="e-icons e-close"></span>
                        </div>
                      </template>

                      <template v-slot:usedBy="{ data }">
                        <div class="text-danger">
                          <span v-if="data.usedBy.length > 0"
                                class="e-icons e-check"></span>
                          <span v-else class="e-icons e-close"></span>
                        </div>
                      </template>

                      <template v-slot:parent="{ data }">
                        <div>
                          <span v-if="data.parent && data.parent.ptype" class="e-icons e-check"></span>
                          <span v-else class="e-icons e-close"></span>
                        </div>
                      </template>
                    </ejs-grid>

                    <div class="d-flex justify-content-around" :key="activeTab">
                      <ejs-pager
                        :pageSize="perPage"
                        :pageSizes="[5,10,20,30]"
                        :locale="lang"
                        :enableRtl="lang == 'per'"
                        :totalRecordsCount="pages"
                        v-model="currentPage"
                        :currentPage="currentPage"
                        @click="getVal($event)"
                      ></ejs-pager>

                    </div>

                  </b-container>
                </div>
                <div v-if="activeTab === 'tab2'" class="h-100">
                  <b-container fluid>
                    <ejs-grid
                      ref="data_manager_conf"
                      :width="'auto'"
                      :allowSorting="true"
                      :locale="lang"
                      :loadingIndicator="{ indicatorType: 'Shimmer' }"
                      :allowExcelExport="true"
                      :allowFiltering="true"
                      :editSettings="confTableOptions.editSettings"
                      :allowPdfExport="true"
                      :enableAdaptiveUI="true"
                      :toolbar="toolbarGenerator"
                      :pdfExportComplete="pdfExportComplete"
                      :toolbarClick="confToolbarClick"
                      @actionBegin="actionBegin"
                      @dataBound="dataBounded"
                      :allowPaging="false"
                      :enableRtl="lang == 'per'"
                      :pageSettings="{ pageSize: 5,pageSizes: [5, 10, 20, 50] }"
                      :selectionSettings="confTableOptions.selectionOptions"
                      :dataSource="confItems"
                    >
                      <e-columns>
                        <e-column width="50" type="checkbox"></e-column>
                        <e-column
                          v-for="(item, index) in confFields"
                          :key="index"
                          :width="60"
                          :field="item.key"
                          :headerText="item[lang]"
                          :template="item.hasTemplate ? item.key : null"
                        ></e-column>
                      </e-columns>

                      <template v-slot:modify="{data}">
                        <div style="display: flex; justify-content: flex-end">
                          <b-button
                            pill
                            size="sm"
                            v-permission="'dataManager_edit'"
                            variant="outline-success"
                            @click="editJsonItem('config', data)"
                            class="mr-2"
                            style="
                              outline: none !important;
                              border: none;
                              box-shadow: none;
                            "
                          >
                            <b-icon
                              icon="pencil-square"
                              aria-hidden="true"
                            ></b-icon>
                          </b-button>
                          <b-button
                            pill
                            v-permission="'dataManager_remove'"
                            size="sm"
                            variant="outline-danger"
                            @click="removeJsonItem(data)"
                            class="mr-2"
                            style="
                              margin-inline: 10px;
                              outline: none !important;
                              border: none;
                              box-shadow: none;
                            "
                          >
                            <b-icon
                              icon="trash-fill"
                              aria-hidden="true"
                            ></b-icon>
                          </b-button>
                        </div>
                      </template>

                      <template v-slot:archive="{data}">
                        <div>
                          <i v-if="data.archive && data.archive.enable">
                            <font-awesome-icon
                              icon="fas fa-check-circle"
                              class="check-mark"
                            />
                          </i>
                          <i v-else>
                            <font-awesome-icon
                              icon="fas fa-xmark-circle"
                              class="x-mark"
                            />
                          </i>
                        </div>
                      </template>

                      <template v-slot:usedBy="{data}">
                        <div>
                          <i v-if="data.usedBy.length > 0">
                            <font-awesome-icon
                              icon="fas fa-check-circle"
                              class="check-mark"
                            />
                          </i>
                          <i v-else>
                            <font-awesome-icon
                              icon="fas fa-xmark-circle"
                              class="x-mark"
                            />
                          </i>
                        </div>
                      </template>

                      <template v-slot:parent="{data}">
                        <div>
                          <i v-if="data.parent && data.parent.ptype">
                            <font-awesome-icon
                              icon="fas fa-check-circle"
                              class="check-mark"
                            />
                          </i>
                          <i v-else>
                            <font-awesome-icon
                              icon="fas fa-xmark-circle"
                              class="x-mark"
                            />
                          </i>
                        </div>
                      </template>
                    </ejs-grid>
                    <ejs-pager
                      :pageSize="5"
                      :totalRecordsCount="pages"
                      :currentPage="1"
                      :pageSizes=" [5, 10, 20, 50]"
                      :lacale="lang"
                      @click="getVal($event)"
                    ></ejs-pager>
                  </b-container>
                </div>
                <div v-if="activeTab === 'tab4'" class="h-100">
                  <b-container fluid class="p-0">
                    <ejs-grid
                      ref="data_manager_category"
                      :width="'auto'"
                      :allowSorting="true"
                      :locale="lang"
                      :allowExcelExport="true"
                      :loadingIndicator="{ indicatorType: 'Shimmer' }"
                      :allowFiltering="true"
                      :editSettings="categoryOptions.editSettings"
                      :allowPdfExport="true"
                      :toolbar="toolbarGenerator"
                      :pdfExportComplete="pdfExportComplete"
                      :toolbarClick="categoryToolbarClick"
                      :actionBegin="categoryActionBegin"
                      :dataBound="categoryBound"
                      :allowPaging="true"
                      :enableAdaptiveUI="true"
                      :enableRtl="lang == 'per'"
                      :pageSettings="{ pageSize: 5,pageSizes: [5, 10, 20, 50] }"
                      :selectionSettings="categoryOptions.selectionOptions"
                      :dataSource="categoryOptions.items"
                    >
                      <e-columns>
                        <e-column width="50" type="checkbox"></e-column>
                        <e-column
                          v-for="(item, index) in categoryOptions.fields"
                          :key="index"
                          :field="item.key"
                          :headerText="item[lang]"
                          :template="item.hasTemplate ? item.key : null"
                        ></e-column>
                      </e-columns>
                      <template v-slot:modify="{ data }">
                        <div class="d-flex justify-content-between col-md-3">
                          <span class="e-icons e-edit-6"
                                v-permission="'dataManager_edit'"
                                @click="editCategory(data)"
                                style="font-size: 16px"
                          ></span>
                          <span
                            v-permission="'dataManager_remove'"
                            class="e-icons e-delete-1 text-danger"
                            @click="removeCategory(data._id)"
                            style="font-size: 16px"
                          ></span>
                        </div>
                      </template>
                    </ejs-grid>
                  </b-container>
                </div>
                <div v-if="activeTab === 'tab3'" class="h-100">
                  <b-container fluid class="p-0">
                    <ejs-grid
                      ref="data_manager_class"
                      :width="'auto'"
                      :loadingIndicator="{ indicatorType: 'Shimmer' }"
                      :allowSorting="true"
                      :locale="lang"
                      :allowExcelExport="true"
                      :allowFiltering="true"
                      :editSettings="classOptions.editSettings"
                      :allowPdfExport="true"
                      :toolbar="toolbarGenerator"
                      :enableAdaptiveUI="true"
                      :pdfExportComplete="pdfExportComplete"
                      :toolbarClick="classToolbarClick"
                      :actionBegin="classActionBegin"
                      :dataBound="classBound"
                      :allowPaging="true"
                      :enableRtl="lang == 'per'"
                      :pageSettings="{ pageSize: 5,pageSizes: [5, 10, 20, 50] }"
                      :selectionSettings="classOptions.selectionOptions"
                      :dataSource="classOptions.items"
                    >
                      <e-columns>
                        <e-column width="50" type="checkbox"></e-column>
                        <e-column
                          v-for="(item, index) in classOptions.fields"
                          :key="index"
                          :field="item.key"
                          :headerText="item[lang]"
                          :template="item.hasTemplate ? item.key : null"
                        ></e-column>
                      </e-columns>
                      <template v-slot:modify="{ data }">
                        <div class="d-flex justify-content-between col-md-3">
                          <span class="e-icons e-edit-6"
                                v-permission="'dataManager_edit'"
                                @click="getClassById(data)"
                                style="font-size: 16px"
                          ></span>
                          <span
                            v-permission="'dataManager_remove'"
                            class="e-icons e-delete-1 text-danger"
                            @click="removeClass(data._id)"
                            style="font-size: 16px"
                          ></span>
                        </div>
                      </template>
                    </ejs-grid>
                  </b-container>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <dataManagerModal></dataManagerModal>
    <b-modal
      id="show-obj-val"
      size="lg"
      :title="dict.value[lang]"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      hide-header-close
      no-close-on-backdrop
      body-text-variant="dark"
      content-class="shadow"
      @ok="updateValues"
      @shown="initValueModal"
      :ok-title="dict.ok[lang]"
      :cancel-title="dict.cancel[lang]"
      :dir="dict.dir[lang]"
    >
      <div id="data_value_manage">
        <ejs-grid
          ref="value_datas"
          :key="selectedRow.length"
          :width="'auto'"
          :loadingIndicator="{ indicatorType: 'Shimmer' }"
          :allowSorting="true"
          :locale="lang"
          :allowFiltering="true"
          :editSettings="dataTableOptions.editSettings"
          :toolbar="['Search']"
          :allowPaging="true"
          :enableRtl="lang == 'per'"
          :pageSettings="{ pageSize: 5,pageSizes: [5, 10, 20, 50] }"
          :selectionSettings="dataTableOptions.selectionOptions"
          :dataSource="selectedRow"
        >
          <e-columns>
            <e-column width="50" type="checkbox"></e-column>
            <e-column
              v-for="(item, index) in dataFieldsForVal"
              :key="index"
              :field="item.key"
              :headerText="item[lang]"
              :template="item.hasTemplate ? item.key : null"
            ></e-column>
          </e-columns>
          <template v-slot:value="{data}">
            <div class="col-md-12 col-12 mt-3" :key="renderTime + 'data'">
              <ejs-textbox
                :autocomplete="'off'"
                v-if="data.type != 'Boolean'"
                :required="data.required"
                :type="data.type"
                floatLabelType="Auto"
                :value="data.value"
                :placeholder="dict.value[lang]"
                :enableRtl="lang === 'per'"
                @input="getValues(data,$event)"
              >
              </ejs-textbox>
              <ejs-dropdownlist
                v-if="data.type == 'Boolean'"
                :dataSource="booleanType"
                :value="data.value"
                floatLabelType="Auto"
                :enabled="true"
                :fields="{ text: lang, value: 'value' }"
                :placeholder="dict.value[lang]"
                :enableRtl="lang === 'per'"
                :required="data.required"
                @change="getValues(data,$event)"
              ></ejs-dropdownlist>
            </div>
          </template>
        </ejs-grid>
      </div>
    </b-modal>

    <b-modal
      id="show-list-val"
      size="sm"
      :title="dict.value[lang]"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      hide-header-close
      no-close-on-backdrop
      body-text-variant="dark"
      content-class="shadow"
      @ok="editValuesData"
      :ok-title="dict.ok[lang]"
      :cancel-title="dict.cancel[lang]"
      :dir="dict.dir[lang]"
    >
      <div id="data_value_manage">
        <div class="col-md-12 mx-auto col-12">
          <ejs-textbox
            :autocomplete="'off'"
            v-if="selectedVar.type != 'Boolean'"
            :required="selectedVar.required"
            :type="selectedVar.type"
            floatLabelType="Auto"
            :value="selectedVar.value"
            :placeholder="dict.value[lang]"
            :enableRtl="lang === 'per'"
            @input="getValueForData($event)"
          >
          </ejs-textbox>
          <ejs-dropdownlist
            v-if="selectedVar.type == 'Boolean'"
            :dataSource="booleanType"
            :value="selectedVar.value"
            :enabled="true"
            floatLabelType="Auto"
            :fields="{ text: lang, value: 'value' }"
            :placeholder="dict.value[lang]"
            :enableRtl="lang === 'per'"
            :required="selectedVar.required"
            @change="getValueForData($event)"
          ></ejs-dropdownlist>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="class_generator_for_data"
      size="lg"
      :title="dict[modalMode][lang]"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      hide-header-close
      no-close-on-backdrops
      scrollable
      body-text-variant="dark"
      content-class="shadow"
      :cancel-title="dict.cancel[lang]"
      :ok-title="dict.ok[lang]"
      :dir="dict.dir[lang]"
      @shown="initClassModal"
      @ok="createNewClass"
      @hidden="resetClass"
    >
      <div id="data_mnager_define">
        <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
          <li
            v-for="(item, index) in classTabs"
            class="mga-tab-nav-item"
            @click="selectedTab = item.value"
            :key="index"
          >
            <a
              class="mga-tab-nav-link"
              :class="{ active: selectedTab === item.value }"
              :id="item.name"
              data-toggle="tab"
              role="tab"
              aria-controls="tab1"
              aria-selected="true"
            >
              {{ dict[item.value][lang] }}
            </a>
          </li>
        </ul>

        <div
          class="px-0 mx-0 py-4"
          v-if="selectedTab == 'Archives'"
        >

          <div class="border px-2">
            <div class="row border-bottom mx-0 py-1" v-for="(data1,index) in archiveGenerator " :key="index"
                 style="font-size: 13px">
              <div class="col-md-3 col-12">
                <p class="mt-3">{{ data1.name }}</p>
              </div>
              <div class="col-md-3 col-12 pt-3">
                <ejs-switch
                  :enableRtl="lang == 'per'"
                  v-model="data1.enable"
                  :checked="data1.enable"
                  @change="updateSwitch($event,'enable',data1.id)"
                />
                <span class="mx-2">{{ dict.ArchivesEnable[lang] }}</span>
              </div>
              <div class="col-md-3 col-12 pt-3">
                <ejs-switch
                  :enableRtl="lang == 'per'"
                  :checked="data1.uponChange"
                  v-model="data1.uponChange"
                  @change="updateSwitch($event,'uponChange',data1.id)"
                />
                <span class="mx-2">{{ dict.upon[lang] }}</span>
              </div>
              <div class="col-md-3 col-12">
                <ejs-dropdownlist
                  :dataSource="intervalList"
                  :fields="{ text: lang, value: 'value' }"
                  :placeholder="dict.interval[lang]"
                  :enableRtl="lang === 'per'"
                  :enabled="data1.enable"
                  :value="data1.interval"
                  v-model="data1.interval"
                  :required="data1.uponChange ==true "
                ></ejs-dropdownlist>
              </div>
            </div>
            <ejs-pager
              style="border: unset!important;"
              :pageSize="5"
              v-model="curPage"
              :pageSizes="[5,10,20,50]"
              :locale="lang"
              :enableRtl="lang == 'per'"
              @click="getValArc"
              :totalRecordsCount="data.value.length"
            ></ejs-pager>
          </div>
        </div>

        <div
          class="px-2 mx-auto py-4"
          v-show="selectedTab == 'Definition'"
        >
          <div class="row mx-auto">
            <div class="col-md-6 col-12 my-3">
              <ejs-textbox
                :autocomplete="'off'"
                :value="data.name"
                :placeholder="dict.dataName[lang]"
                :enableRtl="lang === 'per'"
                @input="
                  (val) => {
                    data.name = val.value;
                  }
                "
                :required="true"
              >
              </ejs-textbox>
            </div>
            <div class="col-md-6 col-12 my-3">
              <ejs-dropdownlist
                :dataSource="typeList"
                :value="data.dataType"
                :fields="{ text: lang, value: 'value' }"
                :placeholder="dict.dataType[lang]"
                :enableRtl="lang === 'per'"
                @change="
                  (val) => {
                    data.dataType = val.value;
                  }
                "
                :required="true"
              ></ejs-dropdownlist>
            </div>
          </div>

          <div v-if="data.dataType == 'Object'" class="my-3">
            <div class="d-flex justify-content-end">
              <b-button
                class="my-2 ml-5 exBtn mx-2"
                variant="outline-success"
                size="sm"
                @click="uploadExcelFile"
              >
                {{ dict.importFile[lang] }}
                <i>
                  <font-awesome-icon icon="fas fa-download" />
                </i>
              </b-button>
            </div>
            <div class="card">
              <div class="d-flex px-md-2 px-1 justify-content-between">
                <b-form-checkbox-group
                  @change="selectAllData"
                  :aria-describedby="'ariaDescribedby'"
                  class="mt-1 px-md-0 px-1"
                  v-model="isAll"
                  style="display: flex; justify-content: space-around"
                >
                  <b-form-checkbox></b-form-checkbox>
                </b-form-checkbox-group>
                <div v-if="selectedDatas.length > 1">
                  <b-button
                    class="my-2 ml-5 exBtn"
                    variant="outline-danger"
                    size="sm"
                    @click="deleteAll"
                  >
                    {{ dict.removeAll[lang] }}
                    <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                  </b-button>
                </div>
              </div>

              <b-table
                :items="data.value"
                :fields="curTableOptions.fields"
                :current-page="curTableOptions.currentPage"
                :per-page="curTableOptions.perPage"
                :filter="curTableOptions.filter"
                :filter-included-fields="curTableOptions.filterOn"
                :sort-by.sync="curTableOptions.sortBy"
                :sort-desc.sync="curTableOptions.sortDesc"
                :sort-direction="curTableOptions.sortDirection"
                show-empty
                :empty-text="dict.emptyStr[lang]"
                small
                responsive
                borderless
                ref="classTable"
              >
                <template #cell(selectAll)="row">
                  <b-form-checkbox-group
                    v-model="selectedDatas"
                    :aria-describedby="'ariaDescribedby'"
                    class="mt-2 px-1"
                    style="display: flex; justify-content: space-around"
                  >
                    <b-form-checkbox :value="row.item"></b-form-checkbox>
                  </b-form-checkbox-group>
                </template>
                <template #cell(data)="row">
                  <div class="mb-md-0 mb-5">
                    <div
                      class="
                        row
                        mx-auto
                        align-items-center
                        border-md-0 border-sm border-sm-bottom
                      "
                    >
                      <div class="col-md-3 col-6">
                        <ejs-textbox
                          :autocomplete="'off'"
                          :value="row.item.name"
                          :placeholder="dict.dataName[lang]"
                          :enableRtl="lang === 'per'"
                          @input="
                            (val) => {
                              row.item.name = val.value;
                            }
                          "
                          :required="true"
                        >
                        </ejs-textbox>
                      </div>
                      <div class="col-md-3 col-6">
                        <ejs-dropdownlist
                          :dataSource="typeList2"
                          :value="row.item.type"
                          :fields="{ text: lang, value: 'value' }"
                          :placeholder="dict.dataType[lang]"
                          :enableRtl="lang === 'per'"
                          @change="
                            (val) => {
                              row.item.type = val.value;
                            }
                          "
                          :required="true"
                        ></ejs-dropdownlist>
                      </div>
                      <div class="col-md-3 col-6">
                        <div class="form-check form-switch mt-4">
                          <input
                            v-model="row.item.required"
                            class="form-check-input btn-sm mt-2"
                            type="checkbox"
                            role="switch"
                            :id="row.index + 'checkBox'"
                          />
                          <label
                            class="form-check-label mt-0 pt-0"
                            style="font-size: 14px"
                            :for="row.index + 'checkBox'"
                          >{{ dict.requireField[lang] }}</label
                          >
                        </div>
                      </div>
                      <div
                        class="col-md-3 col-6"
                        :class="{ disableArchive: !row.item.required }"
                      >
                        <div v-if="row.item.type != 'Boolean'" :key="row.item.type">
                          <ejs-textbox
                            :autocomplete="'off'"
                            :value="row.item.value"
                            :placeholder="dict.dataValue[lang]"
                            :validatorInstance="validator"
                            :enableRtl="lang === 'per'"
                            :input-type="row.item.type"
                            @input="
                            (val) => {
                              row.item.value = val.value;
                            }
                          " :required="row.item.required">
                          </ejs-textbox>
                        </div>
                        <div v-if="row.item.type == 'Boolean'" :key="row.item.type">
                          <ejs-dropdownlist
                            :dataSource="booleanType"
                            :value="row.item.value"
                            :enabled="true"
                            :fields="{ text: lang, value: 'value' }"
                            :placeholder="dict.dataValue[lang]"
                            :enableRtl="lang === 'per'"
                            @change="
                            (val) => {
                              row.item.value = val.value;
                            }
                          "
                            :required="row.item.required"
                          ></ejs-dropdownlist>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template #cell(modify)="row">
                  <div class="align-items-center mt-2 px-1">
                    <i
                      v-if="row.index == 0"
                      class="mt-4 text-success cursor-pointer"
                      @click="addNewValue()"
                    >
                      <font-awesome-icon icon="fas fa-plus-square" />
                    </i>
                    <i
                      v-if="row.index != 0"
                      class="reset mt-4 cursor-pointer"
                      @click="removeValue(row.item.id)"
                    >
                      <b-icon
                        icon="trash-fill"
                        aria-hidden="true"
                        class="mt-2"
                      ></b-icon>
                    </i>
                  </div>
                </template>
              </b-table>

              <b-row align-h="between" class="g-0 mb-0 p-2">
                <b-col lg="2" sm="4" md="3" class="my-1">
                  <component
                    :is="'DropDownList'"
                    :id="'per_page_200'"
                    :dataSource="curTableOptions.pageOptions"
                    :value="curTableOptions.perPage"
                    :fields="{ text: 'text', value: 'value' }"
                    :placeholder="dict.general.perPage[lang]"
                    :enableRtl="lang === 'per'"
                    @change="
                      (val) => {
                        curTableOptions.perPage = val;
                      }
                    "
                  ></component>
                </b-col>

                <b-col lg="4" sm="6" md="5" class="my-1 mt-4">
                  <b-pagination
                    v-model="curTableOptions.currentPage"
                    :total-rows="data.value.length"
                    :per-page="curTableOptions.perPage"
                    align="fill"
                    size="sm"
                    class="my-0"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="category_generator_for_data"
      size="md"
      :title="dict[modalMode][lang]"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      scrollable
      hide-header-close
      no-close-on-backdrop
      body-text-variant="dark"
      content-class="shadow"
      :cancel-title="dict.cancel[lang]"
      :ok-title="dict.ok[lang]"
      :dir="dict.dir[lang]"
      @shown="initModalCats"
      @ok="createNewCategory"
      @hidden="resetCategory"
    >
      <div id="data_category_manage" class="row">
        <div class="col-md-6 col-12 my-3">
          <ejs-textbox
            :autocomplete="'off'"
            :id="'data_name_definition_category'"
            v-model="category.name"
            :locale="lang"
            :enableRtl="lang == 'per'"
            :required="true"
            :placeholder="dict.categoryName[lang]"
          ></ejs-textbox>
        </div>
        <div class="col-md-6 col-12 my-3">
          <ejs-dropdownlist
            :id="'alerts_typeColor'"
            :required="true"
            :locale="lang"
            :dataSource="catTypeList"
            :enableRtl="lang === 'per'"
            v-model="category.type"
            :fields="{ text: lang, value: 'value' }"
            :placeholder="dict.categoryType[lang]"
          ></ejs-dropdownlist>

        </div>
        <div class="col-md-6 col-12" v-if="category.type == 'datas'">
          <data-picker-selector :is-multi-select="true" :value="category.value" />
        </div>
        <div class="col-md-6 col-12" v-show="category.type == 'categories'">
          <ejs-multiselect
            :id="'data_category_definition'"
            :dataSource="categoryList"
            v-model="category.value"
            :required="true"
            :locale="lang"
            :fields="{ text: 'text', value: 'value' }"
            :placeholder="dict.dataCat[lang]"
            :allowCustomValue="true"
          ></ejs-multiselect>
        </div>
      </div>
    </b-modal>
    <upload-excel />
    <dataPickerModal></dataPickerModal>
  </div>
</template>

<script>
/* eslint-disable */

import dataManagerModal from "./dataManagerModal.vue";
import { v4 } from "uuid";
import UploadExcel from "@/components/base/uploadExcel.vue";
import { FormValidator } from "@syncfusion/ej2-inputs";
import dataPickerModal from "@/components/setContModalDataPicker.vue";
import DataPickerSelector from "@/components/base/dataPickerSelector.vue";
import {
  Page,
  Sort,
  Toolbar,
  PdfExport,
  ExcelExport,
  Edit,
  Search
} from "@syncfusion/ej2-vue-grids";
import { DialogUtility } from "@syncfusion/ej2-popups";
import { debounce } from "@/services/data.service";

export default {
  name: "masterEdit",
  components: {
    DataPickerSelector,
    dataPickerModal,
    UploadExcel,
    dataManagerModal
  },
  data() {
    return {
      curPage: 1,
      selectedVar: {},
      perPage: 5,
      theme: "",
      currentPage: 1,
      pages: null,
      currentId: null,
      categoryList: [],
      renderTime: 0,
      translateDataType: {
        Number: { eng: "Number", per: "عدد" },
        String: { eng: "String", per: "رشته" },
        Boolean: { eng: "Boolean", per: "داده منطقی (Boolean)" },
        Object: { eng: "Object", per: "شی (آبجکت)" }

      },
      intervalList: [{ per: "یک مورد انتخاب کنید", eng: "Select One", value: "null" },
        { per: "هیچکدام", eng: "none", value: "none" },
        { per: "2 ثانیه", eng: "2 sec", value: "2_s" },
        { per: "5 ثانیه", eng: "5 sec", value: "5_s" },
        { per: "10 ثانیه", eng: "10 sec", value: "10_s" },
        { per: "15 ثانیه", eng: "15 sec", value: "15_s" },
        { per: "30 ثانیه", eng: "30 sec", value: "30_s" },
        { per: "1 دقیقه", eng: "1 min", value: "1_m" },
        { per: "3 دقیقه", eng: "3 min", value: "3_m" },
        { per: "5 دقیقه", eng: "5 min", value: "5_m" },
        { per: "15 دقیقه", eng: "15 min", value: "15_m" },
        { per: "30 دقیقه", eng: "30 min", value: "30_m" },
        { per: "45 دقیقه", eng: "45 min", value: "45_m" },
        { per: "1 ساعت", eng: "1 hour", value: "1_h" }
      ],
      title: "",
      translateTypes: {
        number: "Number",
        string: "String",
        boolean: "Boolean"
      },
      typeList2: [
        { per: "عدد", eng: "number", value: "Number" },
        { per: "رشته", eng: "string", value: "String" },
        { per: "داده منطقی (Boolean)", eng: "boolean", value: "Boolean" }
      ],
      selectedRow: [],
      activeTab: "tab1",
      tabs: [
        { name: "tab1", value: "tab1" },
        // { name: "tab2", value: "tab2" },
        { name: "tab3", value: "tab3" },
        { name: "tab4", value: "tab4" }
      ],
      dataItems: [],
      dataFields: [
        { key: "name", per: "نام", eng: "Name", hasTemplate: false },
        { key: "type", per: "نوع داده", eng: "Type", hasTemplate: true },
        { key: "value", per: "مقدار", eng: "Value", hasTemplate: true },
        { key: "parent", per: "والد", eng: "Parent", hasTemplate: true },
        { key: "usedBy", per: "استفاده شده", eng: "Used by", hasTemplate: true },
        { key: "generation", per: "ساخت داده", eng: "Generation", hasTemplate: true },
        { key: "archive", per: "آرشیو شده", eng: "Archive", hasTemplate: true },
        { key: "alert", per: "هشدار", eng: "Alert", hasTemplate: true },
        { key: "modify", per: "", eng: "", hasTemplate: true }
      ],
      curTableOptions: {
        selectionOptions: {
          type: "Multiple",
          persistSelection: false,
          checkboxOnly: true
        },
        editSettings: {
          allowEditing: false,
          allowAdding: true,
          allowDeleting: true,
          mode: "Dialog"
        },
        items: [],
        name: "",
        fields: [
          { key: "selectAll", label: "" },
          {
            key: "data",
            label: ""
          },
          { key: "modify", label: "" }
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "active",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: []
      },
      archiveTable: {
        items: [],
        name: "",
        fields: [
          { key: "name", per: "نام", eng: "name", hasTemplate: false },
          { key: "modify", per: "", eng: "", hasTemplate: true }
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "active",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: []
      },
      booleanType: [{ per: "صحیح", eng: true, value: true }, { per: "غلط", eng: false, value: false }],
      dataFieldsForVal: [
        // { key: '_id', label: 'id', sortable: true, sortDirection: 'asc' },
        { key: "name", per: "نام", eng: "Name", hasTemplate: false },
        { key: "type", per: "نوع", eng: "Type", hasTemplate: false },
        { key: "value", per: "مقدار", eng: "Value", hasTemplate: true }
      ],
      confItems: [],
      confFields: [
        { key: "name", per: "نام", eng: "Name", hasTemplate: false },
        { key: "value", per: "مقدار", eng: "Value", hasTemplate: true },
        { key: "parent", per: "والد", eng: "Parent", hasTemplate: true },
        { key: "usedBy", per: "استفاده شده", eng: "Used by", hasTemplate: true },
        { key: "archive", per: "آرشیو شده", eng: "Archive", hasTemplate: true },
        { key: "modify", per: "", eng: "", hasTemplate: true }
      ],
      selectedDatas: [],
      typeList: [{ per: "یک مورد را انتخاب کنید", eng: "Select One", value: "null" },
        { per: "شی ( آبجکت )", eng: "object", value: "Object" }
      ],
      selectedId: "",
      data: {
        value: [
          { name: "", type: "", id: v4(), value: "", required: false, enable: false, interval: null, uponChange: false }
        ],
        name: "",
        dataType: ""
      },
      category: {
        name: "",
        type: "",
        value: [],
        id: v4()
      },
      dataTableOptions: {
        selectionOptions: {
          type: "Multiple",
          persistSelection: false,
          checkboxOnly: true
        },
        editSettings: {
          allowEditing: false,
          allowAdding: true,
          allowDeleting: true,
          mode: "Dialog"
        }
      },
      classOptions: {
        selectionOptions: {
          type: "Multiple",
          persistSelection: false,
          checkboxOnly: true
        },
        editSettings: {
          allowEditing: false,
          allowAdding: true,
          allowDeleting: true,
          mode: "Dialog"
        },
        items: [],
        fields: [
          { key: "name", per: "نام", eng: "Name", hasTemplate: false },
          { key: "dataType", per: "نوع", eng: "Type", hasTemplate: false },
          { key: "modify", hasTemplate: true, per: "", eng: "" }
        ]
      },
      categoryOptions: {
        selectionOptions: {
          type: "Multiple",
          persistSelection: false,
          checkboxOnly: true
        },
        editSettings: {
          allowEditing: false,
          allowAdding: true,
          allowDeleting: true,
          mode: "Dialog"
        },
        items: [],
        fields: [
          { key: "name", per: "نام", eng: "Name", hasTemplate: false, sortDirection: "asc" },
          { key: "modify", hasTemplate: true, per: "", eng: "" }
        ]
      },
      selectedTab: "Definition",
      classTabs: [
        { text: "Definition", value: "Definition" },
        { text: "Archives", value: "Archives" }
      ],
      valueTableOptions: {
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "topic",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: []
      },

      confTableOptions: {
        selectionOptions: {
          type: "Multiple",
          persistSelection: false,
          checkboxOnly: true
        },
        editSettings: {
          allowEditing: false,
          allowAdding: true,
          allowDeleting: true,
          mode: "Dialog"
        }
      },
      modalMode: "New",
      dict: {
        refresh: { eng: "refresh datas", per: "به روزرسانی داده ها" },
        perPage: { eng: "per page", per: "تعداد در هر صفحه" },
        emptyStr: { per: "هیچ رکوردی برای نمایش وجود ندارد", eng: "No records to display" },
        deleteConfirm: { eng: "Confirmation Delete", per: "تاییدیه حذف" },
        deleteConfirmContent: {
          eng: "Are you sure to remove the data ?",
          per: "آیا از حذف این داده اطمینان دارید ؟"
        },
        upon: { eng: "upon change", per: "به محض تغییر" },
        removeAll: { eng: "delete All", per: "حذف همه" },
        dataCat: { eng: "child name", per: "نام دسته فرزند" },
        dataName: { eng: "data name", per: "نام داده" },
        value: { eng: "Edit data's value", per: "ویرایش مقدار داده" },
        valueList: { eng: "values data", per: "لیست داده ها" },
        categoryName: { eng: "category name", per: "نام گروه" },
        dataValue: { eng: "default value", per: "مقدار پیش فرض" },
        interval: { eng: "interval", per: "نرخ زمانی" },
        importFile: { eng: "import data", per: "وارد کردن داده" },
        dataType: { eng: "data type", per: "نوع داده" },
        New: { per: "افزودن آیتم", eng: "Add Item" },
        Edit: { per: "ویرایش آیتم", eng: "Edit Item" },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        dir: { eng: "ltr", per: "rtl" },
        search: { eng: "Type to Search", per: "برای جستجو تایپ کنید" },
        title: { eng: "data manager", per: "مدیریت داده ها" },
        tab1: { eng: "data", per: "داده" },
        tab2: { eng: "structure", per: "ساختار" },
        tab4: { eng: "data category", per: "گروه داده" },
        tab3: { eng: "class", per: "کلاس " },
        Definition: { eng: "Definition", per: "تعریف داده ها" },
        Archives: { eng: "Archives", per: "آرشیو داده ها" },
        ArchivesEnable: { eng: "enable archive", per: "فعال کردن آرشیو" },
        requireField: { eng: "required", per: "فیلد اجباری" },
        wbname: { eng: "workbench name", per: "نام میزکار" },
        wbowner: { eng: "workbench owner", per: "مالک میزکار" },
        wbresources: { eng: "workbench resources", per: "منابع میزکار" },
        regmsg: { eng: "Don't have an account?", per: "حساب کاربری ندارید؟" },
        addData: { eng: "new", per: "جدید " },
        register: { eng: "Register now", per: "ثبت نام" },
        errCaptcha: { eng: "invalid captcha", per: "کد امنیتی وارد شده اشتباه می باشد" },
        errUserPass1: { eng: "invalid username or password", per: "نام کاربری یا کلمه عبور اشتباه است" },
        errUserPass2: { eng: "check your keyboard language", per: "• وضعيت زبان(En/FA)سيستم خود را كنترل كنيد" },
        errUserPass3: { eng: "the password is caps sensitive", per: "• پسورد به حروف بزرگ و كوچك حساس است" },
        general: {
          nextBtn: { "per": "ادامه", "eng": "Next" },
          pageTitle: { "per": "ایجاد اتصال Http server", "eng": "Add http server Connection" },
          perPage: { per: "تعداد در هر صفحه", eng: "per page" },
          searchBox: { per: "جستجو", eng: "search" }
        },
        categoryType: { eng: "type", per: "نوع داده" }
      },
      catTypeList: [
        { value: "categories", per: "گروه بندی ها", eng: "categories" },
        { value: "datas", per: "داده ها", eng: "datas" }
      ],
      validator: null,
      validator1: null,
      validator2: null,
      isAll: false,
      cssLinks: []
    };
  },
  watch: {
    "wb": {
      handler() {
        this.getContConf(1, 5);
        this.getCategories();
        this.getAllClasses();
      }, deep: true
    },
    "category.type": {
      handler(val) {
        if (val == "datas") {
          this.$store.dispatch("chpge/parent2child", {
            task: "pickData",
            from: "getPostMap",
            filter: {
              dataType: ["!complex"],
              usedBy: [],
              parents: [],
              archive: null,
              withChild: false,
              perPage: 5000,
              page: 1
            }
          });

        }
      }, deep: true
    },
    updatePage(newValue, oldValue) {
      if (this.perPage != 5 && this.perPage != null)
        this.getContConf(1, this.perPage);
      else
        this.getContConf(1, 5);
      this.currentPage = 1;
    },
    activeTab(val) {
      this.getContConf(1, 5);

      if (val == "tab3" || val == "tab1")
        this.getAllClasses();
      else if (val == "tab4" || val == "tab1")
        this.getAllCategories();
      else if (val == "1")
        this.getContConf(1, 5);
    },
    child2parent(val) {
      if (val.task == "pickData") {
        if (val.from == "removing") {
          if (this.category.value.indexOf(val.item._id) > -1) {
            this.category.value.splice(this.category.value.indexOf(val.item._id), 1);
          }
        } else if (val.from == "add") {
          if (this.category.value.indexOf(val.item._id) <= -1) {
            this.category.value.push(val.item._id);
          }
        }
      }
      if (val.task == "excel_file_data") {
        if (this.data.value.length <= 0 || (!this.data.value[0].name && !this.data.value[0].type)) {
          this.data.value = [];
          this.data.archive = [];
        }

        val.item.forEach((item) => {
          this.data.value.push({
            name: item[0],
            type: this.translateTypes[item[1]] || "String",
            id: v4(),
            value: "",
            enableArchive: false,
            required: false,
            enable: false, interval: null, uponChange: false,
            archived: false
          });
        });
        this.$root.$emit("bv::hide::modal", "modal-upload-excel-component");
      }
    }
  },
  computed: {
    archiveGenerator() {
      const start = (this.curPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.data.value.slice(start, end);
    },
    wb() {
      return this.$store.state.data.workbench;
    },
    toolbarGenerator() {
      if (!this.userPemission.includes("dataManager_add")) {
        return ["Search", "ExcelExport",
          {
            text: this.dict.refresh[this.lang],
            id: "refresh_table",
            prefixIcon: "e-icons e-refresh-2"
          }
        ];
      } else {
        return ["Search", "ExcelExport", {
          text: this.dict[this.activeTab][this.lang] + " " + this.dict.addData[this.lang],
          id: "add_data",
          prefixIcon: "e-icons e-add"
        },
          {
            text: this.dict.refresh[this.lang],
            id: "refresh_table",
            prefixIcon: "e-icons e-refresh-2"
          }];
      }
    },
    updatePage() {
      return this.$store.state.chpge.reload;
    },
    workbench() {
      return this.$store.state.data.workbench.activeWorkbench;
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    userPemission() {
      return this.$store.state.data.user.permissions;
    }
  },
  methods: {
    isClass(arg) {
      if (arg != "Number" && arg != "String" && arg != "Boolean" && arg != "Object" && arg != null)
        return true;
    },
    updateSwitch(value, field, id) {
      const index = this.data.value.indexOf(this.data.value.find(item => item.id == id));
      this.data.value[index][field] = value.checked;
    },
    v4,
    // async loadStyles() {
    //   if (this.theme === "material") {
    //     await import("@syncfusion/ej2-base/styles/material.css");
    //     await import("@syncfusion/ej2-vue-grids/styles/material.css");
    //   } else if (this.theme === "fabric") {
    //     await import("@syncfusion/ej2-base/styles/fabric-dark.css");
    //     await import("@syncfusion/ej2-vue-grids/styles/fabric-dark.css");
    //   }
    // },

    getValues(data, e) {
      this.selectedRow.find((item) => item.id == data.id).value = e.value;
    },
    getValueForData(e) {
      this.selectedVar.value = e.value;
    },
    getVal(e) {
      if (e.newProp.hasOwnProperty("pageSize"))
        this.perPage = e.newProp.pageSize;

      this.currentPage = e.currentPage;
      this.getContConf(e.currentPage, this.perPage);
    },
    getValArc(e) {
      if (e.newProp.hasOwnProperty("pageSize"))
        this.perPage = e.newProp.pageSize;

      this.curPage = e.currentPage;
    },
    actionBegin(args) {
      if (args.requestType == "paging") {
        this.getContConf(args.currentPage, this.perPage);
      }
      if (args.requestType == "add") {
        this.addJsonItem("data");
      }
      if (args.requestType == "refresh") {
        this.$refs.data_manager.showSpinner();
      }

    },
    excelQueryCellInfo(args) {
      args.value = JSON.stringify(args.value);
    },
    StructureActionBegin(args) {
      if (args.requestType == "add") {
        this.addJsonItem("config");
      }
    },
    categoryActionBegin(args) {
      if (args.requestType == "add") {
        this.addNewCategory();
      }
      if (args.requestType == "refresh") {
        this.$refs.data_manager_category.showSpinner();
      }
    },
    categoryBound() {
      this.$refs.data_manager_category.hideSpinner();
    },
    classBound() {
      this.$refs.data_manager_class.hideSpinner();
    },
    classActionBegin(args) {
      if (args.requestType == "add") {
        this.addNewClass();
      }
      if (args.requestType == "refresh") {
        this.$refs.data_manager_class.showSpinner();
      }
    },

    dataBounded() {
      this.$refs.data_manager.hideSpinner();
    },
    toolbarClick(args) {
      if (args.item.id.includes("excelexport")) {
        this.$refs.data_manager.excelExport();
      } else if (args.item.id.includes("add_data")) {
        this.addJsonItem("data");
      } else if (args.item.id.includes("refresh_table")) {
        this.initModal();
      }
    },
    pdfExportComplete() {
      this.$refs.data_manager.hideSpinner();
      this.$refs.data_manager_conf.hideSpinner();
      this.$refs.data_manager_class.hideSpinner();
      this.$refs.data_manager_category.hideSpinner();
    },
    confToolbarClick(args) {
      if (args.item.id.includes("excelexport")) {
        this.$refs.data_manager_conf.excelExport();
      }
      if (args.item.id.includes("add_conf")) {
        this.addJsonItem("config");
      }
    },
    classToolbarClick(args) {
      if (args.item.id.includes("excelexport")) {
        this.$refs.data_manager_class.excelExport();
      }
      if (args.item.id.includes("add_data")) {
        this.addNewClass();
      } else if (args.item.id.includes("refresh_table")) {
        this.getAllClasses();
      }
    },
    categoryToolbarClick(args) {
      if (args.item.id.includes("pdfexport")) {
        this.$refs.data_manager_category.showSpinner();
        this.$refs.data_manager_category.pdfExport();
      } else if (args.item.id.includes("excelexport")) {
        this.$refs.data_manager_category.excelExport();
      } else if (args.item.id.includes("csvexport")) {
        this.$refs.data_manager_category.csvExport();
      } else if (args.item.id.includes("add_data")) {
        this.addNewCategory();
      } else if (args.item.id.includes("refresh_table")) {
        this.getAllCategories();
      }
    },
    selectAllData(e) {
      if (e == true) {
        this.selectedDatas = this.data.value;
      } else this.selectedDatas = [];
    },
    deleteAll() {
      this.data.value = [
        {
          name: "",
          type: "",
          id: v4(),
          value: "",
          required: false,
          enable: false,
          interval: null,
          uponChange: false,
          archived: false
        }
      ];
      this.isAll = false;
      this.selectedDatas = [];
    },
    addNewValue() {
      this.data.value.push({
        name: "",
        type: "",
        id: v4(),
        value: "",
        required: false,
        enable: false,
        interval: null,
        uponChange: false,
        archived: false
      });
    },
    removeValue(id) {
      this.data.value.splice(this.data.value.indexOf(this.data.value.find((x) => x.id == id)), 1);
    },
    addNewClass() {
      this.modalMode = "New";
      this.$bvModal.show("class_generator_for_data");
    },
    addNewCategory() {
      this.modalMode = "New";
      this.$bvModal.show("category_generator_for_data");
    },
    showVal(id, val, type, item) {
      if (type != "Number" && type != "String" && type != "Boolean") {
        this.currentId = id;
        this.selectedRow = val;
        this.$bvModal.show("show-obj-val");
      } else {
        this.currentId = id;
        this.selectedVar = item;
        this.$bvModal.show("show-list-val");
      }

    },
    initModal() {
      this.title = "Data Management";
      this.getContConf(1, 5);

      // this.getAllControlNodes();
    },
    onReset() {
      this.topic = null;
      this.$store.dispatch("chpge/changePage", "lighting");
    },
    getContConf(currentPage, perPage) {
      this.$store.dispatch("data/getAllData", {
        dataType: ["!complex"],
        usedBy: [],
        parents: [],
        archive: null,
        getAllDataForDataManager: true,
        perPage: perPage,
        page: currentPage
      }).then(
        (data) => {
          this.confItems = data["newmatches"].filter(obj => {
            return (obj.type === "complex");
          });

          this.dataItems = data["newmatches"].filter(obj => {
            return (obj.type != "complex");
          });
          this.pages = data.numberOfalldatas;
          this.$refs.data_manager.refresh();
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          this.$refs.data_manager.hideSpinner();
          // this.successful = false;
        }
      );
    },
    addJsonItem(type) {
      if (type === "data")
        this.$store.dispatch("chpge/parent2child", { task: "sentData2edit", type: "New", item: {} });
      else if (type === "config")
        this.$store.dispatch("chpge/parent2child", { task: "sentConf2edit", type: "New", item: {} });

      this.$nextTick(() => {
        this.$root.$emit("bv::show::modal", "modal-data-manager");
      });
    },
    editJsonItem(type, item) {
      if (type === "data") {
        this.$store.dispatch("chpge/parent2child", { task: "sentData2edit", type: "Edit", item: item });

      } else if (type === "config") {
        this.$store.dispatch("chpge/parent2child", { task: "sentConf2edit", type: "Edit", item: item });
      }

      this.$nextTick(() => {
        this.$root.$emit("bv::show::modal", "modal-data-manager");
      });
    },
    removeJsonItem: function(item) {

      const DialogObj = DialogUtility.confirm({
        title: this.dict.deleteConfirm[this.lang],
        locale: this.lang,
        position: "center center",
        enableRtl: this.lang == "per",
        content: this.dict.deleteConfirmContent[this.lang],
        rtl: true,
        okButton: {
          text: this.dict.ok[this.lang], click: () => {
            this.$store.dispatch("data/removeData", { _id: item._id }).then(
              data => {
                this.message = data.message;
                this.successful = true;
                this.getContConf(1, 5);
                this.$refs.data_manager.refresh();
                this.currentPage = 1;
              },
              error => {
                this.message =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
                this.successful = false;
              }
            );
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }
    },
    initClassModal() {
      setTimeout(() => {
        this.renderTime += 1;
      }, 500);
      if (this.modalMode == "New") {
        this.data = {
          value: [
            {
              name: "", type: "", id: v4(), value: "", required: false,
              enable: false,
              interval: null,
              uponChange: false
            }
          ],
          name: "",
          dataType: ""
        };
      }
    },
    resetClass() {
      this.validator = null;
      this.selectedTab = "Definition";
      this.data = {
        value: [
          { name: "", type: "", id: v4(), value: "", required: false, enable: false, interval: null, uponChange: false }
        ],
        name: "",
        dataType: ""
      };
      this.modalMode = "New";
      this.renderTime = 0;
      this.isAll = false;
    },
    createNewClass($bv) {
      $bv.preventDefault();
      const isValid = new FormValidator("#data_mnager_define").validate();
      if (isValid) {
        if (this.modalMode == "Edit") {
          this.updateClass();
        } else {
          this.$store.dispatch("data/createNewClass", this.data).then((res) => {
            this.getAllClasses();
          }).catch(() => {
          });
        }

        this.$nextTick(() => {
          this.$bvModal.hide("class_generator_for_data");
        });
      }
    },
    getAllClasses() {
      this.$store.dispatch("data/getAllClasses").then((res) => {
        this.classOptions.items = res;
        this.classOptions.totalRows = res.length;
      });
    },
    getAllCategories() {
      this.categoryList = [];
      this.$store.dispatch("data/getAllCategory").then((res) => {
        this.categoryOptions.items = res;
        this.categoryOptions.totalRows = res.length;
        res.forEach((item) => {
          this.categoryList.push({ text: item.name, value: item._id });
        });
      });
    },
    removeClass(id) {
      const DialogObj = DialogUtility.confirm({
        title: this.dict.deleteConfirm[this.lang],
        locale: this.lang,
        position: "center center",
        enableRtl: this.lang == "per",
        content: this.dict.deleteConfirmContent[this.lang],
        rtl: true,
        okButton: {
          text: this.dict.ok[this.lang], click: () => {
            this.$store.dispatch("data/removeClassForData", { id: id }).then(() => {
              this.getAllClasses();
              this.$refs.data_manager_class.refresh();
            }).catch((error) => {
              // alert((error.response && error.response.data && error.response.data.message) ||
              //   error.message ||
              //   error.toString());
            });

            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }

    },
    removeCategory(id) {
      const DialogObj = DialogUtility.confirm({
        title: this.dict.deleteConfirm[this.lang],
        locale: this.lang,
        position: "center center",
        enableRtl: this.lang == "per",
        content: this.dict.deleteConfirmContent[this.lang],
        rtl: true,
        okButton: {
          text: this.dict.ok[this.lang], click: () => {
            this.$store.dispatch("data/removeCategoryForData", { id: id }).then(() => {
              this.getAllCategories();
              this.$refs.data_manager_category.refresh();
            }).catch((error) => {
              // alert((error.response && error.response.data && error.response.data.message) ||
              //   error.message ||
              //   error.toString());
            });

            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }
    },
    getClassById(item) {
      this.selectedId = item._id;
      this.data = item;
      this.modalMode = "Edit";
      this.$bvModal.show("class_generator_for_data");
      // this.$store.dispatch("data/getClassById", { id: id }).then((res) => {
      //   this.data = res;
      //   this.modalMode = "Edit";
      //   this.$bvModal.show("class_generator_for_data");
      // });
    },
    updateClass() {
      this.$store.dispatch("data/updateClass", {
        id: this.selectedId,
        name: this.data.name,
        value: this.data.value
      }).then((res) => {
        this.$bvModal.hide("class_generator_for_data");
        this.modalMode = "New";
        this.getAllClasses();
      });
    },
    uploadExcelFile() {
      this.$root.$emit("bv::show::modal", "modal-upload-excel-component");
    },
    updateValues($bv) {
      $bv.preventDefault();
      let updatedData = [];
      this.selectedRow.forEach((item) => {
        updatedData.push({ id: item.id, value: item.value });
      });

      const isValid = new FormValidator("#data_value_manage").validate();
      if (isValid) {
        this.$store.dispatch("data/updateData", { _id: this.currentId, values: updatedData }).then(
          data => {

            this.message = data.message;
            this.successful = true;

            this.$store.dispatch("chpge/updatePage", this.updatePage + 1);
            this.$nextTick(() => {
              this.$bvModal.hide("show-obj-val");
            });
          },
          error => {
            this.message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            this.successful = false;
            // alert(this.message);
          }
        );
      }
    },
    initValueModal() {
      this.valueTableOptions.totalRows = this.selectedRow.length;
    },
    editValuesData($bv) {
      $bv.preventDefault();
      this.$store.dispatch("data/updateData", {
        _id: this.currentId,
        values: [{ id: "", value: this.selectedVar.value }]
      }).then(
        data => {

          this.message = data.message;
          this.successful = true;

          this.$store.dispatch("chpge/updatePage", this.updatePage + 1);
          this.$nextTick(() => {
            this.$bvModal.hide("show-list-val");
          });
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
          // alert(this.message);
        }
      );
    },
    initModalCats() {
      setTimeout(() => {
        this.renderTime += 1;
        this.validator2 = new FormValidator("#data_category_manage");
      }, 200);
    },
    resetCategory() {
      this.category = {
        name: "",
        type: "",
        value: [],
        id: v4()
      };
      this.renderTime = 0;
      this.selectedId = null;
      this.$store.state.chpge.parent2child = null;

    },
    createNewCategory($bv) {
      $bv.preventDefault();
      const isValid = new FormValidator("#data_category_manage").validate()
      if (isValid) {
        if (this.modalMode == "Edit") {
          this.updateCategories();
        } else {
          this.$store.dispatch("data/createNewCategory", this.category).then((res) => {
            this.$nextTick(() => {
              this.$bvModal.hide("category_generator_for_data");
            });
            this.getAllCategories();
          });
        }
      }
    },
    updateCategories() {
      console.log(this.category);
      this.$store.dispatch("data/updateCategory", {
        id: this.selectedId,
        name: this.category.name,
        value: this.category.value
      }).then((res) => {
        this.getAllCategories();
        this.$bvModal.hide("category_generator_for_data");
        this.modalMode = "New";
      });
    },
    editCategory(item) {
      this.category.value = [];
      item.value.forEach(x => this.category.value.push(x._id));
      this.selectedId = item._id;
      this.category.name = item.name;
      this.category.type = item.type;
      this.modalMode = "Edit";
      this.$bvModal.show("category_generator_for_data");
    },
    pickData() {
      let filterParents = [];
      this.$store.dispatch("chpge/parent2child", {
        task: "pickData",
        from: "getPostMap",
        filter: {
          dataType: ["!complex"],
          usedBy: [],
          parents: filterParents,
          archive: null,
          withChild: false,
          perPage: 5000,
          page: 1
        }
      });
      this.$root.$emit("bv::show::modal", "modal-pick-data");
    },
    getCategories() {
      this.$store.dispatch("data/getAllCategoryforList").then((res) => {
        res.forEach((item) => {
          this.categoryList.push({
            text: item.name, value: item._id
          });
        });
      });
    },
    sendDataSSE() {
      let vars = [];
      this.dataItems.forEach((item) => {
        vars.push(item.id);
      });
      this.$store.dispatch("data/sendDataForSSE", { id: vars });
    }
  },
  mounted() {
    this.initModal();
    this.getAllClasses();
    this.getAllCategories();
    // this.$store.dispatch("data/sendDataForSSE", { id: [] });
  },
  provide: {
    grid: [Page, Sort, Toolbar, Edit, Search, ExcelExport]
  }
};
</script>
<style scoped src="@/assets/styles/theme.css"></style>
<style scoped>
.disableArchive {
  color: rgba(0, 0, 0, 0.42);
  background: transparent linear-gradient(90deg, rgba(0, 0, 0, 0.42) 0, rgba(0, 0, 0, 0.42) 33%, transparent 0) repeat-x bottom -1px left 0;
  background-size: 4px 1px;
  border-bottom-color: transparent;
  pointer-events: none;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.check-mark {
  color: #22d3ee;
}

.x-mark {
  color: #164e635d;
}

.apply {
  color: #198754;
  cursor: pointer;
}

.apply :hover {
  color: #9b9b9b;
}

.reset {
  color: #dc3545;
  cursor: pointer;
}

.reset :hover {
  color: #9b9b9b;
}

.download-link {
  margin-bottom: 10px;
}
</style>
<style scoped>
.shimmer-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 10; /* Above grid */
}

.shimmer-item {
  width: 80%;
  height: 20px;
  background: linear-gradient(
    90deg,
    rgba(200, 200, 200, 0.2) 25%,
    rgba(200, 200, 200, 0.6) 50%,
    rgba(200, 200, 200, 0.2) 75%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: transform 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(-100%);
}
</style>

