import DataService from "../services/data.service";
import axios from "axios";
import authHeader from "@/services/auth-header";

// const user = JSON.parse(localStorage.getItem('user'));
// const initialState = user
//   ? { status: { loggedIn: true }, user, allUsers:null }
//   : { status: { loggedIn: false }, user: null, allUsers:null };

const initialState = {
  avatarUpdated: 0,
  contConf: null,
  haveSignUp: {},
  buildConf: null,
  equipments: null,
  dashboard: null,
  groups: null,
  Scenarios: null,
  floorScenario: null,
  data: null,
  workbench: null,
  user: null,
  expired: false,
  permissionList: [],
  roleList: [],
  sysRoles: [],
  workbenchLists: [],
  adminUsersLists: [],
  allResources: [],
  mapLayers: [],
  notifSetting: {},
  usedResources: [],
  resource: {
    takhsis: {},
    used: {},
    all: {}
  },
  currentMarker: {},
  markerList: [],
  markerTanker: [],
  xslxDatas: [],
  theme: "material"
};

export const data = {
  namespaced: true,
  state: initialState,
  getters: {
    getAllResource: state => {
      return state.resource;
    },
    getUserPermission: state => {
      return state.user;
    },
    updateAvatar: state => {
      return state.avatarUpdated;
    }

  },
  actions: {
    addRuleEditorCell({ commit }, item) {
      return DataService.addRuleEditorCell(item).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    removeRuleEditorCell({ commit }, item) {
      return DataService.removeRuleEditorCell(item).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    editRuleEditorCell({ commit }, item) {
      return DataService.editRuleEditorCell(item).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getRuleEditorCells({ commit }) {
      return DataService.getRuleEditorCells().then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getRuleEditorBlocks({ commit }) {
      return DataService.getRuleEditorBlocks().then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getRuleEditorCategories({ commit }) {
      return DataService.getRuleEditorCategories().then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updateAgain({ commit }, data) {
      commit("avatarUpdated", data);
    },
    getNotifSetting({ commit }) {
      return DataService.getNotifListSetting().then(
        (response) => {
          commit("updateNotifSetting", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    createSetting({ commit }, data) {
      return DataService.createSettingForNotif(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    updateSetting({ commit }, data) {
      return DataService.updateSettingForNotif(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },
    getAllNotifs({ commit }, data) {
      return DataService.getNotifList(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },
    seenNotif({ commit }, data) {
      return DataService.seenNotification(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    deleteNotif({ commit }, data) {
      return DataService.removeNotification(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },
    deleteNotifSetting({ commit }, data) {
      return DataService.deleteSettingForNotif(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },
    getSSOPage({ commit }, data) {
      return DataService.getSSOUrl(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },
    getUsersAvatar({ commit }, data) {
      return DataService.usersAvatar(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },
    getWbAvatar({ commit }, data) {
      return DataService.WbAvatar(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },
    setTheme({ commit }, theme) {
      commit("SET_THEME", theme);
    },
    resetPasswordPost({ commit }, data) {
      return DataService.resetPassword(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },
    saveEventSetting({ commit }, data) {
      return DataService.createEventSetting(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },
    createNewEventType({ commit }, data) {
      return DataService.createEventType(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    createNewEventRule({ commit }, data) {
      return DataService.createEventRule(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    createNewCategory({ commit }, data) {
      return DataService.createCategoryForData(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },
    getAllCategory({ commit }, data) {
      return DataService.getAllCategoryForData().then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getAllEventTypes({ commit }, data) {
      return DataService.getAllEventTyps().then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    getAllEventRules({ commit }, data) {
      return DataService.getAllEventRule().then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getAllCategoryforList({ commit }, data) {
      return DataService.getAllCategoryListForData().then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    removeCategoryForData({ commit }, data) {
      return DataService.deleteCategoryForData(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    removeEventTypes({ commit }, data) {
      return DataService.deleteEventTypes(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    removeEventRule({ commit }, data) {
      return DataService.deleteEventRules(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updateEventTypes({ commit }, data) {
      return DataService.updateEventTypes(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    updateEventRule({ commit }, data) {
      return DataService.updateEventRules(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    updateCategory({ commit }, data) {
      return DataService.updateCategoryForData(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    createNewClass({ commit }, data) {
      return DataService.createClassForData(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },
    getAllClasses({ commit }, data) {
      return DataService.getAllClassForData().then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    removeClassForData({ commit }, data) {
      return DataService.deleteClassForData(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    getClassById({ commit }, data) {
      return DataService.getOneClassForData(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    updateClass({ commit }, data) {
      return DataService.updateClassForData(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },


    getXlsxConfirm({ commit }, data) {
      return DataService.getXlsxConfirm().then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    getKCloakes({ commit }, data) {
      return DataService.getAllCloakes().then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    createCloak({ commit }, data) {
      return DataService.createKeyCloak(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    deleteCloak({ commit }, data) {
      return DataService.deleteKeyCloak(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    editCloak({ commit }, data) {
      return DataService.editKeyCloak(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },
    editCloakStatus({ commit }, data) {
      return DataService.editKeyCloakStatus(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    getCloakById({ commit }, data) {
      return DataService.getCloakesById(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },


    removeAllXslxFile({ commit }, data) {
      return DataService.removeAllXslx(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    removeSeletedRec({ commit }, data) {
      return DataService.removeSelectedXslx(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    deleteMarker({ commit }, data) {
      return DataService.removeMarker(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    createMapLayer({ commit }, data) {
      return DataService.createMapLayers(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    getSingleMapLayer({ commit }, data) {
      return DataService.getSingleMap(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    editMapLayer({ commit }, data) {
      return DataService.editMapLayers(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    getAllMaps({ commit }) {
      return DataService.getMapLayers().then(
        (response) => {
          commit("updateLayers", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    invFromAdmin({ commit }, data) {
      return DataService.inviteToWbAdmin(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    uploadFiles({ commit }, data) {
      return DataService.uploadLicence(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    uploadFileXlsx({ commit }, data) {
      return DataService.uploadXlsxFile(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    dlXlsx({ commit }, data) {
      return DataService.searchForXlsx(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    checkValidateLicense({ commit }) {
      return DataService.checkLicenceValidate().then(
        (response) => {
          commit("updateLicenseStatus", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    getPermission({ commit }) {
      return DataService.getPermissionList().then(
        (response) => {
          commit("updatePermissionList", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    getSignupSetting({ commit }) {
      return DataService.getAdminSignUp().then(
        (response) => {
          commit("updateSignupSetting", response.data.message);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },
    setAdminRegister({ commit }, data) {
      return DataService.setAdminSignUp(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    getRolesList({ commit }) {
      return DataService.getRoles().then(
        (response) => {
          commit("updateRolesList", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },

    getRolesListAdmin({ commit }, data) {
      return DataService.getRolesAdmin(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },
    getSysRolesList({ commit }) {
      return DataService.getSysRoles().then(
        (response) => {
          commit("updateSysRoles", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },
    removeRoleById({ commit }, data) {
      return DataService.removeRole(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getWbLicenceById({ commit }, data) {
      return DataService.getLicenceById(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    setWbLicence({ commit }, data) {
      return DataService.setLicences(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getCurWbLicence({ commit }, data) {
      return DataService.getWbCurLicences(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getRoleData({ commit }, data) {
      return DataService.getTheRole(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updateRoleInfo({ commit }, data) {
      return DataService.editRole(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getContConf({ commit }) {
      return DataService.getContConf().then(
        (response) => {
          commit("getContConfSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("getContConfFailure");
          return Promise.reject(error);
        }
      );
    },
    uploadContConf({ commit }) {
      return DataService.uploadContConf().then(
        (response) => {
          // commit('getContConfSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getContConfFailure');
          return Promise.reject(error);
        }
      );
    },
    createMaster({ commit }, data) {
      return DataService.createMaster(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    sendMasterSetting({ commit }, data) {
      return DataService.sendSettingMaster(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    applyMasterSetting({ commit }, data) {
      return DataService.applySettingMaster(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    createRole({ commit }, data) {
      return DataService.createNewRole(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    createRoleForAdmin({ commit }, data) {
      return DataService.createNewRoleAdmin(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    editMaster({ commit }, data) {
      return DataService.editMaster(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    removeMaster({ commit }, data) {
      return DataService.removeMaster(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getFileMaster({ commit }, data) {
      return DataService.getFileMaster(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    createCloud({ commit }, data) {
      return DataService.createCloud(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    createHttpServer({ commit }, data) {
      return DataService.createhttpRequest(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    editHttpServer({ commit }, data) {
      return DataService.editHttpRequest(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    editCloud({ commit }, data) {
      return DataService.editCloud(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    editIngestion({ commit }, data) {
      return DataService.updateIngestion(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    removeCloud({ commit }, data) {
      return DataService.removeCloud(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getFileCloud({ commit }, data) {
      return DataService.getFileCloud(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    uploadFileCloud({ commit }, formData) {
      return DataService.uploadFileCloud(formData).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    createHttps({ commit }, data) {
      return DataService.createHttps(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    editHttps({ commit }, data) {
      return DataService.editHttps(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    removeHttps({ commit }, data) {
      return DataService.removeHttps(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    removeHttpserver({ commit }, data) {
      return DataService.removeHttpServer(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getFileHttps({ commit }, data) {
      return DataService.getFileHttps(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    createSlave({ commit }, data) {
      return DataService.createSlave(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    editSlave({ commit }, data) {
      return DataService.editSlave(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    removeSlave({ commit }, data) {
      return DataService.removeSlave(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    createSlot({ commit }, data) {
      return DataService.createSlot(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    editSlot({ commit }, data) {
      return DataService.editSlot(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    removeSlot({ commit }, data) {
      return DataService.removeSlot(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    // getContConf({ commit }) {
    //   return DataService.getContConf().then(
    //     response => {
    //       commit('getContConfSuccess', response.data);
    //       return Promise.resolve(response.data);
    //     },
    //     error => {
    //       commit('getContConfFailure');
    //       return Promise.reject(error);
    //     }
    //   );
    // },
    // uploadContConf({ commit }) {
    //   return DataService.uploadContConf().then(
    //     response => {
    //       // commit('getContConfSuccess', response.data);
    //       return Promise.resolve(response.data);
    //     },
    //     error => {
    //       // commit('getContConfFailure');
    //       return Promise.reject(error);
    //     }
    //   );
    // },
    // createMaster({ commit }, topic) {
    //   return DataService.createMaster(topic).then(
    //     response => {
    //       //commit('createMasterSuccess');
    //       return Promise.resolve(response.data);
    //     },
    //     error => {
    //       //commit('createMasterFailure');
    //       return Promise.reject(error);
    //     }
    //   );
    // },
    // editMaster({ commit }, topic) {
    //   return DataService.editMaster(topic).then(
    //     response => {
    //       //commit('createMasterSuccess');
    //       return Promise.resolve(response.data);
    //     },
    //     error => {
    //       //commit('createMasterFailure');
    //       return Promise.reject(error);
    //     }
    //   );
    // },
    // removeMaster({ commit }, topic) {
    //   return DataService.removeMaster(topic).then(
    //     response => {
    //       //commit('createMasterSuccess');
    //       return Promise.resolve(response.data);
    //     },
    //     error => {
    //       //commit('createMasterFailure');
    //       return Promise.reject(error);
    //     }
    //   );
    // },
    // createSlave({ commit }, slave) {
    //   return DataService.createSlave(slave).then(
    //     response => {
    //       //commit('createMasterSuccess');
    //       return Promise.resolve(response.data);
    //     },
    //     error => {
    //       //commit('createMasterFailure');
    //       return Promise.reject(error);
    //     }
    //   );
    // },
    // editSlave({ commit }, slave) {
    //   return DataService.editSlave(slave).then(
    //     response => {
    //       //commit('createMasterSuccess');
    //       return Promise.resolve(response.data);
    //     },
    //     error => {
    //       //commit('createMasterFailure');
    //       return Promise.reject(error);
    //     }
    //   );
    // },
    // removeSlave({ commit }, slave) {
    //   return DataService.removeSlave(slave).then(
    //     response => {
    //       //commit('createMasterSuccess');
    //       return Promise.resolve(response.data);
    //     },
    //     error => {
    //       //commit('createMasterFailure');
    //       return Promise.reject(error);
    //     }
    //   );
    // },
    // createSlot({ commit }, slot) {
    //   return DataService.createSlot(slot).then(
    //     response => {
    //       //commit('createMasterSuccess');
    //       return Promise.resolve(response.data);
    //     },
    //     error => {
    //       //commit('createMasterFailure');
    //       return Promise.reject(error);
    //     }
    //   );
    // },
    // editSlot({ commit }, slot) {
    //   return DataService.editSlot(slot).then(
    //     response => {
    //       //commit('createMasterSuccess');
    //       return Promise.resolve(response.data);
    //     },
    //     error => {
    //       //commit('createMasterFailure');
    //       return Promise.reject(error);
    //     }
    //   );
    // },
    // removeSlot({ commit }, slot) {
    //   return DataService.removeSlot(slot).then(
    //     response => {
    //       //commit('createMasterSuccess');
    //       return Promise.resolve(response.data);
    //     },
    //     error => {
    //       //commit('createMasterFailure');
    //       return Promise.reject(error);
    //     }
    //   );
    // },
    //======================================================
    uploadMap({ commit }, formData) {
      return DataService.uploadMap(formData).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    restoreBackup({ commit }, formData) {
      return DataService.uploadbackUp(formData).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getBackup({ commit }) {
      return DataService.getBackups().then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getBuildConf({ commit }) {
      return DataService.getBuildConf().then(
        (response) => {
          commit("getBuildConfSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("getBuildConfFailure");
          return Promise.reject(error);
        }
      );
    },
    getfloorMap({ commit }, floor) {
      return DataService.getfloorMap(floor).then(
        (response) => {
          // commit('getBuildConfSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getBuildConfFailure');
          return Promise.reject(error);
        }
      );
    },
    classGetById({ commit }, floor) {
      return DataService.getClassId(floor).then(
        (response) => {
          // commit('getBuildConfSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getBuildConfFailure');
          return Promise.reject(error);
        }
      );
    },

    formGetById({ commit }, floor) {
      return DataService.getFormId(floor).then(
        (response) => {
          // commit('getBuildConfSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getBuildConfFailure');
          return Promise.reject(error);
        }
      );
    },

    getfloorScenarios({ commit }, floor) {
      return DataService.getfloorScenarios(floor).then(
        (response) => {
          commit("floorScenarioSuccess", {
            floor: floor,
            selectedScenario: response.data.selectedScenario
              ? response.data.selectedScenario.name
              : null
          }); //
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getBuildConfFailure');
          return Promise.reject(error);
        }
      );
    },
    setfloorScenario({ commit }, data) {
      commit("floorScenarioSuccess", data);
      return DataService.setfloorScenario(data).then(
        (response) => {
          // commit('getBuildConfSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getBuildConfFailure');
          return Promise.reject(error);
        }
      );
    },

    editFloor({ commit }, floor) {
      return DataService.editFloor(floor).then(
        (response) => {
          //commit('createMasterSuccess');
          return Promise.resolve(response.data);
        },
        (error) => {
          //commit('createMasterFailure');
          return Promise.reject(error);
        }
      );
    },
    removeFloor({ commit }, floor) {
      return DataService.removeFloor(floor).then(
        (response) => {
          //commit('createMasterSuccess');
          return Promise.resolve(response.data);
        },
        (error) => {
          //commit('createMasterFailure');
          return Promise.reject(error);
        }
      );
    },
    editZone({ commit }, zone) {
      return DataService.editZone(zone).then(
        (response) => {
          //commit('createMasterSuccess');
          return Promise.resolve(response.data);
        },
        (error) => {
          //commit('createMasterFailure');
          return Promise.reject(error);
        }
      );
    },
    // getZone({ commit }, zone) {
    //   return DataService.getZone(zone).then(
    //     response => {
    //       //commit('createMasterSuccess');
    //       return Promise.resolve(response.data);
    //     },
    //     error => {
    //       //commit('createMasterFailure');
    //       return Promise.reject(error);
    //     }
    //   );
    // },
    //======================================================
    getEquipments({ commit }) {
      return DataService.getEquipments().then(
        (response) => {
          // commit('getEquipmentsSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getEquipmentsFailure');
          return Promise.reject(error);
        }
      );
    },
    getEquipmentsofFloor({ commit }, data) {
      return DataService.getEquipmentsofFloor(data).then(
        (response) => {
          //commit('clickonMapSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          //commit('clickonMapFailure');
          return Promise.reject(error);
        }
      );
    },

    createEquipment({ commit }, equipment) {
      return DataService.createEquipment(equipment).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    editEquipment({ commit }, equipment) {
      return DataService.editEquipment(equipment).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    removeEquipment({ commit }, equipment) {
      return DataService.removeEquipment(equipment).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    //======================================================
    // getData({ commit },id) {
    //   console.log(id)
    //   return DataService.getData(id).then(
    //     response => {
    //       commit('getDataSuccess', response.data);
    //       return Promise.resolve(response.data);
    //     },
    //     error => {
    //       commit('getDataFailure');
    //       return Promise.reject(error);
    //     }
    //   );
    // },

    // createEdge({ commit },edge) {
    //   return DataService.createEdge(edge).then(
    //     response => {
    //       return Promise.resolve(response.data);
    //     },
    //     error => {
    //       return Promise.reject(error);
    //     }
    //   );
    // },

    // createRecord({ commit },record) {
    //   return DataService.createRecord(record).then(
    //     response => {
    //       return Promise.resolve(response.data);
    //     },
    //     error => {
    //       return Promise.reject(error);
    //     }
    //   );
    // },
    //======================================================
    getGroups({ commit }) {
      return DataService.getGroups().then(
        (response) => {
          commit("getGroupsSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("getGroupsFailure");
          return Promise.reject(error);
        }
      );
    },
    getGroupsofFloor({ commit }, data) {
      return DataService.getGroupsofFloor(data).then(
        (response) => {
          //commit('clickonMapSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          //commit('clickonMapFailure');
          return Promise.reject(error);
        }
      );
    },
    createGroup({ commit }, equipment) {
      return DataService.createGroup(equipment).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    editGroup({ commit }, equipment) {
      return DataService.editGroup(equipment).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    removeGroup({ commit }, equipment) {
      return DataService.removeGroup(equipment).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    //======================================================
    getScenarios({ commit }) {
      return DataService.getScenarios().then(
        (response) => {
          commit("getScenariosSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("getScenariosFailure");
          return Promise.reject(error);
        }
      );
    },
    createScenario({ commit }, scenario) {
      return DataService.createScenario(scenario).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    editScenario({ commit }, scenario) {
      return DataService.editScenario(scenario).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    removeScenario({ commit }, scenario) {
      return DataService.removeScenario(scenario).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    //======================================================
    createDashboard({ commit }, dashboard) {
      return DataService.createDashboard(dashboard).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    addDashboardItem({ commit }, item) {
      return DataService.addDashboardItem(item).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    removeDashboardItem({ commit }, item) {
      return DataService.removeDashboardItem(item).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    editDashboard({ commit }, dashboard) {
      return DataService.editDashboard(dashboard).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getDashboard({ commit }, data) {
      return DataService.getDashboard(data).then(
        (response) => {
          commit("getDashboardSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("getDashboardFailure");
          return Promise.reject(error);
        }
      );
    },
    getWidgetsList({ commit }) {
      return DataService.getWidgetsList().then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getWidgetFile({ commit }, data) {
      return DataService.getWidgetFile(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    setRecordSettings({ commit }, data) {
      return DataService.setRecordSettings(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getRecordSettings({ commit }) {
      return DataService.getRecordSettings().then(
        (response) => {
          // commit('getRecordSettingsSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getRecordSettingsFailure');
          return Promise.reject(error);
        }
      );
    },
    getChartData({ commit }, data) {
      return DataService.getChartData(data).then(
        (response) => {
          // commit('getContConfSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getContConfFailure');
          return Promise.reject(error);
        }
      );
    },
    getHistoricalData({ commit }, data) {
      return DataService.getHistoricalData(data).then(
        (response) => {
          // commit('getContConfSuccess', response.data);
          return Promise.resolve(response);
        },
        (error) => {
          // commit('getContConfFailure');
          return Promise.reject(error);
        }
      );
    },
    getSingleIngestion({ commit }, data) {
      return DataService.getsingleIngestion(data).then(
        (response) => {
          // commit('getContConfSuccess', response.data);
          return Promise.resolve(response);
        },
        (error) => {
          // commit('getContConfFailure');
          return Promise.reject(error);
        }
      );
    },
    removeIngestion({ commit }, data) {
      return DataService.ingestionRemove(data).then(
        (response) => {
          // commit('getContConfSuccess', response.data);
          return Promise.resolve(response);
        },
        (error) => {
          // commit('getContConfFailure');
          return Promise.reject(error);
        }
      );
    },
    cancelIngestion({ commit }, data) {
      return DataService.ingestionCancel(data).then(
        (response) => {
          // commit('getContConfSuccess', response.data);
          return Promise.resolve(response);
        },
        (error) => {
          // commit('getContConfFailure');
          return Promise.reject(error);
        }
      );
    },
    getAllIngestion({ commit }) {
      return DataService.getDataIngestion().then(
        (response) => {
          // commit('getContConfSuccess', response.data);
          return Promise.resolve(response);
        },
        (error) => {
          // commit('getContConfFailure');
          return Promise.reject(error);
        }
      );
    },
    getEdgeById({ commit }, data) {
      return DataService.getData(data).then(
        (response) => {
          // commit('getContConfSuccess', response.data);
          return Promise.resolve(response);
        },
        (error) => {
          // commit('getContConfFailure');
          return Promise.reject(error);
        }
      );
    },
    getControlBlocks({ commit }, data) {
      return DataService.getControlBlocks(data).then(
        (response) => {
          // commit('getContConfSuccess', response.data);
          return Promise.resolve(response);
        },
        (error) => {
          // commit('getContConfFailure');
          return Promise.reject(error);
        }
      );
    },
    addControlBlocks({ commit }, data) {
      return DataService.addControlBlocks(data).then(
        (response) => {
          // commit('getContConfSuccess', response.data);
          return Promise.resolve(response);
        },
        (error) => {
          // commit('getContConfFailure');
          return Promise.reject(error);
        }
      );
    },
    uploadControlBlocks({ commit }, formData) {
      return DataService.uploadControlBlocks(formData).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getMainSysLicences({ commit }) {
      return DataService.mainLicences().then(
        (response) => {
          commit("getAllRes", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    getUsedSysLicences({ commit }) {
      return DataService.getUsageLicence().then(
        (response) => {
          commit("getAllUse", response.data);
          commit("getUsedResources", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    getAllSysTakhsis({ commit }) {
      return DataService.getAlltakhsis().then(
        (response) => {
          commit("getResTakh", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    nodeHandler({ commit }, data) {
      return DataService.nodeHandler(data).then(
        (response) => {
          // commit('getContConfSuccess', response.data);
          return Promise.resolve(response);
        },
        (error) => {
          // commit('getContConfFailure');
          return Promise.reject(error);
        }
      );
    },
    getNodeData({ commit }, data) {
      return DataService.getNodeData(data).then(
        (response) => {
          // commit('getContConfSuccess', response.data);
          return Promise.resolve(response);
        },
        (error) => {
          // commit('getContConfFailure');
          return Promise.reject(error);
        }
      );
    },

    linkHandler({ commit }, data) {
      return DataService.linkHandler(data).then(
        (response) => {
          // commit('getContConfSuccess', response.data);
          return Promise.resolve(response);
        },
        (error) => {
          // commit('getContConfFailure');
          return Promise.reject(error);
        }
      );
    },
    eventGetCur({ commit }, data) {
      return DataService.eventGetCur(data).then(
        (response) => {
          // commit('getContConfSuccess', response.data);
          return Promise.resolve(response);
        },
        (error) => {
          // commit('getContConfFailure');
          return Promise.reject(error);
        }
      );
    },
    eventGetArc({ commit }, data) {
      return DataService.eventGetArc(data).then(
        (response) => {
          // commit('getContConfSuccess', response.data);
          return Promise.resolve(response);
        },
        (error) => {
          // commit('getContConfFailure');
          return Promise.reject(error);
        }
      );
    },
    eventAcknowledge({ commit }, data) {
      return DataService.eventAcknowledge(data).then(
        (response) => {
          // commit('getContConfSuccess', response.data);
          return Promise.resolve(response);
        },
        (error) => {
          // commit('getContConfFailure');
          return Promise.reject(error);
        }
      );
    },
    eventRemove({ commit }, data) {
      return DataService.eventRemove(data).then(
        (response) => {
          // commit('getContConfSuccess', response.data);
          return Promise.resolve(response);
        },
        (error) => {
          // commit('getContConfFailure');
          return Promise.reject(error);
        }
      );
    },
    editLicenceLimit({ commit }, data) {
      return DataService.editLimitForLicence(data).then(
        (response) => {
          // commit('getDataSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getDataFailure');
          return Promise.reject(error);
        }
      );
    },
    getAllForms({ commit }) {
      return DataService.getFormsList().then(
        (response) => {
          // commit('getDataSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getDataFailure');
          return Promise.reject(error);
        }
      );
    },
    getAllData({ commit }, data) {
      return DataService.getAllData(data).then(
        (response) => {
          // commit('getDataSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getDataFailure');
          return Promise.reject(error);
        }
      );
    },
    createData({ commit }, data) {
      return DataService.createData(data).then(
        (response) => {
          // commit('getDataSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getDataFailure');
          return Promise.reject(error);
        }
      );
    },
    newFormGeneration({ commit }, data) {
      return DataService.createForm(data).then(
        (response) => {
          // commit('getDataSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getDataFailure');
          return Promise.reject(error);
        }
      );
    },
    editFormGeneration({ commit }, data) {
      return DataService.updateForm(data).then(
        (response) => {
          // commit('getDataSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getDataFailure');
          return Promise.reject(error);
        }
      );
    },
    removeFormGeneration({ commit }, data) {
      return DataService.removeForm(data).then(
        (response) => {
          // commit('getDataSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getDataFailure');
          return Promise.reject(error);
        }
      );
    },
    updateData({ commit }, data) {
      return DataService.updateData(data).then(
        (response) => {
          // commit('getDataSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getDataFailure');
          return Promise.reject(error);
        }
      );
    },
    removeData({ commit }, data) {
      return DataService.removeData(data).then(
        (response) => {
          // commit('getDataSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getDataFailure');
          return Promise.reject(error);
        }
      );
    },
    getWBdata({ commit }) {
      return DataService.getWBdata().then(
        (response) => {
          commit("getWBdata", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getWBListdata({ commit }) {
      return DataService.getWBdataList().then(
        (response) => {
          commit("getWBdatas", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getUsersAdminListdata({ commit }) {
      return DataService.getUsersAdminataList().then(
        (response) => {
          commit("getUserAdminListdatas", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    changeWBlabel({ commit }, data) {
      return DataService.changeWBlabel(data).then(
        (response) => {
          commit("getWBdata", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    editWBProfile({ commit }, form) {
      return DataService.editWBProfile(form).then(
        (response) => {
          commit("getWBdata", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    changeWBowner({ commit }, data) {
      return DataService.changeWBowner(data).then(
        (response) => {
          commit("getWBdata", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    createWB({ commit }, wb) {
      return DataService.createWB(wb).then(
        (uwb) => {
          commit("getWBdata", uwb.data);
          return Promise.resolve(uwb);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    selectWB({ commit }, wb) {
      return DataService.selectWB(wb).then(
        (uwb) => {
          commit("getWBdata", uwb.data);
          return Promise.resolve(uwb);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getUserData({ commit }) {
      return DataService.getUserData().then(
        (response) => {
          commit("getUserData", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    removeUserData({ commit }) {
      commit("removeUserData");
    },
    editUserData({ commit }, data) {
      return DataService.editUserData(data).then(
        (response) => {
          commit("getUserData", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    uploadUserAvatar({ commit }, form) {
      return DataService.uploadUserAvatar(form).then(
        (response) => {
          commit("getUserData", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    getWBusers({ commit }, data) {
      return DataService.getWBusers(data).then(
        (response) => {
          // commit('getDataSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getDataFailure');
          return Promise.reject(error);
        }
      );
    },
    inviteWBusers({ commit }, data) {
      return DataService.inviteWBusers(data).then(
        (response) => {
          commit("getWBdata", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getDataFailure');
          return Promise.reject(error);
        }
      );
    },
    removeWBusers({ commit }, data) {
      return DataService.removeWBusers(data).then(
        (response) => {
          commit("getWBdata", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    removeWBuserAdmin({ commit }, data) {
      return DataService.removeWBuserAdmins(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    sendDataForSSE({ commit }, data) {
      return DataService.getDataSSE(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    removeSystemUserAdmin({ commit }, data) {
      return DataService.removeUserFromAll(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    transferAdministrator({ commit }, data) {
      return DataService.transferAdminRole(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    editRoleWBusers({ commit }, data) {
      return DataService.editRoleWBusers(data).then(
        (response) => {
          commit("getWBdata", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    editRoleWBByAdmin({ commit }, data) {
      return DataService.editRoleWBAdmin(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    editRoleSystem({ commit }, data) {
      return DataService.editRoleSystem(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    respInviteWBusers({ commit }, data) {
      return DataService.respInviteWBusers(data).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    changeTheme({ commit }, data) {
      commit("changeTheme", data);
    }

    // changeControl({ commit },control) {
    //   return DataService.removeScenario(scenario).then(
    //     response => {
    //       return Promise.resolve(response.data);
    //     },
    //     error => {
    //       return Promise.reject(error);
    //     }
    //   );
    // },
  },
  mutations: {
    avatarUpdated(state, value) {
      state.avatarUpdated += value;
    },
    SET_THEME(state, theme) {
      state.theme = theme;
    },
    getDataForXslx(state, value) {
      // console.log(value );
      state.xslxDatas = value;
    },
    updateTanker(state, value) {
      state.markerTanker.push(value);
    },
    removeDest(state, value) {
      state.markerTanker.splice(state.markerTanker.indexOf(value.id), 1);
    },
    updateMarkerList(state, value) {
      state.markerList.push(value);
    },
    updateItemInList(state, value) {
      state.markerList.splice(state.markerList.indexOf(value.id), 1);
    },
    getCurrentmarker(state, value) {
      state.currentMarker = value;
    },
    getResTakh(state, value) {
      state.resource.takhsis = value.message;
    },
    getAllRes(state, value) {
      state.resource.all = value.message;
    },
    getAllResources(state, value) {
      state.allResources = value;
    },
    getUsedResources(state, value) {
      state.usedResources = value;
    },
    getAllUse(state, value) {
      state.resource.used = value.message;
    },
    updatePermissionList(state, value) {
      state.permissionList = value;
    },
    updateNotifSetting(state, value) {
      state.notifSetting = value;
    },
    updateLayers(state, value) {
      state.mapLayers = value;
    },
    updateLicenseStatus(state, value) {
      state.expired = value.message;
    },
    updateSignupSetting(state, value) {
      state.haveSignUp = { value };
    },
    updateRolesList(state, value) {
      state.roleList = value;
    },
    updateSysRoles(state, value) {
      state.sysRoles = value;
    },
    // getDataSuccess(state, data) {
    //   state.data = data;
    // },
    // getDataFailure(state) {
    //   state.data = null;
    // },
    getContConfSuccess(state, data) {
      state.contConf = data;
    },
    getContConfFailure(state) {
      state.contConf = null;
    },
    getBuildConfSuccess(state, data) {
      state.buildConf = data;
    },
    getBuildConfFailure(state) {
      state.buildConf = null;
    },
    getEquipmentsSuccess(state, data) {
      state.equipments = data;
    },
    getEquipmentsFailure(state) {
      state.equipments = null;
    },
    getGroupsSuccess(state, data) {
      state.groups = data;
    },
    getGroupsFailure(state) {
      state.groups = null;
    },
    getScenariosSuccess(state, data) {
      state.Scenarios = data;
    },
    getScenariosFailure(state) {
      state.Scenarios = null;
    },
    floorScenarioSuccess(state, data) {
      state.floorScenario = data;
    },
    getDashboardSuccess(state, data) {
      state.dashboard = data;
    },
    getDashboardFailure(state) {
      state.dashboard = null;
    },
    getWBdata(state, data) {
      state.workbench = data;
      localStorage.setItem(
        "activeWorkbench",
        JSON.stringify(state.workbench.activeWorkbench)
      );
    },
    getWBdatas(state, data) {
      state.workbenchLists = data;
    },
    getUserAdminListdatas(state, data) {
      state.adminUsersLists = data.message;
    },
    getUserData(state, data) {
      state.user = data;
    },
    removeUserData(state) {
      state.user = null;
    },
    changeTheme(state, data) {
      state.user.theme[data.key + "Color"] = data.value.bg;
      state.user.theme[data.key + "Font"] = data.value.font;
    }

    // updateWorkbench(state,UWB){
    //   var activeWorkbench = {};
    //   if (UWB.length>0){
    //     let indx = UWB.findIndex(x=>(x.active))
    //     if (indx !== -1){
    //       activeWorkbench = UWB[indx]
    //     }
    //   }
    //   state.workBenches = UWB;
    //   state.activeWorkbench = activeWorkbench;
    // }
  }
};
