<template>
  <div :dir="dict.dir[lang]">
    <b-container fluid style="padding: 0 !important">
      <ejs-grid
        ref="grid_keyClock"
        :width="'auto'"
        :locale="lang"
        :excelQueryCellInfo="excelQueryCellInfo"
        :enableRtl="lang=='per'"
        :allowSorting="true"
        :loadingIndicator="{ indicatorType: 'Shimmer' }"
        :allowFiltering="true"
        :editSettings="curTableOptions.editSettings"
        :allowExcelExport="true"
        :allowPdfExport="true"
        :enableAdaptiveUI="true"
        :toolbar="toolbarGenerator"
        :pdfExportComplete="pdfExportComplete"
        :toolbarClick="toolbarClick"
        :allowPaging="true"
        :pageSettings="{ pageSize: 5,pageSizes: [5, 10, 20, 50] }"
        :selectionSettings="curTableOptions.selectionOptions"
        :dataSource="curTableOptions.items"
        :actionBegin="actionBegin"
        @dataBound="dataBounded"
        :actionComplete="actionComplete"
      >
        <e-columns>
          <e-column width="50" type="checkbox"></e-column>
          <e-column
            v-for="(item, index) in curTableOptions.fields"
            :key="index"
            :width="150"
            :field="item.key"
            :headerText="item[lang]"
            :template="item.hasTemplate ? item.key : null"
          ></e-column>
        </e-columns>
        <template v-slot:status="{ data }">
          <div v-permission="'admin_status'">
            <ejs-switch
              @change="changeStatus($event,data)"
              :value="data.status"
              :checked="data.status"
            />
          </div>
        </template>
        <template v-slot:modify="{ data }">
          <div class="d-flex justify-content-between col-3 gap-md-0 gap-5">
            <span
              class="e-icons text-danger e-delete-1"
              v-permission="'admin_remove_connection'"
              style="font-size: 17px"
              @click="deleteCloaks(data)"
            ></span>
            <span
              class="e-icons e-edit-6"
              v-permission="'admin_connection_edit'"
              style="font-size: 17px"
              @click="getDataForEdit(data)"
            ></span>
          </div>

          <!--            <b-button-->
          <!--              v-if="!licence"-->
          <!--              pill-->
          <!--              size="sm"-->
          <!--              variant="outline-success"-->
          <!--              class="mr-2"-->
          <!--              :id="data.id"-->
          <!--              @click="showUsers(data)"-->
          <!--              style="outline: none !important; border: none; box-shadow: none"-->
          <!--            >-->
          <!--              <b-icon icon="eye" aria-hidden="true"></b-icon>-->
          <!--            </b-button>-->
        </template>
      </ejs-grid>
    </b-container>
    <b-modal
      id="set-cloak"
      size="lg"
      class="position-relative"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      hide-header-close
      content-class="shadow"
      @shown="initModal"
      @hidden="resetModal"
      :dir="dict.dir[lang]"
    >

      <template #modal-header>
        <div :dir="dict.dir[lang]" class="d-flex justify-content-between">
          <h5 v-if="mode == 'New'">{{ dict.titleModal[lang] }}</h5>
          <h5 v-if="mode == 'edit'">{{ dict.titleModalEdit[lang] }}</h5>
        </div>
      </template>
      <div id="connection_create">
        <div class="row">
          <div
            class="col-md-6 col-12 my-2"
            v-for="item in fields"
            :key="item.name"
          >
            <ejs-textbox
              :autocomplete="'off'"
              :id="item.name"
              :enableRtl="lang == 'per'"
              :required="item.required"
              floatLabelType="Auto"
              :locale="lang"
              :type="item.type"
              :placeholder="item.name"
              v-if="item.name != 'authType'"
              v-model="infos[item.name]"
            ></ejs-textbox>
            <ejs-dropdownlist
              v-if="item.name == 'authType'"
              id="dropdownlist_key_clock"
              floatLabelType="Auto"
              :locale="lang"
              :messages="validationMessage"
              :required="item.required"
              :enableRtl="lang=='per'"
              :dataSource="typeList"
              v-model="infos.authType"
              :placeholder="item.name"
              :disabled="mode=='edit'"
              :fields="{ text: 'text', value: 'value' }"
            ></ejs-dropdownlist>
          </div>
        </div>

      </div>
      <template #modal-footer>
        <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-end">
          <div class="d-flex mx-2" style="gap: 10px">
            <b-button variant="secondary" @click="closeModal">
              {{
                dict.cancel[lang]
              }}
            </b-button>
          </div>
          <div class="d-flex" style="gap: 10px">
            <b-button variant="primary" @click="submitData">
              <b-spinner v-if="loading" small label="Loading..."></b-spinner>

              <span v-else>{{
                  dict.ok[lang]
                }}</span>
            </b-button>
          </div>
        </div>
      </template>
      <!--      <loading-page class="position-absolute top-0 bottom-0" style="right: 0!important;left: 0!important; top: 0!important;"/>-->

    </b-modal>
  </div>
</template>

<script>
import { host } from "@/models/host";
import { persianLacale } from "../lacale/persian";
import { L10n } from "@syncfusion/ej2-base";
import {
  Page,
  Sort,
  Toolbar,
  PdfExport,
  ExcelExport,
  Edit,
  Search
} from "@syncfusion/ej2-vue-grids";
import { DialogUtility } from "@syncfusion/ej2-popups";
import { FormValidator } from "@syncfusion/ej2-inputs";


import { mapGetters } from "vuex";
import LoadingPage from "@/components/base/loadingPage.vue";

export default {
  name: "connections",
  data() {
    return {
      validator: null,
      loading: false,
      mode: "",
      curTableOptions: {
        items: [],
        fields: [
          {
            key: "title",
            eng: "title",
            per: "عنوان",
            hasTemplate: false,
            sortDirection: "asc"
          },
          {
            key: "type",
            eng: "type",
            per: "نوع",
            hasTemplate: false,
            sortDirection: "asc"
          },
          {
            key: "details.baseUrl",
            eng: "base Url",
            per: "لینک اصلی",
            hasTemplate: false,
            sortDirection: "asc"
          },
          {
            key: "status",
            eng: "status",
            per: "وضعیت",
            hasTemplate: true,
            sortDirection: "asc"
          },
          { key: "modify", eng: "", per: "", hasTemplate: true }
        ],
        selectionOptions: {
          type: "Multiple",
          persistSelection: true,
          checkboxOnly: true
        },
        editSettings: {
          allowEditing: false,
          allowAdding: true,
          allowDeleting: true,
          mode: "Dialog",
          template: "set_clock"
        },
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "active",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: []
      },
      dict: {
        refresh: { eng: "refresh datas", per: "به روزرسانی اطلاعات" },
        placeholder: {
          eng: "enter email of inviting user",
          per: "ایمیل کاربر مد نظر را وارد کنید"
        },
        dir: { eng: "ltr", per: "rtl" },
        updloadText: { per: "آپلود فایل", eng: "browse" },
        required: { per: "این فیلد اجباری است", eng: "this field is required" },
        createWB: { eng: "create new workbench", per: "ایجاد میزکار جدید" },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        th_floorTitle: { eng: "name", per: "نام" },
        validName: { eng: "name is required!", per: "ورود نام الزامی است!" },
        search: { eng: "Type to Search", per: "برای جستجو تایپ کنید" },
        clear: { eng: "clear", per: "حذف" },
        title: { eng: "admin panel", per: "پنل مدیریت" },
        titleModal: { eng: "add connection", per: "افزودن اتصال جدید" },
        titleModalEdit: { eng: "edit connection", per: "ویرایش اتصال" },
        labelModal: { eng: "user's email", per: "ایمیل کاربر" },
        tab1: { eng: "licence manager", per: "مدیریت منابع" },
        tab2: { eng: "workbench managment", per: "مدیریت میزکار" },
        tab3: { eng: "users management", per: "مدیریت کاربران" },
        tab4: { eng: "roles management", per: "مدیریت نقش ها" },
        wbname: { eng: "workbench name", per: "نام میزکار" },
        wbowner: { eng: "workbench owner", per: "مالک میزکار" },
        wbresources: { eng: "workbench resources", per: "منابع میزکار" },
        modalTitle: { eng: "users list", per: "لیست کاربران" },
        modalLicenceTitle: { eng: "licences", per: "تخصیص منابع" },
        NewConnection: { eng: "New connection", per: "ایجاد اتصال" },

        regmsg: { eng: "Don't have an account?", per: "حساب کاربری ندارید؟" },
        register: { eng: "Register now", per: "ثبت نام" },
        errCaptcha: {
          eng: "invalid captcha",
          per: "کد امنیتی وارد شده اشتباه می باشد"
        },
        errUserPass1: {
          eng: "invalid username or password",
          per: "نام کاربری یا کلمه عبور اشتباه است"
        },
        errUserPass2: {
          eng: "check your keyboard language",
          per: "• وضعيت زبان(En/FA)سيستم خود را كنترل كنيد"
        },
        errUserPass3: {
          eng: "the password is caps sensitive",
          per: "• پسورد به حروف بزرگ و كوچك حساس است"
        },
        deleteConfirm: { eng: "Confirmation Delete", per: "تاییدیه حذف" },
        deleteConfirmContent: {
          eng: "Are you sure to remove the connection ?",
          per: "آیا از حذف این اتصال اطمینان دارید ؟"
        }
      },
      infos: {
        authType: "",
        title: "",
        realm: "",
        baseUrl: "",
        clientId: "",
        clientSecret: "",
        status: false
      },
      id: "",
      typeList: [
        { text: "Key cloak", value: "Keycloak" }
        // { text: "google", value: "google" }
      ],
      errors: [],
      submitted: false,
      falseStatus: [],
      fields: [
        {
          readonly: false,
          name: "authType",
          param: "name",
          value: "",
          required: true,
          per: "نام",
          eng: "name",
          type: "select"
        },
        {
          name: "title",
          param: "familyName",
          readonly: false,
          value: "",
          type: "text",
          required: true,
          per: "نام خانوادگی",
          eng: "familyName"
        },
        {
          name: "realm",
          param: "",
          readonly: false,
          value: "",
          type: "text",
          required: true,
          per: "رمز عبور",
          eng: "password"
        },
        {
          name: "baseUrl",
          param: "",
          readonly: false,
          value: "",
          type: "url",
          required: true,
          per: "تایید رمز عبور",
          eng: "password repeat"
        },
        {
          name: "clientId",
          param: "phoneNumber",
          readonly: false,
          value: "",
          type: "text",
          required: true,
          per: "شماره موبایل",
          eng: "phoneNumber"
        },
        {
          name: "clientSecret",
          param: "",
          readonly: true,
          value: "",
          type: "text",
          required: true,
          per: "ایمیل",
          eng: "email"
        }
      ]
    };
  },
  computed: {
    ...mapGetters("chpge", ["screenWidth", "screenHeight"]),
    lang() {
      return this.$store.state.chpge.lang;
    },
    userPermissions() {
      return this.$store.state.data.user.permissions;
    },
    toolbarGenerator() {
      if (this.userPermissions.includes("admin_add_connection"))
        return ["Search", "ExcelExport", {
          text: this.dict.NewConnection[this.lang],
          id: "new_connection",
          prefixIcon: "e-icons e-add"
        }, {
          text: this.dict.refresh[this.lang],
          id: "refresh_table",
          prefixIcon: "e-icons e-refresh-2"
        }];
      else return ["Search", "ExcelExport", {
        text: this.dict.refresh[this.lang],
        id: "refresh_table",
        prefixIcon: "e-icons e-refresh-2"
      }];
    },
    validationMessage() {
      return { required: this.dict.required[this.lang] };
    }
  },
  methods: {
    dataBounded(e) {
      this.$refs.grid_keyClock.hideSpinner();
    },
    excelQueryCellInfo(args) {
      // console.log(args);
      // args.value=JSON.stringify(args.value)
    },
    actionBegin(args) {
      if (args.requestType == "cancel") {
        this.fields = [
          {
            readonly: false,
            name: "authType",
            param: "name",
            value: "",
            required: true,
            per: "نام",
            eng: "name",
            type: "select"
          },
          {
            name: "title",
            param: "familyName",
            readonly: false,
            value: "",
            type: "text",
            required: true,
            per: "نام خانوادگی",
            eng: "familyName"
          },
          {
            name: "realm",
            param: "",
            readonly: false,
            value: "",
            type: "text",
            required: true,
            per: "رمز عبور",
            eng: "password"
          },
          {
            name: "baseUrl",
            param: "",
            readonly: false,
            value: "",
            type: "url",
            required: true,
            per: "تایید رمز عبور",
            eng: "password repeat"
          },
          {
            name: "clientId",
            param: "phoneNumber",
            readonly: false,
            value: "",
            type: "text",
            required: true,
            per: "شماره موبایل",
            eng: "phoneNumber"
          },
          {
            name: "clientSecret",
            param: "",
            readonly: true,
            value: "",
            type: "text",
            required: true,
            per: "ایمیل",
            eng: "email"
          }
        ];
      }
      if (args.requestType == "beginEdit") {
        this.mode = "beginEdit";
        let selectedRow = args.rowData;
        this.fields.forEach((item1) => {
          Object.keys(selectedRow).forEach((item2) => {
            if (item1.eng == item2) {
              item1.value = selectedRow[item2];
            }
          });
        });
      } else if (args.requestType == "add") {
        this.mode = "add";
      }

      if (args.requestType === "refresh" || args.requestType === "paging") {
        this.$refs.grid_keyClock.showSpinner();
      }

    },
    actionComplete(args) {
      if (args.requestType == "save") {
        if (this.mode == "add") {
          let data = {};
          this.fields.forEach((item) => {
            data[item.name] = item.value;
          });
          this.$store.dispatch("auth/register", data).then(
            (resp) => {
              // this.getAdminUsers();
            },
            (error) => {
              console.log(error);
            }
          );
        } else if (args.requestType == "beginEdit") {
          console.log(args);
        }
      }
      if (args.requestType == "cancel") {
        this.fields = [
          {
            readonly: false,
            name: "authType",
            param: "name",
            value: "",
            required: true,
            per: "نام",
            eng: "name",
            type: "select"
          },
          {
            name: "title",
            param: "familyName",
            readonly: false,
            value: "",
            type: "text",
            required: true,
            per: "نام خانوادگی",
            eng: "familyName"
          },
          {
            name: "realm",
            param: "",
            readonly: false,
            value: "",
            type: "text",
            required: true,
            per: "رمز عبور",
            eng: "password"
          },
          {
            name: "baseUrl",
            param: "",
            readonly: false,
            value: "",
            type: "url",
            required: true,
            per: "تایید رمز عبور",
            eng: "password repeat"
          },
          {
            name: "clientId",
            param: "phoneNumber",
            readonly: false,
            value: "",
            type: "text",
            required: true,
            per: "شماره موبایل",
            eng: "phoneNumber"
          },
          {
            name: "clientSecret",
            param: "",
            readonly: true,
            value: "",
            type: "text",
            required: true,
            per: "ایمیل",
            eng: "email"
          }
        ];
      }
    },
    host() {
      return host;
    },
    toolbarClick(args) {
      if (args.item.id.includes("pdfexport")) {
        this.$refs.grid_keyClock.showSpinner();
        this.$refs.grid_keyClock.pdfExport();
      } else if (args.item.id.includes("excelexport")) {
        this.$refs.grid_keyClock.excelExport();
      } else if (args.item.id.includes("csvexport")) {
        this.$refs.grid_keyClock.csvExport();
      } else if (args.item.id.includes("new_connection")) {
        this.addNew();
      } else if (args.item.id.includes("refresh_table")) {
        this.getData();
      }
    },
    pdfExportComplete() {
      this.$refs.grid_keyClock.hideSpinner();
    },
    getData() {
      this.$store.dispatch("data/getKCloakes").then((res) => {
        this.curTableOptions.items = res;
      });
      setTimeout(() => {
        this.checkStatus();
      }, 200);
    },
    addNew() {
      this.mode = "New";
      this.$bvModal.show("set-cloak");
    },
    submitData($bv) {
      $bv.preventDefault();
      this.loading = true;
      const isValid = this.validator.validate();
      Object.entries(this.infos).forEach((item) => {
        if (item[1] == "" && item[0] != "status") this.errors.push(item[0]);
      });
      let details = {
        realm: this.infos.realm,
        baseUrl: this.infos.baseUrl,
        clientId: this.infos.clientId,
        clientSecret: this.infos.clientSecret
      };

      if (isValid && this.mode != "edit") {
        this.$store
          .dispatch("data/createCloak", {
            status: this.infos.status,
            authType: this.infos.authType,
            title: this.infos.title,
            details: details
          })
          .then((res) => {
            this.loading = false;
            this.$bvModal.hide("set-cloak");
            this.infos = {
              authType: "",
              title: "",
              realm: "",
              baseUrl: "",
              clientId: "",
              clientSecret: "",
              status: false
            };
          }).catch(() => {
          this.loading = false;
        });
      } else if (isValid && this.mode == "edit") {
        this.$store
          .dispatch("data/editCloak", {
            type: "edit",
            title: this.infos.title,
            details: details,
            id: this.id
          })
          .then((res) => {
            this.loading = false;
            this.$bvModal.hide("set-cloak");
            this.infos = {
              authType: "",
              title: "",
              realm: "",
              baseUrl: "",
              clientId: "",
              clientSecret: "",
              status: false
            };
          }).catch(() => {
          this.loading = false;
        });
      } else this.loading = false;
    },
    resetModal() {
      this.infos = {
        authType: "",
        title: "",
        realm: "",
        baseUrl: "",
        clientId: "",
        clientSecret: "",
        status: false
      };
      this.mode = "";
      this.loading = false;
      this.getData();
    },
    initModal() {
      setTimeout(() => {
        this.validator = new FormValidator("#connection_create");
      }, 200);
    },
    closeModal() {
      this.$bvModal.hide("set-cloak");
    },
    changeStatus(e, value) {
      if (e.checked != value.status) {
        this.$store
          .dispatch("data/editCloakStatus", {
            type: "OnOff",
            id: value._id,
            status: e.checked
          })
          .then((res) => {
            this.getData();
          });
      } else this.getData();
    },
    getDataForEdit(val) {
      this.mode = "edit";
      this.$bvModal.show("set-cloak");
      this.infos.status = val.status;
      this.id = val._id;
      this.infos.title = val.title;
      this.infos.authType = val.type;
      this.infos.realm = val.details.realm;
      this.infos.baseUrl = val.details.baseUrl;
      this.infos.clientId = val.details.clientId;
      this.infos.clientSecret = val.details.clientSecret;
    },
    deleteCloaks(val) {
      const DialogObj = DialogUtility.confirm({
        title: this.dict.deleteConfirm[this.lang],
        locale: this.lang,
        position: "center center",
        Enabletrl: this.lang == "per" ? true : false,
        content: this.dict.deleteConfirmContent[this.lang],
        okButton: {
          text: this.dict.ok[this.lang], click: () => {
            this.$store.dispatch("data/deleteCloak", { id: val._id }).then(() => {
              this.getData();
            });
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }
    },
    checkStatus() {
      this.falseStatus = [];
      this.curTableOptions.items.forEach((val) => {
        if (val.status == false) this.falseStatus.push(val);
      });
    }
  },
  watch: {
    lang(val) {
      L10n.setCulture(val);
    }
  },
  provide: {
    grid: [Page, Sort, Toolbar, PdfExport, ExcelExport, Edit, Search]
  },
  mounted() {
    this.getData();
  }
};
</script>
