<template>
  <div ref="mainCont" class="card-body p-1 d-flex justify-content-center align-items-center"
       style="height:calc(100%); width: 100%">
    <!-- <div class="card-header" style="min-height: 40px;">
      <div class="row">
        <div class="col-8 ">
          <p class="mb-0">
            {{ myData.attribute.title }}
          </p>

        </div>
        <div class="col-2 text-center" v-if="editable">
          <span class="edit" @click="editWidget">
            <i><font-awesome-icon icon="fas fa-pencil" /></i>
          </span>
        </div>
        <div class="col-2 text-center" v-if="editable">
          <span class="remove" @click="removeWidget">
            <i><font-awesome-icon icon="fas fa-xmark" /></i>
          </span>
        </div>
      </div>
    </div> -->


    <!-- <div ref="mainCont" class="card-body p-1 d-flex justify-content-center align-items-center"
         style="height: calc(100% - 40px); width:100%;"> -->
    <div v-if="myData && myData.datas[0]" class="row w-100">

      <div class="col-8" style="display: flex; justify-content: start; align-items: center; text-align: center;">
        <i class="mb-0">
          {{ myData.attribute.title }}
        </i>
      </div>
      <div class="col-4">
        <div class="d-flex align-items-center justify-content-end">
          <b-avatar
            :id="plotId+'_avatar'"
            :src=matchedAvatar
            size="50px"
            class="rounded-circle shadow-10 avatar"
          >
          </b-avatar>
        </div>
      </div>

      <b-tooltip :target="plotId+'_avatar'" :title=matchedTooltip></b-tooltip>


      <!-- </div> -->

    </div>

    <b-modal
      :id="plotId"
      size="lg"
      centered
      body-class="my-body-class"
      hide-header-close
      :title="modalTitle[lang]"
      :ok-title="dict.ok[lang]"
      :cancel-title="dict.cancel[lang]"
      :dir="dict.dir[lang]"
      @shown="initModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <div>
        <b-container fluid="md" class="p-0">
          <div class="p-3">
            <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
              <li
                v-for="(item, index) in tabList"
                class="mga-tab-nav-item"
                @click="activeTab = item"
                :key="index"
              >
                <a
                  class="mga-tab-nav-link"
                  :class="{ active: activeTab === item }"
                  :id="item"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="tab1"
                  aria-selected="true"
                >
                  {{ dict[item][lang] }}
                </a>
              </li>
            </ul>
          </div>
          <div class="card-body h-100 p-3 " style="overflow: hidden;">
            <transition name="slide-fade" mode="out-in">
              <div class="tab-content h-100" :key="activeTab">
                <div v-if="activeTab === 'data'" class="h-100 ">
                  <div fluid class="container-fluid p-0 h-100 ">
                    <div class="d-flex align-items-center px-4">
                      <ejs-textbox
                        :value="myData.datas[0]['name']"
                        :placeholder="dict.pickData[lang]"
                        :enableRtl="lang == 'per'"
                        floatLabelType="Auto"
                        :showClearButton="true"
                        v-model="myData.datas[0]['name']"
                      ></ejs-textbox>
                      <b-button @click="addItem" class="btn-sm" variant="primary"><span
                        class="e-icons e-edit-6 "></span>
                      </b-button>
                    </div>

                  </div>
                </div>

                <div v-if="Object.keys(gaugeOptions).includes(activeTab)" class="h-100 ">
                  <div fluid class="container-fluid p-0 h-100 ">
                    <div v-for="(option, index) in gaugeOptions[activeTab]" :key="index"
                         class="d-flex align-items-center p-2">
                      <!-- <div class="col-3" :class="lang==='per'?'text-end':'text-start'"  for="username">{{dict[option.name][lang]}}</div> -->
                      <div class="col-12">
                        <div v-if="option.type==='text'" class="d-flex">
                          <ejs-textbox
                            :value="myData.attribute[option.name]"
                            :enableRtl="lang == 'per'"
                            floatLabelType="Auto"
                            :showClearButton="true"
                            v-model="myData.attribute[option.name]"
                          ></ejs-textbox>
                        </div>
                        <ejs-textbox
                          v-if="option.type==='array'"
                          :value="myData.attribute[option.name]"
                          :enableRtl="lang == 'per'"
                          floatLabelType="Auto"
                          @input="splitMajorTicks(option.name)"
                          :showClearButton="true"
                          v-model="myData.attribute[option.name]"
                        ></ejs-textbox>
                        <ejs-textbox
                          v-if="option.type==='number'"
                          :value="myData.attribute[option.name]"
                          :enableRtl="lang == 'per'"
                          floatLabelType="Auto"
                          type="number"
                          :showClearButton="true"
                          v-model="myData.attribute[option.name]"
                        ></ejs-textbox>
                        <ejs-dropdownlist
                          v-if="option.type==='boolean'"
                          :placeholder="dict[option.name][lang]"
                          :enableRtl="lang === 'per'"
                          :dataSource="strokeTicksList"
                          floatLabelType="Auto"
                          :fields="{ text: lang, value: 'value' }"
                          v-model="myData.attribute[option.name]"
                          @change="(val)=>{myData.attribute[option.name]=val.value}"
                        ></ejs-dropdownlist>
                        <ejs-textbox
                          v-if="option.type==='color'"
                          :value="myData.attribute[option.name]"
                          :enableRtl="lang == 'per'"
                          floatLabelType="Auto"
                          type="color"
                          :showClearButton="true"
                          v-model="myData.attribute[option.name]"
                        ></ejs-textbox>

                        <ejs-dropdownlist
                          v-if="option.type==='option'"
                          :placeholder="dict[option.name][lang]"
                          :enableRtl="lang === 'per'"
                          :dataSource="option.options"
                          floatLabelType="Auto"
                          :fields="{ text: 'text', value: 'value' }"
                          v-model="myData.attribute[option.name]"
                          @change="(val)=>{myData.attribute[option.name]=val.value}"
                        ></ejs-dropdownlist>

                        <div v-else-if="option.type==='table'"
                             style="border: 1px solid #ced4da; border-radius: 0.25rem;">
                          <!-- thead-class="hidden_header" -->
                          <b-table ref="table2" :items="myData.attribute[option.name]" :fields="option.fields"
                                   responsive="sm" small>
                            <template #cell(value)="row">
                              <div class="d-flex align-items-center p-2">
                                <ejs-textbox
                                  :value="row.item.value"
                                  :enableRtl="lang == 'per'"
                                  floatLabelType="Auto"
                                  :showClearButton="true"
                                  v-model="row.item.value"
                                ></ejs-textbox>
                              </div>
                            </template>

                            <!-- :src="host+row.item.image"  -->

                            <template #cell(image)="row">
                              <div class="d-flex align-items-center p-2">
                                <div @click="changeIcon(row.index)">
                                  <b-avatar
                                    :src=getImageLinkById(row.item.image)
                                    size="50px"
                                    class="rounded-circle shadow-10 bg-alternate avatar"
                                  ></b-avatar>
                                </div>
                              </div>
                            </template>

                            <template #cell(tooltip)="row">
                              <div class="d-flex align-items-center p-2">
                                <ejs-textbox
                                  :value="row.item.tooltip"
                                  :enableRtl="lang == 'per'"
                                  floatLabelType="Auto"
                                  :showClearButton="true"
                                  v-model="row.item.tooltip"
                                ></ejs-textbox>
                              </div>
                            </template>

                          </b-table>
                          <div style="display:flex; justify-content: right; align-items: center;">
                            <b-button @click="addRow(option.name, {from:'',to:'',color:'#fff'})" size="sm"
                                      variant="outline-danger"
                                      style="width:20px; padding: 0px;  outline: none !important; border: none; box-shadow: none;">
                              <i>
                                <font-awesome-icon icon="fas fa-plus" />
                              </i>
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </b-container>
      </div>
    </b-modal>

    <b-modal
      :id="plotId+'_photoGallery'"
      size="md"
      :ok-title="dict.ok[lang]"
      :cancel-title="dict.cancel[lang]"
      :dir="dict.dir[lang]"
      centered
      hide-header-close
      content-class="shadow"
      :title="dict.selectPhoto[lang]"

    >
      <template #modal-footer>
        <div class="d-flex w-100 justify-content-between">

          <div>
            <b-button variant="secondary" style="margin-right:10px" @click="handlephotoGalleryModalReset">
              {{ dict.cancel[lang] }}
            </b-button>

          </div>
        </div>
      </template>
      <div
        style="height:100%; width:100%; display:flex; justify-content:center; align-items:center; flex-wrap:wrap; gap:5px">


        <div v-for="(photo, i) in photoGallery" :key="i" @click="setNewImage(photo.id)">

          <!-- <img :src="photo" class="rounded-circle shadow-lg" style="width: 100px;" alt="Avatar" /> -->

          <b-avatar

            :src="photo.link"
            size="100px"
            class="rounded-3 shadow-lg "
          ></b-avatar>

        </div>
      </div>


    </b-modal>

    <dataPickerModal :plotId="plotId" />
  </div>
</template>

<script>
/* eslint-disable */

import dataPickerModal from "./widgetPickDataModal.vue";
import { host } from "../models/host";
import onState from "@/assets/images/on-state.svg";
import offState from "@/assets/images/off-state.svg";
import photo_3 from "@/assets/images/photo_3.gif";
import photo_4 from "@/assets/images/photo_4.gif";
import photo_5 from "@/assets/images/photo_5.gif";
import photo_6 from "@/assets/images/photo_6.png";
import green_circle from "@/assets/images/green-circle.png";
import red_circle from "@/assets/images/red-circle.png";
import gray_circle from "@/assets/images/gray-cricle.png";

import fire_circle_blue from "@/assets/images/mapIcons/fire_circle_blue.png";
import fire_circle_red from "@/assets/images/mapIcons/fire_circle_red.png";
import fire_circle_green from "@/assets/images/mapIcons/fire_circle_green.png";
import fire_circle_yellow from "@/assets/images/mapIcons/fire_circle_yellow.png";
import fire_circle_gray from "@/assets/images/mapIcons/fire_circle_gray.png";


export default {
  name: "CustomPath",
  components: {
    dataPickerModal
  },
  props: {
    plotId: String,
    data: Object,
    editable: Boolean,
    resized: Number,
    setting: Boolean
  },
  data() {
    return {
      host: host,
      images: { onState: onState, offState: offState },

      // photoGallery:[onState,offState,photo_3,photo_4,photo_5,photo_6],

      photoGallery: [

        { id: "photo_1", link: onState },
        { id: "photo_2", link: offState },
        { id: "photo_3", link: photo_3 },
        { id: "photo_4", link: photo_4 },
        { id: "photo_5", link: photo_5 },
        { id: "photo_6", link: photo_6 },
        // { id: "photo_7", link: green_circle },
        // { id: "photo_8", link: gray_circle },
        // { id: "photo_9", link: red_circle },
        { id: "photo_10", link: fire_circle_blue },
        { id: "photo_11", link: fire_circle_red },
        { id: "photo_12", link: fire_circle_green },
        { id: "photo_13", link: fire_circle_yellow },
        { id: "photo_14", link: fire_circle_gray }

      ],
      defaultImage: { id: "photo_0", link: gray_circle },

      selectedState: null,


      activeTab: "data", // Set the initial active tab
      tabList: ["data", "title", "basic"],


      modalTitle: { eng: "Edit widget", per: "ویرایش ویجت" },
      myData: {
        datas: [{ name: "" }],
        attribute: {}
      },


      gaugeOptions: {

        title: [
          { name: "title", type: "text", default: false }

        ],
        basic: [
          {
            name: "states",
            type: "table",
            fields: [{ key: "value", label: "value", type: "number" }, {
              key: "image",
              label: "image",
              type: "image"
            }, { key: "tooltip", label: "tooltip", type: "text" }],
            default: [{ value: 0, image: "photo_6", tooltip: "test" }]
          }

        ]

      },

      myDataTmp: { datas: [] },

      groupFields: [
        { key: "name", label: "" },
        { key: "modify", label: "" }],

      submitted: false,
      successful: false,
      message: "",

      strokeTicksList: [
        { text: "true", value: true },
        { text: "false", value: false }
      ],

      textinfoList: [
        { text: "percent", value: "percent" },
        { text: "value", value: "value" },
        { text: "label and value", value: "label+value" },
        { text: "label and percent", value: "label+percent" },
        { text: "label and percent and value", value: "label+percent+value" }
      ],
      textpositionList: [
        { text: "inside", value: "inside" },
        { text: "outside", value: "outside" },
        { text: "auto", value: "auto" },
        { text: "none", value: "none" }
      ],
      insidetextorientationList: [
        { text: "horizontal", value: "horizontal" },
        { text: "radial", value: "radial" },
        { text: "tangential", value: "tangential" },
        { text: "auto", value: "auto" }
      ],
      holeList: [
        { text: "none", value: "0" },
        { text: "0.2", value: "0.2" },
        { text: "0.4", value: "0.4" },
        { text: "0.6", value: "0.6" }
      ],
      showlegendList: [
        { text: "show", value: "true" },
        { text: "don't show", value: "false" }
      ],
      dict: {
        selectPhoto: { eng: "Select a Photo", per: "انتخاب عکس" },
        dir: { eng: "ltr", per: "rtl" },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        pickData: { per: "انتخاب داده", eng: "Pick Data" },
        title: { eng: "title", per: "عنوان" },
        data: { eng: "data", per: "داده" },
        basic: { eng: "basic", per: "تنظیمات پایه" },
        value: { eng: "value", per: "تنظیمات پایه" },
        units: { eng: "units", per: "تنظیمات پایه" },
        ticks: { eng: "ticks", per: "تنظیمات پایه" },
        numbers: { eng: "numbers", per: "تنظیمات پایه" },
        animation: { eng: "animation", per: "تنظیمات پایه" },
        valueBox: { eng: "valueBox", per: "تنظیمات پایه" },
        border: { eng: "border", per: "تنظیمات پایه" },
        needle: { eng: "needle", per: "تنظیمات پایه" },
        highlights: { eng: "highlights", per: "تنظیمات پایه" },
        bar: { eng: "bar", per: "تنظیمات پایه" },
        radial: { eng: "radial", per: "تنظیمات پایه" },
        linear: { eng: "linear", per: "تنظیمات پایه" },

        minValue: { eng: "minimum value", per: "مقدار کمینه" },
        maxValue: { eng: "maximum value", per: "مقدار بیشینه" },
        units: { eng: "unit", per: "واحد" },


        majorTicks: { eng: "major ticks", per: "تیک های اصلی" },
        minorTicks: { eng: "number of minor ticks", per: "تعداد تیک های فرعی" },
        strokeTicks: { eng: "stroke ticks", per: "نوار پشت تیک ها" },

        colorPlate: { eng: "color plate", per: "رنگ پس زمینه" },
        colorPlateEnd: { eng: "color plate end", per: "رنگ پس زمینه" },
        borderShadowWidth: { eng: "border shadow width", per: "ضخامت سایه حاشیه" },
        borders: { eng: "borders", per: "حاشیه" },
        needleType: { eng: "needle type", per: "نوع نشانگر" },
        needleWidth: { eng: "needle width", per: "ضخامت نشانگر" },
        needleCircleSize: { eng: "needle circle size", per: "اندازه دایره نشانگر" },
        needleCircleOuter: { eng: "needle circle outer", per: "دایره بیرونی نشانگر" },
        needleCircleInner: { eng: "needle circle inner", per: "دایره درونی نشانگر" },
        animationDuration: { eng: "animation duration", per: "مدت زمان انیمیشن" },
        animationRule: { eng: "animation rule", per: "نوع انیمیشن" },
        animateOnInit: { eng: "animate on init.", per: "نوع انیمیشن" },
        colorTitle: { eng: "colorTitle", per: "نوع انیمیشن" },
        fontTitle: { eng: "fontTitle", per: "نوع انیمیشن" },
        fontTitleSize: { eng: "fontTitleSize", per: "نوع انیمیشن" },
        fontTitleStyle: { eng: "fontTitleStyle", per: "نوع انیمیشن" },
        fontTitleWeight: { eng: "fontTitleWeight", per: "نوع انیمیشن" },

        valueInt: { eng: "valueInt", per: "نوع انیمیشن" },
        valueDec: { eng: "valueDec", per: "نوع انیمیشن" },
        colorValueText: { eng: "colorValueText", per: "نوع انیمیشن" },
        colorValueTextShadow: { eng: "colorValueTextShadow", per: "نوع انیمیشن" },
        animatedValue: { eng: "animatedValue", per: "نوع انیمیشن" },
        fontValue: { eng: "fontValue", per: "نوع انیمیشن" },
        fontValueSize: { eng: "fontValueSize", per: "نوع انیمیشن" },
        fontValueStyle: { eng: "fontValueStyle", per: "نوع انیمیشن" },
        fontValueWeight: { eng: "fontValueWeight", per: "نوع انیمیشن" },

        colorUnits: { eng: "colorUnits", per: "نوع انیمیشن" },
        fontUnits: { eng: "fontUnits", per: "نوع انیمیشن" },
        fontUnitsSize: { eng: "fontUnitsSize", per: "نوع انیمیشن" },
        fontUnitsStyle: { eng: "fontUnitsStyle", per: "نوع انیمیشن" },
        fontUnitsWeight: { eng: "fontUnitsWeight", per: "نوع انیمیشن" },


        exactTicks: { eng: "exactTicks", per: "نوع انیمیشن" },
        majorTicksInt: { eng: "majorTicksInt", per: "نوع انیمیشن" },
        majorTicksDec: { eng: "majorTicksDec", per: "نوع انیمیشن" },
        colorMajorTicks: { eng: "colorMajorTicks", per: "نوع انیمیشن" },
        colorMinorTicks: { eng: "colorMinorTicks", per: "نوع انیمیشن" },
        colorStrokeTicks: { eng: "colorStrokeTicks", per: "نوع انیمیشن" },

        colorNumbers: { eng: "colorNumbers", per: "نوع انیمیشن" },
        fontNumbers: { eng: "fontNumbers", per: "نوع انیمیشن" },
        fontNumbersSize: { eng: "fontNumbersSize", per: "نوع انیمیشن" },
        fontNumbersStyle: { eng: "fontNumbersStyle", per: "نوع انیمیشن" },
        fontNumbersWeight: { eng: "fontNumbersWeight", per: "نوع انیمیشن" },
        numbersMargin: { eng: "numbersMargin", per: "نوع انیمیشن" },


        animation: { eng: "animation", per: "نوع انیمیشن" },
        animationDuration: { eng: "animationDuration", per: "نوع انیمیشن" },
        animationRule: { eng: "animationRule", per: "نوع انیمیشن" },


        valueBox: { eng: "valueBox", per: "نوع انیمیشن" },
        valueBoxWidth: { eng: "valueBoxWidth", per: "نوع انیمیشن" },
        valueBoxStroke: { eng: "valueBoxStroke", per: "نوع انیمیشن" },
        valueText: { eng: "valueText", per: "نوع انیمیشن" },
        valueTextShadow: { eng: "valueTextShadow", per: "نوع انیمیشن" },
        valueBoxBorderRadius: { eng: "valueBoxBorderRadius", per: "نوع انیمیشن" },
        colorValueBoxRect: { eng: "colorValueBoxRect", per: "نوع انیمیشن" },
        colorValueBoxRectEnd: { eng: "colorValueBoxRectEnd", per: "نوع انیمیشن" },
        colorValueBoxBackground: { eng: "colorValueBoxBackground", per: "نوع انیمیشن" },
        colorValueBoxShadow: { eng: "colorValueBoxShadow", per: "نوع انیمیشن" },

        borders: { eng: "borders", per: "نوع انیمیشن" },
        colorBorderOuter: { eng: "colorBorderOuter", per: "نوع انیمیشن" },
        colorBorderOuterEnd: { eng: "colorBorderOuterEnd", per: "نوع انیمیشن" },
        colorBorderMiddle: { eng: "colorBorderMiddle", per: "نوع انیمیشن" },
        colorBorderMiddleEnd: { eng: "colorBorderMiddleEnd", per: "نوع انیمیشن" },
        colorBorderInner: { eng: "colorBorderInner", per: "نوع انیمیشن" },
        colorBorderInnerEnd: { eng: "colorBorderInnerEnd", per: "نوع انیمیشن" },
        colorBorderShadow: { eng: "colorBorderShadow", per: "نوع انیمیشن" },
        borderOuterWidth: { eng: "borderOuterWidth", per: "نوع انیمیشن" },
        borderMiddleWidth: { eng: "borderMiddleWidth", per: "نوع انیمیشن" },
        borderInnerWidth: { eng: "borderInnerWidth", per: "نوع انیمیشن" },
        borderShadowWidth: { eng: "borderShadowWidth", per: "نوع انیمیشن" },

        needle: { eng: "needle", per: "نوع انیمیشن" },
        needleShadow: { eng: "needleShadow", per: "نوع انیمیشن" },
        needleType: { eng: "needleType", per: "نوع انیمیشن" },
        needleStart: { eng: "needleStart", per: "نوع انیمیشن" },
        needleEnd: { eng: "needleEnd", per: "نوع انیمیشن" },
        needleWidth: { eng: "needleWidth", per: "نوع انیمیشن" },
        colorNeedle: { eng: "colorNeedle", per: "نوع انیمیشن" },
        colorNeedleEnd: { eng: "colorNeedleEnd", per: "نوع انیمیشن" },
        colorNeedleShadowUp: { eng: "colorNeedleShadowUp", per: "نوع انیمیشن" },
        colorNeedleShadowDown: { eng: "colorNeedleShadowDown", per: "نوع انیمیشن" },

        highlights: { eng: "highlights", per: "نوع انیمیشن" },
        highlightsWidth: { eng: "highlightsWidth", per: "نوع انیمیشن" },
        highlightsLineCap: { eng: "highlightsLineCap", per: "نوع انیمیشن" },

        barWidth: { eng: "barWidth", per: "نوع انیمیشن" },
        barStrokeWidth: { eng: "barStrokeWidth", per: "نوع انیمیشن" },
        barProgress: { eng: "barProgress", per: "نوع انیمیشن" },
        colorBar: { eng: "colorBar", per: "نوع انیمیشن" },
        colorBarStroke: { eng: "colorBarStroke", per: "نوع انیمیشن" },
        colorBarProgress: { eng: "colorBarProgress", per: "نوع انیمیشن" },
        colorBarShadow: { eng: "colorBarShadow", per: "نوع انیمیشن" },
        barShadow: { eng: "barShadow", per: "نوع انیمیشن" },

        ticksAngle: { eng: "ticksAngle", per: "نوع انیمیشن" },
        startAngle: { eng: "startAngle", per: "نوع انیمیشن" },
        colorNeedleCircleOuter: { eng: "colorNeedleCircleOuter", per: "نوع انیمیشن" },
        colorNeedleCircleOuterEnd: { eng: "colorNeedleCircleOuterEnd", per: "نوع انیمیشن" },
        colorNeedleCircleInner: { eng: "colorNeedleCircleInner", per: "نوع انیمیشن" },
        colorNeedleCircleInnerEnd: { eng: "colorNeedleCircleInnerEnd", per: "نوع انیمیشن" },
        needleCircleSize: { eng: "needleCircleSize", per: "نوع انیمیشن" },
        needleCircleInner: { eng: "needleCircleInner", per: "نوع انیمیشن" },
        needleCircleOuter: { eng: "needleCircleOuter", per: "نوع انیمیشن" },
        animationTarget: { eng: "animationTarget", per: "نوع انیمیشن" },
        useMinPath: { eng: "useMinPath", per: "نوع انیمیشن" },
        barStartPosition: { eng: "barStartPosition", per: "نوع انیمیشن" },

        borderRadius: { eng: "borderRadius", per: "نوع انیمیشن" },
        barBeginCircle: { eng: "barBeginCircle", per: "نوع انیمیشن" },
        tickSide: { eng: "tickSide", per: "نوع انیمیشن" },
        needleSide: { eng: "needleSide", per: "نوع انیمیشن" },
        numberSide: { eng: "numberSide", per: "نوع انیمیشن" },
        ticksWidth: { eng: "ticksWidth", per: "نوع انیمیشن" },
        ticksWidthMinor: { eng: "ticksWidthMinor", per: "نوع انیمیشن" },
        ticksPadding: { eng: "ticksPadding", per: "نوع انیمیشن" },
        barLength: { eng: "barLength", per: "نوع انیمیشن" },
        colorBarEnd: { eng: "colorBarEnd", per: "نوع انیمیشن" },
        colorBarProgressEnd: { eng: "colorBarProgressEnd", per: "نوع انیمیشن" },


        mode: { eng: "chart mode", per: "نوع ترسیم" },
        shape: { eng: "chart shape", per: "نحوه ترسیم" },
        dash: { eng: "chart dash", per: "نوع خط" },
        grid: { eng: "grid color", per: "رنگ گرید" },
        line: { eng: "line color", per: "رنگ خطوط" },
        font: { eng: "font color", per: "رنگ نوشتار" },
        textinfo: { eng: "text info", per: "رنگ نوشتار" },
        textposition: { eng: "text position", per: "رنگ نوشتار" },
        insidetextorientation: { eng: "inside text orientation", per: "رنگ نوشتار" },
        hole: { eng: "hole size", per: "رنگ نوشتار" },
        showlegend: { eng: "legend", per: "رنگ نوشتار" }
      }
    };
  },
  computed: {
    updatedData() {
      return this.$store.state.panel.data;
    },
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    myImage() {

    },
    matchedAvatar() {
      var image = "";
      if (this.myData.attribute.states != undefined)
        for (var icon of this.myData.attribute.states) {
          if (this.myData.datas[0].value === parseFloat(icon.value)) {
            image = this.getImageLinkById(icon.image);
          }
        }
      if (image)
        return image;
      else
        return this.defaultImage.link;
    },
    matchedTooltip() {
      var tooltip = "";
      if (this.myData.attribute.states != undefined)
        for (var icon of this.myData.attribute.states) {
          if (this.myData.datas[0].value === parseFloat(icon.value)) {
            tooltip = icon.tooltip;
          }
        }
      if (tooltip)
        return tooltip;
      else
        return "invalid";

    }

  },
  watch: {
    updatedData(newValue) {
      for (var Data of newValue) {
        if ((this.myData.datas[0]) && (JSON.stringify(Data.id) === JSON.stringify(this.myData.datas[0]._id))) {

          this.myData.datas[0].value = parseFloat(Data.value);
        }
      }
    },
    child2parent(newValue) {
      if ((newValue.task === "pickData") && (newValue.to === this.plotId)) {
        if (newValue.item.type === "data") {
          this.myData.datas = [{ ...newValue.item, value: 0 }];
        }
      }
    },
    resized() {
      this.resizeChart();
    },
    setting(newValue) {
      if (newValue) {
        this.$root.$emit("bv::show::modal", this.plotId);
      }
    },
    data(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        if (newValue) {
          this.myData = JSON.parse(JSON.stringify(newValue));
          // this.$refs.table1.refresh();
          this.selChart();
        }
      }
    },
    myData(newvalue) {
    }
  },
  methods: {
    splitMajorTicks(option) {
      if (typeof this.myData.attribute[option] === "string") {
        this.myData.attribute[option] = this.myData.attribute[option].split(",");
      }
    },
    addRow(option, data) {

      this.myData.attribute[option].push(data);
    },
    booltext(option, val) {

    },
    removeWidget() {
      this.$bvModal.msgBoxConfirm("Please confirm that you want to delete this widget")
        .then(value => {
          if (value === true) {
            this.$store.dispatch("chpge/child2parent", { task: "rmDashItem", item: this.plotId });
          }
        })
        .catch(err => {
        });
    },
    editWidget() {
      // console.log('editwidget',this.plotId)
      this.$root.$emit("bv::show::modal", this.plotId);
    },

    selChart() {
      var options = {
        width: 0,
        height: 0
      };

      Object.keys(this.gaugeOptions).forEach(key => {
        this.gaugeOptions[key].forEach(opt => {
          if (typeof this.myData.attribute[opt.name] !== "undefined") {
            if (opt.type === "number")
              options[opt.name] = parseFloat(this.myData.attribute[opt.name]);
            else
              options[opt.name] = this.myData.attribute[opt.name];

          } else {
            options[opt.name] = opt.default;
          }
          // options[opt.name] = typeof this.myData.attribute[opt.name]!=='undefined'? this.myData.attribute[opt.name]:opt.default;
          // console.log('oooo',key,opt.name,this.myData.attribute[opt.name],opt.default,options[opt.name])
        });
      });

      this.myData.attribute = JSON.parse(JSON.stringify(options));


      setTimeout(() => {
        this.resizeChart();
      }, "100");

      this.getData();
    },

    getData() {
      if (this.myData.datas[0]) {
        this.$store.dispatch("panel/getData", { id: [this.myData.datas[0]._id] }).then(
          data => {
          },
          error => {
            this.message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
        // this.sendDataSSE([this.myData.datas[0]._id])
      }
    },
    sendDataSSE(datas) {
      this.$store.dispatch("data/sendDataForSSE", { id: datas });
    },


    initModal: function() {
      this.myDataTmp = JSON.parse(JSON.stringify(this.myData));
    },
    resetModal: function() {
      this.myData = JSON.parse(JSON.stringify(this.myDataTmp));

      this.submitted = null;

      this.successful = null;
      this.message = null;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then(isValid => {
        if (isValid) {

          this.$store.dispatch("data/editDashboard", { _id: this.plotId, data: this.myData }).then(
            data => {
              this.$store.dispatch("chpge/child2parent", { task: "updateDash" });
              this.myDataTmp = JSON.parse(JSON.stringify(this.myData));
              this.$nextTick(() => {
                this.$bvModal.hide(this.plotId);
              });
            },
            error => {
              this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              this.successful = false;
              // alert(this.message);
            }
          );
        }
      });
    },
    addItem: function(type) {
      this.$store.dispatch("chpge/parent2child", {
        task: "pickData", from: this.plotId, filter: {
          dataType: ["Number", "Object"], usedBy: [], parents: [], archive: null, page: 1,
          perPage: 5000,
          withChild: false
        }
      });
      this.$root.$emit("bv::show::modal", "modal-widget-pick-data-" + this.plotId);
    },
    removeItem: function(index) {
      this.$bvModal.msgBoxConfirm("Please confirm that you want to delete this group")
        .then(value => {
          if (value === true) {
            this.myData.datas.splice(index, 1);
          }
        })
        .catch(err => {
        });
    },

    resizeChart() {
      const divElement = this.$refs.mainCont;
      if (divElement) {
        this.myData.attribute.height = divElement.clientHeight;
        this.myData.attribute.width = divElement.clientWidth;
      }
    },
    changeIcon(index) {
      this.selectedState = index;
      this.$root.$emit("bv::show::modal", this.plotId + "_photoGallery");
    },
    setNewImage(image) {
      this.myData.attribute.states[this.selectedState].image = image;
      this.$nextTick(() => {
        this.$bvModal.hide(this.plotId + "_photoGallery");
      });
    },
    handlephotoGalleryModalReset() {
      this.$bvModal.hide(this.plotId + "_photoGallery");
    },
    getImageLinkById(id) {
      const image = this.photoGallery.find(image => image.id === id);
      return image ? image.link : "";
    }

  },


  mounted() {
    if (this.data && this.data.datas && this.data.attribute) {
      this.myData = this.data;
    }
    this.selChart();
  }
};
</script>


<style scoped>
.remove {
  cursor: pointer;
  color: rgb(175, 235, 11);
  font-size: 18px;
}

.edit {
  cursor: pointer;
  color: rgb(9, 180, 210);
  font-size: 18px;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: transform 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(-100%);

}


</style>

<style>
.my-body-class {
  padding: 0px !important;
  /* Add any other custom styles you want */
}
</style>

<style>

.sevensegment {
  font-family: 'Seven Segment', sans-serif;
  font-size: 40px;
  font-weight: 600
}
</style>

