<template>
  <div
    :ref="'mainCont'+plotId"
    class="p-1 align-center justify-content-center"
    :id="plotId"
    style="height: calc(100%); width: 100%"
  >
    <div class="col-md-12 d-flex px-3 col-12 gap-3" v-if="plotId">
      <ejs-lineargauge
        :key="plotId"
        :width="myData.attribute.width + 'px'"
        :height="myData.attribute.height + 'px'"
        :tooltip="{ enable: true }"
        :useGroupingSeparator="true"
        :title="myData.attribute.title"
        orientation="Horizantal"
      >
        <e-axes>
          <e-axis
            :line="myData.attribute.line"
            :majorTicks="myData.attribute.majorTicks"
            :minorTicks="myData.attribute.minorTicks"
            :isInversed="true"
            :minimum="myData.attribute.axis.min"
            :maximum="myData.attribute.axis.max"
            :enableRtl="lang == 'per'"
          >
            <e-pointers>
              <e-pointer
                :enableDrag="false"
                :animationDuration="1000"
                :value="myData.attribute.value"
              ></e-pointer>
            </e-pointers>
          </e-axis>
        </e-axes>
      </ejs-lineargauge>
      <span
        v-if="myData.datas.length > 0"
        @click="editValuesData"
        class="e-icons e-check mt-5"
      ></span>
    </div>
    <b-modal
      :id="plotId"
      size="lg"
      centered
      :ok-title="dict.ok[lang]"
      :cancel-title="dict.cancel[lang]"
      :dir="dict.dir[lang]"
      body-class="my-body-class"
      hide-header-close
      :title="dict.modalTitle[lang]"
      @shown="initModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <div>
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li
              v-for="(tab, index) in tabList"
              :key="index"
              class="nav-item"
              @click="activeTab = tab"
            >
              <p
                class="nav-link"
                :class="{ active: activeTab === tab }"
                :id="tab"
                data-toggle="tab"
                role="tab"
                aria-controls="tab1"
                aria-selected="true"
              >
                {{ dict[tab][lang] }}
              </p>
            </li>
          </ul>
        </div>
        <div class="card-body h-100 p-3" style="overflow: hidden">
          <transition name="slide-fade" mode="out-in">
            <div class="tab-content h-100" :key="activeTab">
              <div v-if="activeTab === 'data'" class="h-100">
                <div class="d-flex align-items-center">
                  <ejs-textbox
                    :placeholder="dict.pickData[lang]"
                    :enableRtl="lang == 'per'"
                    floatLabelType="Auto"
                    v-model="selectedData.name"
                    :showClearButton="true"
                  ></ejs-textbox>
                  <b-button @click="pickData" class="btn-sm" variant="primary"
                  ><span class="e-icons e-edit-6"></span>
                  </b-button>
                </div>
              </div>
              <div v-if="activeTab === 'basic'" class="h-100">
                <div class="p-3">
                  <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
                    <li
                      v-for="(item, index) in tabs"
                      class="mga-tab-nav-item"
                      @click="activeBasic = item"
                      :key="index"
                    >
                      <a
                        class="mga-tab-nav-link"
                        :class="{ active: activeBasic === item }"
                        :id="item"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="tab1"
                        aria-selected="true"
                      >
                        {{ dict[item][lang] }}
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="row mx-auto" v-if="activeBasic == 'line'">
                  <div class="col-md-6 my-2 col-12">
                    <ejs-textbox
                      type="text"
                      :placeholder="dict.setTitle[lang]"
                      :enableRtl="lang == 'per'"
                      floatLabelType="Auto"
                      :showClearButton="true"
                      v-model="myData.attribute.title"
                    >
                    </ejs-textbox>
                  </div>
                  <div class="col-md-6 my-2 col-12">
                    <ejs-textbox
                      :placeholder="dict.pickData[lang]"
                      :enableRtl="lang == 'per'"
                      floatLabelType="Auto"
                      type="number"
                      v-model="myData.attribute.line.height"
                      :showClearButton="true"
                    ></ejs-textbox>
                  </div>
                  <div class="col-md-6 my-2 col-12">
                    <ejs-textbox
                      :placeholder="dict.pickData[lang]"
                      :enableRtl="lang == 'per'"
                      floatLabelType="Auto"
                      type="number"
                      v-model="myData.attribute.line.width"
                      :showClearButton="true"
                    ></ejs-textbox>
                  </div>
                  <div class="col-md-6 my-2 col-12">
                    <ejs-textbox
                      :placeholder="dict.pickData[lang]"
                      :enableRtl="lang == 'per'"
                      floatLabelType="Auto"
                      type="color"
                      v-model="myData.attribute.line.color"
                      :showClearButton="true"
                    ></ejs-textbox>
                  </div>
                </div>
                <div class="row mx-auto" v-if="activeBasic == 'majorTicks'">
                  <div class="col-md-6 my-2 col-12">
                    <ejs-textbox
                      type="number"
                      :placeholder="dict.setTitle[lang]"
                      :enableRtl="lang == 'per'"
                      floatLabelType="Auto"
                      :showClearButton="true"
                      v-model="myData.attribute.majorTicks.interval"
                    >
                    </ejs-textbox>
                  </div>
                  <div class="col-md-6 my-2 col-12">
                    <ejs-textbox
                      :placeholder="dict.pickData[lang]"
                      :enableRtl="lang == 'per'"
                      floatLabelType="Auto"
                      type="color"
                      v-model="myData.attribute.majorTicks.color"
                      :showClearButton="true"
                    ></ejs-textbox>
                  </div>
                </div>
                <div class="row mx-auto" v-if="activeBasic == 'minorTicks'">
                  <div class="col-md-6 my-2 col-12">
                    <ejs-textbox
                      type="number"
                      :placeholder="dict.setTitle[lang]"
                      :enableRtl="lang == 'per'"
                      floatLabelType="Auto"
                      :showClearButton="true"
                      v-model="myData.attribute.minorTicks.interval"
                    >
                    </ejs-textbox>
                  </div>
                  <div class="col-md-6 my-2 col-12">
                    <ejs-textbox
                      :placeholder="dict.pickData[lang]"
                      :enableRtl="lang == 'per'"
                      floatLabelType="Auto"
                      type="color"
                      v-model="myData.attribute.minorTicks.color"
                      :showClearButton="true"
                    ></ejs-textbox>
                  </div>
                </div>
                <div class="row mx-auto" v-if="activeBasic == 'labelStyle'">
                  <div class="col-md-6 my-2 col-12">
                    <ejs-textbox
                      :placeholder="dict.pickData[lang]"
                      :enableRtl="lang == 'per'"
                      floatLabelType="Auto"
                      type="color"
                      v-model="myData.attribute.labelStyle.font.color"
                      :showClearButton="true"
                    ></ejs-textbox>
                  </div>
                </div>
                <div class="row mx-auto" v-if="activeBasic == 'axis'">
                  <div class="col-md-6 my-2 col-12">
                    <ejs-textbox
                      :placeholder="dict.pickData[lang]"
                      :enableRtl="lang == 'per'"
                      floatLabelType="Auto"
                      type="number"
                      v-model="myData.attribute.axis.min"
                      :showClearButton="true"
                    ></ejs-textbox>
                  </div>
                  <div class="col-md-6 my-2 col-12">
                    <ejs-textbox
                      :placeholder="dict.pickData[lang]"
                      :enableRtl="lang == 'per'"
                      floatLabelType="Auto"
                      type="number"
                      v-model="myData.attribute.axis.max"
                      :showClearButton="true"
                    ></ejs-textbox>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </b-modal>

    <dataPickerModal :plotId="plotId" />
  </div>
</template>

<script>
import dataPickerModal from "@/components/widgetPickDataModal.vue";
import {
  LinearGaugeComponent,
  AxisDirective,
  AxesDirective,
  PointerDirective,
  PointersDirective
} from "@syncfusion/ej2-vue-lineargauge";
import { debounce } from "@/services/data.service";

export default {
  name: "widgetLinearGuage",
  components: {
    dataPickerModal,
    "ejs-lineargauge": LinearGaugeComponent,
    "e-axes": AxesDirective,
    "e-axis": AxisDirective,
    "e-pointers": PointersDirective,
    "e-pointer": PointerDirective
  },
  props: {
    plotId: String,
    data: Object,
    editable: Boolean,
    resized: Number,
    setting: Boolean
  },
  data() {
    return {
      tabList: ["data", "basic"],
      tabs: ["line", "majorTicks", "minorTicks", "labelStyle", "axis"],
      activeTab: "data",
      activeBasic: "line",
      dict: {
        cancel: { eng: "cancel", per: "لغو" },
        ok: { eng: "ok", per: "تایید" },
        pickData: { per: "انتخاب داده", eng: "Pick Data" },
        setTitle: { per: "عنوان فیلد ورودی", eng: "input title" },
        modalTitle: { per: "ویرایش ویجت", eng: "Edit widget" },
        dir: { eng: "ltr", per: "rtl" },
        data: { eng: "data", per: "داده" },
        basic: { eng: "basic", per: "تنظیمات پایه" },
        line: { per: "محور اصلی", eng: "line" },
        majorTicks: { per: "تیک های اصلی", eng: "major Ticks" },
        minorTicks: { per: "تیک های فرعی", eng: "minor Ticks" },
        labelStyle: { per: "فرمت مقادیر", eng: "label Style" },
        min: { per: "حداقل مقدار", eng: "minimum value" },
        max: { per: "حداکثر مقدار", eng: "maximum value" },
        axis: { per: "فرمت مقادیر", eng: "axis" }
      },
      myData: {
        datas: [],
        attribute: {
          name: "",
          title: "linear guage",
          value: 0,
          height: 200,
          width: 300,
          line: {
            height: 150,
            width: 2,
            color: "#4286f4"
          },
          majorTicks: {
            interval: 20,
            color: "Orange"
          },
          minorTicks: {
            interval: 5,
            color: "Orange"
          },
          labelStyle: {
            font: {
              color: "red"
            }
          },
          axis: {
            min: 0,
            max: 100
          }
        }
      },
      selectedData: {
        id: "",
        name: "",
        type: "",
        dataType: ""
      }
    };
  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    },
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    updatedData() {
      return this.$store.state.panel.data;
    }
  },
  watch: {
    resized() {
      this.resizeGuage();
    },
    updatedData(newValue) {
      for (var Data of newValue) {
        if (this.myData.datas[0] && Data.id == this.myData.datas[0]._id) {
          this.myData.attribute.value = Data.value;
        }
      }
    },
    setting(newValue) {
      if (newValue) this.$bvModal.show(this.plotId);
    },
    child2parent(newValue) {
      if (newValue.to == this.plotId) {
        this.myData.datas[0] = newValue.item;
        this.selectedData = newValue.item;
      }
    }
  },
  methods: {
    resizeGuage() {
      const divElement = this.$refs["mainCont" + this.plotId];
      if (divElement) {
        this.myData.attribute.height = divElement.clientHeight;
        this.myData.attribute.width = divElement.clientWidth - 10;
      }

      debounce(() => {
        this.handleSubmit();
      }, 1000);
    },
    initModal() {
    },
    resetModal() {
    },
    handleOk($bv) {
      $bv.preventDefault();
      this.handleSubmit();
    },
    editValuesData() {
      this.$store.dispatch("data/updateData", {
        _id: this.data["datas"][0]._id,
        values: [{ id: "", value: this.myData.attribute.value }]
      });
    },
    pickData() {
      this.$store.dispatch("chpge/parent2child", {
        task: "pickData",
        from: this.plotId,
        filter: {
          dataType: ["Number", "Object", "String"],
          usedBy: [],
          parents: [],
          archive: null,
          page: 1,
          perPage: 5000,
          withChild: false
        }
      });
      this.$root.$emit(
        "bv::show::modal",
        "modal-widget-pick-data-" + this.plotId
      );
    },
    handleSubmit() {
      this.$validator.validate().then((isValid) => {
        if (isValid) {
          this.$store
            .dispatch("data/editDashboard", {
              _id: this.plotId,
              data: {
                attribute: this.myData.attribute,
                datas: this.myData.datas
              }
            })
            .then(
              (data) => {
                this.$store.dispatch("chpge/child2parent", {
                  task: "updateDash"
                });
                this.$nextTick(() => {
                  this.$bvModal.hide(this.plotId);
                });
              },
              (error) => {
                this.message =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
              }
            );
        }
      });
    }
  },
  mounted() {
    if (this.data != null) {
      console.log(this.data);
      this.myData = this.data;
      this.selectedData = this.data["datas"][0];
    }
  }
};
</script>

<style scoped></style>
