var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid p-0 bg-none",class:_vm.lang === 'per' ? 'text-end' : 'text-start',attrs:{"dir":_vm.dict.dir[_vm.lang]}},[_c('div',{staticClass:"row g-0 p-2",class:_vm.lang === 'per' ? 'text-end' : 'text-start'},[_c('div',{staticClass:"col-lg-12 h-100"},[_c('div',{staticClass:" h-100",staticStyle:{"background-color":"rgba(255, 255, 255, 0.75) !important"}},[_c('b-container',{staticClass:"p-0",attrs:{"fluid":""}},[_c('ejs-grid',{ref:"notifications_list",attrs:{"width":'auto',"editSettings":_vm.editSettings,"selectionSettings":_vm.selectionOptions,"excelQueryCellInfo":_vm.excelQueryCellInfo,"actionBegin":_vm.actionBegin,"toolbarClick":_vm.toolbarClick,"allowSorting":true,"locale":_vm.lang,"allowExcelExport":true,"loadingIndicator":{ indicatorType: 'Shimmer' },"allowPdfExport":true,"toolbar":["Search","ExcelExport"],"allowPaging":true,"enableRtl":_vm.lang == 'per',"pageSettings":{ pageSize: 5, pageSizes: [5, 10, 20, 50] },"dataSource":_vm.list},scopedSlots:_vm._u([{key:"source",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.dict[data.source][_vm.lang])+" ")]}},{key:"readStatus.isRead",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.readStatus.isRead ? _vm.dict.seenNotif[_vm.lang] : _vm.dict.unseen[_vm.lang])+" ")]}},{key:"readStatus.readTime",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.getIrDate(data.readStatus.readTime, _vm.lang) || "-")+" ")]}},{key:"timestamp",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.getIrDate(data.timestamp, _vm.lang) || "-")+" ")]}},{key:"readStatus.username",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.readStatus.username || "-")+" ")]}},{key:"modify",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"d-flex justify-content-between col-md-3"},[_c('span',{staticClass:"e-icons e-delete-1 text-danger",staticStyle:{"font-size":"16px"},on:{"click":function($event){return _vm.remove(data._id)}}})])]}}])},[_c('e-columns',[_c('e-column',{attrs:{"width":"50","type":"checkbox"}}),_vm._l((_vm.fields),function(item,index){return _c('e-column',{key:index,attrs:{"width":_vm.screenWidth<=600?  100 :item.width,"field":item.key,"headerText":item[_vm.lang],"template":item.hasTemplate ? item.key : null}})})],2)],1)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }