<template>
  <div class="overflow-y-scroll">
    <div class="gridness" v-if="chartsInfo.length>0">
      <div
        class="cont"
        v-for="(item, index) in chartsInfo"
        :id="'holder_' + item.chartData.names"
        :key="index"
      >
      </div>
    </div>
  </div>
</template>
<script>
import Plotly from "plotly.js-dist/plotly";
import { mapGetters } from "vuex";

export default {
  name: "wbLicence",
  data() {
    return {
      plotRendered: false,
      data: [],
      usage: [],
      usedResources: [],
      layout: {
        autosize: true,
        margin: {
          l: 0,
          r: 0,
          b: 0,
          t: 0
        },
        showlegend: true,
        legend: {
          x: 1,
          y: 1,
          bgcolor: "#E2E2E2",
          bordercolor: "#FFFFFF",
          borderwidth: 1,
          font: {
            family: "Vazir",
            size: 12,
            color: "black"
          },
          orientation: "v"
        },
        paper_bgcolor: "rgba(0,0,0,0)"
      },
      resultUpload: {},
      file: "",
      dict: {
        free: { per: "فضای آزاد", eng: "free space" },
        allocated: { per: "فضای اختصاص داده شده", eng: "allocated space" },
        used: { per: "فضای استفاده شده", eng: "used space" },
        updloadText: { per: "آپلود فایل", eng: "browse" },
        dir: { per: "rtl", eng: "ltr" },
        text: { per: "انتخاب فایل", eng: "choose file" },
        uploadHeader: {
          per: "بارگذاری فایل منابع",
          eng: "upload licence file"
        },
        cancel: { per: "بستن", eng: "cancel" },
        ok: { per: "دخیره", eng: "save" },
        // jsonMqtt: { per: "پروتکل mqtt با نگاشت جیسون", eng: "Json Mqtt" },
        // mtr22Mqtt: { per: "پروتکل mqtt با نگاشت mtr22", eng: "Mtr22 Mqtt" },
        // jsonHttps: { per: "پروتکل https با نگاشت جیسون", eng: "Json Https" },
        // jsonHttpsServers: { per: "پروتکل سرور https با نگاشت جیسون", eng: "Json Https Servers" },
        // jsonCloud: { per: "تعداد فضای ابری", eng: "Json Cloud" },
        // totalControlNodes: { per: "دستورات", eng: "Rules" },
        // totalEventRules: { per: "قوانین رویدادها", eng: "Event Rules" },
        // totalEventTypes: { per: "انواع رویدادها", eng: "Event Types" },
        // totalDataIngestionModules: { per: "فرایند استخراج داده", eng: "Data Ingestion Modules" },
        // totalProcessFlows: { per: "چرخه های فرایند", eng: "Process Flows" },
        // totalData: { per: "تعداد دیتاهای سیستم", eng: "Datas" },
        // totalMaps: { per: "نقشه ها", eng: "Maps" },
        // totalDashboardWidgets: { per: "ویجت های دشبورد", eng: "Dashboard Widgets" },
        // numberOfWorkbenches: { per: "میزکارها", eng: "Workbenches" },
        jsonMqtt: { per: "پروتکل mqtt با نگاشت جیسون", eng: "Json Mqtt" },
        mtr22Mqtt: { per: "پروتکل mqtt با نگاشت mtr22", eng: "Mtr22 Mqtt" },
        jsonHttps: { per: "پروتکل https با نگاشت جیسون", eng: "Json Https" },
        jsonHttpsServers: {
          per: "پروتکل سرور https با نگاشت جیسون",
          eng: "Json Https Servers"
        },
        jsonCloud: { per: "تعداد فضای ابری", eng: "Json Cloud" },
        totalControlNodes: { per: "دستورات", eng: "Rules" },
        totalEventRules: { per: "قوانین رویدادها", eng: "Event Rules" },
        totalEventTypes: { per: "انواع رویدادها", eng: "Event Types" },
        totalDataIngestionModules: {
          per: "فرایند استخراج داده",
          eng: "Data Ingestion Modules"
        },
        totalProcessFlows: { per: "چرخه های فرایند", eng: "Process Flows" },
        totalData: { per: "تعداد دیتاهای سیستم", eng: "Datas" },
        totalMaps: { per: "نقشه ها", eng: "Maps" },
        totalDashboardWidgets: {
          per: "ویجت های دشبورد",
          eng: "Dashboard Widgets"
        },
        numberOfWorkbenches: { per: "میزکارها", eng: "Workbenches" }

      },
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [
              "rgb(104, 106, 108)",
              "rgb(17, 60, 252)",
              "rgb(86, 170, 255)"
            ],
            hoverOffset: 4
          }
        ]
      },
      chartsInfo: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  computed: {
    ...mapGetters("chpge", ["screenWidth", "screenHeight"]),
    lang() {
      return this.$store.state.chpge.lang;
    },
    holeUsage() {
      return this.$store.state.data.usedResources.message;
    },
    allRes() {
      return this.$store.state.data.resource;
    },
    wb() {
      return this.$store.state.data.workbench;
    }
  },
  methods: {
    computeRemain(name, mainVal) {
      if (Object.keys(this.usedResources).length > 0) {
        if (typeof this.usedResources[name] == "object") {
          return mainVal - this.usedResources[name].connections;
        } else
          return mainVal - this.usedResources[name];
      }
    },
    getWbCurLicense() {
      this.$store
        .dispatch("data/getCurWbLicence", { workbench: this.$store.state.data.workbench.activeWorkbench.id })
        .then((res) => {
          this.usage = res.message;
        });
    },

    getWbCurLicenseUsed() {
      this.$store
        .dispatch("data/getWbLicenceById", { workbench: this.$store.state.data.workbench.activeWorkbench.id })
        .then((res) => {
          this.usedResources = res.message;
          // console.log(res.message);
          this.generator();
        });
    },

    generator() {
      this.chartsInfo = [];
      Object.entries(this.usage).forEach((item) => {
        if (item[1].connections != 0 && item[1] != 0) {
          this.chartsInfo.push({
            chartData: {
              type: "pie",
              textposition: "inside",
              automargin: true,
              names: item[0],
              info: item[0],
              title: {
                text: this.dict[item[0]][this.lang],
                font: {
                  family: "Vazir",
                  size: 15,
                  color: "black"
                },
                x: 0.5,
                y: 0.9,
                xref: "paper",
                yref: "paper"
              },
              marker: {
                colors: [
                  "rgb(104, 106, 108)",
                  "rgb(17, 60, 252)"
                ]
              },
              hoverinfo: "label+percent",
              textinfo: "label+value+percent",
              insidetextorientation: "radial",
              labels: [this.dict.free[this.lang]],
              values: [
                this.computeRemain(
                  item[0],
                  typeof item[1] == "object" ? item[1].connections : item[1]
                )
              ],
              hoverOffset: 4
            }
          });
        }
      });
      Object.entries(this.usedResources).forEach((item) => {
        this.chartsInfo.forEach((chart) => {
          if (chart.chartData.names == item[0]) {
            const a = typeof item[1] == "object" ? item[1].connections : item[1];
            chart.chartData.labels.push(this.dict.used[this.lang]);
            chart.chartData.values.push(a);
          }

        });
      });
      this.chartsInfo.forEach((chart) => {
        Object.entries(this.usedResources).forEach((item) => {
          if (chart.chartData.names == item[0]) {
            const a =
              typeof item[1] == "object" ? item[1].connections : item[1];
            const b =
              typeof this.usedResources[item[0]] == "object"
                ? this.usedResources[item[0]].connections
                : this.usedResources[item[0]];
            chart.chartData.labels.push(this.dict.allocated[this.lang]);
            chart.chartData.values.push(a - b);
          }
        });
        setTimeout(() => {
          Plotly.newPlot(
            "holder_" + chart.chartData.names,
            [chart.chartData],
            this.layout,
            {
              displayModeBar: false,
              displaylogo: false,
              responsive: true
            }
          );
        }, 300);
      });

    }
  },
  watch: {
    screenWidth() {
      this.generator();
    },
    lang() {
      this.generator();
    },
    "wb": {
      handler(v) {
        this.getWbCurLicense();
        setTimeout(() => {
          this.getWbCurLicenseUsed();
        }, 100);
      }, deep: true
    }
  },
  mounted() {
    this.getWbCurLicense();
    setTimeout(() => {
      this.getWbCurLicenseUsed();
    }, 100);
  }
};
</script>
<style>
.gridness {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 12px;
  height: 90vh;
  margin: 0;
}

.cont {
  width: 100%;
  height: 100%;
}
</style>
