<template>
  <div :key="renderForColor" :dir="dict.dir[lang]" id="app" class="body">
    <div ref="themeContainer"></div>
    <main
      v-if="currentUser && appComponents[selPage] != 'signInForm'"
      class="h-100"
    >
      <Header />
      <sidebar />
      <div :class="pageClasses" class="position-relative h-100">
        <!--        <loading-page class="position-absolute top-0 bottom-0" style="right: 0!important;left: 0!important;" />-->

        <component :is="appComponents[selPage]" />
      </div>
    </main>
    <main v-else-if="!currentUser" class="h-100">
      <component :is="appComponents[selPage]" />
    </main>

    <!-- <main class="h-100"> 
      <Header/>
      <sidebar/>
        <div :class="pageClasses">  
          <component   :is='appComponents["setRecords"]'/>
      </div>
    </main>  -->
    <!--    v-if="$store.state.data.user.settings.showPopUpNotifications"-->
    <ejs-toast
      v-if="Object.keys(currentEvent.event).length>0 && showPopUp"
      :key="currentEvent.event.id"
      :showCloseButton="currentEvent.setting.clean"
      :icon="notifIcon(currentEvent.event.meta)"
      :buttons="button"
      :cssClass="classToast(currentEvent.event.meta)"
      :extendedTimeout="0"
      :width="currentEvent.setting.width+'px'"
      dir="Ltr"
      :beforeOpen="beforeOpen"
      :position="JSON.parse(currentEvent.setting.position)"
      :progressDirection="lang=='per' ? 'Rtl':'Ltr' "
      :showProgressBar="true"
      ref="defaultRef"
      :title="dict[currentEvent.event.message.action][lang]"
      :timeOut="currentEvent.setting.duration"
      :content='lang == "per" ? currentEvent.event.message.perMessage : currentEvent.event.message.enMessage'>
    </ejs-toast>

    <b-modal
      id="logout_timeout"
      size="xl"
      centered
      hide-header
      hide-footer
      body-class="lt-body-class"
      no-close-on-backdrop
      @shown="initLogoutModal"
    >
      <div
        :dir="dict.dir[lang]"
        class="d-flex w-100 h-100 justify-content-between"
      >
        {{ `${dict.statement1[lang]} ${CDT} ${dict.statement2[lang]}` }}

        <b-button variant="primary" @click="closeLogoutTimeout">{{
            dict.continue[lang]
          }}
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="modal-upload-licence"
      size="md"
      centered
      header-border-variant="primary"
      header-class="my-modal-header "
      cancel-disabled
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
    >
      <template #modal-header>
        <div :dir="dict.dir[lang]">
          <h5>{{ dict.uploadHeader[lang] }}</h5>
        </div>
      </template>
      <div>
        <p style="font-weight: bolder" class="text-bold">{{ dict.liText[lang] }}</p>
      </div>
      <div :dir="dict.dir[lang]" class="form-group mb-2">
        <label class="form-label" for="indoorMap">{{ dict.text[lang] }} </label>
        <div class="d-flex justify-content-between align-items-center">
          <input
            class="form-control"
            type="file"
            @change="uploadLicence"
            accept=".megashid"
          />
        </div>
      </div>
    </b-modal>
    <invite-user />
  </div>
</template>

<script>
/* eslint-disable */

import Header from "./components/Header.vue";
import sidebar from "./components/sidebar.vue";
import WebMap from "./components/WebMap.vue";
import Login from "./components/Login.vue";
import Home from "./components/home.vue";
import Dashboard from "./components/dashboard.vue";
import Profile from "./components/profile.vue";
import Accounts from "./components/accounts.vue";
import AccountEdit from "./components/accountEdit.vue";
import controllerSettings from "./components/setCont.vue";
import Master from "./components/setContMaster.vue";
import Https from "./components/setContHttps.vue";
import Cloud from "./components/setContCloud.vue";
import Slave from "./components/setContSlave.vue";
import Slot from "./components/setContSlot.vue";
import buildingSettings from "./components/setBuild.vue";
import Floor from "./components/setBuildFloor.vue";
import Zone from "./components/setBuildZone.vue";
import feederSettings from "./components/setItem.vue";
import feeder from "./components/setItemFeeder.vue";
import groupSettings from "./components/setGroup.vue";
import group from "./components/setGroupModal.vue";
import scenarioSettings from "./components/setScenario.vue";
import scenario from "./components/setScenarioModal.vue";
import processFlowDiagram from "./components/processFlowDiagram1.vue";
import createProccess from "@/components/processFlowComponents/crud/createProccess.vue";
import setComponent from "./components/setComponent.vue";
import setDashboard from "./components/setDashboard.vue";
import setRecords from "./components/widgets/widgetTen.vue";
import setHistoricalData from "./components/setHistoricalData.vue";
import controlEditor from "./components/controlEditor.vue";
import controlEditor2 from "./components/controlEditor2.vue";
import eventEditor from "./components/eventEditor1.vue";
import dataManager from "./components/dataManager.vue";
import signInForm from "./components/signInForm.vue";
import signUpForm from "./components/signUpForm.vue";
import workbenchManager from "./components/workbenchManager.vue";
import adminPanel from "./components/adminPanel.vue";
import indexMap from "@/components/mapComponents/indexMap.vue";
import indexTanker from "@/components/mapComponents/indexMap.vue";
import httpServer from "@/components/setHttpServer.vue";
import formGenerator from "@/components/formGenerator.vue";

import { winsize } from "./models/host";
import store from "@/store";
import { DropDownList, MultiSelect } from "@syncfusion/ej2-dropdowns";
import { TextBox } from "@syncfusion/ej2-inputs";
import Vue from "vue";
import { loadCldr, L10n, setCulture, enableRtl } from "@syncfusion/ej2-base";
import { mapActions } from "vuex";
import { v4 } from "uuid";
import { persianLacale } from "@/lacale/persian";
import LoadingPage from "@/components/base/loadingPage.vue";
import numbers from "@syncfusion/ej2-cldr-data/main/fa/numbers.json";
import timeZoneNames from "@syncfusion/ej2-cldr-data/main/fa/timeZoneNames.json";
// import gregorian from "cldr-data/main/fa/ca-gregorian.json";
import gregorian from "cldr-data/main/fa/ca-persian.json";
import numberingSystems from "@syncfusion/ej2-cldr-data/supplemental/numberingSystems.json";
import { ToastComponent } from "@syncfusion/ej2-vue-notifications";
import { ToastUtility } from "@syncfusion/ej2-vue-notifications";
import InviteUser from "@/components/headerModalInvited.vue";

export default {
  name: "App",
  components: {
    InviteUser,
    "ejs-toast": ToastComponent,
    LoadingPage,
    Header,
    sidebar,
    httpServer,
    WebMap,
    indexTanker,
    indexMap,
    // LightPanel,
    // LightItem,
    // LightGroup,
    // Panel,
    Login,
    Home,
    Dashboard,
    Profile,
    Accounts,
    AccountEdit,
    controllerSettings,
    Master,
    Https,
    Cloud,
    Slave,
    Slot,
    buildingSettings,
    Floor,
    Zone,
    feederSettings,
    feeder,
    groupSettings,
    group,
    scenarioSettings,
    scenario,
    processFlowDiagram,
    // mapZoneSetting,
    // mapSetting,
    // addEquip,
    // setEquip,
    // setEquipDataSel,
    setComponent,
    setDashboard,
    setRecords,
    setHistoricalData,
    controlEditor,
    controlEditor2,
    eventEditor,
    dataManager,
    signInForm,
    signUpForm,
    workbenchManager,
    createProccess,
    adminPanel,
    formGenerator
  },
  data() {
    return {
      button: [],
      reRender: 0,
      settings: {},
      renderForColor: 0,
      currentTheme: null,
      CDT: 30,
      dynamicComponentNames: [],
      timer: null,
      dict: {
        closed: { per: "بستن", eng: "close" },
        seen: { per: "مشاهده شد", eng: "seen" },
        "Signed in": { per: "ورود کاربر", eng: "signed in" },
        "Acknowledged": { per: "تایید شدن", eng: "Acknowledged" },
        "Disabled": { per: "غیرفعال شدن", eng: "Disabled" },
        "Activated": { per: "فعال شدن", eng: "Activated" },
        "Invited": { per: "دعوت کاربر", eng: "Invited" },
        "Accepted": { per: "قبول عضویت میزکار", eng: "Accepted" },
        "Decline": { per: "رد عضویت میزکار", eng: "Decline" },
        dir: { eng: "ltr", per: "rtl" },
        ok: { eng: "ok", per: "بله" },
        cancel: { eng: "cancel", per: "کنسل" },
        continue: { eng: "continue", per: "ادامه" },
        statement1: {
          eng: `You will be automatically logged out in the`,
          per: `شما به صورت خودکار تا .`
        },
        statement2: {
          eng: `next second, if you want to continue, click on the continue button.`,
          per: `ثانیه دیگر از سامانه خارج خواهید شد، در صورت تمایل به ادامه حضور روی دکمه ادامه کلیک کنید`
        },
        updloadText: { per: "آپلود فایل", eng: "browse" },
        text: { per: "انتخاب فایل", eng: "choose file" },
        uploadHeader: {
          per: "بارگذاری فایل منابع",
          eng: "upload licence file"
        },
        liText: {
          per: "متاسفانه لایسنس شما معتبر نمی باشد. لطفا برای آپلود لایسنس جدید اقدام کنید.",
          eng: "Unfortunately your license is out of reach. Please upload the license file to use the system."
        }
      },
      appComponents: {
        lighting: "WebMap",
        login: "Login",
        home: "Home",
        dashboard: "Dashboard",
        profile: "Profile",
        accounts: "Accounts",
        accountEdit: "AccountEdit",
        controllerSettings: "controllerSettings",
        master: "Master",
        https: "Https",
        cloud: "Cloud",
        slave: "Slave",
        slot: "Slot",
        httpServer: "httpServer",
        buildingSettings: "buildingSettings",
        floor: "Floor",
        zone: "Zone",
        feederSettings: "feederSettings",
        feeder: "feeder",
        groupSettings: "groupSettings",
        group: "group",
        scenarioSettings: "scenarioSettings",
        scenario: "scenario",
        processFlowDiagram: "processFlowDiagram",
        setDashboard: "setDashboard",
        setRecords: "setRecords",
        setHistoricalData: "setHistoricalData",
        controlEditor: "controlEditor",
        controlEditor2: "controlEditor2",
        eventEditor: "eventEditor",
        dataManager: "dataManager",
        signin: "signInForm",
        signup: "signUpForm",
        workbenchManager: "workbenchManager",
        createProcess: "createProccess",
        adminPanel: "adminPanel",
        indexMap: "indexMap",
        tankerIndex: "indexTanker",
        formGenerator: "formGenerator"
      },
      itemsArranged: [],
      ng: 0,
      currentEvent: {
        event: {},
        setting: {}
      }
    };
  },
  computed: {
    notificationsSetting() {
      return this.$store.state.data.notifSetting;
    },
    theme() {
      return this.$store.state.data.theme;
    },
    showPopUp() {
      if (this.$store.state.data.user != null)
        return this.$store.state.data.user.settings.showPopUpNotifications;
      else return false;
    },
    currentUser() {
      return this.$store.state.data.user;
    },
    logoutTimeout() {
      return this.$store.state.chpge.logoutTimeout;
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    selPage() {
      return this.$store.state.chpge.page;
    },
    sseStatus() {
      return this.$store.state.panel.sse;
    },
    newEvents() {
      return this.$store.state.panel.event;
    },
    sidebar() {
      return this.$store.state.chpge.sidebar;
    },
    lockSidebar() {
      return this.$store.state.chpge.lockSidebar;
    },
    pageColor() {
      if (this.currentUser)
        return this.currentUser.theme.pageColor;
      else
        return "";
    },
    pageFont() {
      if (this.currentUser)
        return this.currentUser.theme.pageFont;
      else
        return "dark";
    },
    pageClasses() {
      const classes = {
        "closed-sidebar": !(this.lockSidebar && this.sidebar)
      };

      if (this.dict.dir[this.lang] === "rtl")
        classes["app-main__outer-rtl"] = true;
      else if (this.dict.dir[this.lang] === "ltr")
        classes["app-main__outer-ltr"] = true;

      if (this.pageColor) {
        classes[this.pageColor] = true;
      } else {
        classes["bg-default-page"] = true;
      }

      if (this.pageFont) {
        classes[this.pageFont + "-font"] = true;
      } else {
        classes["dark-font"] = true;
      }
      return classes;
    },
    checkLogin() {
      return this.$store.state.auth.status.loggedIn;
    },
    user() {
      return this.$store.state.data.user;
    },
    workbench() {
      return this.$store.state.data.workbench;
    },
    colorUpdated() {
      return this.$store.state.data.avatarUpdated;
    }
  },
  watch: {
    notificationsSetting(value) {
      if (value.length > 0) {
        this.settings = value[0].setting;
        this.currentEvent.setting = value[0].setting;
      }
    },
    colorUpdated() {
      this.renderForColor += 1;
    },
    user() {
      this.reRender += 1;
    },
    workbench: {
      handler(v) {

        const wbItems = this.workbench["workbenches"].filter(x => x.status == "waiting");
        if (wbItems.length > 0) {
          this.$store.dispatch("chpge/parent2child", {
            task: "invitedWBs",
            items: wbItems
          });
          this.$root.$emit("bv::show::modal", "modal-invite-user_table");
        }
        this.reRender += 1;
      }, deep: true
    },
    theme(newTheme) {
      this.loadTheme(newTheme);
    },
    sseStatus(newValue, oldValue) {
      if (newValue == false) {
        setTimeout(this.$store.dispatch("panel/sseSubscription"), 5000);
        // this.$store.dispatch('panel/sseSubscription')
      }
    },
    newEvents(newValue) {
      this.currentEvent = {
        event: newValue,
        setting: this.settings[newValue.priority]
      };
      setTimeout(() => {
        this.$refs.defaultRef.ej2Instances.show();
      }, 200);
      // ToastUtility.show({
      //   title: 'Toast Title',
      //   content: 'Toast shown using utility function with ToastModel',
      //   timeOut: 20000,
      //   width: 600,
      //   extendedTimeout: 0,
      //   cssClass: 'e-toast-danger',
      //   position: { X: 'Top', Y: 'Center' },
      //   showCloseButton: true,
      //   showProgressBar: true,
      // },"Success");


      // ToastUtility.show(this.lang == "per" ? newValue.message.perMessage : newValue.message.enMessage, 'Error', Number(newValue.meta.eventType.details.duration) || 2000)
      // newValue.forEach((event) => {
      //   this.$notify({
      //     type: event.type,
      //     title: event.message.action,
      //     text: this.lang == "per" ? event.message.perMessage : event.message.engMessage,
      //     duration: Number(event.meta.eventType.details.duration) || 2000,
      //     speed: Number(event.meta.eventType.details.speed) || 50,
      //     closeOnClick: event.meta.eventType.details.clean || true,
      //     max: 4,
      //     ng: event.meta.eventType.details.clean
      //   });
      // });
    },
    logoutTimeout(newValue) {
      if (newValue === "1") {
        this.openLogoutTimeout();
        this.$store.dispatch("chpge/changeLogoutTimeout", "0");
      }
    },
    checkLogin: {
      handler(val) {
        if (val) {
          this.$store.dispatch("panel/sseSubscription");
          this.$store.dispatch("data/getPermission");
          // this.$store.dispatch("data/getWBListdata");
        }
      }
    }
  },
  methods: {
    ...mapActions(["setTheme"]),
    notifIcon(val) {
      switch (val.eventType.details.type) {
        case "notice":
          return "e-toast-success-icon";
          break;
        case  "alarm":
          return "e-toast-error-icon";
          break;
        case  "info":
          return "e-toast-info-icon";
          break;
        case  "warning":
          return "e-toast-warning-icon";
          break;
        default:
          return "e-toast-info-icon";
      }


    },
    classToast(val) {
      switch (val.eventType.details.type) {
        case "notice":
          return "e-toast-success";
          break;
        case  "alarm":
          return "e-toast-danger";
          break;
        case  "info":
          return "e-toast-info";
          break;
        case  "warning":
          return "e-toast-warning";
          break;
        default:
          return "e-toast-info";
      }


    },
    getPositionToast(val) {
      return val;
    },
    updateScreenSize() {
      this.$store.dispatch("chpge/updateScreenSize");
    },
    createAndRegisterDynamicComponent(type) {
      const template = `
          <div>
            <input type="text" tabindex="1" :id="this.id" :name="this.id" />
            <div :id="'error-' + id" class="error-container"></div>
          </div>
        `;
      const data = () => ({
        cid: ""
      });
      const computed = {};
      const methods = {
        handleChange(args) {
          this.$emit("change", args.value);
        },
        initMultiSelect() {

          if (type === "MultiSelect") {
            this.element = new MultiSelect({
              dataSource: this.dataSource,
              value: this.value,
              fields: this.fields,
              placeholder: this.placeholder,
              floatLabelType: this.floatLabelType,
              enableRtl: this.enableRtl,
              change: this.handleChange
            });
          } else if (type === "DropDownList") {
            this.element = new DropDownList({
              dataSource: this.dataSource,
              value: this.value,
              fields: this.fields,
              enabled: this.enabled,
              placeholder: this.placeholder,
              floatLabelType: this.floatLabelType,
              enableRtl: this.enableRtl,
              change: this.handleChange
            });
          } else if (type === "TextBox") {
            this.element = new TextBox({
              value: this.value,
              placeholder: this.placeholder,
              floatLabelType: this.floatLabelType,
              enableRtl: this.enableRtl,
              change: this.handleChange,
              enabled: this.readOnly,
              showClearButton: true,
              htmlAttributes: { type: this.inputType }
            });
          }
          this.element.appendTo(`#${this.id}`);
        },
        addValidator() {
          this.validatorInstance.addRules(`${this.id}`, this.validatorRules);
          // console.log('this.validatorInstance',JSON.stringify(this.validatorInstance.properties))
        },
        removeValidator() {
          this.validatorInstance.removeRules(this.id);
          // console.log('this.validatorInstance',JSON.stringify(this.validatorInstance.properties))
        }
      };
      const mounted = function() {
        this.initMultiSelect();
        this.addValidator();
      };
      const beforeDestroy = function() {
        // console.log('destroy element', this.id)
        this.removeValidator();
      };
      const props = {
        id: {
          type: String,
          required: true
        },
        dataSource: {
          type: Array,
          required: type === "TextBox" ? false : true
        },
        value: {
          value: [Number, String, Array],
          required: true
        },
        fields: {
          type: Object,
          required: type === "TextBox" ? false : true
        },
        placeholder: {
          type: String,
          default: "Enter value"
        },
        floatLabelType: {
          type: String,
          default: "Auto"
        },
        enableRtl: {
          type: Boolean,
          default: false
        },
        validatorInstance: {
          type: Object
          // required: true
        },
        validatorRules: {
          type: Object
          // required: true
        },
        readOnly: {
          type: Boolean,
          default: true
        },
        enabled: {
          type: Boolean,
          default: true
        },
        inputType: {
          type: String,
          default: "text"
        }
      };

      // Directly use Vue.extend to create the dynamic component
      const DynamicComponent = Vue.extend({
        template,
        data,
        computed,
        methods,
        mounted,
        beforeDestroy,
        props
      });

      Vue.component(type, DynamicComponent);
      this.dynamicComponentNames.push(type);
    },
    beforeOpen: function() {
      if (this.currentEvent.setting.sound == true) {
        var audio = new Audio("https://sedatoseda.com/wp-content/uploads/camion-de-bomberos-10315.mp3");
        audio.play();
        setTimeout(() => {
          audio.pause();
        }, this.currentEvent.setting.duration || 5000);
      }
    },
    uploadLicence(val) {
      if (val.target.files[0].name.includes(".megashid")) {
        const formData = new FormData();
        formData.append("file", val.target.files[0]);
        this.$store.dispatch("data/uploadFiles", formData).then((res) => {
          this.$store.dispatch("data/checkValidateLicense");
          this.$bvModal.hide("modal-upload-licence");
        });
      }
    },
    notificationColor: function(type) {
      var color = "#000000";
      if (type === "notice")
        color = "#42A85F";
      if (type === "warning")
        color = "#f48a06";
      if (type === "alarm")
        color = "#B82E24";
      if (type === "info")
        color = "#0d35d0";
      return { background: color };
    },
    openLogoutTimeout: function() {
      // this.modalData = {type:'Add', index:null}
      this.$root.$emit("bv::show::modal", "logout_timeout");
    },
    closeLogoutTimeout: function() {
      this.CDT = 30;
      clearTimeout(this.timer);

      this.$store.dispatch("auth/extendLogin");
      // this.$store.dispatch('panel/getData', {id:[]})
      this.$bvModal.hide("logout_timeout");
    },
    initLogoutModal: function() {
      this.CDTimer();
    },
    CDTimer: function() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.CDT > 0) {
          this.CDT = this.CDT - 1;
          this.CDTimer();
        } else {
          this.CDT = 30;
          clearTimeout(this.timer);
          this.$bvModal.hide("logout_timeout");
          this.$store.dispatch("auth/logout");
          this.$store.dispatch("data/removeUserData");
          this.$store.dispatch("chpge/changePage", "signin");
        }
      }, 1000);
    }
  },
  mounted() {
    this.button[0] = {
      model: { content: this.dict.closed[this.lang] },
      click: (val) => {
        this.$refs.defaultRef.hide();
      }
    };
    // setTimeout(() => {
    //   const wbItems = this.workbench["workbenches"].filter(x => x.status == "waiting");
    //   if (wbItems.length > 0) {
    //     this.$store.dispatch("chpge/parent2child", {
    //       task: "invitedWBs",
    //       items: wbItems
    //     });
    //     this.$root.$emit("bv::show::modal", "modal-invite-user_table");
    //   }
    // }, 200);
    this.createAndRegisterDynamicComponent("MultiSelect");
    this.createAndRegisterDynamicComponent("DropDownList");
    this.createAndRegisterDynamicComponent("TextBox");


    this.$store.dispatch("chpge/updateScreenSize");
    if (this.checkLogin) {
      this.$store.dispatch("data/getNotifSetting");
      this.$store.dispatch("panel/sseSubscription");
      this.$store.dispatch("data/getPermission");
      this.$store.commit("panel/setSocket", false);

      // this.$store.dispatch("data/getWBListdata");
    }
    // setTimeout(() => {
    //   if (this.$store.state.data.expired == false && this.$store.state.data.user != null) {
    //     this.$bvModal.show("modal-upload-licence");
    //   }
    // }, 200);

    window.addEventListener("resize", this.updateScreenSize);

  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateScreenSize);
  },
  created() {
    loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);
    L10n.load({
      "per": persianLacale,
      "fa": persianLacale
    });
  }
};
</script>

<style>

:root {
  --material-primary: #6200ee; /* Change this to your desired primary color */
  --material-secondary: #03dac5; /* Change this to your desired secondary color */
  --material-info: #2196f3;
  --material-success: #4caf50;
  --material-danger: #f44336;
  --material-input-border: #d32f2f; /* Default border color */
  --material-input-hover-border: #1976d2; /* Hover state border color */
  --material-input-focus-border: #ff5722; /* Focus state border color */
  --material-input-background: #f5f5f5; /* Input background color */

}

.leaflet-control-attribution.leaflet-control {
  display: none !important;
}

.e-confirm-dialog.e-control.e-dialog.e-lib {
  left: unset !important;
}

.e-confirm-dialog.e-control-rtl .e-dlg-content {
  direction: rtl !important;
  text-align: right !important;
}

.e-confirm-dialog.e-control-rtl .e-dlg-header-content {
  direction: rtl !important;
  text-align: right !important;
}

.e-confirm-dialog.e-control-rtl .e-footer-content {
  direction: ltr !important;
  text-align: left !important;
}

.e-confirm-dialog.e-control-ltr .e-dlg-content {
  direction: ltr !important;
  text-align: left !important;
}

.e-confirm-dialog.e-control-ltr .e-dlg-header-content {
  direction: ltr !important;
  text-align: left !important;
}

.e-confirm-dialog.e-control-ltr .e-footer-content {
  direction: rtl !important;
  text-align: right !important;
}

.modal-content {
  border-radius: unset !important;
}

.modal-header.border-primary {
  border-color: lightgrey !important;
}

.e-link.e-numericitem.e-spacing.e-currentitem.e-active {
  background: #113cfc !important;
}

.e-icons:hover {
  cursor: pointer !important;
}

.mga-tab-nav:hover {
  cursor: pointer !important;
}


.modal-footer .btn.btn-secondary {
  background: unset !important;
  color: black !important;
  border: unset !important;
  border-radius: unset !important;
}

.modal-footer .btn.btn-secondary:hover {
  background: lightgrey !important;
  color: black !important;
  border: unset !important;
  border-radius: unset !important;
}

.modal-footer .btn.btn-primary {
  background: #113cfc !important;
  color: white !important;
  border: unset !important;
  border-radius: unset !important;
}

.modal-footer .btn.btn-primary:hover {
  background: #113cfc !important;
  color: white !important;
  border: 1px solid !important;
  border-radius: unset !important;
}

.btn .btn-sm.btn-primary {
  border-radius: unset !important;
  background-color: #113cfc !important;
}
</style>
<style lang="scss">
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/fabric.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-notifications/styles/fabric.css';
@import '../node_modules/@syncfusion/ej2-vue-grids/styles/material.css';
@import "../node_modules/@syncfusion/ej2-icons/styles/fabric.css";
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../node_modules/@syncfusion/ej2-vue-notifications/styles/material.css";
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";

#app {
  height: 100%;
  width: 100%;
  font-family: Vazir, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;

  .app-main__outer-ltr {
    min-height: 100vh;
    flex: 1;
    flex-direction: column;
    display: flex;
    z-index: 8;
    padding: 60px 0 0 280px;
    transition: all .2s;
  }

  .app-main__outer-ltr.closed-sidebar {
    padding-left: 90px;
  }

  .app-main__outer-rtl {
    min-height: 100vh;
    flex: 1;
    flex-direction: column;
    display: flex;
    z-index: 8;
    padding: 60px 280px 0 0;
    transition: all .2s;
  }

  .app-main__outer-rtl.closed-sidebar {
    padding-right: 90px;
  }


  @media (max-width: 991.98px) {
    .app-main__outer-ltr {

      padding: 60px 0 0 0px;

    }
    .app-main__outer-ltr.closed-sidebar {
      padding-left: 0px;
    }
  }


  .my-notification {
    margin: 0 10px 10px;
    display: flex;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .1), 0 2px 15px 0 rgba(0, 0, 0, .05);
    background: #ffffff;
    border-radius: 8px;

    .notification-icon {
      width: 15%;
      font-size: 35px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px 0px 0px 8px;
    }

    .notification-content {
      width: 75%;
      display: flex;
      flex-direction: column;
    }

    .notification-button {
      width: 10%;
      padding-top: 5px;

    }

    .notification-close {
      font-size: 15px;
      cursor: pointer;
    }

    .notification-close:hover {
      color: #9e9d9b
    }

    .notification-title {
      height: 50%;
      color: #222d32;
      font-size: 16px;
      border-bottom: 1px solid #555c64;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }

    .notification-text {
      height: 50%;
      color: #222d32;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;

    }
  }

  .lt-body-class {
    background-color: #ffc107;
    padding: 10px;
    min-height: 70px;
    /* border: 1px solid rgb(4, 202, 169) !important; */
    border-radius: inherit !important;
  }
}

</style>

