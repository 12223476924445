<template>
  <div :dir="dict.dir[lang]" class="container-fluid h-100 p-0 bg-white">
    <div class="container-panel">
      <div class="left-panel">
        <div class="panel-header d-flex align-items-center">

          <img
            src="../assets/images/logo-persian.png"
            alt="megashid"
            class="col-md justify-content-center col-3"
            style="margin-left: 15px; margin-right: 10px;"
          />
          <!--          <img-->
          <!--            src="../assets/images/fava.jpg"-->
          <!--            alt="Hosco"-->
          <!--            class="col-md-2 justify-content-center col-3"-->
          <!--            style="margin-left: 15px; margin-right: 10px;"-->
          <!--          />-->
          <!--          <img-->
          <!--            src="../assets/images/irisa-logo-transparent.png"-->
          <!--            alt="irisa"-->
          <!--            class="col-md-2 justify-content-center col-3"-->
          <!--            style="margin-left: 15px; margin-right: 10px;aspect-ratio: 18 / 8;"-->
          <!--          />-->
        </div>
        <div class="right-panel-content">
          <div class="suggestion">
            <div class="list">
              <div :title="dict.suggestion1[lang]">
                {{ dict.suggestion1[lang] }}
              </div>
              <!-- <div :title="dict.suggestion2[lang]"> {{dict.suggestion2[lang]}} </div> -->
              <div :title="dict.suggestion3[lang]">
                {{ dict.suggestion3[lang] }}
              </div>
              <div :title="dict.suggestion4[lang]">
                {{ dict.suggestion4[lang] }}
              </div>
            </div>
          </div>
        </div>

        <!--        <div class="right-panel-footer">-->
        <!--          <div class="media">-->
        <!--            <a class="media-ball" href="https://www.megashid.ir"-->
        <!--            ><img class="megashid-src" alt=""-->
        <!--            /></a>-->
        <!--            <a class="media-ball" href="https://www.aparat.com/megashid.ir"-->
        <!--            ></a>-->
        <!--            <a class="media-ball" href=""></a>-->
        <!--          </div>-->
        <!--          <div class="copyright-text">-->
        <!--            <p>{{ dict.copyright[lang] }}</p>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <div class="right-panel"></div>

      <div class="center-panel">
        <h2 class="fw-bold mb-5">{{ dict.title[lang] }}</h2>
        <transition name="slide-fade" mode="out-in">
          <div>

            <div
              :class="
            lang === 'per'
              ? 'form-outline mb-4 text-end'
              : 'form-outline mb-4 text-start'
          "
            >
              <label class="form-label" for="username" style="margin-left: 0px">{{
                  dict.username[lang]
                }}</label>
              <input
                type="text"
                id="username"
                class="form-control"
                v-model="credentials.username"
                v-validate="'required'"
                name="username"
              />
              <div
                v-if="submitted && errors.has('username')"
                class="alert alert-danger"
                role="alert"
              >
                {{dict.usernameReq[lang]}}
              </div>
            </div>

            <div
              :class="
            lang === 'per'
              ? 'form-outline mb-4 text-end'
              : 'form-outline mb-4 text-start'
          "
            >
              <label class="form-label" for="password" style="margin-left: 0px">{{
                  dict.password[lang]
                }}</label>
              <input
                type="password"
                id="password"
                class="form-control"
                v-model="credentials.password"
                v-validate="'required'"
                name="password"
              />
              <div
                v-if="submitted && errors.has('password')"
                class="alert alert-danger"
                role="alert"
              >
                {{dict.passReq[lang]}}
              </div>
            </div>

            <div class="input-group input-group-outline mb-3">
              <input
                type="text"
                id="code"
                class="form-control w-50"
                @keydown.enter="handleLogin"
                v-model="credentials.captchaInput"
                name="code"
                v-validate="'required'"
              />
              <button
                @click="refreshCaptcha"
                class="btn btn-light btn-block w-50"
                :disabled="loading"
              >
                <img :src="captchaImageUrl" alt="CAPTCHA" />
              </button>
              <div
                v-if="submitted && errors.has('code')"
                class="alert alert-danger"
                role="alert"
              >
                {{dict.requireCode[lang]}}
              </div>
            </div>

            <div class="d-grid gap-2">
              <button
                @click="handleLogin"
                class="btn btn-primary btn-block mb-4"
                :disabled="loading"

              >
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
                {{ dict.signin[lang] }}
              </button>
<!--              <button @click="openSSo" class="btn-outline-primary text-primary btn-block bg-white btn-sm px-2 py-1">-->
<!--                <span>{{dict.favaLogin[lang]}}</span>-->
<!--                <img src="@/assets/images/fava.jpg" width="35" class="img-fluid mx-1">-->
<!--              </button>-->
              <!--          her for key cloak-->
            </div>




            <div>

            </div>
            <div
              v-if="!successful"
              :class="
            lang === 'per'
              ? 'text-end alert alert-danger'
              : 'text-start alert alert-danger'
          "
            >
              <p v-if="message === 'err1'" class="mb-0">
                {{ dict.errCaptcha[lang] }}
              </p>
              <p v-if="message === 'err2'" class="mb-0">
                {{ dict.errUserPass1[lang] }}
              </p>
              <p v-if="message === 'err2'" class="mb-0">
                {{ dict.errUserPass2[lang] }}
              </p>
              <p v-if="message === 'err2'" class="mb-0">
                {{ dict.errUserPass3[lang] }}
              </p>
            </div>

            <hr />
            <p v-if="canSignUp">
              {{ dict.regmsg[lang] }}
              <a href="#" @click="showRegisterForm">{{ dict.register[lang] }}</a>
            </p>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import bgImage from "@/assets/images/login-bg-3.jpg";
import { v4 } from "uuid";

export default {
  name: "LoginForm",
  data() {
    return {
      dict: {
        passReq: { eng: "Password is required!", per: "وارد کردن رمزعبور الزامی است!" },
        usernameReq: { eng: "Username is required!", per: "وارد کردن نام کاربری الزامی است!" },
        requireCode: { eng: "Code is required!", per: "وارد کردن کد الزامی است!" },
        favaLogin: { eng: "Fava login", per: "ورود از طریق فاوا" },
        dir: { eng: "ltr", per: "rtl" },
        title: { eng: "Log in to platform", per: "ورود به سامانه" },
        username: { eng: "user", per: "نام کاربری" },
        password: { eng: "password", per: "گذرواژه" },
        signin: { eng: "Log in", per: "ورود" },
        regmsg: { eng: "Don't have an account?", per: "حساب کاربری ندارید؟" },
        register: { eng: "Register now", per: "ثبت نام" },
        irisa: { eng: "Irisa integrated systems login", per: "ورود با سیستم های یکپارچه ایریسا" },
        megashid: { eng: "login", per: "ورود" },
        errCaptcha: {
          eng: "invalid captcha",
          per: "کد امنیتی وارد شده اشتباه می باشد"
        },
        errUserPass1: {
          eng: "invalid username or password",
          per: "نام کاربری یا کلمه عبور اشتباه است"
        },
        errUserPass2: {
          eng: "check your keyboard language",
          per: "• وضعيت زبان(En/FA)سيستم خود را كنترل كنيد"
        },
        errUserPass3: {
          eng: "the password is caps sensitive",
          per: "• پسورد به حروف بزرگ و كوچك حساس است"
        },
        err3: {
          eng: "you have not workbench on system.wait to invite.",
          per: "متاسفانه هنوز به میزکاری دعوت نشده اید."
        },
        suggestion1: {
          eng: "Use reliable and up-to-date browsers such as Google Chrome.",
          per: "از مرورگر های مطمئن و بروز مانند گوگل کروم استفاده کنید."
        },
        suggestion2: {
          eng: "Change your password at short intervals.",
          per: "رمز خود را در فواصل زمانی کوتاه تغییر دهید."
        },
        suggestion3: {
          eng: "Be sure to log out when you're done.",
          per: "پس از انجام و اتمام کار، حتما از سیستم خارج شوید."
        },
        suggestion4: {
          eng: "Never share your username and password with others.",
          per: "هرگز نام کاربری و گذرواژه خود را در اختیار دیگران قرار ندهید."
        },
        copyright: {
          eng: "© Mohareke Gostar Arshid Co. All Rights Reserved.",
          per: "© کلیه حقوق متعلق به شرکت محرکه گستر آرشید است."
        }
      },
      credentials: {
        username: "",
        password: "",
        captchaInput: "",
        sessionId: "",
        title: "megashid"
      },
      captchaImageUrl: "",
      loading: false,
      successful: true,
      submitted: true,
      run1: false,
      run2: false,
      canSignUp: false,
      activeTab: "megashid",
      loginTypeTab: [
        { name: "megashid", value: "megashid", title: "megashid" }
      ]
    };
  },
  methods: {
    chooseType(tab, title) {
      this.activeTab = tab;
      this.credentials.title = title;
    },
    handleLogin() {
      this.submitted = true;
      this.successful = true;
      this.loading = true;
      this.credentials.title = this.$store.state.data.haveSignUp.value.authenticationMethods.length > 0 ? this.$store.state.data.haveSignUp.value.authenticationMethods[0].title : "megashid";
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        } else {
          this.$store.dispatch("auth/login", this.credentials).then(
            (resp) => {
              this.submitted = false;
              this.initiateData();

            },
            (error) => {
              this.loading = false;
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              if (this.message === "err3") {
                // alert(this.dict[this.message][this.lang]);
              } else if (this.message === "err1" || this.message === "err2") {
                this.submitted = false;
                this.successful = false;
                (this.credentials = {
                  username: "",
                  password: "",
                  captchaInput: "",
                  sessionId: "",
                  title: this.$store.state.data.haveSignUp.value.authenticationMethods.length > 0 ? this.$store.state.data.haveSignUp.value.authenticationMethods[0].title : "megashid"
                }),
                  this.refreshCaptcha();
              } else {
                // alert(this.message);
              }
            }
          );
        }
      });
    },
    openSSo() {
      let id = v4();
      this.$store.dispatch("data/getSSOPage", { id: id }).then((res) => {
        window.open(res, "_blank");
        this.$store.dispatch("panel/sseSubscriptionّFava", id)
      });
    },
    refreshCaptcha() {
      this.$store.dispatch("auth/captcha").then(
        (response) => {
          this.captchaImageUrl = response.data.imageUrl;
          this.credentials.sessionId = response.data.sessionId;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    showRegisterForm() {
      this.$store.dispatch("chpge/changePage", "signup");
    },
    loadImage() {
      const initialImageUrl = bgImage;
    },
    setImage(imageUrl) {
      const imageContainer = this.$refs.imageContainer;
      imageContainer.style.backgroundImage = `url(${imageUrl})`;
      imageContainer.style.backgroundSize = "cover";
      imageContainer.style.backgroundPosition = "center";
    },
    changeImage(event) {
      const selectedImage = event.target.files[0];

      if (selectedImage) {
        const imageUrl = URL.createObjectURL(selectedImage);
      }
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("data/removeUserData");
      this.$store.dispatch("chpge/changePage", "signin");
    },
    async getWBdata() {
      await this.$store.dispatch("data/getWBdata").then(
        (response) => {
          this.run2 = true;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    async checkLicenceValidation() {
      await this.$store.dispatch("data/checkValidateLicense").then((res) => {
        if (res.message == false)
          this.$root.$emit("bv::show::modal", "modal-upload-licence");
      });
      await this.$store.dispatch("data/getUsedSysLicences");
      await this.$store.dispatch("data/getAllSysTakhsis");
      await this.$store.dispatch("data/getMainSysLicences");
      await this.$store.dispatch("data/getUsedSysLicences");
    },
    async getUserData() {
      await this.$store.dispatch("data/getUserData").then(
        (response) => {
          this.run1 = true;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    async initiateData() {
      await this.getWBdata();
      await this.getUserData();

      if (this.run1 && this.run2)
        this.$store.dispatch("chpge/changePage", "lighting");

      await this.checkLicenceValidation();

    }
  },
  computed: {
    newUser() {
      return this.$store.state.panel.newUser;
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  async created() {
    if (this.loggedIn) {
      this.initiateData();
    } else {
      this.refreshCaptcha();
      setTimeout(() => {
        this.$store.dispatch("data/getSignupSetting");
      }, 100);
    }
  },
  mounted() {
    setTimeout(() => {
      this.$store.dispatch("data/getSignupSetting");
    }, 1000);
  },
  watch: {
    newUser(val) {
      console.log(val.response);
      if (val.response!=undefined) {
        this.$store.commit("auth/loginSuccess", val.response.out);
        this.initiateData();
      } else console.log(val);

    }
  }
};
</script>

<style>
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: transform 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(-100%);
}

.cascading-right {
  margin-right: 75%;
  margin-left: -75%;
}

@media (max-width: 991.98px) {
  .cascading-right {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.cascading-left {
  margin-right: -75%;
  margin-left: 75%;
}

@media (max-width: 991.98px) {
  .cascading-left {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.left-panel {
  width: 50vw;
  height: 100vh;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.right-panel {
  width: 50vw;
  height: 100vh;
  background-image: url("../assets/images/login-bg-1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.container-panel {
  width: 100vw;
  height: 100vh;
  display: flex;
}

.center-panel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  height: auto;
  margin: 0 21px 17px 0;
  padding: 36px 32px 37px 31px;
  border-radius: 4px;
  -webkit-backdrop-filter: blur(17px);
  backdrop-filter: blur(17px);
  box-shadow: 0 35px 50px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}

.panel-header {
  padding: 10px;
}

.right-panel-content {
  padding: 10px;
  width: 100%;
}

.right-panel-footer {
  padding: 10px;
  color: #8d8d8d;
  width: 100%;
}

.logo {
  position: absolute;
  right: 28px;
  top: 28px;
}

.suggestion {
  margin-bottom: 50px;
  padding-right: 20px;
  font-size: 0.75em;
  text-wrap: normal;
}

.media-ball {
  width: 65px;
  height: 65px;
  padding: 15px;
  display: flex;
  border-radius: 50%;
  background-color: #9da3aa;
  margin: 0 10px 0 10px;
}

.media {
  width: 340px;
  height: 65px;
  right: 0;
  display: flex;

  margin-bottom: 10px;
}

.copyright-text {
  text-align: end;
  padding: 0 30px 0 30px;
  font-size: small;
  font-family: sans-serif;
}

@media (max-width: 768px) {
  .right-panel {
    display: none;
  }

  .left-panel {
    width: 100vw;
    background-color: #eee;
  }

  .center-panel {
    top: 10%;
    transform: translate(-50%);
  }

  .right-panel-content {
    display: none;
  }

  .panel-header {
    width: 100%;
    height: 10%;

    //display: flex;
    justify-content: center;
    align-items: center;
  }

  .media {
    width: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    justify-content: space-evenly;
  }

  .copyright-text {
    text-align: center;
    padding-left: 0;
  }

  .right-panel-footer {
    background-color: #16457d;
    color: #ffffff;
    width: 100%;
  }
}

@media (max-width: 410px) {
  .center-panel {
    width: 90%;
  }
}

div.list {
  counter-reset: list-number;
}

div.list div {
  max-width: calc(100% - 210px) !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  display: flex;
  margin-bottom: 10px;
}

div.list div:before {
  counter-increment: list-number;
  content: "";
  margin-right: 8px;
  margin-left: 8px;

  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-color: #16457d;
  border-radius: 50%;
  color: #fff;
  box-shadow: 0 35px 50px 0 rgba(0, 0, 0, 0.16);
}

.partially-span {
  font-weight: bold;
}

.megashid-src {
  content: url("../assets/images/megashidLogo.png");
  width: 100%;
  height: 100%;
}

.aparat-src {
  content: url("../assets/images/لوگوی_شرکت_فولاد_هرمزگان.svg.png");
  width: 100%;
  height: 100%;
}

.irisa-src {
  content: url("../assets/images/irisa-logo-transparent.png");
  width: 100%;
  height: 100%;
}
</style>
