// const host = window.location.protocol + "//" + "192.168.2.22" + ":1882";
// const host = window.location.protocol + "//" + "192.168.2.97" + ":1882";
// const host = window.location.protocol + "//"  + window.location.hostname + ":1882";
const host = window.location.protocol + "//"  + window.location.hostname;

const winsize = { height: window.innerHeight, width: window.innerWidth };


// const host = 'http://192.168.1.129';
// const host = window.location.protocol + "//" + window.location.hostname
module.exports ={ host, winsize };