<template>
  <div>
    <b-modal
      id="modal-get-mapping"
      size="lg"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      hide-header-close
      no-close-on-backdrop
      content-class="shadow"
      @shown="initGetmap"
      @hidden="hideInitMap"
      :dir="dict.dir[lang]"
      :cancel-title="dict.cancel[lang]"
      :ok-title="dict.ok[lang]"
      @cancel="hideInitMap"
      @ok="handleGetMapOk"
    >
      <template #modal-header>
        <div :dir="dict.dir[lang]">
          <h5 v-if="pageMode=='New'">{{ dict.titleTranslateAdd[lang] }}</h5>
          <h5 v-if="pageMode=='Edit'">{{ dict.titleTranslateEdit[lang] }}</h5>
        </div>
      </template>
      <div :dir="dict.dir[lang]" id="form_types">
        <div class="row">
          <div class="col-12 col-md-6 mt-3">
            <ejs-textbox
              v-model="data.key"
              :autocomplete="'off'"
              :title="dict.keyTitle[lang]"
              :placeholder="dict.keyPH[lang]"
              :enableRtl="lang === 'per'"
              :required=" true"
              :maxLength="100"

            ></ejs-textbox>
          </div>
          <div class="col-12 col-md-6 mt-3">
            <ejs-dropdownlist
              :title="dict.typeTitle[lang]"
              :dataSource="typeList"
              :autocomplete="'off'"
              :value="data.type"
              :fields="{ text: lang, value: 'value' }"
              :placeholder="dict.typePH[lang]"
              :enableRtl="lang === 'per'"
              :required="true"
              v-model="data.type"
            ></ejs-dropdownlist>
          </div>
        </div>
        <div class="row">
          <div
            class="col-12 col-md-6 mt-3"
            v-if="data.type != 'arrayOfObject' && data.type == 'array'"
          >
            <ejs-textbox
              v-model="data.index"
              :autocomplete="'off'"
              :placeholder="dict.indexPH[lang]"
              :enableRtl="lang === 'per'"
              :required=" true"
              type="number"
            ></ejs-textbox>
          </div>
          <div
            class="col col-md-6 mt-3"
            v-if="
              data.type != 'arrayOfObject' &&
              data.type != null &&
              data.type != 'none'
            "
          >
            <div class="d-flex align-items-center">
              <ejs-textbox
                :value="data.data.name"
                :placeholder="dict.pickData[lang]"
                :enableRtl="lang === 'per'"
                floatLabelType="Auto"
                :showClearButton="true"
                v-model="data.data.name"
              ></ejs-textbox>
              <b-button @click="pickData" class="btn-sm" variant="primary"><span class="e-icons e-edit-6 "></span>
              </b-button>

            </div>
            <!--            <b-input-group style="flex-wrap: inherit">-->
            <!--              <b-form-input-->
            <!--                disabled-->
            <!--                name="data"-->
            <!--                type="text"-->
            <!--                placeholder="Select a data"-->
            <!--                v-model="data.data.name"-->
            <!--                :state="submitted ? !errors.has('data') : null"-->
            <!--                v-validate="'required|min:1|max:200'"-->
            <!--                style="width: 100%"-->
            <!--              ></b-form-input>-->

            <!--              <b-input-group-append>-->
            <!--                <b-button @click="pickData">Select</b-button>-->
            <!--              </b-input-group-append>-->
            <!--            </b-input-group>-->
          </div>
        </div>

        <div class="bg-white mt-5" v-if="data.type == 'arrayOfObject'">
          <div class="mga-tab h-100 p-0">
            <div class="mga-tab-header">
              <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">

                <li v-for="(item,index) in tabs" class="mga-tab-nav-item" @click="tabIndex = item.name"
                    :key="index">
                  <a class="mga-tab-nav-link"
                     :class="{ active: tabIndex === item.name }"
                     :id="item.name"
                     data-toggle="tab"
                     role="tab"
                     aria-controls="tab1"
                     aria-selected="true"
                  >
                    {{ dict[item.name][lang] }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div v-show="tabIndex=='identifiers'">
            <identifierstable :is-http="isHttp" :page-type="currentTab" />
          </div>

          <div v-show="tabIndex=='values'">
            <set-value-table :page-type="pageTypes" />
          </div>
        </div>
      </div>
    </b-modal>
    <dataPickerModal></dataPickerModal>
  </div>
</template>

<script>
import dataPickerModal from "@/components/setContModalDataPicker.vue";
import { v4 as uuidv4 } from "uuid";
import Identifierstable from "@/components/getMapsComponents/identifierstable.vue";
import SetValueTable from "@/components/getMapsComponents/setValueTable.vue";
import { FormValidator } from "@syncfusion/ej2-inputs";

export default {
  name: "setGetMappingJson",
  props: { currentTab: { default: "" }, isHttp: { default: false } },
  components: { SetValueTable, Identifierstable, dataPickerModal },
  data() {
    return {
      data: {
        _id: "",
        key: "",
        index: "",
        type: null,
        data: {
          _id: "",
          name: "",
          type: "",
          dataType: ""
        },
        identifiers: [],
        datas: []
      },
      typeList: [
        { per: "یک مورد انتخاب کنید", eng: "Select One", value: null },
        { per: "هیچکدام", eng: "none", value: "none" },
        { per: "آرایه", eng: "array", value: "array" },
        { per: "ویژگی", eng: "property", value: "property" },
        { per: "آرایه ای از شی ها", eng: "array of object", value: "arrayOfObject" }
      ],
      tabIndex: "identifiers",
      tabs: [
        { name: "identifiers" },
        { name: "values" }
      ],
      mainTitle: "",
      pageTypes: "",
      to: "",
      pageMode: "",
      idenMode: "",
      setValMode: "",
      keyList: [],
      reRen: false,
      submitted: false,
      successful: false,
      validator: null,
      dict: {
        dir: { eng: "ltr", per: "rtl" },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        identifiers: { eng: "identifiers", per: "متغیرها" },
        values: { eng: "values", per: "مقادیر" },
        titleTranslateAdd: { eng: "New Item", per: "افزودن آیتم" },
        titleTranslateEdit: { eng: "Edit Item", per: "ویرایش آیتم" },
        transition: { eng: "slide-fade-ltr", per: "slide-fade-rtl" },
        transition2: { eng: "slide2-fade-ltr", per: "slide2-fade-rtl" },
        keyPH: { eng: "Enter the key", per: "key را وارد کنید" },
        keyTitle: { eng: "key", per: "نام متغیر" },
        indexPH: { eng: "Enter index", per: "index را وارد کنید" },
        pickData: { per: "انتخاب داده", eng: "Pick Data" },
        typeTitle: { eng: "type", per: "نوع متغیر" },
        typePH: { eng: "Enter type", per: "نوع متغیر خودرا انتخاب کنید" }
      }
    };
  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    },
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    updatePage() {
      return this.$store.state.chpge.reload;
    }
  },
  watch: {
    child2parent(newValue, oldValue) {
      if (newValue.task === "pickData" && newValue.to === "getPostMap") {
        if (this.data.type !== "arrayOfObject") {
          this.data.data = newValue.item;
        }
      }

      if (
        newValue.task == "setDataMapping" &&
        newValue.type == "Edit" &&
        newValue.from == "setvalue"
      ) {
        this.data.data = newValue.item;
      }

      if (
        newValue.task == "setDataMapping" &&
        newValue.type == "Edit" &&
        newValue.from == "setIdentifier"
      ) {
        this.data.identifiers = newValue.item.filter(
          (item) => item.key != "modify"
        );
      }
    },
    parent2child(newValue, oldValue) {

      if (newValue.task === "editGetMappingMain") {
        this.pageTypes = "Get";
        this.pageMode = newValue.type;
        this.mainTitle = newValue.type + " Item";
        this.to = newValue.from;

        if (newValue.type == "Edit") {
          this.data = newValue.item;
        }
      } else if (newValue.task === "editPostMappingMain") {
        this.pageTypes = "Post";
        this.pageMode = newValue.type;
        this.mainTitle = newValue.type + " Item";
        this.to = newValue.from;

        if (newValue.type == "Edit") {
          this.data = newValue.item;
        }
      }
    }
  },
  methods: {
    pickData() {
      let filterParents = [];
      this.$store.dispatch("chpge/parent2child", {
        task: "pickData",
        from: "getPostMap",
        filter: {
          dataType: ["!complex"],
          usedBy: [],
          parents: filterParents,
          archive: null,
          withChild: false,
          page: 1,
          perPage: 5000
        }
      });
      this.$root.$emit("bv::show::modal", "modal-pick-data");
    },
    initGetmap() {
      this.reRen = true;
      if (this.pageMode == "New") {
        this.idenMode = "";
        this.setValMode = "";
        this.data = {
          _id: "",
          key: "",
          index: "",
          type: null,
          data: {
            _id: "",
            name: "",
            type: "",
            dataType: ""
          },
          identifiers: [],
          datas: []
        };
      }


    },
    hideInitMap() {
      this.reRen = false;
      this.pageTypes = "";
      this.validator = null;
      this.tabIndex = "identifiers";
      this.pageMode = "";
      this.idenMode = "";
      this.setValMode = "";
      this.submitted = false;
      this.successful = false;
      this.data = {
        _id: "",
        key: "",
        index: "",
        type: null,
        data: {
          _id: "",
          name: "",
          type: "",
          dataType: ""
        },
        identifiers: [],
        datas: []
      };

      this.$nextTick(() => {
        this.$bvModal.hide("modal-get-mapping");
      });
    },
    handleSubmit() {
      const isValid = new FormValidator("#form_types").validate();
      this.message = "";
      this.submitted = true;
      if (isValid) {

        if (this.pageMode === "New") {
          this.data._id = uuidv4();
        }

        this.$store.dispatch("chpge/child2parent", {
          task: "editMapJson/identify",
          to: this.to,
          type: this.pageMode,
          item: JSON.parse(JSON.stringify(this.data))
        });
        this.$nextTick(() => {
          this.$bvModal.hide("modal-get-mapping");
        });
      }
    },
    handleGetMapOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    }
  }
};
</script>
