<template>
  <div class="row mx-auto">
    <div class="col-md-6 col-12 my-1" v-for="(item,index) in currentForm['details']['elements']" :key="index">
      {{item}}
      <component
        v-if="item.field.component != 'ejs-slider'"
        :enableRtl="lang == 'per'"
        :dataSource="item.field.options"
        floatLabelType="Auto"
        :field="{ text: lang, value: 'value' }"
        :placeholder="item.field.attributes.placeHolder.value"
        :label="item.field.attributes.placeHolder.value"
        :rows="item.field.component == 'ejs-textarea'? item.field.attributes.rows.value: null"
        :type="item.field.type != null ? item.field.type : ''"
        :is="item.field.component"
      ></component>
      <component
        v-if="item.field.component == 'ejs-slider'"
        :enableRtl="lang == 'per'"
        :dataSource="item.field.options"
        floatLabelType="Auto"
        :field="{ text: lang, value: 'value' }"
        :placeholder="item.field.attributes.placeHolder.value"
        :label="item.field.attributes.placeHolder.value"
        :type="item.field.type != null ? item.field.type : ''"
        :ticks="{
                    placement: 'After',
                    largeStep: item.field.attributes.largeStep.value,
                    smallStep: item.field.attributes.smallStep.value,
                    showSmallTicks: item.field.attributes.showSmallTicks.value,
                  }"
        :is="item.field.component"
      ></component>
      <span
        v-if="item.field.component == 'ejs-switch'"
        class="mx-2"
      >{{ item.field.attributes.placeHolder.value }}</span
      >
    </div>
  </div>
</template>
<script>

import { DropDownButtonComponent } from "@syncfusion/ej2-vue-splitbuttons";
import { NumericTextBoxComponent } from "@syncfusion/ej2-vue-inputs";
import {
  DashboardLayoutComponent,
  PanelDirective,
  PanelsDirective
} from "@syncfusion/ej2-vue-layouts";
import widgetPieChart from "@/components/widgetPieChart.vue";
import CustomPath from "@/components/widgetPieChart.vue";
import {
  AccordionComponent,
  AccordionItemDirective,
  AccordionItemsDirective
} from "@syncfusion/ej2-vue-navigations";

export default {
  name: "formWidget",
  components: {
    "ejs-dropdownbutton": DropDownButtonComponent,
    "ejs-numerictextbox": NumericTextBoxComponent,
    "ejs-dashboardlayout": DashboardLayoutComponent,
    "ejs-accordion": AccordionComponent,
    "e-accordionitem": AccordionItemDirective,
    "e-accordionitems": AccordionItemsDirective,
    "e-panel": PanelDirective,
    "e-panels": PanelsDirective
  },
  data() {
    return {
      drpItems: [
        {
          text: "colorInput",
          value: "ejs-textbox",
          icon: "e-icons e-rename-3 mx-auto",
          details: {
            eng: "input field to enter only color",
            per: "کادر انتخاب رنگ"
          }
        },
        {
          text: "textInput",
          value: "ejs-textbox",
          icon: "e-icons e-rename-3 mx-auto",
          details: { eng: "input single line", per: "کادر متن تک خطی" }
        },
        {
          text: "numberInput",
          value: "ejs-textbox",
          icon: "e-icons e-rename-3 mx-auto",
          details: {
            eng: "input field to enter only number",
            per: "کادر وارد کردن اعداد"
          }
        },
        {
          text: "selectInput",
          value: "ejs-dropdownlist",
          icon: "e-icons e-rename-3 mx-auto",
          details: {
            eng: "input field to choose single value",
            per: "کادر انتخاب تک مقداری"
          }
        },
        {
          text: "multiselectInput",
          value: "ejs-textbox",
          icon: "e-icons e-rename-3 mx-auto",
          details: {
            eng: "input field to choose multi values",
            per: "کادر انتخاب چند مقداری"
          }
        },
        {
          text: "textArea",
          value: "ejs-textarea",
          icon: "e-icons e-rename-3 mx-auto",
          details: {
            eng: "input for long texts",
            per: "کادر وارد کردن متن های طولانی"
          }
        },
        {
          text: "switchInput",
          value: "ejs-switch",
          icon: "e-icons e-rename-3 mx-auto",
          details: { eng: "input to change status", per: "کادر تغییر وضعیت" }
        },
        {
          text: "radioButton",
          value: "ejs-radiobutton",
          icon: "e-icons e-rename-3 mx-auto",
          details: {
            eng: "input to choose value",
            per: "کادر دایره ای انتخاب مقدار"
          }
        },
        {
          text: "checkBox",
          value: "ejs-checkbox",
          icon: "e-icons e-rename-3 mx-auto",
          details: {
            eng: "input to choose value",
            per: "کادر مربعی انتخاب مقدار"
          }
        },
        {
          text: "rangeSlider",
          value: "ejs-slider",
          icon: "e-icons e-rename-3 mx-auto",
          details: {
            eng: "slider to choose range values",
            per: "اسلایدر انتخاب بازه"
          }
        }
      ],
      currentForm: {}
    };
  },
  methods: {
    getClass(id) {
      this.$store.dispatch("data/getEdgeById", { id: [id] }).then(res => {
        console.log(res);
      });
    },
  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    },
    child2child() {
      return this.$store.state.chpge.child2child;
    }
  },
  watch: {
    child2child(val) {
      if (val.task == "pick_form") {
        this.currentForm = val.item;
       this.getClass(val.assignedData.id)
      }
    }
  }
};
</script>
<style scoped>

</style>