<template>
  <div
    :dir="dict.dir[lang]"
    class="container-fluid p-0 bg-none"
    :class="lang === 'per' ? 'text-end' : 'text-start'"
    style="min-height: calc(100vh - 60px)"
  >
    <div class="p-3 m-0 bg-page-title d-flex">
      <div
        :class="
          dict.dir[lang] === 'rtl'
            ? 'page-title-icon-rtl'
            : 'page-title-icon-ltr'
        "
      >
        <img
          src="@/assets/icons/maps_manager-1.png"
          alt="dashboard"
          style="width: 60px"
        />
      </div>

      <div class="d-flex align-items-center">
        <h5 class="mb-0">{{ dict.title[lang] }}</h5>
      </div>
    </div>

    <div
      class="row g-0 p-2"
      :class="lang === 'per' ? 'text-end' : 'text-start'"
      style="min-height: calc(100vh - 152px)"
    >
      <div class="col-lg-12 h-100">
        <div
          class="card h-100"
          style="
            background-color: rgba(255, 255, 255, 0.75) !important;
            min-height: calc(100vh - 168px);
          "
        >
          <div class="p-3">
            <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
              <li
                v-for="(item, index) in tabs"
                class="mga-tab-nav-item"
                @click="activeTab = item.value"
                :key="index"
              >
                <a
                  class="mga-tab-nav-link"
                  :class="{ active: activeTab === item.value }"
                  :id="item.name"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="tab1"
                  aria-selected="true"
                >
                  {{ dict[item.value][lang] }}
                </a>
              </li>
            </ul>
          </div>

          <div class="card-body h-100" style="overflow: hidden">
            <transition name="slide-fade" mode="out-in">
              <b-container v-if="activeTab == 'tab1'" fluid class="p-0">
                <ejs-grid
                  ref="map_indoor1"
                  :key="curTableOptions.items.length"
                  :width="'auto'"
                  :locale="lang"
                  :allowSorting="true"
                  :enableAdaptiveUI="true"
                  :allowExcelExport="true"
                  :allowFiltering="true"
                  :editSettings="curTableOptions.editSettings"
                  :allowPdfExport="true"
                  :toolbar="toolabrGenerator"
                  :pdfExportComplete="pdfExportComplete"
                  :toolbarClick="MaptoolbarClick"
                  :actionBegin="mapActionBegin"
                  :allowPaging="true"
                  :enableRtl="lang == 'per'"
                  :pageSettings="{ pageSize: 5,pageSizes: [5, 10, 20, 50] }"
                  :selectionSettings="curTableOptions.selectionOptions"
                  :dataSource="curTableOptions.items"
                >
                  <e-columns>
                    <e-column width="50" type="checkbox"></e-column>
                    <e-column
                      v-for="(item, index) in curTableOptions.fields"
                      :key="index"
                      :width="150"
                      :field="item.key"
                      :headerText="item[lang]"
                      :template="item.hasTemplate ? item.key : null"
                    ></e-column>
                  </e-columns>
                  <template v-slot:type="{ data }">
                    {{ mapTypeTranslates[data.type][lang] }}
                  </template>
                  <template v-slot:modify="{ data }">
                    <div class="d-flex justify-content-between col-md-3">
                      <span
                        v-permission="'map_edit'"
                        class="e-icons e-edit-6"
                        @click="editIndoorMap(data)"
                        style="font-size: 17px"
                      ></span>
                      <span
                        v-permission="'map_remove'"
                        class="e-icons e-delete-1 text-danger"
                        @click="removeIndoorMap(data)"
                        style="font-size: 17px"
                      ></span>
                    </div>
                  </template>
                </ejs-grid>
              </b-container>
            </transition>

            <transition name="slide-fade" mode="out-in">
              <b-container v-if="activeTab == 'tab2'" fluid class="p-0">
                <ejs-grid
                  ref="map_indoor2"
                  :key="arcTableOptions.items.length"
                  :width="'auto'"
                  :allowSorting="true"
                  :locale="lang"
                  :allowExcelExport="true"
                  :allowFiltering="true"
                  :enableAdaptiveUI="true"
                  :editSettings="arcTableOptions.editSettings"
                  :allowPdfExport="true"
                  :toolbar="toolabrGenerator"
                  :pdfExportComplete="pdfExportComplete"
                  :toolbarClick="MaptoolbarClick"
                  :actionBegin="mapActionBegin"
                  :allowPaging="true"
                  :enableRtl="lang == 'per'"
                  :pageSettings="{ pageSize: 5,pageSizes: [5, 10, 20, 50] }"
                  :selectionSettings="curTableOptions.selectionOptions"
                  :dataSource="arcTableOptions.items"
                >
                  <e-columns>
                    <e-column width="50" type="checkbox"></e-column>
                    <e-column
                      v-for="(item, index) in arcTableOptions.fields"
                      :key="index"
                      :field="item.key"
                      :width="150"
                      :headerText="item[lang]"
                      :template="item.hasTemplate ? item.key : null"
                    ></e-column>
                  </e-columns>
                  <template v-slot:type="{ data }">
                    {{ mapTypeTranslates[data.type][lang] }}
                  </template>
                  <template v-slot:modify="{ data }">
                    <div class="d-flex justify-content-between col-md-3">
                      <span
                        v-permission="'map_edit'"
                        class="e-icons e-edit-6"
                        @click="editMarker(data)"
                        style="font-size: 17px"
                      ></span>
                      <span
                        v-permission="'map_remove'"
                        class="e-icons e-delete-1 text-danger"
                        @click="removeIndoorMap(data)"
                        style="font-size: 17px"
                      ></span>
                    </div>
                  </template>
                </ejs-grid>
              </b-container>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <indoorMapModal />
    <map-manager />
    <b-modal
      id="modal-upload-map"
      size="md"
      centered
      hide-header-close
      no-close-on-backdrop
      header-border-variant="primary"
      header-class="my-modal-header "
      @hidden="resetModal"
      :title="dict.uploadIndoorMap[lang]"
      :dir="dict.dir[lang]"
    >
      <template #modal-footer>
        <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-end">
          <div class="d-flex" style="gap: 10px">
            <b-button variant="secondary" @click="resetModal"
            >{{ dict.cancel[lang] }}
            </b-button>
            <b-button variant="primary" @click="submitFile"
            >{{ dict.ok[lang] }}
            </b-button>
          </div>
        </div>
      </template>

      <div id="validMe" :dir="dict.dir[lang]" class="form-group mb-2">
        <div class="mb-3">
          <ejs-textbox
            :autocomplete="'off'"
            :enableRtl="lang == 'per'"
            :placeholder="dict.enterMapName[lang]"
            :required="true"
            floatLabelType="Auto"
            v-model="indoorMap.name"
          ></ejs-textbox>
        </div>
        <label class="form-label" for="indoorMap">{{
            dict.indoorMap[lang]
          }}</label>
        <div class="d-flex justify-content-between align-items-center">
          <ejs-uploader
            ref="indoorMap"
            id="indoorMap"
            :enableRtl="lang == 'per'"
            @selected="uploadFile"
            :multiple="false"
            allowedExtensions=".osm"
          ></ejs-uploader>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-new-marker"
      size="md"
      centered
      hide-header-close
      no-close-on-backdrop
      header-border-variant="primary"
      header-class="my-modal-header "
      @hidden="resetMarkerModal"
      @cancel="resetMarkerModal"
      :title="dict.newMarker[lang]"
      :dir="dict.dir[lang]"
    >
      <template #modal-footer>
        <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-end">
          <div class="d-flex" style="gap: 10px">
            <b-button variant="secondary" @click="resetMarkerModal"
            >{{ dict.cancel[lang] }}
            </b-button>
            <b-button variant="primary" @click="createLayer"
            >{{ dict.ok[lang] }}
            </b-button>
          </div>
        </div>
      </template>

      <div
        id="validate_marker_name"
        :dir="dict.dir[lang]"
        class="form-group mb-2"
      >
        <div class="mb-3">
          <ejs-textbox
            :autocomplete="'off'"
            :enableRtl="lang == 'per'"
            :placeholder="dict.enterMarkerName[lang]"
            :required="true"
            floatLabelType="Auto"
            v-model="marker.name"
          ></ejs-textbox>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import indoorMapModal from "./mapManagerIndoorModal.vue";
import {
  Page,
  Sort,
  Toolbar,
  PdfExport,
  ExcelExport,
  Edit,
  Search
} from "@syncfusion/ej2-vue-grids";
import { FormValidator } from "@syncfusion/ej2-inputs";
import MapManager from "@/components/mapManager.vue";
import { DialogUtility } from "@syncfusion/ej2-popups";

export default {
  components: {
    MapManager,
    indoorMapModal
  },
  data() {
    return {
      activeTab: "tab1",
      tabs: [
        { name: "tab1", value: "tab1" },
        { name: "tab2", value: "tab2" }
      ],
      curTableOptions: {
        items: [],
        selectionOptions: {
          type: "Multiple",
          persistSelection: false,
          checkboxOnly: true
        },
        editSettings: {
          allowEditing: false,
          allowAdding: true,
          allowDeleting: true,
          mode: "Dialog"
        },
        fields: [
          {
            key: "title",
            eng: "name",
            per: "نام",
            hasTemplate: false
          },
          {
            key: "type",
            eng: "type",
            per: "نوع",
            hasTemplate: true
          },
          { key: "modify", eng: "", per: "", hasTemplate: true }
        ]
      },
      arcTableOptions: {
        items: [],
        fields: [
          {
            key: "title", eng: "name",
            per: "نام", hasTemplate: false
          },
          {
            key: "type", eng: "type",
            per: "نوع", hasTemplate: true
          },
          { key: "modify", eng: "", per: "", hasTemplate: true }
        ],
        selectionOptions: {
          type: "Multiple",
          persistSelection: false,
          checkboxOnly: true
        },
        editSettings: {
          allowEditing: false,
          allowAdding: true,
          allowDeleting: true,
          mode: "Dialog"
        }
      },
      indoorMap: {
        name: "",
        file: null
      },
      marker: {
        name: ""
      },
      buildingStruct: [],
      successful: true,
      submitted: false,
      dict: {
        refresh: { eng: "refresh datas", per: "به روزرسانی اطلاعات" },
        confirmTitle: { eng: "close confirmation", per: "تایید بستن" },
        confirmRemoveTitle: { eng: "remove confirmation", per: "تایید حذف" },
        confirmText: {
          eng: "Are you sure to remove this record?",
          per: "آیا از حذف این رکورد اطمینان دارید؟"
        },
        dir: { eng: "ltr", per: "rtl" },
        title: { eng: "map manager", per: "مدیریت نقشه ها" },

        tab1: { eng: "indoor maps", per: "نقشه های داخلی" },
        tab2: { eng: "markers", per: "نشان گرها" },

        uploadIndoorMap: {
          eng: "upload indoor map",
          per: "بارگذاری نقشه داخلی"
        },
        newMarker: {
          eng: "marker layer name",
          per: "نام لایه مارکر"
        },
        newMap: {
          eng: "New map",
          per: "نقشه جدید"
        },
        newMark: {
          eng: "New marker",
          per: "مارکر جدید"
        },
        indoorMap: { eng: "map file", per: "فایل نقشه" },
        chooseFile: { eng: "choose file", per: "انتخاب فایل" },
        noFile: { eng: "no file chosen", per: "فایلی انتخاب نشده است" },
        mapName: { eng: "map name", per: "نام نقشه" },
        enterMapName: { eng: "enter map name", per: "نام نقشه را وارد کنید" },
        enterMarkerName: { eng: "layer name", per: "نام لایه" },

        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },

        validName: { eng: "name is required!", per: "ورود نام الزامی است!" },

        tab3: { eng: "events", per: "رخدادها" },
        active: { eng: "active", per: "فعال" },
        acknowledge: { eng: "acknowledge", per: "تایید" },

        th_floorTitle: { eng: "name", per: "نام" },

        th_name: { eng: "name", per: "نام" },

        th_modify: { eng: "", per: "" },
        search: { eng: "Type to Search", per: "برای جستجو تایپ کنید" },
        clear: { eng: "clear", per: "حذف" },

        po_5: { eng: "5 rows", per: "5 ردیف" },
        po_10: { eng: "10 rows", per: "10 ردیف" },
        po_15: { eng: "15 rows", per: "15 ردیف" },
        po_100: { eng: "100 rows", per: "ردیف های بیشتر" },

        wbname: { eng: "workbench name", per: "نام میزکار" },
        wbowner: { eng: "workbench owner", per: "مالک میزکار" },
        wbresources: { eng: "workbench resources", per: "منابع میزکار" },

        regmsg: { eng: "Don't have an account?", per: "حساب کاربری ندارید؟" },
        register: { eng: "Register now", per: "ثبت نام" },
        errCaptcha: {
          eng: "invalid captcha",
          per: "کد امنیتی وارد شده اشتباه می باشد"
        },
        errUserPass1: {
          eng: "invalid username or password",
          per: "نام کاربری یا کلمه عبور اشتباه است"
        },
        errUserPass2: {
          eng: "check your keyboard language",
          per: "• وضعيت زبان(En/FA)سيستم خود را كنترل كنيد"
        },
        errUserPass3: {
          eng: "the password is caps sensitive",
          per: "• پسورد به حروف بزرگ و كوچك حساس است"
        }
      },
      mapTypeTranslates: {
        marker: { eng: "marker", per: "نشانگر" },
        indoormap: { eng: "indoormap", per: "نقشه داخلی" }
      }
    };
  },
  watch: {
    updatePage() {
      this.getIndoorMaps();
    },
    activeTab(newValue, oldValue) {
      this.getIndoorMaps();
    },
    lang(newValue) {
      this.arcTableOptions.fields.forEach((field) => {
        field.label = this.dict["th_" + field.key][newValue];
      });
      this.curTableOptions.fields.forEach((field) => {
        field.label = this.dict["th_" + field.key][newValue];
      });
      this.arcTableOptions.pageOptions.forEach((field) => {
        field.text = this.dict["po_" + field.value][newValue];
      });
      this.curTableOptions.pageOptions.forEach((field) => {
        field.text = this.dict["po_" + field.value][newValue];
      });
    },
    updateMap(newValue, oldValue) {
      this.getIndoorMaps();
    }
  },
  computed: {
    updatePage() {
      return this.$store.state.chpge.reload;
    },
    toolabrGenerator() {
      if (!this.permissionGenerator.includes("map_upload")) {
        return ["Search"];
      } else {
        if (this.activeTab == "tab1") {
          return [
            "Search",
            { text: this.dict.newMap[this.lang], id: "addNewMap", prefixIcon: "e-icons e-add" },
            { text: this.dict.refresh[this.lang], id: "refresh_table",prefixIcon: "e-icons e-refresh-2" }
          ];
        } else {
          return [
            "Search",
            {
              text: this.dict.newMark[this.lang],
              id: "addNewMarker",
              prefixIcon: "e-icons e-add"
            },
            {
              text: this.dict.refresh[this.lang],
              id: "refresh_table",
              prefixIcon: "e-icons e-refresh-2"
            }
          ];
        }
      }
    },
    workbench() {
      return this.$store.state.data.workbench.activeWorkbench;
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    updateMap() {
      return this.$store.state.panel.updateMap;
    },
    permissionGenerator() {
      return this.$store.state.data.user.permissions;
    }
  },
  mounted() {
    this.getIndoorMaps();
    this.$store.dispatch("data/sendDataForSSE", { id: [] });
  },

  methods: {
    MaptoolbarClick(args) {
      if (args.item.id.includes("pdfexport")) {
        this.$refs.map_indoor1.showSpinner();
        this.$refs.map_indoor2.pdfExport();
      } else if (args.item.id.includes("excelexport")) {
        this.$refs.map_indoor1.excelExport();
      } else if (args.item.id.includes("csvexport")) {
        this.$refs.map_indoor1.csvExport();
      } else if (args.item.id.includes("addNewMap")) {
        this.addIndoorMap();
      } else if (args.item.id.includes("addNewMarker")) {
        this.$bvModal.show("modal-new-marker");
      } else if (args.item.id.includes("refresh_table")) {
        this.getIndoorMaps();
      }
    },
    mapActionBegin(args) {
      if (args.requestType != "paging" && args.requestType != "refresh") {
        args.cancel = true;
        if (args.requestType == "add") {
          this.addIndoorMap();
        }
      }
    },
    pdfExportComplete() {
      this.$refs.map_indoor1.hideSpinner();
      this.$refs.map_indoor2.hideSpinner();
    },
    getIrDate(idate, lang) {
      const date = new Date(idate);
      if (lang === "eng") return date;
      else if (lang === "per") {
        var time = this.formatDateTime(date, {
          hour: "numeric",
          minute: "numeric",
          second: "numeric"
        });
        var year = this.formatDateTime(date, { year: "numeric" });
        var month = this.formatDateTime(date, { month: "long" });
        var wday = this.formatDateTime(date, { weekday: "long" });
        var day = this.formatDateTime(date, { day: "numeric" });
        var str = `${wday} ${day} ${month} ${year} ساعت  ${time}`;
        return str;
      }
    },
    formatDateTime(date, option) {
      return new Intl.DateTimeFormat("fa-IR", option).format(date);
    },
    getIndoorMaps() {
      this.$store.dispatch("data/getAllMaps").then(
        (data) => {
          this.curTableOptions.items = data.message.filter(
            (item) => item.type != "marker"
          );
          this.arcTableOptions.items = data.message.filter(
            (item) => item.type == "marker"
          );
          this.$refs.map_indoor2.ej2Instances.refresh();
          this.$refs.map_indoor1.ej2Instances.refresh();
          this.curTableOptions.totalRows = this.curTableOptions.items.length;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    addIndoorMap() {
      this.$bvModal.show("modal-upload-map");
    },
    editIndoorMap(map) {
      if (map.type == "indoormap") {
        this.$store.dispatch("chpge/parent2child", {
          task: "editIndoorMap",
          from: "mapManager",
          map: map
        });
        this.$root.$emit("bv::show::modal", "modal-indoor-map");
      }
    },
    removeIndoorMap(map) {
      const DialogObj = DialogUtility.confirm({
        title: this.dict.confirmRemoveTitle[this.lang],
        locale: this.lang,
        content: this.dict.confirmText[this.lang],
        rtl: true,
        okButton: {
          text: this.dict.ok[this.lang], click: () => {
            this.$store.dispatch("data/deleteMarker", { id: map._id }).then(
              (data) => {
                this.message = data.message;
                this.successful = true;
                this.getIndoorMaps();
                //this.getGroups();
              },
              (error) => {
                this.message =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
                this.successful = false;
                // alert(this.message);
              }
            );
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }
    },
    editMarker(marker) {
      this.$store.dispatch("chpge/parent2child", {
        task: "editMarker",
        from: "mapManager",
        map: marker
      });
      this.$bvModal.show("modal-new-indoor-map");
    },
    uploadFile(e) {
      this.indoorMap.file = e.filesData[0].rawFile;
    },
    submitFile() {
      const isValid = new FormValidator("#validMe").validate();
      if (isValid) {
        const formData = new FormData();
        formData.append("name", this.indoorMap.name);
        formData.append("file", this.indoorMap.file);

        this.$store.dispatch("data/uploadMap", formData).then(
          (data) => {
            this.getIndoorMaps();
            this.$nextTick(() => {
              this.$bvModal.hide("modal-upload-map");
            });
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.successful = false;
            // alert(this.message);
          }
        );
      }
    },
    createLayer() {
      const isValid = new FormValidator("#validate_marker_name").validate();
      if (isValid) {
        this.$store
          .dispatch("data/createMapLayer", {
            title: this.marker.name,
            type: "marker",
            details: {
              map: {
                fillColor: "",
                color: "",
                weight: "",
                fillOpacity: "",
                opacity: "",
                smoothFactor: "",
                minZoom: 5,
                maxZoom: 22,
                maxNativeZoom: 25,
                viewZoom: 13,
                tileId: 1,
                center: [0, 0]
              },
              markers: []
            }
          })
          .then(
            (data) => {
              this.getIndoorMaps();
              this.marker.name = "";
              this.$nextTick(() => {
                this.$bvModal.hide("modal-new-marker");
              });
            },
            (error) => {
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              this.successful = false;
              // alert(this.message);
            }
          );
      }
    },
    resetModal() {
      (this.indoorMap = {
        file: null,
        name: ""
      }),
        (this.submitted = false),
        this.$nextTick(() => {
          this.$bvModal.hide("modal-upload-map");
        });
    },
    resetMarkerModal() {
      this.$bvModal.hide("modal-new-marker");
      this.marker.name = "";
      this.submitted = false;


    }
  },
  provide: {
    grid: [Page, Sort, Toolbar, PdfExport, ExcelExport, Edit, Search]
  }
};
</script>

<style scoped src="@/assets/styles/theme.css"></style>
<style>
.table-t-notice {
  background-color: #58d68d;
}

.table-t-alarm {
  background-color: #ec7063;
}

.table-t-warning {
  background-color: #f1c40f;
}

.reset {
  color: #dc3545;
  cursor: pointer;
}

.reset :hover {
  color: #9b9b9b;
}

.my-modal-header {
  display: block !important;
}
</style>
<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: transform 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(-100%);
}
</style>
