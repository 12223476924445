<template>
  <div
    :dir="dict.dir[lang]"
    class="container-fluid p-0 bg-none"
    :class="lang === 'per' ? 'text-end' : 'text-start'"
  >
    <div
      class="row g-0 p-2"
      :class="lang === 'per' ? 'text-end' : 'text-start'"
    >
      <div class="col-lg-12 h-100">
        <div
          class=" h-100"
          style="
            background-color: rgba(255, 255, 255, 0.75) !important;
          "
        >
          <b-container fluid class="p-0">
            <ejs-grid
              ref="notifications_list"
              :width="'auto'"
              :editSettings="editSettings"
              :selectionSettings="selectionOptions"
              :excelQueryCellInfo="excelQueryCellInfo"
              :actionBegin="actionBegin"
              :toolbarClick="toolbarClick"
              :allowSorting="true"
              :locale="lang"
              :allowExcelExport="true"
              :loadingIndicator="{ indicatorType: 'Shimmer' }"
              :allowPdfExport="true"
              :toolbar='["Search","ExcelExport"]'
              :allowPaging="true"
              :enableRtl="lang == 'per'"
              :pageSettings="{ pageSize: 5, pageSizes: [5, 10, 20, 50] }"
              :dataSource="list"
            >
              <e-columns>
                <e-column width="50" type="checkbox"></e-column>
                <e-column
                  v-for="(item, index) in fields"
                  :key="index"
                  :width="screenWidth<=600?  100 :item.width"
                  :field="item.key"
                  :headerText="item[lang]"
                  :template="item.hasTemplate ? item.key : null"
                ></e-column>
              </e-columns>
              <template v-slot:source="{ data }">
                {{ dict[data.source][lang] }}
              </template>
              <template v-slot:readStatus.isRead="{ data }">
                {{ data.readStatus.isRead ? dict.seenNotif[lang] : dict.unseen[lang] }}
              </template>
              <template v-slot:readStatus.readTime="{ data }">
                {{ getIrDate(data.readStatus.readTime, lang) || "-" }}
              </template>
              <template v-slot:timestamp="{ data }">
                {{ getIrDate(data.timestamp, lang) || "-" }}
              </template>
              <template v-slot:readStatus.username="{ data }">
                {{ data.readStatus.username || "-" }}
              </template>
              <template v-slot:modify="{ data }">
                <div class="d-flex justify-content-between col-md-3">
                    <span
                      class="e-icons e-delete-1 text-danger"
                      @click="remove(data._id)"
                      style="font-size: 16px"
                    ></span>
                </div>
              </template>
            </ejs-grid>
          </b-container>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {
  Page,
  Sort,
  Toolbar,
  PdfExport,
  ExcelExport,
  Edit,
  Search
} from "@syncfusion/ej2-vue-grids";

import { v4 } from "uuid";
import { DialogUtility } from "@syncfusion/ej2-popups";
import { mapGetters } from "vuex";


export default {
  name: "notificationManager",
  props: {},
  data() {
    return {
      editSettings: {
        allowEditing: false,
        allowAdding: true,
        allowDeleting: true,
        mode: "Dialog"
      },
      selectionOptions: {
        type: "Multiple",
        persistSelection: false,
        checkboxOnly: true
      },
      tabs: [
        { text: "input fields", value: "input_fields" },
        { text: "output fields", value: "output_fields" },
        { text: "elements", value: "elements" }
      ],
      accardions: ["name", "validation", "attributes", "options"],
      activeTab: "input_fields",
      isVisible: false,
      showSetting: false,
      position: { x: 0, y: 0 }, // Initial position
      dragging: false, // Drag state
      offset: { x: 0, y: 0 },
      dict: {
        readTime: { eng: "seen time", per: "زمان مشاهده" },
        api: { eng: "system's notification", per: "اعلان سیستمی" },
        event: { eng: "event notification", per: "اعلان رویدادها" },
        valueInt: { eng: "valueInt", per: "مقدار اولیه" },
        valueDec: { eng: "valueDec", per: "مقدار کاهش" },
        colorValueText: { eng: "colorValueText", per: "رنگ متن" },
        createConfirm: {
          eng: "create class confirmation",
          per: "تاییدیه ساخت داده"
        },
        createConfirmContent: {
          eng: "Do you want to create a class for these elements ?",
          per: "آیا تمایل به ساخت داده نوع کلاس برای این فرم دارید؟"
        },
        deleteConfirm: { eng: "Confirmation Delete", per: "تاییدیه حذف" },
        errNoName: {
          eng: "set the name for every red elements",
          per: "لطفا برای المان های مشخص شده با رنگ قرمز نامی انتخاب کنید."
        },
        deleteConfirmContent: {
          eng: "Are you sure to remove the data ?",
          per: "آیا از حذف این رکورد اطمینان دارید ؟"
        },
        largeStep: { per: "مرحله بزرگ", eng: "large Step" },
        smallStep: { per: "مرحله کوچک", eng: "small Step" },
        showSmallTicks: { per: "نمایش تیک ها", eng: "showSmall Ticks" },
        rows: { per: "ردیف ها", eng: "rows" },
        options: { per: "مقادیر", eng: "options" },
        attributes: { per: "ویژگی ها", eng: "attributes" },
        name: { per: "نام المان", eng: "Element name" },
        validation: { per: "اعتبار سنجی", eng: "validation" },
        input_fields: { per: "کادرهای ورودی", eng: "input fields" },
        output_fields: { per: "کادرهای خروجی", eng: "output fields" },
        elements: { per: "المان های دیگر", eng: "elements" },
        textArea: { per: "کادر متنی", eng: "textArea" },
        switchInput: { per: "کلید تغییر حالت", eng: "switch Input" },
        radioButton: { per: "دکمه انتخاب دایره ای", eng: "radio Button" },
        checkBox: { per: "دکمه انتخاب مربعی", eng: "checkBox Button" },
        rangeSlider: { per: "اسلایدر بازه", eng: "range Slider" },
        add: { eng: "New option", per: "مقدار جدید" },
        addOptions: { eng: "Select box options", per: "لیست مقادیر" },
        selectBox: { eng: "New element", per: "المان جدید" },
        dir: { eng: "ltr", per: "rtl" },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        titleForm: { eng: "Forms manager", per: "مدیریت فرم ها" },
        formTitle: { eng: "form title", per: "عنوان فرم" },
        addData: { eng: "New form", per: "ایجاد فرم " },
        New: { eng: "New form", per: "فرم جدید" },
        EditField: { eng: "Edit field", per: "ویرایش فیلد" },
        colorInput: { eng: "input color", per: "کادر انتخاب رنگ" },
        textInput: { eng: "input text", per: "کادر متن" },
        numberInput: { eng: "input number", per: "کادر عدد" },
        selectInput: { eng: "Input select box", per: "کادر انتخاب مقدار" },
        multiselectInput: {
          eng: "Input multi select box",
          per: "کادر انتخاب چند مقداری"
        },
        required: { eng: "required", per: "مقدار آن خالی نباشد" },
        min: { eng: "min", per: "حداقل مقدار" },
        max: { eng: "max", per: "حداکثر مقدار" },
        minLength: { eng: "minLength", per: "حداقل تعداد کاراکتر" },
        maxLength: { eng: "maxLength", per: "حداکثر تعداد کاراکتر" },
        placeHolder: { eng: "placeHolder", per: "عنوان" },
        value: { eng: "value", per: "مقدار" },
        titles: { eng: "title", per: "عنوان" },
        linearGauge: { eng: "linear Gauge", per: "گیج خطی" },
        radialGauge: { eng: "radial Gauge", per: "گیج شعاعی" },
        pieChart: { eng: "pie Chart", per: "نمودار دایره ای" },
        lineChart: { eng: "line Chart", per: "نمودار خطی" },
        basic: { per: "تنظیمات پایه", eng: "basic" },
        units: { per: "تنظیمات واحدها", eng: "units" },
        ticks: { per: "تنظیمات تیک ها", eng: "ticks" },
        minValue: { eng: "minimum value", per: "مقدار کمینه" },
        maxValue: { eng: "maximum value", per: "مقدار بیشینه" },
        colorPlate: { eng: "color plate", per: "رنگ پس زمینه" },
        colorPlateEnd: { eng: "color plate end", per: "رنگ انتهای صفحه" },
        title: { eng: "title", per: "عنوان" },
        colorUnits: { eng: "colorUnits", per: "واحدهای رنگی" },
        fontUnits: { eng: "fontUnits", per: "واحدهای فونت" },
        fontUnitsSize: { eng: "fontUnitsSize", per: "سایز فونت واحد" },
        exactTicks: { eng: "exactTicks", per: "دقت تیک‌ها" },
        majorTicksInt: { eng: "majorTicksInt", per: "تعداد تیک‌های اصلی (عدد صحیح)" },
        majorTicksDec: { eng: "majorTicksDec", per: "تعداد تیک‌های اصلی (اعشار)" },
        colorMajorTicks: { eng: "colorMajorTicks", per: "رنگ تیک‌های اصلی" },
        colorMinorTicks: { eng: "colorMinorTicks", per: "رنگ تیک‌های فرعی" },
        colorStrokeTicks: { eng: "colorStrokeTicks", per: "رنگ خطوط تیک‌ها" },
        majorTicks: { eng: "major ticks", per: "تیک های اصلی" },
        minorTicks: { eng: "number of minor ticks", per: "تعداد تیک های فرعی" },
        strokeTicks: { eng: "stroke ticks", per: "نوار پشت تیک ها" },
        seenNotif: { eng: "seen", per: "مشاهده شده" },
        unseen: { eng: "unseen", per: "مشاهده نشده" }
      },
      chosenComponent: null,
      type: "New",
      pageMode: "New",
      fields: [
        { key: "source", per: "نوع اعلان", eng: " notification's type", hasTemplate: true, width: 150 },
        { key: "timestamp", per: "زمان ایجاد", eng: "received time", hasTemplate: true, width: 250 },
        { key: "readStatus.isRead", per: "وضعیت مشاهده", eng: "seen status", hasTemplate: true, width: 100 },
        { key: "readStatus.readTime", per: "زمان مشاهده", eng: "seen time", hasTemplate: true, width: 250 },
        {
          key: "readStatus.username",
          per: "مشاهده شده توسط",
          eng: "seen by",
          hasTemplate: true
        },
        { key: "modify", per: "", eng: "", hasTemplate: true }
      ],
      items: [],
      selectedComponent: {},
      list: [],
      formTitle: "",
      selectedRowId: "",
      referedID: null,
      booleanType: [
        { per: "صحیح", eng: true, value: true },
        { per: "غلط", eng: false, value: false }
      ]
    };
  },
  methods: {
    formatDateTime(date, option, lang) {
      if (lang == "per")
        return new Intl.DateTimeFormat("fa-IR", option).format(date);
      if (lang == "eng")
        return new Intl.DateTimeFormat("en-Us", option).format(date);
    },
    getIrDate(idate, lang) {
      const date = new Date(idate);
      if (lang === "eng") {
        var time1 = this.formatDateTime(
          date,
          {
            hour: "numeric",
            minute: "numeric",
            second: "numeric"
          },
          this.lang
        );
        var year1 = this.formatDateTime(date, { year: "numeric" }, this.lang);
        var month1 = this.formatDateTime(date, { month: "long" }, this.lang);
        var wday1 = this.formatDateTime(date, { weekday: "long" }, this.lang);
        var day1 = this.formatDateTime(date, { day: "numeric" }, this.lang);
        var str1 = `${wday1} ${day1} ${month1} ${year1} at  ${time1}`;
        return str1;
      } else if (lang === "per") {
        var time = this.formatDateTime(
          date,
          {
            hour: "numeric",
            minute: "numeric",
            second: "numeric"
          },
          this.lang
        );
        var year = this.formatDateTime(date, { year: "numeric" }, this.lang);
        var month = this.formatDateTime(date, { month: "long" }, this.lang);
        var wday = this.formatDateTime(date, { weekday: "long" }, this.lang);
        var day = this.formatDateTime(date, { day: "numeric" }, this.lang);
        var str = `${wday} ${day} ${month} ${year} ساعت  ${time}`;
        return str;
      }
    },
    actionBegin(args) {
    },
    toolbarClick(args) {
      if (args.item.id.includes("excelexport")) {
        this.$refs.notifications_list.excelExport();
      }
    },
    dataBounded() {
      this.$refs.notifications_list.hideSpinner();
    },
    getList() {
      this.$store.dispatch("data/getAllNotifs").then(res => {
        this.list = res;
        // this.$refs.notifications_list.ej2Instances.refresh();
      });
    },
    excelQueryCellInfo(args) {
      this.fields.forEach(item => {
        if (item.key == args.column.template) {
          if ((item.key == "timestamp" || item.key == "readStatus.readTime") && args.data[args.column.template] != undefined)
            args.value = this.getIrDate(args.data[args.column.template], this.lang);
          if ((item.key == "source") && args.data[args.column.template] != undefined)
            args.value = this.dict[args.data[args.column.template]][this.lang];
          if ((item.key == "readStatus.isRead") && args.data[args.column.template] == undefined)
            args.value = (args.data[args.column.template] == true) ? this.dict.seenNotif[this.lang] : this.dict.unseen[this.lang];
        }
      });
      // if (args.data[args.column.template] != undefined)
      //   args.value = args.data[args.column.template];
    },
    remove(id) {
      const DialogObj = DialogUtility.confirm({
        title: this.dict.deleteConfirm[this.lang],
        locale: this.lang,
        position: "center center",
        Enabletrl: this.lang == "per" ? true : false,
        content: this.dict.deleteConfirmContent[this.lang],
        okButton: {
          text: this.dict.ok[this.lang],
          click: () => {
            this.$store
              .dispatch("data/deleteNotif", { _id: id })
              .then(() => {
                this.getList();
              });
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }
    }
  },
  computed: {
    ...mapGetters("chpge", ["screenWidth", "screenHeight"]),
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  provide: {
    grid: [Page, Sort, Toolbar, PdfExport, ExcelExport, Edit, Search]
  },
  mounted() {
    this.getList();
  }
};
</script>
<style>
.resize-handle {
  position: absolute;
  background: #fff;
  z-index: 10;
  cursor: nwse-resize !important;
}

.bottom-right {
  bottom: -5px;
  right: -5px;
  cursor: nwse-resize;
}

</style>
<style scoped>
@import "../../node_modules/@syncfusion/ej2-vue-layouts/styles/material.css";
</style>
