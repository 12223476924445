<template>
  <div>
    <div :dir="dict.dir[lang]" :class="headerClasses">
      <div class="logo-src">
        <img
          src="@/assets/images/logo-persian.png"
          alt="megashid"
          height="40 %"
          style="margin-left: 15px; margin-right: 10px"
        />
      </div>
      <div class="header__content">
        <div class="header-left">
          <div>
            <div class="header-left">
              <ul
                class="header-megamenu nav"
                ng-reflect-ng-style="[object Object]"
                style="opacity: 1"
              >
                <li class="nav-item">
                  <b-dropdown
                    id="dropdown-wb"
                    ref="dropdown"
                    variant="none"
                    class="workbench-dropdown m-2"
                  >
                    <template #button-content>
                      <div v-if="workBenches.length > 0">
                        {{ activeWorkbench.label }}
                        <!-- {{workBenches.filter(x=>(x.active))[0].label}} -->
                      </div>
                      <div v-else>
                        {{ dict.withoutWB[lang] }}
                      </div>
                    </template>
                    <b-dropdown-header>
                      <div class="background-container">
                        <div class="background-content">
                          {{ dict.selectWB[lang] }}
                        </div>
                      </div>
                    </b-dropdown-header>

                    <b-dropdown-item-button
                      v-for="(item, index) in workBenches"
                      :key="index"
                      @click="
                        item.status !== 'waiting'
                          ? changeWorkbench(item.id)
                          : showWaitingInv([item])
                      "
                      style="border-radius: inherit"
                    >
                      <div
                        v-if="item.status !== 'waiting'"
                        class="dropdown-item-content"
                      >
                        <div style="width: 20%">
                          <b-avatar
                            variant="info"
                            :text="`${item.label ? item.label[0] : ''} ${
                              item.label ? item.label[0] : ''
                            }`"
                          ></b-avatar>
                        </div>

                        <div style="width: 20%">
                          <font-awesome-icon
                            v-if="item.permission === 'admin'"
                            icon="fas fa-user-tie "
                            size="lg"
                          />
                          <font-awesome-icon
                            v-else-if="item.permission === 'user'"
                            icon="fas fa-user-cog "
                            size="lg"
                          />
                          <font-awesome-icon
                            v-else-if="item.permission === 'guest'"
                            icon="fas fa-user-lock "
                            size="lg"
                          />
                        </div>
                        <div style="width: 60%">{{ item.label }}</div>
                        <!-- {{ item.status }} -->
                      </div>
                      <div v-else class="dropdown-item-content">
                        <div style="width: 20%">
                          <font-awesome-icon
                            v-if="item.permission === 'admin'"
                            icon="fas fa-user-tie "
                            size="lg"
                          />
                          <font-awesome-icon
                            v-else-if="item.permission === 'user'"
                            icon="fas fa-user-cog "
                            size="lg"
                          />
                          <font-awesome-icon
                            v-else-if="item.permission === 'guest'"
                            icon="fas fa-user-lock "
                            size="lg"
                          />
                        </div>
                        <div style="width: 50%">{{ item.label }}</div>
                        <span
                          class="badge rounded-pill bg-primary text-light"
                          style="width: 30%"
                        >{{ dict.waiting[lang] }}</span
                        >
                        <!-- {{ item.status }} -->
                      </div>
                    </b-dropdown-item-button>

                    <!--                    <b-dropdown-divider v-if="(currentUser && (currentUser.systemRole==='administrator')) || workBenches.findIndex(x=>(x.status==='creator'))===-1"></b-dropdown-divider>-->
                    <!--                    <b-dropdown-item-button v-if="(currentUser && (currentUser.systemRole==='administrator')) ||  workBenches.findIndex(x=>(x.status==='creator'))===-1"  @click="createWorkbench" style="border-radius: inherit;">-->
                    <!--                      <div class="dropdown-item-content">-->
                    <!--                        -->
                    <!--                        <div style="width:20%">-->
                    <!--                          <font-awesome-icon icon="fas fa-plus " size="lg" />-->
                    <!--                        </div>-->
                    <!--                        <div style="width:80%">{{dict.createWB[lang]}}</div>-->

                    <!--                      </div>-->
                    <!--                    </b-dropdown-item-button>-->
                  </b-dropdown>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="header-right">
          <b-dropdown
            id="dropdown-wb"
            ref="dropdown"
            style="width: fit-content"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
            class="language-dropdown m-2"
          >
            <template #button-content>
              <div class="dropdown-item-content justify-content-center">
                <!-- <b-avatar variant="info" :src="dropdownLngItems.filter(x => (x.value===lang))[0].imageUrl"></b-avatar> -->

                <b-avatar
                  :text="
                    dropdownLngItems.filter((x) => x.value === lang)[0].abrev
                  "
                  class="
                    rounded-circle
                    shadow-10
                    bg-alternate
                    avatar
                    text-white
                  "
                ></b-avatar>
              </div>
            </template>
            <b-dropdown-header>
              <div class="background-container">
                <div class="background-content" style="font-weight: lighter">
                  {{ dict.selectLang[lang] }}
                </div>
              </div>
            </b-dropdown-header>
            <b-dropdown-item-button
              v-for="(item, index) in dropdownLngItems"
              :key="index"
              @click="changeLanguage(item.value)"
              style="border-radius: inherit"
            >
              <!-- <div class="dropdown-item-content">
                <img :src="item.imageUrl" alt="Flag" height="30rem" style="margin-left: 0px; margin-right: 20px;">
                {{ item.label }}
              </div> -->

              <div class="dropdown-item-content">
                <div style="width: 50%">
                  <b-avatar
                    :text="item.abrev"
                    class="
                      rounded-circle
                      shadow-10
                      bg-alternate
                      avatar
                      text-white
                    "
                  ></b-avatar>
                  <!-- <img :src="item.imageUrl" alt="Flag" height="30rem"> -->
                </div>
                <div style="width: 50%">{{ item[lang] }}</div>
              </div>
            </b-dropdown-item-button>
          </b-dropdown>
          <b-dropdown
            size="sm"
            toggle-class="text-decoration-none d-flex align-items-center position-relative"
            style="width: fit-content; outline: unset !important"
            variant="link"
            class="m-md-2"
            no-caret
            :right="lang == 'per'"
          >
            <!-- Button Content -->
            <template #button-content>
              <div class="d-flex">
                <b-icon
                  icon="bell-fill"
                  font-scale="4"
                  :scale="lang == 'per' ? 0.65 : 0.65"
                  variant="black"
                ></b-icon>
                <!-- Notification Badge -->
                <b-badge
                  v-if="notificationCount > 0"
                  variant="success"
                  class="position-absolute"
                  :style="{
                    top: '15px',
                    right: lang == 'eng' ? '17px' : undefined,
                    left: lang == 'per' ? '17px' : undefined,
                    'font-size': '0.5rem',
                    'background-color': 'red',
                    'border-radius': '100%',
                    'font-weight': 'lighter',
                  }"
                >
                  {{ computeNotifs }}
                </b-badge>
              </div>
            </template>

            <!-- Dropdown Content -->
            <template>
              <div
                :dir="dict.dir[lang]"
                class="px-3 mb-2 pt-3 notif-container row position-relative"
              >
                <div
                  class="col-md-11 col-11 bg-white"
                >
                  <ul
                    class="
                      mga-tab-nav
                      col-10
                      mga-tab-nav-tabs
                      d-md-flex
                      bg-white
                      position-fixed
                      d-block
                    "
                  >
                    <li
                      v-for="(item, index) in ['unread', 'read']"
                      class="mga-tab-nav-item text-dark"
                      @click="activeTab = item"
                      :key="index"
                      style="font-size: 13px"
                    >
                      <a
                        class="mga-tab-nav-link fw-lighter"
                        :class="{ active: activeTab === item }"
                        :id="item"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="tab1"
                        aria-selected="true"
                      >
                        <span
                          v-if="item == 'unread'"
                          class="e-icons e-eye-slash pt-1"
                        ></span>
                        <span v-else class="e-icons e-eye pt-1"></span>
                        <span class="mx-1 pb-1">{{ dict[item][lang] }}</span>
                      </a>
                    </li>
                  </ul>
                  <div class="mt-5 mb-6 pb-6" v-if="items.length > 0"
                       :style="{'overflow-y': items.length > 3 ? 'scroll' : 'hidden',  'max-height': '23rem',}">
                    <div
                      :class="{
                        'text-end': lang == 'per',
                        'text-start': lang == 'eng',
                        'mt-3 px-2 border-bottom py-0 mb-0': true,
                      }"
                      v-for="(item, index) in notifListGenerator"
                      :key="index"
                    >
                      <div class="d-flex justify-content-between">
                        <span class="py-0" style="font-weight: bold; font-size: 13px">{{ dict[item.message["action"]][lang] }}</span>
                        <p
                          v-if="!item['readStatus'].isRead"
                          class="py-0 my-0 text-primary cursor-pointer"
                          :style="{
                            'text-align':
                              lang == 'eng'
                                ? 'right!important'
                                : 'left!important',
                            'font-size': '11px',
                          }"
                          @click="seenNotif(item._id)"
                        >
                          {{ dict.seen[lang] }}
                        </p>
                      </div>
                      <p
                        style="font-size: 13px"
                        class="text-gray mt-0 pt-1 pb-0 mb-0"
                      >
                        {{
                          lang == "per"
                            ? item.message.perMessage
                            : item.message.enMessage
                        }}
                      </p>

                      <p
                        class="py-0 mb-0 mt-2"
                        :style="{
                          'text-align':
                            lang == 'eng'
                              ? 'right!important'
                              : 'left!important',
                          'font-size': '11px',
                        }"
                      >
                        {{ getIrDate(item.timestamp, lang) }}
                      </p>
                    </div>
                  </div>
                  <div v-if="items.length<=0" class="col-md-12 mx-auto text-center">
                    <img
                      src="@/assets/images/noNotif.jpg"
                      alt=""
                      class="img-fluid mx-auto col-6 col-md-6 mt-5"
                    />
                    <p style="font-size: 13px">{{ dict.noNotif[lang] }}</p>
                  </div>
                  <!--                  <div-->
                  <!--                    class="-->
                  <!--                      shadow-lg-->
                  <!--                      fixed-bottom-->
                  <!--                      col-12-->
                  <!--                      bg-white-->
                  <!--                      cursor-pointer-->
                  <!--                    "-->
                  <!--                    style="bottom: -2rem"-->
                  <!--                    @click="openManager"-->
                  <!--                  >-->
                  <!--                    <p-->
                  <!--                      style="font-size: 13px"-->
                  <!--                      class="text-center text-primary pt-3"-->
                  <!--                    >-->
                  <!--                      {{ dict.showMore[lang] }}-->
                  <!--                    </p>-->
                  <!--                  </div>-->
                </div>
                <div @click="openSettingsModal" class="col-md-1 col-1 px-3">
                  <span
                    :title="dict.settings[lang]"
                    class="e-icons e-settings text-primary"
                  ></span>
                </div>
              </div>
            </template>
          </b-dropdown>
          <!-- profile -->

          <b-dropdown
            id="dropdown-wb"
            ref="dropdown"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
            class="profile-dropdown m-2"
          >
            <template #button-content>
              <div class="dropdown-item-content justify-content-center">
                <b-avatar
                  style="border: 1px dashed #0d6efd"
                  variant="info"
                  :src="avatarUrl"
                  :text="`${currentUser.fname ? currentUser.fname[0] : 'U'} ${
                    currentUser.lname ? currentUser.lname[0] : 'N'
                  }`"
                ></b-avatar>
              </div>
            </template>
            <b-dropdown-header>
              <div class="background-container">
                <div class="background-content">
                  <b-avatar
                    variant="info"
                    :src="avatarUrl"
                    :text="`${currentUser.fname ? currentUser.fname[0] : 'U'} ${
                      currentUser.lname ? currentUser.lname[0] : 'N'
                    }`"
                  ></b-avatar>
                  {{ `${currentUser.fname} ${currentUser.lname}` }}
                </div>
              </div>
            </b-dropdown-header>
            <b-dropdown-item-button @click="selectPage('profile')">
              <div class="dropdown-item-content">
                <div style="width: 20%">
                  <font-awesome-icon icon="fas fa-user-circle " size="xl" />
                </div>
                <div style="width: 80%">{{ dict.profile[lang] }}</div>
              </div>
            </b-dropdown-item-button>
            <b-dropdown-item-button @click.prevent="logOut">
              <div class="dropdown-item-content">
                <div style="width: 20%">
                  <font-awesome-icon icon="fas fa-sign-out " size="lg" />
                </div>
                <div style="width: 80%">{{ dict.signout[lang] }}</div>
              </div>
            </b-dropdown-item-button>
          </b-dropdown>

          <!--
                  <b-navbar-nav v-if="!currentUser" class="ml-auto">
                    <b-nav-item @click="selectPage('signin',$event)">
                      <b-icon icon="box-arrow-in-right" aria-hidden="true" style="margin-bottom: 4px;"></b-icon>
                    </b-nav-item>
                  </b-navbar-nav > -->
        </div>
      </div>
      <div class="header__mobile-menu">
        <div @click="toggleSidebar(!sidebar)" class="hamburger w-100">
          <i v-if="!sidebar">
            <font-awesome-icon icon="fas fa-bars" size="lg" />
          </i>
          <i v-else>
            <font-awesome-icon icon="fas fa-close" size="lg" />
          </i>
        </div>
      </div>
      <div class="header__menu">
        <div @click="toggleHeaderMenu" class="hamburger w-100">
          <i>
            <font-awesome-icon icon="fas fa-ellipsis-vertical" size="lg" />
          </i>
        </div>
      </div>
    </div>

    <newWorkbenchModal />
    <invitedWorkbenchesModal />
    <b-modal
      id="modal_settings_notif"
      size="lg"
      centered
      @ok="newSetting"
      scrollable
      @shown="getNotificationSetting"
      hide-header-close
      no-close-on-backdrop
      header-border-variant="primary"
      header-class="my-modal-header "
      :title="dict.settings[lang]"
      :dir="dict.dir[lang]"
      :ok-title="dict.ok[lang]"
      :cancel-title="dict.cancel[lang]"
    >
      <div class="my-3 px-3">
        <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
          <li
            v-for="(item, index) in priorityList"
            class="mga-tab-nav-item text-dark"
            @click="activePrio = item.value"
            :key="index"
            style="font-size: 13px"
          >
            <a
              class="mga-tab-nav-link fw-lighter"
              :class="{ active: activePrio === item.value }"
              :id="item.value"
              data-toggle="tab"
              role="tab"
              aria-controls="tab1"
              aria-selected="true"
            >
              <span class="mx-1 pb-1">{{ item[lang] }}</span>
            </a>
          </li>
        </ul>
        <div class="my-3 row">
          <div
            v-for="(item, index2) in settingNotifications[activePrio]"
            :key="index2"
            class="col-md-6 my-2 col-12"
          >
            <component
              :is="item.component"
              :key="index2"
              :placeholder="item[lang]"
              :enableRtl="lang === 'per'"
              floatLabelType="Auto"
              v-model="params[activePrio][item.name]"
              :min="item.min"
              :max="item.max"
              :type="item.type"
              :fields="{ text: lang, value: 'value' }"
              :dataSource="item.dataSource"
              @input="getValue($event, item.name)"
              @change="getValue($event, item.name)"
            ></component>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal_list_notif"
      size="xl"
      centered
      scrollable
      hide-header-close
      no-close-on-backdrop
      header-border-variant="primary"
      header-class="my-modal-header "
      :title="dict.listTitle[lang]"
      :dir="dict.dir[lang]"
      :ok-title="dict.ok[lang]"
      :cancel-title="dict.cancel[lang]"
    >
      <div>
        <notification-manager />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { host } from "../models/host";
import newWorkbenchModal from "./headerModalNWB.vue";
import invitedWorkbenchesModal from "./headerModalInvited.vue";
import flagUSA from "@/assets/images/flagUSA.svg";
import flagIRAN from "@/assets/images/flagIRAN.svg";
import obama from "@/assets/images/obama.jpg";
import { mapGetters } from "vuex";
import { L10n, setCulture, enableRtl } from "@syncfusion/ej2-base";
import { persianLacale } from "@/lacale/persian";
import NotificationManager from "@/components/notificationManager.vue";

export default {
  name: "header",
  components: {
    NotificationManager,
    newWorkbenchModal,
    invitedWorkbenchesModal
  },
  data() {
    return {
      params: {
        1: {
          "position": null,
          "color": null,
          "duration": null,
          "speed": null,
          "clean": null,
          "sound": null,
          "width": null,
          "height": null
        },
        2: {
          "position": null,
          "color": null,
          "duration": null,
          "speed": null,
          "clean": null,
          "sound": null,
          "width": null,
          "height": null
        },
        3: {
          "position": null,
          "color": null,
          "duration": null,
          "speed": null,
          "clean": null,
          "sound": null,
          "width": null,
          "height": null
        },
        4: {
          "position": null,
          "color": null,
          "duration": null,
          "speed": null,
          "clean": null,
          "sound": null,
          "width": null,
          "height": null
        },
        5: {
          "position": null,
          "color": null,
          "duration": null,
          "speed": null,
          "clean": null,
          "sound": null,
          "width": null,
          "height": null
        }
      },
      notifList: [],
      activePrio: 1,
      activeType: "api",
      priorityList: [
        { per: "خیلی کم", eng: "Very Low", value: 1 },
        { per: "کم", eng: "Low", value: 2 },
        {
          per: "متوسط",
          eng: "Medium",
          value: 3
        },
        { per: "زیاد", eng: "High", value: 4 },
        { per: "خیلی زیاد", eng: "Very High", value: 5 }
      ],
      notifTypes: [
        { per: "اعلانات سیستمی", eng: "system's notifications", value: "api" },
        { per: "رویدادها", eng: "events", value: "event" }
      ],
      settingNotifications: {
        1: [
          {
            name: "position",
            per: "موقعیت نمایش اعلان",
            eng: "notification position",
            component: "ejs-dropdownlist",
            type: "list",
            dataSource: [
              {
                per: "یک مورد انتخاب کنید",
                eng: "Select One",
                value: "null"
              },
              {
                per: "پایین سمت راست",
                eng: "bottom right",
                value: "{ \"X\":\"Right\", \"Y\":\"Bottom\" }"
              },
              {
                per: "پایین سمت چپ",
                eng: "bottom left",
                value: "{ \"X\":\"Left\", \"Y\":\"Bottom\" }"
              },
              {
                per: "بالا سمت راست",
                eng: "top right",
                value: "{ \"X\":\"Right\", \"Y\":\"Top\" }"
              },
              {
                per: "بالا سمت چپ",
                eng: "top left",
                value: "{ \"X\":\"Left\", \"Y\":\"Top\" }"
              },
              {
                per: "بالا وسط صفحه",
                eng: "top center",
                value: "{ \"X\":\"Center\", \"Y\":\"Top\" }"
              },
              {
                per: "پایین وسط صفحه",
                eng: "bottom center",
                value: "{ \"X\":\"Center\", \"Y\":\"Bottom\" }"
              }, {
                per: "وسط صفحه",
                eng: "center",
                value: "{ \"X\":\"Center\", \"Y\":\"Center\" }"
              }
            ],
            value: ""
          },
          // {
          //   name: "color",
          //   per: "رنگ اعلان",
          //   eng: "notification color",
          //   component: "ejs-textbox",
          //   type: "color",
          //   value: "",
          //   dataSource: null,
          // },
          {
            name: "duration",
            per: "مدت زمان نمایش اعلان (میلی ثانیه)",
            eng: "alert duration (ms)",
            component: "ejs-textbox",
            type: "number",
            value: "",
            dataSource: null
          },
          {
            name: "speed",
            per: "سرعت نمایش اعلان",
            eng: "alert speed",
            component: "ejs-textbox",
            type: "number",
            value: "",
            dataSource: null
          },
          {
            name: "clean",
            per: "بستن اعلان با کلیک روی آن",
            eng: "close alarm when clicked on",
            component: "ejs-dropdownlist",
            type: "list",
            value: "",
            dataSource: [
              { per: "بسته شود", eng: "close it", value: true },
              { per: "بسته نشود", eng: "no close", value: false }
            ]
          },
          {
            name: "sound",
            per: "پخش صدا هنگام نمایش اعلان",
            eng: "play sound when alarmed",
            component: "ejs-dropdownlist",
            type: "list",
            value: "",
            dataSource: [
              { per: "پخش شود", eng: "play sound", value: true },
              { per: "پخش نشود", eng: "no play", value: false }
            ]
          },
          {
            name: "width",
            per: "تنظیم عرض صفحه اعلان (px)",
            eng: "alarm box width (px)",
            component: "ejs-textbox",
            type: "number",
            value: "",
            min: 300,
            max: 900,
            dataSource: null
          }
          // {
          //   name: "height",
          //   per: "تنظیم طول صفحه اعلان (px)",
          //   eng: "alarm box height (px)",
          //   component: "ejs-textbox",
          //   type: "number",
          //   value: "",
          //   min: 0,
          //   max: 100,
          //   dataSource: null
          // }
        ],
        2: [
          {
            name: "position",
            per: "موقعیت نمایش اعلان",
            eng: "notification position",
            component: "ejs-dropdownlist",
            type: "list",
            dataSource: [
              {
                per: "یک مورد انتخاب کنید",
                eng: "Select One",
                value: "null"
              },
              {
                per: "پایین سمت راست",
                eng: "bottom right",
                value: "{ \"X\":\"Right\", \"Y\":\"Bottom\" }"
              },
              {
                per: "پایین سمت چپ",
                eng: "bottom left",
                value: "{ \"X\":\"Left\", \"Y\":\"Bottom\" }"
              },
              {
                per: "بالا سمت راست",
                eng: "top right",
                value: "{ \"X\":\"Right\", \"Y\":\"Top\" }"
              },
              {
                per: "بالا سمت چپ",
                eng: "top left",
                value: "{ \"X\":\"Left\", \"Y\":\"Top\" }"
              },
              {
                per: "بالا وسط صفحه",
                eng: "top center",
                value: "{ \"X\":\"Center\", \"Y\":\"Top\" }"
              },
              {
                per: "پایین وسط صفحه",
                eng: "bottom center",
                value: "{ \"X\":\"Center\", \"Y\":\"Bottom\" }"
              }, {
                per: "وسط صفحه",
                eng: "center",
                value: "{ \"X\":\"Center\", \"Y\":\"Center\" }"
              }
            ],
            value: null
          },
          // {
          //   name: "color",
          //   per: "رنگ اعلان",
          //   eng: "notification color",
          //   component: "ejs-textbox",
          //   type: "color",
          //   value: "",
          //   dataSource: null,
          // },
          {
            name: "duration",
            per: "مدت زمان نمایش اعلان (میلی ثانیه)",
            eng: "alert duration (ms)",
            component: "ejs-textbox",
            type: "number",
            value: "",
            dataSource: null
          },
          {
            name: "speed",
            per: "سرعت نمایش اعلان",
            eng: "alert speed",
            component: "ejs-textbox",
            type: "number",
            value: "",
            dataSource: null
          },
          {
            name: "clean",
            per: "بستن اعلان با کلیک روی آن",
            eng: "close alarm when clicked on",
            component: "ejs-dropdownlist",
            type: "list",
            value: "",
            dataSource: [
              { per: "بسته شود", eng: "close it", value: true },
              { per: "بسته نشود", eng: "no close", value: false }
            ]
          },
          {
            name: "sound",
            per: "پخش صدا هنگام نمایش اعلان",
            eng: "play sound when alarmed",
            component: "ejs-dropdownlist",
            type: "list",
            value: "",
            dataSource: [
              { per: "پخش شود", eng: "play sound", value: true },
              { per: "پخش نشود", eng: "no play", value: false }
            ]
          },
          {
            name: "width",
            per: "تنظیم عرض صفحه اعلان (px)",
            eng: "alarm box width (px)",
            component: "ejs-textbox",
            type: "number",
            value: "",
            min: 300,
            max: 900,
            dataSource: null
          }
          // {
          //   name: "height",
          //   per: "تنظیم طول صفحه اعلان (%)",
          //   eng: "alarm box height (%)",
          //   component: "ejs-textbox",
          //   type: "number",
          //   value: "",
          //   min: 0,
          //   max: 100,
          //   dataSource: null
          // }
        ],
        3: [
          {
            name: "position",
            per: "موقعیت نمایش اعلان",
            eng: "notification position",
            component: "ejs-dropdownlist",
            type: "list",
            dataSource: [
              {
                per: "یک مورد انتخاب کنید",
                eng: "Select One",
                value: "null"
              },
              {
                per: "پایین سمت راست",
                eng: "bottom right",
                value: "{ \"X\":\"Right\", \"Y\":\"Bottom\" }"
              },
              {
                per: "پایین سمت چپ",
                eng: "bottom left",
                value: "{ \"X\":\"Left\", \"Y\":\"Bottom\" }"
              },
              {
                per: "بالا سمت راست",
                eng: "top right",
                value: "{ \"X\":\"Right\", \"Y\":\"Top\" }"
              },
              {
                per: "بالا سمت چپ",
                eng: "top left",
                value: "{ \"X\":\"Left\", \"Y\":\"Top\" }"
              },
              {
                per: "بالا وسط صفحه",
                eng: "top center",
                value: "{ \"X\":\"Center\", \"Y\":\"Top\" }"
              },
              {
                per: "پایین وسط صفحه",
                eng: "bottom center",
                value: "{ \"X\":\"Center\", \"Y\":\"Bottom\" }"
              }, {
                per: "وسط صفحه",
                eng: "center",
                value: "{ \"X\":\"Center\", \"Y\":\"Center\" }"
              }
            ],
            value: ""
          },
          // {
          //   name: "color",
          //   per: "رنگ اعلان",
          //   eng: "notification color",
          //   component: "ejs-textbox",
          //   type: "color",
          //   value: "",
          //   dataSource: null,
          // },
          {
            name: "duration",
            per: "مدت زمان نمایش اعلان (میلی ثانیه)",
            eng: "alert duration (ms)",
            component: "ejs-textbox",
            type: "number",
            value: "",
            dataSource: null
          },
          {
            name: "speed",
            per: "سرعت نمایش اعلان",
            eng: "alert speed",
            component: "ejs-textbox",
            type: "number",
            value: "",
            dataSource: null
          },
          {
            name: "clean",
            per: "بستن اعلان با کلیک روی آن",
            eng: "close alarm when clicked on",
            component: "ejs-dropdownlist",
            type: "list",
            value: "",
            dataSource: [
              { per: "بسته شود", eng: "close it", value: true },
              { per: "بسته نشود", eng: "no close", value: false }
            ]
          },
          {
            name: "sound",
            per: "پخش صدا هنگام نمایش اعلان",
            eng: "play sound when alarmed",
            component: "ejs-dropdownlist",
            type: "list",
            value: "",
            dataSource: [
              { per: "پخش شود", eng: "play sound", value: true },
              { per: "پخش نشود", eng: "no play", value: false }
            ]
          },
          {
            name: "width",
            per: "تنظیم عرض صفحه اعلان (px)",
            eng: "alarm box width (px)",
            component: "ejs-textbox",
            type: "number",
            value: "",
            min: 300,
            max: 900,
            dataSource: null
          }
          // {
          //   name: "height",
          //   per: "تنظیم طول صفحه اعلان (%)",
          //   eng: "alarm box height (%)",
          //   component: "ejs-textbox",
          //   type: "number",
          //   value: "",
          //   min: 0,
          //   max: 100,
          //   dataSource: null
          // }
        ],
        4: [
          {
            name: "position",
            per: "موقعیت نمایش اعلان",
            eng: "notification position",
            component: "ejs-dropdownlist",
            type: "list",
            dataSource: [
              {
                per: "یک مورد انتخاب کنید",
                eng: "Select One",
                value: "null"
              },
              {
                per: "پایین سمت راست",
                eng: "bottom right",
                value: "{ \"X\":\"Right\", \"Y\":\"Bottom\" }"
              },
              {
                per: "پایین سمت چپ",
                eng: "bottom left",
                value: "{ \"X\":\"Left\", \"Y\":\"Bottom\" }"
              },
              {
                per: "بالا سمت راست",
                eng: "top right",
                value: "{ \"X\":\"Right\", \"Y\":\"Top\" }"
              },
              {
                per: "بالا سمت چپ",
                eng: "top left",
                value: "{ \"X\":\"Left\", \"Y\":\"Top\" }"
              },
              {
                per: "بالا وسط صفحه",
                eng: "top center",
                value: "{ \"X\":\"Center\", \"Y\":\"Top\" }"
              },
              {
                per: "پایین وسط صفحه",
                eng: "bottom center",
                value: "{ \"X\":\"Center\", \"Y\":\"Bottom\" }"
              }, {
                per: "وسط صفحه",
                eng: "center",
                value: "{ \"X\":\"Center\", \"Y\":\"Center\" }"
              }
            ],
            value: ""
          },
          // {
          //   name: "color",
          //   per: "رنگ اعلان",
          //   eng: "notification color",
          //   component: "ejs-textbox",
          //   type: "color",
          //   value: "",
          //   dataSource: null,
          // },
          {
            name: "duration",
            per: "مدت زمان نمایش اعلان (میلی ثانیه)",
            eng: "alert duration (ms)",
            component: "ejs-textbox",
            type: "number",
            value: "",
            dataSource: null
          },
          {
            name: "speed",
            per: "سرعت نمایش اعلان",
            eng: "alert speed",
            component: "ejs-textbox",
            type: "number",
            value: "",
            dataSource: null
          },
          {
            name: "clean",
            per: "بستن اعلان با کلیک روی آن",
            eng: "close alarm when clicked on",
            component: "ejs-dropdownlist",
            type: "list",
            value: "",
            dataSource: [
              { per: "بسته شود", eng: "close it", value: true },
              { per: "بسته نشود", eng: "no close", value: false }
            ]
          },
          {
            name: "sound",
            per: "پخش صدا هنگام نمایش اعلان",
            eng: "play sound when alarmed",
            component: "ejs-dropdownlist",
            type: "list",
            value: "",
            dataSource: [
              { per: "پخش شود", eng: "play sound", value: true },
              { per: "پخش نشود", eng: "no play", value: false }
            ]
          },
          {
            name: "width",
            per: "تنظیم عرض صفحه اعلان (px)",
            eng: "alarm box width (px)",
            component: "ejs-textbox",
            type: "number",
            value: "",
            min: 300,
            max: 900,
            dataSource: null
          }
          // {
          //   name: "height",
          //   per: "تنظیم طول صفحه اعلان (%)",
          //   eng: "alarm box height (%)",
          //   component: "ejs-textbox",
          //   type: "number",
          //   value: "",
          //   min: 0,
          //   max: 100,
          //   dataSource: null
          // }
        ],
        5: [
          {
            name: "position",
            per: "موقعیت نمایش اعلان",
            eng: "notification position",
            component: "ejs-dropdownlist",
            type: "list",
            dataSource: [
              {
                per: "یک مورد انتخاب کنید",
                eng: "Select One",
                value: "null"
              },
              {
                per: "پایین سمت راست",
                eng: "bottom right",
                value: "{ \"X\":\"Right\", \"Y\":\"Bottom\" }"
              },
              {
                per: "پایین سمت چپ",
                eng: "bottom left",
                value: "{ \"X\":\"Left\", \"Y\":\"Bottom\" }"
              },
              {
                per: "بالا سمت راست",
                eng: "top right",
                value: "{ \"X\":\"Right\", \"Y\":\"Top\" }"
              },
              {
                per: "بالا سمت چپ",
                eng: "top left",
                value: "{ \"X\":\"Left\", \"Y\":\"Top\" }"
              },
              {
                per: "بالا وسط صفحه",
                eng: "top center",
                value: "{ \"X\":\"Center\", \"Y\":\"Top\" }"
              },
              {
                per: "پایین وسط صفحه",
                eng: "bottom center",
                value: "{ \"X\":\"Center\", \"Y\":\"Bottom\" }"
              }, {
                per: "وسط صفحه",
                eng: "center",
                value: "{ \"X\":\"Center\", \"Y\":\"Center\" }"
              }
            ],
            value: ""
          },
          // {
          //   name: "color",
          //   per: "رنگ اعلان",
          //   eng: "notification color",
          //   component: "ejs-textbox",
          //   type: "color",
          //   value: "",
          //   dataSource: null,
          // },
          {
            name: "duration",
            per: "مدت زمان نمایش اعلان (میلی ثانیه)",
            eng: "alert duration (ms)",
            component: "ejs-textbox",
            type: "number",
            value: "",
            dataSource: null
          },
          {
            name: "speed",
            per: "سرعت نمایش اعلان",
            eng: "alert speed",
            component: "ejs-textbox",
            type: "number",
            value: "",
            dataSource: null
          },
          {
            name: "clean",
            per: "بستن اعلان با کلیک روی آن",
            eng: "close alarm when clicked on",
            component: "ejs-dropdownlist",
            type: "list",
            value: "",
            dataSource: [
              { per: "بسته شود", eng: "close it", value: true },
              { per: "بسته نشود", eng: "no close", value: false }
            ]
          },
          {
            name: "sound",
            per: "پخش صدا هنگام نمایش اعلان",
            eng: "play sound when alarmed",
            component: "ejs-dropdownlist",
            type: "list",
            value: "",
            dataSource: [
              { per: "پخش شود", eng: "play sound", value: true },
              { per: "پخش نشود", eng: "no play", value: false }
            ]
          },
          {
            name: "width",
            per: "تنظیم عرض صفحه اعلان (px)",
            eng: "alarm box width (px)",
            component: "ejs-textbox",
            type: "number",
            value: "",
            min: 300,
            max: 900,
            dataSource: null
          }
          // {
          //   name: "height",
          //   per: "تنظیم طول صفحه اعلان (%)",
          //   eng: "alarm box height (%)",
          //   component: "ejs-textbox",
          //   type: "number",
          //   value: "",
          //   min: 0,
          //   max: 100,
          //   dataSource: null
          // }
        ]
      },
      items: [],
      notificationCount: 1,
      activeTab: "unread",
      avatarUrl: null,
      iselectedPage: [],
      color0: "white",
      color1: "transparent",
      color2: "transparent",
      selectedScenario: null,
      dropdownWBItems: [],
      headerMenu: false,
      dropdownLngItems: [
        {
          eng: "English",
          per: "انگلیسی",
          label: "English",
          abrev: "En",
          imageUrl: flagUSA,
          value: "eng"
        },
        {
          eng: "Persian",
          per: "فارسی",
          label: "فارسی",
          abrev: "فا",
          imageUrl: flagIRAN,
          value: "per"
        }
      ],
      profile: { name: "احمدرضا شفیعی", imageUrl: obama },
      selectedWorkbench: "محرکه گستر آرشید",
      selectedView: null,
      optionsView: [
        { text: "Please select an View", value: null },
        { text: "Map View", value: "Map" },
        { text: "List View", value: "List" }
      ],
      dict: {
        transition: { eng: "slide-fade-ltr", per: "slide-fade-rtl" },
        "Signed in": { per: "ورود کاربر", eng: "signed in" },
        Acknowledged: { per: "تایید شدن", eng: "Acknowledged" },
        Disabled: { per: "غیرفعال شدن", eng: "Disabled" },
        Activated: { per: "فعال شدن", eng: "Activated" },
        Invited: { per: "دعوت کاربر", eng: "Invited" },
        Accepted: { per: "قبول عضویت میزکار", eng: "Accepted" },
        Decline: { per: "رد عضویت میزکار", eng: "Decline" },
        noNotif: {
          eng: "No notification to show !",
          per: "هیچ اعلانی برای نمایش وجود ندارد!"
        },
        seen: { eng: "seen", per: "دیده شدن" },
        cancel: { eng: "Cancel", per: "لغو" },
        showMore: {
          eng: "show notification's list",
          per: "مشاهده لیست اعلانات"
        },
        listTitle: {
          eng: "notification's list",
          per: "لیست اعلانات"
        },
        ok: { eng: "Ok", per: "قبول" },
        events: { eng: "events", per: "رویدادها" },
        api: { eng: "system's notifications", per: "اعلانات سیستمی" },
        unread: { eng: "unread notifications", per: "دیده نشده" },
        read: { eng: "read notifications", per: "دیده شده" },
        settings: { eng: "settings", per: "تنظیمات" },
        notifications: { eng: "notifications", per: "اعلانات" },
        dir: { eng: "ltr", per: "rtl" },
        waiting: { eng: "waiting", per: "در انتظار" },
        profile: { eng: "profile settings", per: "تنظیمات پروفایل" },
        signout: { eng: "sign out", per: "خروج" },
        selectWB: { eng: "Workbench selection", per: "انتخاب میزکار" },
        withoutWB: { eng: "without Workbench", per: "بدون میزکار" },
        selectLang: { eng: "Language selection", per: "انتخاب زبان" },
        createWB: { eng: "create new workbench", per: "ایجاد میزکار جدید" }
      }
    };
  },
  computed: {
    newEvents() {
      return this.$store.state.panel.event;
    },
    notifListGenerator() {
      let list = [];
      if (this.activeTab == "unread") {
        list = this.items.filter((item) => item.readStatus.isRead == false);
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.notificationCount = list.length;
        return list;
      } else if (this.activeTab == "read")
        return this.items.filter((item) => item.readStatus.isRead == true);
      else return [];
    },
    computeNotifs() {
      if (this.notificationCount <= 99) return this.notificationCount;
      else return " +99";
    },
    currentUser() {
      return this.$store.state.data.user;
    },
    myworkBench() {
      return this.$store.state.data.workbench;
    },
    workBenches() {
      if (this.myworkBench) {
        return this.myworkBench.workbenches;
      } else return [];
    },
    activeWorkbench() {
      if (this.myworkBench) {
        return this.myworkBench.activeWorkbench;
      } else return {};
    },
    headerColor() {
      if (this.currentUser) return this.currentUser.theme.headerColor;
      else return "";
    },
    headerFont() {
      if (this.currentUser) return this.currentUser.theme.headerFont;
      else return "dark";
    },
    headerClasses() {
      const classes = {
        "header-shadow": true
      };

      // if (this.headerMenu){
      //   classes['header-menu-open'] = true
      //   console.log('update header menu')
      // }else{
      //   classes['header-menu-open'] = false

      // }

      classes["header-menu-open"] = this.headerMenu;

      if (this.dict.dir[this.lang] === "rtl") classes["header-rtl"] = true;
      else if (this.dict.dir[this.lang] === "ltr") classes["header-ltr"] = true;

      if (this.headerColor) {
        classes[this.headerColor] = true;
      } else {
        classes["bg-default-header"] = true;
      }

      if (this.headerFont) {
        classes[this.headerFont + "-font"] = true;
      } else {
        classes["dark-font"] = true;
      }

      if (this.lang === "per") {
        classes["text-end"] = true;
      } else {
        classes["text-start"] = true;
      }
      return classes;
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    sidebar() {
      return this.$store.state.chpge.sidebar;
    },
    updateAvatars() {
      return this.$store.state.data.avatarUpdated;
    }
  },
  mounted() {
    this.$store.dispatch(
      "chpge/changeLanguage",
      this.$store.state.data.user.settings.default_language
    );
    this.getAvatar();
    this.getNotifications();

    // this.getWBdata()
    // this.getUserData()
  },
  watch: {
    newEvents() {
      this.getNotifications();
    },
    workBenches(newValue) {
      this.getNotifications();
      var waitingWB = this.workBenches.filter((x) => x.status === "waiting");
      if (waitingWB.length > 0) {
        this.$store.dispatch("chpge/parent2child", {
          task: "invitedWBs",
          items: waitingWB
        });
        // this.$root.$emit("bv::show::modal", "modal-invite-user_table");
      }
    },
    updateAvatars() {
      this.getAvatar();
    }
  },
  methods: {
    resetNotif() {
      this.$store.dispatch("data/deleteNotifSetting", { _id: this.notifList[0]._id });
    },
    getValue(e, name) {
      if (e.name == "input") {
        this.params[this.activePrio][name] = e.value;
      } else if (e.name == "select") {
        this.params[this.activePrio][name] = e.itemData.value;
      } else if (e.name == "change") {
        this.params[this.activePrio][name] = e.value;
      }
    },
    formatDateTime(date, option, lang) {
      if (lang == "per")
        return new Intl.DateTimeFormat("fa-IR", option).format(date);
      if (lang == "eng")
        return new Intl.DateTimeFormat("en-Us", option).format(date);
    },
    getIrDate(idate, lang) {
      const date = new Date(idate);
      if (lang === "eng") {
        var time1 = this.formatDateTime(
          date,
          {
            hour: "numeric",
            minute: "numeric",
            second: "numeric"
          },
          this.lang
        );
        var year1 = this.formatDateTime(date, { year: "numeric" }, this.lang);
        var month1 = this.formatDateTime(date, { month: "long" }, this.lang);
        var wday1 = this.formatDateTime(date, { weekday: "long" }, this.lang);
        var day1 = this.formatDateTime(date, { day: "numeric" }, this.lang);
        var str1 = `${wday1} ${day1} ${month1} ${year1} at  ${time1}`;
        return str1;
      } else if (lang === "per") {
        var time = this.formatDateTime(
          date,
          {
            hour: "numeric",
            minute: "numeric",
            second: "numeric"
          },
          this.lang
        );
        var year = this.formatDateTime(date, { year: "numeric" }, this.lang);
        var month = this.formatDateTime(date, { month: "long" }, this.lang);
        var wday = this.formatDateTime(date, { weekday: "long" }, this.lang);
        var day = this.formatDateTime(date, { day: "numeric" }, this.lang);
        var str = `${wday} ${day} ${month} ${year} ساعت  ${time}`;
        return str;
      }
    },
    getNotifications() {
      this.$store.dispatch("data/getAllNotifs").then((res) => {
        this.items = res;
        this.activeTab = "unread";
        if (this.items.length <= 0) this.notificationCount = this.items.length;
      });
    },
    getNotificationSetting() {
      this.$store.dispatch("data/getNotifSetting").then((res) => {
        this.notifList = res;
        if (res.length > 0) this.params = res[0].setting;
      });
    },
    newSetting(e) {
      e.preventDefault();
      if (this.notifList.length <= 0) {
        this.$store
          .dispatch("data/createSetting", { setting: this.params })
          .then((res) => {
            this.$nextTick(() => {
              this.$bvModal.hide("modal_settings_notif");
            });
            this.getNotificationSetting();
          });
      } else this.updateSetting();
    },
    updateSetting() {
      this.$store
        .dispatch("data/updateSetting", {
          updates: {
            setting: this.params,
            _id: this.notifList[0]._id
          }
        })
        .then((res) => {
          this.$nextTick(() => {
            this.$bvModal.hide("modal_settings_notif");
          });
          this.getNotificationSetting();
        });
    },
    seenNotif(id) {
      this.$store.dispatch("data/seenNotif", { _id: id }).then((res) => {
        this.getNotifications();
      });
    },
    openSettingsModal() {
      this.$bvModal.show("modal_settings_notif");
    },
    openManager() {
      this.$bvModal.show("modal_list_notif");
    },
    getAvatar() {
      this.$store
        .dispatch("data/getUsersAvatar", { id: this.currentUser.id })
        .then((response) => {
          if (response != null) {
            this.avatarUrl = response;
          }
        });
    },
    host() {
      return this.host;
    },
    logOut() {
      this.$store.dispatch("panel/closeSse");
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("data/removeUserData");
      this.$store.dispatch("chpge/changePage", "signin");
    },
    selectPage: function(page) {
      this.$store.dispatch("chpge/changePage", page);
    },
    changeLanguage(lang) {
      this.$store.dispatch("chpge/changeLanguage", lang);
      setCulture(lang);
      L10n.load({
        per: persianLacale
      });
    },
    // changeWorkbench(id){
    //   console.log(id)
    //   this.workBenches.forEach(element => {
    //     if (element.id === id)
    //       element.active = true
    //     else
    //       element.active = false
    //   });
    //   console.log(this.workBenches)
    // },

    changeWorkbench(id) {
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then((isValid) => {
        if (isValid) {
          var data = { id: id };
          // console.log(data)
          this.$store.dispatch("data/selectWB", data).then(
            (response) => {
              this.getWBdata(this.activeWorkbench);
              this.getUserData();
            },
            (error) => {
              // this.message =
              //   (error.response && error.response.data && error.response.data.message) ||
              //   error.message ||
              //   error.toString();
            }
          );
        }
      });
    },
    getWBdata(activeWorkbench) {
      this.$store.dispatch("data/getWBdata").then(
        (response) => {
          this.$store.dispatch("panel/closeSse");
          this.$store.dispatch("panel/sseSubscription");
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
      // }else{
      //   console.log("you don't have enough permission")
      // }
    },
    getUserData() {
      // console.log('getUserData')
      this.$store.dispatch("data/getUserData").then(
        (response) => {
          // console.log('============user data',response)
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    showWaitingInv(waitingWB) {
      this.$store.dispatch("chpge/parent2child", {
        task: "invitedWBs",
        items: waitingWB
      });
      this.$root.$emit("bv::show::modal", "modal-invite-user_table");
    },
    createWorkbench() {
      // this.$store.dispatch('chpge/parent2child', {task: 'pickData', from:'pubsub'})
      this.$root.$emit("bv::show::modal", "modal-new-workbench");
    },
    toggleSidebar(data) {
      this.$store.dispatch("chpge/lockSidebar", false);
      this.$store.dispatch("chpge/toggleSidebar", data);
    },
    toggleHeaderMenu() {
      this.headerMenu = !this.headerMenu;
    }
  }
};
</script>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"></style>
<style scoped>
.header-rtl,
.header-ltr {
  height: 60px;
  display: flex;
  align-items: center;
  z-index: 10;
  transition: all 0.2s;
  position: fixed;
  width: 100%;
}

.header-rtl .logo-src,
.header-ltr .logo-src {
  display: none;
}

.header-shadow {
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
  0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
  0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
  0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
}

.header-ltr .header__content {
  display: flex;
  align-items: center;
  flex: 1;
  padding-left: 280px;
  height: 60px;
  transition: all 0.2s;
}

.header-rtl .header__content {
  display: flex;
  align-items: center;
  flex: 1;
  padding-right: 280px;
  height: 60px;
  transition: all 0.2s;
}

.header-ltr .header__content .header-left {
  display: flex;
  align-items: center;
  padding: 0 0 0 1.5rem;
}

.header-rtl .header__content .header-left {
  display: flex;
  align-items: center;
  padding: 0 1.5rem 0 0;
}

.header-ltr .header__content .header-right {
  align-items: center;
  display: flex;
  margin-left: auto;
  padding: 0 1.5rem 0 0;
}

.header-rtl .header__content .header-right {
  align-items: center;
  display: flex;
  margin-right: auto;
  padding: 0 0 0 1.5rem;
}

.header__menu,
.header__mobile-menu {
  display: none;
  padding: 0 1.5rem;
  height: 60px;
  width: 20%;
  align-items: center;
}

.dropdown-item-content {
  display: flex;
  align-items: center;
  min-height: 50px;
}

.header__mobile-menu .hamburger,
.header__menu .hamburger {
  color: #5b98e9;
  cursor: pointer;
  font-size: x-large;
  text-align: center;
}

.header__mobile-menu .hamburger :hover,
.header__menu .hamburger :hover {
  color: #9b9b9b;
}

@media (max-width: 991.98px) {
  .header-rtl,
  .header-ltr {
    justify-content: space-between;
    z-index: 25;
  }

  .header-rtl .logo-src,
  .header-ltr .logo-src {
    display: block;
    order: 2;
  }

  .header-rtl .header__content,
  .header-ltr .header__content {
    visibility: hidden;
    opacity: 0;
    box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
    position: absolute;
    left: 2%;
    width: 96%;
    height: 80px;
    top: 0;
    transition: all 0.2s;
    background: #ffffff;
    border-radius: 10px;
    justify-content: center;
    //padding: 0 10px !important;
    /* overflow: hidden; */
  }

  .header-ltr .header__content .header-left {
    padding-left: 0;
  }

  .header-rtl .header__content .header-left {
    padding-right: 0;
  }

  .header-ltr .header__content .header-right {
    padding-right: 0;
  }

  .header-rtl .header__content .header-right {
    padding-left: 0;
  }

  .header-menu-open .header__content {
    visibility: visible;
    opacity: 1;
    top: 65px;
  }

  .header-ltr .header__mobile-menu,
  .header-rtl .header__mobile-menu {
    display: flex;
    order: 1;
    width: 20%;
  }

  .header-ltr .header__menu,
  .header-rtl .header__menu {
    display: flex;
    order: 3;
  }
}
</style>

<style>
::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #aaa;
  height: 100px;
  border-radius: 10px;
}

::-webkit-scrollbar-corner {
  background-color: #aaa;
}

.cursor-pointer {
  cursor: pointer;
}

.notif-container {
  width: 28rem;
  max-height: 26rem;
}

.workbench-dropdown {
  min-width: 20rem !important;
  padding: 0;
}

.workbench-dropdown .dropdown-header .background-container {
  position: relative;
  background-image: url("../assets/images/abstract3.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%; /* Adjust as needed */
  border-radius: inherit;
}

.workbench-dropdown .dropdown-header .background-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(58, 248, 0, 0.527); /* Adjust opacity here */
  pointer-events: none; /* Allows interaction with underlying content */
  border-radius: inherit;
}

.workbench-dropdown .dropdown-header .background-content {
  position: relative;
  z-index: 1; /* Place content above pseudo-element overlay */
  padding: 20px;
  color: rgb(255, 255, 255) !important;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

.workbench-dropdown .dropdown-header {
  padding: 0px;
  background-color: aqua;
  height: 5rem;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.workbench-dropdown .dropdown-menu {
  text-align: inherit;
  min-width: 20rem !important;
  padding: 0;
  border-radius: 10px;
}

.workbench-dropdown .dropdown-toggle {
  border: none; /* Remove the border */
  background-color: transparent;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.light-font .workbench-dropdown .dropdown-toggle:hover {
  color: white;
}

.dark-font .workbench-dropdown .dropdown-toggle:hover {
  color: black;
}

.workbench-dropdown.show .dropdown-toggle {
  border: "1px solid #ced4da" !important;
  background-color: transparent;
  color: inherit;
}

.workbench-dropdown .btn-check:focus + .btn,
.workbench-dropdown .btn:focus {
  outline: none;
  box-shadow: none;
}

.language-dropdown {
  min-width: 10rem !important;
  padding: 0;
}

.language-dropdown .dropdown-header .background-container {
  position: relative;
  background-image: url("../assets/images/changeLang.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%; /* Adjust as needed */
  border-radius: inherit;
}

.language-dropdown .dropdown-header .background-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.77); /* Adjust opacity here */
  pointer-events: none; /* Allows interaction with underlying content */
  border-radius: inherit;
}

.language-dropdown .dropdown-header .background-content {
  position: relative;
  z-index: 1; /* Place content above pseudo-element overlay */
  padding: 20px;
  color: rgb(255, 255, 255) !important;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

.language-dropdown .dropdown-header {
  padding: 0px;
  background-color: aqua;
  height: 5rem;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.language-dropdown .dropdown-menu {
  text-align: inherit;
  min-width: 10rem !important;
  padding: 0;
  border-radius: 10px;
}

.language-dropdown .btn-check:focus + .btn,
.language-dropdown .btn:focus {
  outline: none;
  box-shadow: none;
}

.profile-dropdown {
  min-width: 20rem !important;
  padding: 0;
}

.profile-dropdown .dropdown-header .background-container {
  position: relative;
  background-image: url("../assets/images/3photo.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%; /* Adjust as needed */
  border-radius: inherit;
}

.profile-dropdown .dropdown-header .background-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent; /* Adjust opacity here */
  pointer-events: none; /* Allows interaction with underlying content */
  border-radius: inherit;
}

.profile-dropdown .dropdown-header .background-content {
  position: relative;
  z-index: 1; /* Place content above pseudo-element overlay */
  padding: 20px;
  color: rgb(255, 255, 255) !important;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.profile-dropdown .dropdown-header {
  padding: 0px;
  background-color: aqua;
  height: 5rem;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.profile-dropdown .dropdown-menu {
  text-align: inherit;
  min-width: 20rem !important;
  padding: 0;
  border-radius: 10px;
}

.profile-dropdown .btn-check:focus + .btn,
.profile-dropdown .btn:focus {
  outline: none;
  box-shadow: none;
}
</style>

<style scoped src="@/assets/styles/theme.css"></style>
