<template>
  <div>
    <b-container class="h-100" fluid style="padding: 0 !important">
      <div>
        <ejs-grid
          ref="wb_admin"
          :width="'auto'"
          :locale="lang"
          height="100%"
          :loadingIndicator="{ indicatorType: 'Shimmer' }"
          :allowSorting="true"
          :excelQueryCellInfo="excelQueryCellInfo"
          :allowExcelExport="true"
          :allowFiltering="true"
          :editSettings="curTableOptions.editSettings"
          :allowPdfExport="true"
          :toolbar="toolbarGenerator"
          :pdfExportComplete="pdfExportComplete"
          :toolbarClick="toolbarClick"
          @dataBound="dataBounded"
          @actionBegin="onActionBegin"
          :enableAdaptiveUI="true"
          :allowPaging="true"
          :enableRtl="lang == 'per'"
          :pageSettings="{ pageSize: 5,pageSizes: [5, 10, 20, 50] }"
          :selectionSettings="curTableOptions.selectionOptions"
          :dataSource="
            workbenchItems.length>0 && !isList
              ? curTableOptions.items
              : workbenchGenerator
          "
        >
          <e-columns>
            <e-column width="50" type="checkbox"></e-column>
            <e-column
              v-for="(item, index) in curTableOptions.fields"
              :key="index"
              :field="item.key"
              :width="200"
              :headerText="item[lang]"
              :template="item.hasTemplate ? item.key : null"
            ></e-column>
          </e-columns>
          <template v-slot:creator="{ data }">
            {{
              `${data.creator.fname ? data.creator.fname : ""} ${
                data.creator.lname ? data.creator.lname : ""
              }`
            }}
          </template>
          <template v-slot:status="{ data }">
            {{ translateStatus[data.status][lang] }}
          </template>
          <template v-slot:avatar="{ data }">
            <div>
              <b-avatar
                variant="info"
                :src="avatarUrl[data.id]"
                :text="`${data.creator.fname ? data.creator.fname[0] : ''} ${
                  data.creator.lname ? data.creator.lname[0] : ''
                }`"
                style="background-color: #0d6efd;color: white"
              ></b-avatar>
            </div>
          </template>
          <template v-slot:modify="{ data }">
            <div class="d-flex justify-content-between col-5 gap-md-0 gap-5">
              <span
                v-permission="'admin_workbench\'s_users_list'"
                class="e-icons e-people"
                @click="showUsers(data)"
                style="font-size: 18px"
                :id="data.id"
              ></span>
              <span
                v-permission="'admin_allocate_resource'"
                class="e-icons e-page-setup"
                id="modalbtn"
                style="font-size: 18px"
                @click="openModalLicence(data)"
              ></span>
              <!--              <span class="e-icons e-delete-1 text-danger"-->
              <!--                    style="font-size: 17px"-->
              <!--                    :key="data.id"></span>-->
            </div>
          </template>
        </ejs-grid>
      </div>
    </b-container>
    <b-modal
      id="processModal"
      size="xl"
      centered
      style="width: 150% !important"
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      no-close-on-backdrop
      no-close-on-esc
      @hidden="getWorkbenches"
      @shown="initList"
      content-class="shadow"
      @cancel="showThem=false"
      :cancel-title="dict.cancel[lang]"
      :dir="dict.dir[lang]"
    >
      <template #modal-header>
        <h5>{{ modalTitle }}</h5>
        <i @click="$bvModal.hide('processModal')">
          <font-awesome-icon icon="fas fa-xmark" class="close" />
        </i>
      </template>
      <div>
        <workbench-users v-if="showThem" :user-data="selectedId" />
      </div>
      <template #modal-ok>
        <b-button
          variant="primary"
          class="p-0"
          @click="
            () => {
              loadPanel = false;
            }
          "
        >
          {{ dict.ok[lang] }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="licenceModal"
      size="xl"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      no-close-on-backdrop
      scrollable
      content-class="shadow"
      :dir="dict.dir[lang]"
      @ok="handleLicenceSet"
      :ok-title="dict.ok[lang]"
      :cancel-title="dict.cancel[lang]"
    >
      <template #modal-header>
        <h5 :dir="dict.dir[lang]">{{ dict.modalLicenceTitle[lang] }}</h5>
        <i @click="$bvModal.hide('licenceModal')">
          <font-awesome-icon icon="fas fa-xmark" class="close" />
        </i>
      </template>
      <div>
        <licence-handler ref="wbHandel" :wb-id="wbId" />
      </div>
    </b-modal>
    <b-modal
      id="admin-invite-user"
      size="md"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      hide-header-close
      no-close-on-backdrop
      content-class="shadow"
      :title="dict.titleModal[lang]"
      @ok="invUser"
      :dir="dict.dir[lang]"
    >
      <div>
        <b-form-group
          :label="dict.labelModal[lang]"
          label-for="user"
          style="width: 100%; padding: 0px 10px 10px 0px"
        >
          <b-form-input
            name="user"
            type="email"
            v-model="username"
            :placeholder="dict.placeholder[lang]"
            v-validate="'required|min:1|max:200'"
            style="width: 100%"
          ></b-form-input>
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import WorkbenchUsers from "@/components/workbenchUsers.vue";
import store from "@/store";
import { host } from "@/models/host";
import LicenceHandler from "@/components/licenceHandler.vue";
import {
  Page,
  Sort,
  Toolbar,
  PdfExport,
  ExcelExport,
  Edit,
  Search
} from "@syncfusion/ej2-vue-grids";
import licenceHandler from "@/components/licenceHandler.vue";
import { mapGetters } from "vuex";
import { L10n } from "@syncfusion/ej2-base";

export default {
  props: {
    workbenchItems: {
      default: []
    },
    licence: {
      default: false
    },
    isList: {
      default: false
    }
  },
  name: "workbenchAdministrator",
  components: { LicenceHandler, WorkbenchUsers },
  data() {
    return {
      translateStatus: {
        creator: { eng: "workbench creator", per: "سازنده میزکار" },
        invited: { per: "دعوت شده", eng: "invited" },
        owner: { per: "مالک", eng: "owner" },
        waiting: { per: "در انتظار تایید", eng: "waiting" }
      },
      avatarUrl: {},
      actionMode: "",
      wbId: "",
      label: "",
      currentWbId: "",
      username: "",
      selectedId: "",
      loadPanel: false,
      showThem: false,
      licenceDialog: false,
      curTableOptions: {
        items: [],
        fields: [
          {
            key: "avatar",
            eng: "avatar",
            per: "آواتار",
            hasTemplate: true
          },
          {
            key: "creator",
            eng: "owner",
            per: "مالک میزکار",
            hasTemplate: true
          },
          {
            key: "label",
            eng: "label",
            per: "عنوان",
            hasTemplate: false
          },
          { key: "modify", eng: "", per: "", hasTemplate: true }
        ],
        selectionOptions: {
          type: "Multiple",
          persistSelection: false,
          checkboxOnly: true
        },
        editSettings: {
          allowEditing: false,
          allowAdding: true,
          allowDeleting: true,
          mode: "Dialog"
        }
      },
      dict: {
        refresh: { eng: "refresh datas", per: "به روزرسانی اطلاعات" },
        placeholder: {
          eng: "enter email of inviting user",
          per: "ایمیل کاربر مد نظر را وارد کنید"
        },
        upload: { eng: "upload license", per: "بارگذاری مجوز" },
        dir: { eng: "ltr", per: "rtl" },
        updloadText: { per: "آپلود فایل", eng: "browse" },
        createWB: { eng: "create new workbench", per: "ایجاد میزکار جدید" },
        newWb: { eng: "new workbench", per: "میزکار جدید" },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        th_floorTitle: { eng: "name", per: "نام" },
        validName: { eng: "name is required!", per: "ورود نام الزامی است!" },
        search: { eng: "Type to Search", per: "برای جستجو تایپ کنید" },
        clear: { eng: "clear", per: "حذف" },
        title: { eng: "admin panel", per: "پنل مدیریت" },
        label: { eng: "workbench name", per: "نام میزکار" },
        titleModal: { eng: "invite user", per: "دعوت کاربر به میزکار" },
        labelModal: { eng: "user's email", per: "ایمیل کاربر" },
        tab1: { eng: "licence manager", per: "مدیریت منابع" },
        tab2: { eng: "workbench managment", per: "مدیریت میزکار" },
        tab3: { eng: "users management", per: "مدیریت کاربران" },
        tab4: { eng: "roles management", per: "مدیریت نقش ها" },
        wbname: { eng: "workbench name", per: "نام میزکار" },
        wbowner: { eng: "workbench owner", per: "مالک میزکار" },
        wbresources: { eng: "workbench resources", per: "منابع میزکار" },
        modalTitle: { eng: "users list", per: "لیست کاربران" },
        modalLicenceTitle: { eng: "licences", per: "تخصیص منابع" },

        regmsg: { eng: "Don't have an account?", per: "حساب کاربری ندارید؟" },
        register: { eng: "Register now", per: "ثبت نام" },
        errCaptcha: {
          eng: "invalid captcha",
          per: "کد امنیتی وارد شده اشتباه می باشد"
        },
        errUserPass1: {
          eng: "invalid username or password",
          per: "نام کاربری یا کلمه عبور اشتباه است"
        },
        errUserPass2: {
          eng: "check your keyboard language",
          per: "• وضعيت زبان(En/FA)سيستم خود را كنترل كنيد"
        },
        errUserPass3: {
          eng: "the password is caps sensitive",
          per: "• پسورد به حروف بزرگ و کوچک حساس است"
        }
      }
    };
  },
  methods: {
    getAvatar(id) {
      this.$store
        .dispatch("data/getWbAvatar", { id: id })
        .then((response) => {
          if (response != null) {
            this.$set(this.avatarUrl, id, response);
          }
        });
    },
    onActionBegin(args) {
      if (args.requestType == "refresh" || args.requestType == "paging") {
        this.$refs.wb_admin.showSpinner();
      }
    },
    dataBounded(e) {
      this.$refs.wb_admin.hideSpinner();
    },
    host() {
      return host;
    },
    toolbarClick(args) {
      if (args.item.id.includes("excelexport")) {
        if (this.isList)
          this.$refs.wb_admin.excelExport();
      } else if (args.item.id.includes("csvexport")) {
        this.$refs.wb_admin.csvExport();
      } else if (args.item.id.includes("add_wb")) {
        this.actionMode = "addWb";
        this.$root.$emit("bv::show::modal", "modal-new-workbench");
      } else if (args.item.id.includes("refresh_table")) {
        if (this.workbenchItems.length <= 0 && this.isList) {
          this.getWorkbenches();
          this.$refs.wb_admin.ej2Instances.refresh();
        }
      }
    },
    pdfExportComplete() {
      this.$refs.wb_admin.hideSpinner();
    },
    excelQueryCellInfo(args) {
      this.curTableOptions.fields.forEach((item) => {
        if (args.column.field == item.key && item.key!='modify')
          args.value = args.data[item.key];
      });
      // if (args.column.field == "role") {
      //   args.value = args.data.permission.name;
      // }
      // if (args.column.field == "name") {
      //   args.value = args.data.fname + " " + args.data.lname;
      // }
      // if (args.column.field == "status") {
      //   args.value = this.translateStatus[args.data.status][this.lang];
      // }
    },
    handleOk($bv) {
      $bv.preventDefault();
    },
    handleLicenceSet() {
      this.$refs.wbHandel.setWbLicences();
    },
    invUser() {
      this.$store
        .dispatch("data/invFromAdmin", {
          workbench: this.currentWbId.item.id,
          user: this.username
        })
        .then(() => {
          this.$bvModal.hide("admin-invite-user");
        });
    },
    openModalLicence(val) {
      this.wbId = val.id;
      this.$bvModal.show("licenceModal");
    },
    getWorkbenches() {
      this.$store.dispatch("data/getWBListdata");
      this.curTableOptions.items = this.$store.state.data.workbenchLists;
      this.curTableOptions.items.forEach((item) => {
        this.getAvatar(item.id);
      });
      this.$refs.wb_admin.ej2Instances.refresh();
      this.showThem = false;
    },
    initList() {

    },
    showUsers(data) {
      this.selectedId = data;
      this.showThem = true;
      this.loadPanel = true;
      this.$bvModal.show("processModal");
    },
    createNewWb() {
      this.$store.dispatch("data/createWB", { label: this.label }).then(
        //auth/createWorkbench
        (response) => {
          this.$nextTick(() => {
            this.$store.dispatch("data/getWBListdata");
            this.$bvModal.hide("modal-new-workbench");
          });
          this.$store.dispatch("data/getUserData");
        },
        (error) => {
          // alert("This workbench already exists");
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    addLicence() {
      this.$root.$emit("bv::show::modal", "uploadLicence");
    },
    openInv(data) {
      this.$refs["admin-invite-user"].show();
      this.currentWbId = data;
    }
  },
  computed: {
    ...mapGetters("chpge", ["screenWidth", "screenHeight"]),
    licenceHandler() {
      return licenceHandler;
    },
    userPermission() {
      return this.$store.state.data.user.permissions;
    },
    toolbarGenerator() {
      // this.userPermission.includes("admin_new_wb")
      if (this.isList) {
        return ["Search", {
          text: this.dict.newWb[this.lang],
          id: "add_wb",
          prefixIcon: "e-icons e-add"
        },
          {
            text: this.dict.refresh[this.lang],
            id: "refresh_table",
            prefixIcon: "e-icons e-refresh-2"
          },
          "ExcelExport"

        ];
      } else return ["Search", "ExcelExport"];
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    modalTitle() {
      return this.dict.modalTitle[this.lang];
    },
    workbenchGenerator() {
      return this.$store.state.data.workbenchLists;
    }
  },
  mounted() {

    if (this.workbenchItems.length <= 0 && this.isList) {
      this.getWorkbenches();
      this.$refs.wb_admin.ej2Instances.refresh();
    } else {
      this.curTableOptions.items = this.workbenchItems;
      this.$refs.wb_admin.ej2Instances.refresh();
      this.curTableOptions.fields = [
        {
          key: "label",
          label: "label",
          eng: "label",
          per: "عنوان",
          hasTemplate: false
        },
        {
          key: "status",
          label: "status",
          eng: "status",
          per: "وضعیت",
          hasTemplate: true
        }
      ];
    }
  },
  provide: {
    grid: [Page, Sort, Toolbar, PdfExport, ExcelExport, Edit, Search]
  }
};
</script>

<style>
.rows {
  border-color: #e0e0e0;
  border-style: solid;
  text-align: left;
  padding: 5px;
  border-width: 1px 0px 0px 0px;
}
</style>
