<template>
  <div
    :dir="dict.dir[lang]"
    class="container-fluid p-0 bg-none"
    :class="lang === 'per' ? 'text-end' : 'text-start'"
    style="height: calc(100vh - 60px)"
  >
    <div class="p-3 m-0 bg-page-title d-flex">
      <div
        :class="
          dict.dir[lang] === 'rtl'
            ? 'page-title-icon-rtl'
            : 'page-title-icon-ltr'
        "
      >
        <img
          src="../assets/icons/controller_designer-2.png"
          alt="dashboard"
          style="width: 60px"
        />
      </div>

      <div class="d-flex align-items-center">
        <h5 class="mb-0">{{ dict.title[lang] }}</h5>
      </div>

      <div
        class="d-flex"
        :class="
          dict.dir[lang] === 'rtl'
            ? 'page-title-right-rtl'
            : 'page-title-right-ltr'
        "
      >
        <b-dropdown
          class="page-title-ricon mr-2 block-dropdown"
          right
          size="sm"
          variant="link"
          no-caret
        >
          <template #button-content>
            <i>
              <font-awesome-icon icon="fas fa-cubes-stacked" size="lg" />
            </i>
          </template>

          <b-dropdown-item
            v-for="(controller, i) in controllers"
            :key="i"
            class="item"
            @click="addController(controller._id)"
          >
            <div class="d-flex gap-3">
              <i
              >
                <font-awesome-icon icon="fas fa-cubes-stacked" size="md"
                />
              </i>
              <span>{{ dict[controller.title][lang] }}</span>

            </div>
          </b-dropdown-item>

          <b-dropdown-divider />

          <b-dropdown-item href="#" class="item" @click="createController">
            <div class="d-flex gap-3">
              <i
              >
                <font-awesome-icon icon="fas fa-plus" size="md"
                />
              </i>
              <span>{{ dict.newCont[lang] }}</span>
            </div>
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown
          class="page-title-ricon mr-2 tag-dropdown"
          right
          size="sm"
          variant="link"
          no-caret
        >
          <template #button-content>
            <i>
              <font-awesome-icon icon="fas fa-tag" size="lg" />
            </i>
          </template>

          <b-dropdown-item
            v-for="(tag, i) in tags"
            :key="i"
            href="#"
            class="item"
            @click="addTag(tag._id)"
          >
            <div class="d-flex gap-3">
              <i
              >
                <font-awesome-icon icon="fas fa-tag" size="md"
                />
              </i>
              <span>{{ dict[tag.title][lang] }}</span>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <div ref="pageContainer" class="page_container">
      <!-- <div class="sidebarRight" >
        <div :class="selPanel==='1'? 'slcdItem':'nrmlItem'" @click="expandPanel('1')">
          <div  class="edgeUp">
            <div class="edgeUp1"></div>
          </div>
          <div class="base">
            <i><font-awesome-icon icon="fas fa-cubes-stacked" /></i>
          </div>
          <div  class="edgeDn">
            <div class="edgeDn1"></div>
          </div>
        </div>

        <div :class="selPanel==='2'? 'slcdItem':'nrmlItem'" @click="expandPanel('2')">
          <div  class="edgeUp">
            <div class="edgeUp1"></div>
          </div>
          <div class="base">
            <i><font-awesome-icon icon="fas fa-tag" /></i>
          </div>
          <div  class="edgeDn">
            <div class="edgeDn1"></div>
          </div>
        </div>



      </div> -->

      <!-- <div v-if="isPanelExpanded && selPanel==='1'" class="settingPanel">
        <div class="header">
          <div class="title">
            <i>Controllers</i>
          </div>
          <div class="button" @click="collapsePanel">
            <i ><font-awesome-icon icon="fas fa-xmark" /></i>
          </div>
        </div>
        <div class="body">
          <div class="add" @click="createController">
            <span>add new controller</span>
            <i><font-awesome-icon icon="fas fa-plus" /></i>
          </div>
          <div v-for="(controller,i) in controllers" :key="i" class="item"  >
            <span>{{controller.title}}</span>
            <i @click="addController(controller._id)"><font-awesome-icon icon="fas fa-plus-square" /></i>
            <i><font-awesome-icon icon="fas fa-pencil-square" /></i>
            <i><font-awesome-icon icon="fas fa-xmark-square" /></i>
          </div>
        </div>
      </div>
      <div v-if="isPanelExpanded && selPanel==='2'" class="settingPanel">
        <div class="header">
          <div class="title">
            <i>tags</i>
          </div>
          <div class="button" @click="collapsePanel">
            <i ><font-awesome-icon icon="fas fa-xmark" /></i>
          </div>
        </div>
        <div class="body">
          <div v-for="(tag,i) in tags" :key="i" class="item"  >
            <span>{{tag.title}}</span>
            <i @click="addTag(tag._id)"><font-awesome-icon icon="fas fa-plus-square" /></i>
          </div>
        </div>
      </div> -->

      <!-- :beforeDeleteNode="beforeDeleteNode" -->
      <VueDiagramEditor
        dir="ltr"
        ref="diagram"
        :height="pageContainerHeight"
        class="editor"
        :node-color="nodeColor"
      >
        <pre
          slot="node"
          slot-scope="{ node }"
          class="node-slot"
          @click="nodeModal(node)"
        >

          <!-- <i @click="nodeModal(node)" style="cursor:pointer;"><font-awesome-icon icon="fas fa-pencil"/></i> -->

          <!-- <component   :is='dashComponents[nodeType(node)]' plotId = "0" :data = "node.data.pdata"  :editable="editable" :resized="resized"/> -->

          <!-- <div >{{ nodeType(node) }} </div> -->
          <div>{{ format(node) }} </div>
          <!-- <i @click="updataNode(node)" style="cursor:pointer;"><font-awesome-icon icon="fas fa-pencil"/></i> -->

        </pre>
      </VueDiagramEditor>
    </div>

    <addController></addController>
    <editNode></editNode>
    <b-modal
      id="modal-node-front"
      hide-footer
      hide-header
      centered
      :size="visibleNodeWidth"
      :body-class="nodeModalClass"
    >
      <component
        v-if="visibleNode"
        :is="dashComponents[nodeType(visibleNode)]"
        plotId="0"
        :data="visibleNode.data.pdata"
        :editable="editable"
        :resized="resized"
      />
    </b-modal>
  </div>
</template>

<script>
import VueDiagramEditor from "vue-diagram-editor";
import "vue-diagram-editor/dist/vue-diagram-editor.css";
import addController from "./controlEditorModal.vue";
import editNode from "./controlEditorNode.vue";

import widgetCtrlLighting from "./widgetCtrlLighting.vue";
import widgetCtrlSolarTracker from "./widgetCtrlSolarTracker.vue";
import widgetCtrlThermostat from "./widgetCtrlThermostat.vue";
import widgetCtrlRoofWindow from "./widgetCtrlRoofWindow.vue";
import widgetCtrlGasHeater from "./widgetCtrlGasHeater.vue";
import widgetCtrlCirculationFan from "./widgetCtrlCirculationFan.vue";
import widgetCtrlUnitHeater from "./widgetCtrlUnitHeater.vue";
import widgetCtrlFogger from "./widgetCtrlFogger.vue";
import widgetCtrlExhaustFan from "./widgetCtrlExhaustFan.vue";
import widgetCtrlCooler from "./widgetCtrlCooler.vue";
import widgetCtrlGrowLight from "./widgetCtrlGrowLight.vue";
import widgetCtrlIrrigation from "./widgetCtrlIrrigation.vue";
import widgetCtrlSolarCalculator from "./widgetCtrlSolarCalculator.vue";
import widgetCtrlVPD from "./widgetCtrlVPD.vue";

import widgetCtrlFanPad from "./widgetCtrlFanPad.vue";
import widgetCtrlHeatExchangerTank from "./widgetCtrlHeatExchangerTank.vue";
import widgetCtrlPumpSwitcherA from "./widgetCtrlPumpSwitcherA.vue";
import widgetCtrlPumpSwitcherB from "./widgetCtrlPumpSwitcherB.vue";
import widgetCtrlDieselFuelLevel from "./widgetCtrlDieselFuelLevel.vue";
import widgetCtrlWaterLevel from "./widgetCtrlWaterLevel.vue";
import widgetCtrlUnderFloorHeatingCooling from "./widgetCtrlUnderFloorHeatingCooling.vue";
import widgetCtrlSuggestion from "./widgetCtrlSuggestion.vue";
import widgetCtrlSuggestion2 from "./widgetCtrlSuggestion2.vue";

export default {
  name: "GmailSidebar",
  components: {
    VueDiagramEditor,
    addController,
    editNode,
    widgetCtrlRoofWindow,
    widgetCtrlGasHeater,
    widgetCtrlCirculationFan,
    widgetCtrlUnitHeater,
    widgetCtrlFogger,
    widgetCtrlExhaustFan,
    widgetCtrlCooler,
    widgetCtrlGrowLight,
    widgetCtrlIrrigation,
    widgetCtrlThermostat,
    widgetCtrlLighting,
    widgetCtrlSolarTracker,
    widgetCtrlSolarCalculator,
    widgetCtrlVPD,

    widgetCtrlFanPad,
    widgetCtrlHeatExchangerTank,
    widgetCtrlPumpSwitcherA,
    widgetCtrlPumpSwitcherB,
    widgetCtrlDieselFuelLevel,
    widgetCtrlWaterLevel,
    widgetCtrlUnderFloorHeatingCooling,
    widgetCtrlSuggestion,
    widgetCtrlSuggestion2
  },
  data() {
    return {
      isExpanded: false,
      isPanelExpanded: false,
      selPanel: null,
      pageContainerHeight: 100,
      controllers: [],
      tags: [
        {
          _id: "input-tag",
          title: "input tag",
          size: {
            width: 100,
            height: 30
          },
          ports: {
            out: {
              port1: "data"
            },
            in: {}
          },
          data: {
            color: "white",
            data: {}
          }
        },
        {
          _id: "output-tag",
          title: "output tag",
          size: {
            width: 100,
            height: 30
          },
          ports: {
            in: {
              port1: "data"
            },
            out: {}
          },
          data: {
            color: "white",
            data: {}
          }
        }
      ],
      nodeCnt: 2,
      nodes: {},
      links: {},

      dashComponents: {
        vpdCalculatorController: "widgetCtrlVPD",
        solarCalculatorController: "widgetCtrlSolarCalculator",
        solarTrackerController: "widgetCtrlSolarTracker",
        lightingController: "widgetCtrlLighting",
        thermostatController: "widgetCtrlThermostat",
        roofWindowController: "widgetCtrlRoofWindow",
        gasHeaterController: "widgetCtrlGasHeater",
        circulationFanController: "widgetCtrlCirculationFan",
        unitHeaterController: "widgetCtrlUnitHeater",
        foggerController: "widgetCtrlFogger",
        exhaustFanController: "widgetCtrlExhaustFan",
        coolerController: "widgetCtrlCooler",
        growLightController: "widgetCtrlGrowLight",
        irrigationController: "widgetCtrlIrrigation",

        fanPadController: "widgetCtrlFanPad",
        heatExchangerTankController: "widgetCtrlHeatExchangerTank",
        pumpSwitcherA: "widgetCtrlPumpSwitcherA",
        pumpSwitcherB: "widgetCtrlPumpSwitcherB",
        dieselFuelLevelController: "widgetCtrlDieselFuelLevel",
        waterLevelController: "widgetCtrlWaterLevel",
        underFloorHeatingCoolingController:
          "widgetCtrlUnderFloorHeatingCooling",
        suggestionController: "widgetCtrlSuggestion",
        suggestionController2: "widgetCtrlSuggestion2"
      },
      editable: false,
      resized: 0,
      visibleNode: null,
      visibleNodeHeight: "modal-node-sm",
      visibleNodeWidth: "md",

      dict: {
        "Gas Heater Controller": {
          per: "کنترلر بخاری پرتابی",
          eng: "Gas Heater Controller"
        },
        "Grow Light Controller": { per: "کنترلر نورمصنوعی", eng: "Grow Light Controller" },
        "Fogger Controller": { per: "کنترلر مه پاش", eng: "Fogger Controller" },
        "Unit Heater Controller": { per: "کنترلر شوفاژ", eng: "Unit Heater Controller" },
        "Thermostat Controller": { per: "کنترلر ترموستات", eng: "Thermostat Controller" },
        "Roof Window Controller": { per: "کنترلر پنجره سقفی", eng: "Roof Window Controller" },
        "Irrigation Controller": { per: "کنترلر آبیاری", eng: "Irrigation Controller" },
        "Fan Pad Controller": { per: "کنترلر فن و پد", eng: "Fan Pad Controller" },
        "Exhaust Fan Controller": { per: "کنترلر فن تخلیه رطوبت", eng: "Exhaust Fan Controller" },
        "Cooler Controller": { per: "کنترلر سرمایشی", eng: "Cooler Controller" },
        "Circulation Fan Controller": { per: "کنترلر فن سیرکوله", eng: "Circulation Fan Controller" },
        "Lighting Controller": { per: "کنترلر روشنایی", eng: "Lighting Controller" },
        "input tag": { per: "تگ ورودی", eng: "input tag" },
        "output tag": { per: "تگ خروجی", eng: "output tag" },
        "Under Floor Heating Cooling Controller": {
          per: "کنترلر سرمایش گرمایش کف",
          eng: "Under Floor Heating Cooling Controller"
        },
        "vpd Calculator Controller": { per: "وی پی دی", eng: "vpd Calculator Controller" },
        "Suggestion Controller2": { per: "پیشنهاد دهنده فولاد", eng: "Suggestion Controller" },
        "Suggestion Controller": { per: "پیشنهاد دهنده فولاد", eng: "Suggestion Controller" },


        dir: { eng: "ltr", per: "rtl" },
        newCont: { eng: "add new controller", per: "افزودن کنترلر" },
        title: { eng: "Rule manager", per: "مدیریت دستورات" },
        tab1: { eng: "data", per: "داده" },
        tab2: { eng: "structure", per: "ساختار" },
        tab3: { eng: "controller", per: "رخدادها" },

        wbname: { eng: "workbench name", per: "نام میزکار" },
        wbowner: { eng: "workbench owner", per: "مالک میزکار" },
        wbresources: { eng: "workbench resources", per: "منابع میزکار" },

        regmsg: { eng: "Don't have an account?", per: "حساب کاربری ندارید؟" },
        register: { eng: "Register now", per: "ثبت نام" },
        errCaptcha: {
          eng: "invalid captcha",
          per: "کد امنیتی وارد شده اشتباه می باشد"
        },
        errUserPass1: {
          eng: "invalid username or password",
          per: "نام کاربری یا کلمه عبور اشتباه است"
        },
        errUserPass2: {
          eng: "check your keyboard language",
          per: "• وضعيت زبان(En/FA)سيستم خود را كنترل كنيد"
        },
        errUserPass3: {
          eng: "the password is caps sensitive",
          per: "• پسورد به حروف بزرگ و كوچك حساس است"
        }
      }
    };
  },
  methods: {
    sendIdForSse(data, type) {
      const infos = [];
      if (type == "node") {
        Object.entries(data).forEach((node) => {
          if (typeof node[1].data.data == "object") {
            infos.push(node[1].data.data._id);
          } else
            infos.push(node[1].data.data);
        });
      } else {
        Object.entries(data).forEach(link => {
          infos.push(link[1].id);
        });
      }

      this.$store.dispatch("data/sendDataForSSE", { id: infos });
    },
    init() {

      if (Object.keys(this.nodes).length > 0) {
        this.$refs.diagram.setModel({
          nodes: this.nodes,
          links: this.links
        });
      }

      // var dids=[]

      // Object.keys(this.nodes).forEach(key =>{
      //   if ((this.nodes[key].type != 'input-tag')&&(this.nodes[key].type != 'output-tag')){
      //     dids.push(this.nodes[key].data.data)
      //   }
      // })
      // this.getData(dids);
    },
    format(node) {
      if (node.title === "input tag" || node.title === "output tag")
        return node.data.data ? node.data.data.name : "without data";
      else return node.data.title ? node.data.title : "noname";

      // return node.data.title?node.data.title:'noname';
    },
    nodeType(node) {
      return this.toCamelCase(node.title);
      // if (node.title==='input tag' || node.title==='output tag')
      //   return null;
      // else
      //   return this.toCamelCase(node.data.value.type);
    },
    nodeColor(node) {
      return node.data.color;
    },
    // nodePulsable(node) {
    //   return node.coordinates.y > 200;
    // },

    expandPanel(icon) {
      this.isPanelExpanded = true;
      this.selPanel = icon;
      // this.$emit('expand', icon);
    },
    collapsePanel() {
      this.isPanelExpanded = false;
      this.selPanel = null;
      // this.$emit('collapse', icon);
    },
    expandSidebar() {
      this.isExpanded = true;
      // this.$emit('expand', icon);
    },
    collapseSidebar() {
      this.isExpanded = false;
      // this.$emit('collapse', icon);
    },
    pageHeight() {
      this.pageContainerHeight = this.$refs.pageContainer.clientHeight;
    },
    updataNode(node) {
      if (node.title === "input tag" || node.title === "output tag")
        this.$store.dispatch("chpge/parent2child", {
          task: "updateTag",
          type: "Edit",
          data: node
        });
      else
        this.$store.dispatch("chpge/parent2child", {
          task: "updateCont",
          type: "Edit",
          data: node
        });
      this.$root.$emit("bv::show::modal", "modal-editNode");
    },
    nodeModal(node) {
      if (node.title === "input tag" || node.title === "output tag") {
        this.$store.dispatch("chpge/parent2child", {
          task: "updateTag",
          type: "Edit",
          data: node
        });
        this.$root.$emit("bv::show::modal", "modal-editNode");
      } else {
        this.visibleNode = node;
        this.visibleNodeHeight = "md";
        this.visibleNodeWidth = "md";
        this.$root.$emit("bv::show::modal", "modal-node-front");
      }
    },

    addController(id) {
      this.nodeCnt++;
      var nn = "node-" + this.nodeCnt;

      var indx = this.controllers.findIndex((x) => x._id === id);
      if (indx != -1) {
        var newNode = {
          id: "node-" + this.nodeCnt,
          type: this.controllers[indx]._id,
          title: this.controllers[indx].title,
          size: this.controllers[indx].size,
          portsIn: this.controllers[indx].ports.in,
          portsOut: this.controllers[indx].ports.out,
          data: this.controllers[indx].data
        };

        this.$store.dispatch("chpge/parent2child", {
          task: "updateCont",
          type: "Add",
          data: newNode
        });
        this.$root.$emit("bv::show::modal", "modal-editNode");

        // this.$refs.diagram.addNode(newNode)
        // this.nodesHandler({type:'create', data:newNode})
      }
    },
    addTag(id) {
      this.nodeCnt++;
      var nn = "node-" + this.nodeCnt;

      var indx = this.tags.findIndex((x) => x._id === id);
      if (indx != -1) {
        // console.log('addCompenet: ', id, nn,indx)

        var newNode = {
          id: "node-" + this.nodeCnt,
          type: this.tags[indx]._id,
          title: this.tags[indx].title,
          size: this.tags[indx].size,
          portsIn: this.tags[indx].ports.in,
          portsOut: this.tags[indx].ports.out,
          data: this.tags[indx].data
        };

        this.$store.dispatch("chpge/parent2child", {
          task: "updateTag",
          type: "Add",
          data: newNode
        });
        this.$root.$emit("bv::show::modal", "modal-editNode");

        // this.$refs.diagram.addNode(newNode)
        // this.nodesHandler({type:'create', data:newNode})
      }
    },
    createController() {
      this.$root.$emit("bv::show::modal", "modal-addController");
      // this.$store.dispatch('chpge/changePage','controlEditor')
    },
    getControlBlocks() {
      this.$store.dispatch("data/getControlBlocks").then(
        (data) => {
          this.controllers = data.data;
          // data.data.forEach(i => {
          //   console.log(JSON.stringify(i.title));
          // });
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    nodesHandler(data) {
      this.$store.dispatch("data/nodeHandler", data).then(
        (data) => {
          // console.log(JSON.stringify(data.data))
          this.nodes = data.data;
          this.sendIdForSse(this.nodes, "node");

          Object.keys(this.nodes).forEach((key) => {
            if (
              this.nodes[key].type != "input-tag" &&
              this.nodes[key].type != "output-tag"
            ) {
              this.nodes[key].data["pdata"] = {
                datas: [
                  {
                    type: "complex",
                    _id: this.nodes[key].data.data,
                    name: this.nodes[key].data.title
                  }
                ],
                attribute: { title: this.nodes[key].data.title }
              };
            }
          });


          this.init();
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // alert(this.message);
          //
          // this.successful = false;
        }
      );
    },
    linksHandler(data) {
      this.$store.dispatch("data/linkHandler", data).then(
        (data) => {
          this.links = data.data;
          // this.sendIdForSse(this.links, "link");
          this.init();
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          // this.successful = false;
        }
      );
    },
    getData(dids) {
      if (dids.length > 0) {
        this.$store.dispatch("panel/getData", { id: dids }).then(
          (datas) => {
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    toCamelCase(inputString) {
      // Split the input string into words
      const words = inputString.split(/\s+/);

      // Format each word to start with a lowercase letter
      const camelCaseString = words
        .map((word, index) => {
          if (index === 0) {
            // Capitalize the first word
            return word.charAt(0).toLowerCase() + word.slice(1);
          } else {
            // Format subsequent words to start with an uppercase letter
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          }
        })
        .join("");

      return camelCaseString;
    }

    // beforeDeleteNode() {
    //   return false;
    //   // console.log('before delete')
    //   // this.$bvModal.msgBoxConfirm('Please confirm that you want to delete it.')
    //   // .then(value => {
    //   //   console.log('before delete',value)
    //   //   return value
    //   // })
    // }
  },
  mounted() {
    this.pageHeight();
    this.getControlBlocks();
    this.nodesHandler({ type: "get" });
    this.linksHandler({ type: "get" });
    // this.init()
    this.$refs.diagram.$on("updated-node", (node) => {
      this.nodesHandler({ type: "update", id: node.id, data: node });
    });
    this.$refs.diagram.$on("deleted-node", (node) => {
      this.nodesHandler({ type: "delete", id: node });
    });
    this.$refs.diagram.$on("deleted-link", (link) => {
      this.linksHandler({ type: "delete", id: link });
    });
    this.$refs.diagram.$on("created-link", (link) => {
      this.linksHandler({ type: "create", data: link });
    });
  },
  computed: {
    updateCB() {
      return this.$store.state.chpge.child2parent;
    },
    updatedData() {
      return this.$store.state.panel.data;
    },
    nodeModalClass() {
      const classes = {};
      classes["modal-node-" + this.visibleNodeHeight] = true;
      return classes;
    },
    workbench() {
      return this.$store.state.data.workbench.activeWorkbench;
    },
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  watch: {
    updateCB(newValue, oldValue) {
      if (newValue.task === "updateCB") {
        // console.log('update toolbox')
        this.getControlBlocks();
      } else if (newValue.task === "updateCont") {
        if (newValue.type === "Edit") {
          this.nodesHandler({
            type: "update",
            id: newValue.data.id,
            data: newValue.data
          });
        } else if (newValue.type === "Add") {
          this.nodesHandler({ type: "create", data: newValue.data });
        }
      } else if (newValue.task === "updateTag") {
        if (newValue.type === "Edit") {
          this.nodesHandler({
            type: "update",
            id: newValue.data.id,
            data: newValue.data
          });
        } else if (newValue.type === "Add") {
          this.nodesHandler({ type: "createtags", data: newValue.data });
        }
      }
    },
    updatedData(newValue) {
      for (var data of newValue) {
        Object.keys(this.nodes).forEach((key) => {
          if (
            this.nodes[key].type != "input-tag" &&
            this.nodes[key].type != "output-tag" &&
            this.nodes[key].data.data === data.id
          ) {
            // this.nodes[key].data['value'] = data.value

            // this.nodes[key]['pdata'] = {
            //   datas:[{type: this.nodes[key].title, _id:this.nodes[key].data.data, name:this.nodes[key].data.title}],
            //   attribute:{title:this.nodes[key].data.title}
            // }

          }
        });
      }

      // if (this.myData.datas.length > 0){
      //   for (var Data of newValue){
      //     console.log('**************-------------******************0',Data,this.myData.datas[0]._id)
      //     if (JSON.stringify(Data.id) === JSON.stringify(this.myData.datas[0]._id)){
      //       console.log('**************-------------******************1',Data.value)
      //       this.myData = {...this.data,...Data.value}
      //       if (!this.myData['parameters']){
      //         this.myData['parameters'] = {
      //           schedules:[],
      //           selectedSchedule:'',
      //           activeEvent:'',
      //           settings:[],
      //           controlMode:'manual',
      //           automatic:{},
      //           manual:{},
      //           status:{},
      //         }
      //       }
      //       console.log('**************-------------******************2',this.myData.parameters)

      //       if (this.myData.parameters.controlMode === 'automatic'){
      //         this.updateAutoPanel();
      //         this.updateStatus();
      //       }else if (this.myData.parameters.controlMode === 'schedule'){
      //         this.updateSchPanel();
      //         this.updateStatus();
      //       }else if (this.myData.parameters.controlMode === 'manual'){
      //         this.updateManPanel();
      //       }

      //       this.dataReady = true;
      //     }
      //   }
      // }
    },
    "workbench": {
      handler() {
        this.getControlBlocks();
        this.nodesHandler({ type: "get" });
        this.linksHandler({ type: "get" });
        // this.init()
        this.$refs.diagram.$on("updated-node", (node) => {
          this.nodesHandler({ type: "update", id: node.id, data: node });
        });
        this.$refs.diagram.$on("deleted-node", (node) => {
          this.nodesHandler({ type: "delete", id: node });
        });
        this.$refs.diagram.$on("deleted-link", (link) => {
          this.linksHandler({ type: "delete", id: link });
        });
        this.$refs.diagram.$on("created-link", (link) => {
          this.linksHandler({ type: "create", data: link });
        });
      }, deep: true
    },
  },
  created() {
    window.addEventListener("resize", this.pageHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.pageHeight);
  }
};
</script>
<style scoped src="@/assets/styles/theme.css"></style>
<style>
.modal-node-sm {
  height: 30vh;
  padding: 0 !important;
}

.modal-node-md {
  height: 65vh;
  padding: 0 !important;
}

.modal-node-lg {
  height: 75vh;
  padding: 0 !important;
}

.modal-node-xl {
  height: 90vh;
  padding: 0 !important;
}
</style>
<style scoped>
.page_container {
  width: 100%;
  min-height: calc(100vh - 152px);
  position: relative;
  /* background-color: #3b5775; */
}

.sidebar {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 300px;
  background-color: #f1f1f1;
  border-right: 1px solid #252424;
  border-radius: 0 1rem 1rem 0;
  z-index: 1;
  transition: 0.2s;
}

.sidebar.expanded {
  width: 200px;
  border-radius: 0 1rem 1rem 0;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  border-radius: 50%;
  cursor: pointer;
  /* transition: all 0.2s ease-in-out; */
}

.iconexpanded {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  /* transition: all 0.2s ease-in-out; */
}

.iconexpanded:hover {
  background-color: #ddd;
}

.icon:hover {
  background-color: #ddd;
}

.iconexpanded i {
  font-size: 20px;
  color: #333;
  width: 30%;
}

.iconexpanded span {
  width: 70%;

  font-size: 20px;
  color: #1170af;
  text-align: left;
}

.icon i {
  font-size: 20px;
  color: #333;
  width: 100%;
}

.icon span {
  width: 160px;
  height: 40px;
  font-size: 20px;
  color: #af1111;
}

.tooltip {
  /* position: absolute;
  top: -20px;
  left: 40px; */
  /* display: none; */
  padding: 5px;
  background-color: #333;
  color: #d62121;
  border-radius: 5px;
  font-size: 14px;
}

/* .icon:hover .tooltip {
  display: block;
} */

.sidebarRight {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50px;
  height: 100%;
  background-color: #4f6883;
  z-index: 1;
  transition: 0.2s;
}

.sidebarRight .nrmlItem .base {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  /* background-color: #db4242; */
  margin-left: 5px;
  border-radius: 50%;
  cursor: pointer;
  color: #fff;
  /* transition: all 0.2s ease-in-out; */
}

.sidebarRight .nrmlItem .base:hover {
  background-color: #fff;
  color: #4f6883;
}

.sidebarRight .slcdItem .base {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  /* margin-bottom: 10px; */
  margin-right: 10px;
  border-radius: 0 50% 50% 0;
  cursor: pointer;
  background-color: #e3e8f1;
  color: #1b89b4;
  /* transition: all 0.2s ease-in-out; */
}

.sidebarRight .slcdItem .edgeUp {
  width: 15px;
  height: 15px;
  background-color: #e3e8f1;
}

.sidebarRight .slcdItem .edgeUp1 {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 0 0 0 100%;
  background-color: #4f6883;
}

.sidebarRight .slcdItem .edgeDn {
  width: 15px;
  height: 15px;
  background-color: #e3e8f1;
}

.sidebarRight .slcdItem .edgeDn1 {
  width: 15px;
  height: 15px;
  border-radius: 100% 0 0 0;
  background-color: #4f6883;
}

.settingPanel {
  position: absolute;
  top: 7px;
  bottom: 3px;
  right: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  width: 300px;

  background-color: #e3e8f1;

  border-radius: 1rem;
  z-index: 10000;
  transition: 0.2s;
}

.settingPanel .header {
  display: flex;
  align-items: center;
  justify-content: left;

  height: 6%;
  padding: 5px;
  width: 96%;
  margin-top: 5px;
  /* margin-bottom: 10px;
  border-radius: 50%; */
  border: 1px solid #a9aaac;
  border-radius: inherit;
  /* background-color: #12419777; */
  /* transition: all 0.2s ease-in-out; */
}

.settingPanel .header .title {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: left;
  height: 100%;
  font-weight: bold;
  font-size: large;
  color: #4e81df;
}

.settingPanel .header .button {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.settingPanel .button:hover {
  color: #ddd;
}

.settingPanel .body {
  /* display: flex; */
  /* align-items: flex-start;
  justify-content:flex-start;
  flex-direction: column; */
  height: 91%;
  width: 96%;
  margin-bottom: 5px;
  padding: 5px;
  /* margin: 5px; */
  /* margin-bottom: 10px;
  border-radius: 50%; */
  border-radius: inherit;
  background-color: #4e81df;
  overflow: auto;
  /* transition: all 0.2s ease-in-out; */
}

.settingPanel .body .add {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 5%;
  padding: 10px;
  width: 100%;
  /* margin-top: 5px; */
  /* margin-bottom: 10px;
  border-radius: 50%; */
  border-radius: inherit;
  background-color: #e1e5eb;
  /* transition: all 0.2s ease-in-out; */
}

.settingPanel .body .add i:hover {
  color: #3b63a0;
  cursor: pointer;
}

.settingPanel .body .item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 9%;
  padding: 10px;
  width: 100%;
  margin-top: 5px;
  /* margin-bottom: 10px;
  border-radius: 50%; */
  border-radius: inherit;
  background-color: #3b63a0;
  color: #ddd;

  /* transition: all 0.2s ease-in-out; */
}

.settingPanel .body .item span {
  width: 70%;
}

.settingPanel .body .item i {
  cursor: pointer;
  font-size: large;
}

.settingPanel .body .item i:hover {
  color: rgb(0, 255, 255);
}

.node-slot {
  width: 100%;
  height: 100%;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  border: 2px solid #ffffff;
  background-color: cornflowerblue;
  border-radius: 5px;
}
</style>

<style>
.tag-dropdown .dropdown-menu {
  /* text-align: inherit; */
  min-width: 20rem !important;
  margin-top: 5px;
  /* padding: 0; */
  border-radius: 10px;
}

.tag-dropdown .dropdown-toggle {
  height: 100%;
}

.block-dropdown .dropdown-menu {
  /* text-align: inherit; */
  min-width: 20rem !important;
  margin-top: 5px;
  /* padding: 0; */
  border-radius: 10px;
}

.block-dropdown .dropdown-toggle {
  height: 100%;
}
</style>
