<template>
  <div class="yekan">
    <b-modal
      id="modal-data-picker"
      size="lg"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      content-class="shadow"
      @shown="initModal"
      :dir="dict.dir[lang]"
      @hidden="resetModal"
      hide-footer
    >
      <template #modal-header>
        <h5>{{ modalTitle[lang] }}</h5>
        <i
        >
          <font-awesome-icon
            icon="fas fa-xmark"
            class="close"
            @click="handleClose"
          />
        </i>
      </template>

      <div class="yekan">
        <b-container fluid>
          <b-row align-h="between" class="mb-2">
            <b-col lg="4" sm="6" md="5" class="my-1">
              <b-form-group label-for="filter-input">
                <b-input-group size="sm">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                    >Clear
                    </b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-table
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            stacked="md"
            show-empty
            small
            hover
            @filtered="onFiltered"
            @row-clicked="onRowClicked"
          >
            <template #cell(detail)="row">
              <b-icon-chevron-bar-down
                v-if=" (row.item.type!='Number' && row.item.type!='String' && row.item.type!='Boolean'&& row.item.type!='freeObject'&& row.item.type!='freeArray' ) "
                @click.stop="row.toggleDetails" class="m-1" />
            </template>
            <template #cell(name)="row">
              <p
                v-if="(row.item.type!='Number' && row.item.type!='String' && row.item.type!='Boolean' && row.item.type!='freeObject'&& row.item.type!='freeArray')"
                class="m-1">
                <b>{{ row.item.name }}</b></p>
              <p v-else class="m-1">{{ row.item.name }}</p>
            </template>
            <template #row-details="row">
              <div style="background: rgba(176,176,255,0.65)" class="p-0">
                <div @click.prevent="onRowClicked(item,row)" v-for="(item,index) in row.item.value" :key="index"
                     class="d-flex mx-auto my-0 py-1">
                  <p class="mx-auto mb-0"><span class="mx-1">&#8226;</span>{{ item.name }}</p>
                  <p class="mx-auto mb-0">{{ item.type }}</p>
                </div>
              </div>
            </template>
          </b-table>

          <b-row align-h="between">
            <b-col lg="3" sm="4" md="4" class="my-1">
              <b-form-group label-for="per-page-select">
                <b-form-select
                  style="width: 100%"
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>

            <b-col lg="7" sm="8" md="8" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "dataPickerModal",
  data() {
    return {
      picked: false,
      selected: null,
      to: "",
      type: "data",
      items: [],
      fields: [
        {
          key: "detail",
          label: "",
          _showDetails: true
        },
        { key: "type", label: "type", sortable: true, sortDirection: "asc" },
        { key: "name", label: "name", sortable: true }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [{ value: 5, text: "5 rows" },
        { value: 10, text: "10 rows" },
        { value: 15, text: "15 rows" },
        { value: 100, text: "Show a lot" }],
      sortBy: "name",
      sortDesc: true,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      filterData: {},
      modalTitle: { per: "انتخاب داده", eng: "Pick Data" },
      dict: {
        dir: { per: "انتخاب داده", eng: "Pick Data" }
      }

    };
  },
  computed: {
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    dataPickerCaller() {
      return this.$store.state.chpge.dataPickerCaller;
    },
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  watch: {
    type(newValue) {
      if (newValue === "config") {
        this.getAllControlNodes();
      } else if (newValue === "data") {
        this.getAllData();
      }

    },
    parent2child(newValue) {
      if (newValue.task === "pickedData") {
        this.to = newValue.from;
        this.filterData = newValue.filter;
      }
    },
    dataPickerCaller(newValue) {
      if (newValue.task === "pickedData") {
        this.to = newValue.from;
        this.filterData = newValue.filter;
      }
    }
  },
  methods: {
    initModal: function() {
      this.getAllData();
    },
    resetModal: function() {

      if (!this.picked) {
        this.$store.dispatch("chpge/child2child", {
          task: "pickedData",
          to: this.to,
          item: {}
        });
      }
      this.picked = false;

      this.items = [];
    },
    getAllData() {
      this.$store.dispatch("data/getAllData", this.filterData).then(
        data => {
          // console.log("all data: ",JSON.stringify(data))
          this.items = data.newmatches;
          this.totalRows = this.items.length;
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    getAllControlNodes() {
      this.$store.dispatch("data/nodeHandler", { type: "get" }).then(
        data => {
          var items = [];
          Object.entries(data.data).forEach(item => {
            items.push(items.push({ _id: item[1].id, name: item[1].data.title }));
          });
          this.items = JSON.parse(JSON.stringify(items));
          this.totalRows = this.items.length;
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onRowClicked(item, row, event) {
      let a;
      let objName;
      this.items.forEach((val) => {
        if (val.type != "freeObject" && val.type != "freeArray") {
          if (typeof val.value == "object") {
            if (val.value.filter((v) => v.id == item.id).length > 0) {
              a = row.item._id;
              objName = row.item.name;
            }
          }
        }
      });

      if (this.filterData["withChild"] == false) {
        if (item.type != "Object") {
          this.picked = true;
          this.$store.dispatch("chpge/child2child", {
            task: "pickedData",
            to: this.to,
            item: {
              type: this.type,
              id: a != undefined ? a + "_" + item.id : item.id || item._id,
              name: objName != undefined ? objName + "_" + item.name : item.name || item.name,
              dataType: item.type
            }
          });
          this.$bvModal.hide("modal-data-picker");
        }
      } else {
        this.picked = true;
        this.$store.dispatch("chpge/child2child", {
          task: "pickedData",
          to: this.to,
          item: {
            type: this.type,
            id: item.id,
            name: objName != undefined ? objName + "_" + item.name : item.name || item.name,
            dataType: item.type
          }
        });
        this.$bvModal.hide("modal-data-picker");
      }
    },
    handleClose() {
      this.$bvModal.hide("modal-data-picker");
    }
  }
};
</script>

<style scoped>
.close {
  font-size: 20px;
  color: #dc3545;
  cursor: pointer;
}

.close :hover {
  color: #9b9b9b;
}
</style>