<template>
  <div ref="mainCont" class="card-body p-1 d-flex justify-content-center align-items-center"
       style="height:calc(100%); width: 100%">
    <div class="col-md-12 px-3 col-12 d-flex gap-3">
      <ejs-textbox
        type="text"
        :enableRtl="lang == 'per'"
        floatLabelType="Auto"
        :showClearButton="true"
        :placeholder="myData.attribute.title"
        v-model="myData.attribute.value"
      ></ejs-textbox>
      <span v-if="myData.datas.length>0" @click="editValuesData" class="e-icons e-check mt-5"></span>
    </div>
    <b-modal
      :id="plotId"
      size="lg"
      centered
      :ok-title="dict.ok[lang]"
      :cancel-title="dict.cancel[lang]"
      :dir="dict.dir[lang]"
      body-class="my-body-class"
      hide-header-close
      :title="dict.modalTitle[lang]"
      @shown="initModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <div>
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li v-for="(tab, index) in tabList" :key="index" class="nav-item" @click="activeTab = tab">
              <p class="nav-link"
                 :class="{ active: activeTab === tab }"
                 :id=tab
                 data-toggle="tab"
                 role="tab"
                 aria-controls="tab1"
                 aria-selected="true"
              >
                {{ dict[tab][lang] }}
              </p>
            </li>
          </ul>
        </div>
        <div class="card-body h-100 p-3 " style="overflow: hidden;">
          <transition name="slide-fade" mode="out-in">
            <div class="tab-content h-100" :key="activeTab">
              <div v-if="activeTab === 'data'" class="h-100">
                <div class="d-flex align-items-center">
                  <ejs-textbox
                    :placeholder="dict.pickData[lang]"
                    :enableRtl="lang == 'per'"
                    floatLabelType="Auto"
                    v-model="selectedData.name"
                    :showClearButton="true"
                  ></ejs-textbox>
                  <b-button @click="pickData" class="btn-sm" variant="primary"
                  ><span class="e-icons e-edit-6"></span>
                  </b-button>
                </div>
              </div>
              <div v-if="activeTab === 'basic'" class="h-100">
                <div class="col-md-6 col-12">
                  <ejs-textbox
                    type="text"
                    :placeholder="dict.setTitle[lang]"
                    :enableRtl="lang == 'per'"
                    floatLabelType="Auto"
                    :showClearButton="true"
                    v-model="myData.attribute.title"
                  >
                  </ejs-textbox>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </b-modal>

    <dataPickerModal :plotId="plotId" />
  </div>
</template>

<script>
import dataPickerModal from "@/components/widgetPickDataModal.vue";

export default {
  name: "widgetInputText",
  components: { dataPickerModal },
  props: {
    plotId: String,
    data: Object,
    editable: Boolean,
    resized: Number,
    setting: Boolean
  },
  data() {
    return {
      attr: {
        name: "",
        title: "xzcxz"
      },
      tabList: ["data", "basic"],
      activeTab: "data",
      dict: {
        cancel: { eng: "cancel", per: "لغو" },
        ok: { eng: "ok", per: "تایید" },
        pickData: { per: "انتخاب داده", eng: "Pick Data" },
        setTitle: { per: "عنوان فیلد ورودی", eng: "input title" },
        modalTitle: { per: "ویرایش ویجت", eng: "Edit widget" },
        dir: { eng: "ltr", per: "rtl" },
        data: { eng: "data", per: "داده" },
        basic: { eng: "basic", per: "تنظیمات پایه" }
      },
      myData: {
        datas: [],
        attribute: {
          name: "",
          title: "text",
          value: ""
        }
      },
      selectedData: {
        id: "",
        name: "",
        type: "",
        dataType: ""
      }
    };
  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    },
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    updatedData() {
      return this.$store.state.panel.data;
    }
  },
  watch: {
    updatedData(newValue) {
      for (var Data of newValue) {
        if ((this.myData.datas[0]) && (Data.id == this.myData.datas[0]._id)) {
          this.myData.attribute.value = Data.value;
        }
      }
    },
    setting(newValue) {
      if (newValue)
        this.$bvModal.show(this.plotId);
    },
    child2parent(newValue) {
      if (newValue.to == this.plotId) {
        this.myData.datas[0] = newValue.item;
        this.selectedData = newValue.item;
      }
    }
  },
  methods: {
    editValuesData() {
      this.$store.dispatch("data/updateData", {
        _id: this.data["datas"][0]._id,
        values: [{ id: "", value: this.myData.attribute.value }]
      });
    },
    initModal() {
    },
    resetModal() {
    },
    handleOk($bv) {
      $bv.preventDefault();
      this.handleSubmit();
    },
    pickData() {
      this.$store.dispatch("chpge/parent2child", {
        task: "pickData",
        from: this.plotId,
        filter: {
          dataType: ["Number", "Object", "String"],
          usedBy: [],
          parents: [],
          archive: null,
          page: 1,
          perPage: 5000,
          withChild: false
        }
      });
      this.$root.$emit("bv::show::modal", "modal-widget-pick-data-" + this.plotId);
    },
    handleSubmit() {
      this.$validator.validate().then(isValid => {
        if (isValid) {
          this.$store.dispatch("data/editDashboard", {
            _id: this.plotId,
            data: { attribute: this.myData.attribute, datas: this.myData.datas }
          }).then(
            data => {
              this.$store.dispatch("chpge/child2parent", { task: "updateDash" });
              this.$nextTick(() => {
                this.$bvModal.hide(this.plotId);
              });
            },
            error => {
              this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              this.successful = false;
            }
          );
        }
      });
    }
  },
  mounted() {
    if (this.data != null) {
      this.myData = this.data;
      this.selectedData = this.data["datas"][0];
    }
  }
};
</script>

<style scoped></style>
