<template>
  <div
    :dir="dict.dir[lang]"
    class="container-fluid p-0 bg-none"
    :class="lang === 'per' ? 'text-end' : 'text-start'"
    style="min-height: calc(100vh - 60px)"
  >
    <div class="p-3 m-0 bg-page-title d-flex">
      <div
        style="background: none !important"
        :class="
          dict.dir[lang] === 'rtl'
            ? 'page-title-icon-rtl'
            : 'page-title-icon-ltr'
        "
      >
        <img
          src="@/assets/icons/data_manager-2.png"
          alt="dashboard"
          style="width: 60px"
        />
      </div>

      <div class="d-flex align-items-center">
        <h5 class="mb-0">{{ dict.titleForm[lang] }}</h5>
      </div>
    </div>
    <div
      class="row g-0 p-2"
      :class="lang === 'per' ? 'text-end' : 'text-start'"
      style="min-height: calc(100vh - 152px)"
    >
      <div class="col-lg-12 h-100">
        <div
          class="card h-100"
          style="
            background-color: rgba(255, 255, 255, 0.75) !important;
            min-height: calc(100vh - 168px);
          "
        >
          <div class="p-3">
            <b-container fluid class="p-0">
              <ejs-grid
                ref="forms_list"
                :width="'auto'"
                :allowSorting="true"
                :locale="lang"
                :key="items.length"
                :allowExcelExport="true"
                :loadingIndicator="{ indicatorType: 'Shimmer' }"
                :allowFiltering="true"
                :allowPdfExport="true"
                :editSettings="editSettings"
                :toolbar="toolbarGenerator"
                :toolbarClick="categoryToolbarClick"
                :selectionSettings="selectionOptions"
                :actionBegin="categoryActionBegin"
                :dataBound="categoryBound"
                :allowPaging="true"
                :enableRtl="lang == 'per'"
                :pageSettings="{ pageSize: 5, pageSizes: [5, 10, 20, 50] }"
                :dataSource="items"
              >
                <e-columns>
                  <e-column width="50" type="checkbox"></e-column>
                  <e-column
                    v-for="(item, index) in fields"
                    :key="index"
                    :field="item.key"
                    :headerText="item[lang]"
                    :template="item.hasTemplate ? item.key : null"
                  ></e-column>
                </e-columns>
                <template v-slot:modify="{ data }">
                  <div class="d-flex justify-content-between col-md-3">
                    <span
                      class="e-icons e-edit-6"
                      @click="onEditClick(data)"
                      style="font-size: 16px"
                    ></span>
                    <span
                      class="e-icons e-delete-1 text-danger"
                      @click="remove(data._id)"
                      style="font-size: 16px"
                    ></span>
                  </div>
                </template>
              </ejs-grid>
            </b-container>
          </div>
        </div>
      </div>
    </div>

    <ejs-dialog
      width="100%"
      height="100%"
      :isModal="true"
      :enableHtmlSanitizer="false"
      :visible="isVisible"
      :resizeHandles="['All']"
      :showCloseIcon="true"
      :buttons="[
        {
          click: createForm,
          buttonModel: { content: dict.ok[lang], isPrimary: true },
        },
        { click: resetPage, buttonModel: { content: dict.cancel[lang] } },
      ]"
      :header="dict.New[lang]"
      :close="resetPage"
    >
      <div>
        <div
          :dir="dict.dir[lang]"
          id="form_generation"
          class="py-3 mx-auto row h-100"
        >
          <div
            class="
              col-md-2 col-4
              overflow-scroll-y
              rounded-3
              shadow-lg
              text-dark
              fw-light
            "
            style="
              min-height: 15rem;
              max-height: 45rem;
              overflow-y: scroll;
            "
          >
            <div class="p-3">
              <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
                <li
                  v-for="(item, index) in tabs"
                  class="mga-tab-nav-item"
                  @click="activeTab = item.value"
                  :key="index"
                >
                  <a
                    class="mga-tab-nav-link text-dark fw-lighter"
                    :class="{ active: activeTab === item.value }"
                    :id="item.name"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="tab1"
                    aria-selected="true"
                  >
                    {{ dict[item.value][lang] }}
                  </a>
                </li>
              </ul>
            </div>

            <div v-if="activeTab == 'input_fields'">
              <div
                @click="onSelect(item,'inputs')"
                class="
                  px-3
                  py-1
                  shadow-lg
                  text-dark
                  d-flex
                  gap-2
                  align-items-center
                  rounded-3
                  border
                  my-2
                  cursor-pointer
                "
                style="cursor: pointer"
                v-for="(item, index) in drpItems.inputs"
                :key="index"
              >
                <div
                  class="bg-primary rounded-3 py-1 px-2 text-start text-white shadow-lg"
                >
                  Aa
                </div>
                <div class="col-md">
                  <p class="mt-2 mb-0">{{ dict[item.text][lang] }}</p>
                  <span
                    style="font-size: 12px; font-weight: lighter !important"
                  >{{ item.details[lang] }}</span
                  >
                </div>
              </div>
            </div>
            <div v-if="activeTab == 'output_fields'">
              <div
                @click="onSelect(item,'outputs')"
                class="
                  px-3
                  py-1
                  shadow-lg
                  d-flex
                  gap-2
                  align-items-center
                  rounded-3
                  border
                  my-2
                  cursor-pointer
                "
                style="cursor: pointer"
                v-for="(item, index) in drpItems.outputs"
                :key="index"
              >
                <div
                  class="bg-primary rounded-3 py-1 px-2 text-start text-white shadow-lg"
                >
                  Aa
                </div>
                <div class="col-md">
                  <p class="mt-2 mb-0">{{ dict[item.text][lang] }}</p>
                  <span
                    style="font-size: 12px; font-weight: lighter !important"
                  >{{ item.details[lang] }}</span
                  >
                </div>
              </div>
            </div>
            <div v-if="activeTab == 'elements'">
              <div
                @click="onSelect(item,'elements')"
                class="
                  px-3
                  py-1
                  shadow-lg
                  d-flex
                  gap-2
                  align-items-center
                  rounded-3
                  border
                  my-2
                  cursor-pointer
                "
                style="cursor: pointer"
                v-for="(item, index) in drpItems.elements"
                :key="index"
              >
                <div
                  class="bg-primary rounded-3 py-1 px-2 text-dark text-start shadow-lg"
                >
                  Aa
                </div>
                <div class="col-md">
                  <p class="mt-2 mb-0">{{ dict[item.text][lang] }}</p>
                  <span
                    style="font-size: 12px; font-weight: lighter !important"
                  >{{ item.details[lang] }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-7 mt-4 shadow-lg border-white h-100 border rounded-3 mx-auto col-8"
            id="dragArea"
            style="min-height: 30rem; max-height: 45rem; overflow-y: scroll"
          >
            <div id="titleSection">
              <ejs-textbox
                :value="formTitle"
                v-model="formTitle"
                :enableRtl="lang === 'per'"
                :required="true"
                floatLabelType="Auto"
                :placeholder="dict.formTitle[lang]"
              ></ejs-textbox>
            </div>
            <div class="row my-3" :key="list.length">
              <div
                class="col-md-6 d-flex gap-4 my-3"
                style="cursor: move"
                v-for="item in list"
                :key="item.id"
                :id="item.id"
                @click="dragElement(item.id)"
              >
                <div class="d-flex gap-3" style="cursor: move">
                  <span
                    class="e-icons e-settings text-gray"
                    @click="settingShow(item)"
                  ></span>
                  <span
                    class="e-icons e-close-6 text-gray"
                    style="cursor: pointer !important"
                    @click="removeField(item.field, item.id)"
                  ></span>
                </div>
                <component
                  v-if="item.field.component != 'ejs-slider' && item.field.component != 'ejs-circulargauge' && item.field.component != 'ejs-lineargauge' && item.field.component!='ejs-chart'  && item.field.component!='ejs-accumulationchart'"
                  :enableRtl="lang == 'per'"
                  :dataSource="item.field.options"
                  :field="{ text: lang, value: 'value' }"
                  :placeholder="item.field.attributes.placeHolder.value"
                  :label="item.field.attributes.placeHolder.value"
                  :rows="
                    item.field.component == 'ejs-textarea'
                      ? item.field.attributes.rows.value
                      : null
                  "
                  :type="item.field.type != null ? item.field.type : ''"
                  :is="item.field.component"
                ></component>
                <component
                  v-if="item.field.component == 'ejs-slider' &&  item.field.component != 'ejs-circulargauge'&&  item.field.component != 'ejs-lineargauge' && item.field.component!='ejs-chart' && item.field.component!='ejs-accumulationchart'"
                  :enableRtl="lang == 'per'"
                  :dataSource="item.field.options"
                  :field="{ text: lang, value: 'value' }"
                  :placeholder="item.field.attributes.placeHolder.value"
                  :label="item.field.attributes.placeHolder.value"
                  :type="item.field.type != null ? item.field.type : ''"
                  :ticks="{
                    placement: 'After',
                    largeStep: item.field.attributes.largeStep.value,
                    smallStep: item.field.attributes.smallStep.value,
                    showSmallTicks: item.field.attributes.showSmallTicks.value,
                  }"
                  :is="item.field.component"
                ></component>
                <ejs-circulargauge v-if="item.field.component == 'ejs-circulargauge'" :key="item.id"
                                   style="display:block;"
                                   :id="item.id"
                                   height="300px" align="center"
                                   :enablePointerDrag="enablePointerDrag" :tooltip="tooltip">
                  <e-axes>
                    <e-axis :annotations="annotations" :radius="gaugeradius" :startAngle="startAngle" minimum="0"
                            maximum="500" :endAngle="endAngle" :majorTicks="majorTicks" :lineStyle="lineStyle"
                            :minorTicks="minorTicks" :labelStyle="labelStyle" :ranges="ranges">
                      <e-pointers>
                        <e-pointer :value="value" :cap="cap" :radius="pointerRadius" :color="color"
                                   :animation="animation"></e-pointer>
                      </e-pointers>
                    </e-axis>
                  </e-axes>
                </ejs-circulargauge>
                <ejs-lineargauge v-if="item.field.component=='ejs-lineargauge'" orientation="Horizontal"
                                 height="200px"
                                 width="100%"
                                 :axes="[{minimum: 20,maximum: 140}]">
                </ejs-lineargauge>
                <ejs-chart v-if="item.field.component=='ejs-chart'" width="100%"
                           style="height: 350px;width: 100%"
                           :id="item.id"
                           :key="item.id"
                           :title="title" :primaryXAxis="primaryXAxis" :primaryYAxis="primaryYAxis">
                  <e-series-collection height="300px">
                    <e-series :dataSource="seriesData" type="Line" xName="month" yName="sales"></e-series>
                  </e-series-collection>
                </ejs-chart>

                <ejs-accumulationchart
                  v-if="item.field.component=='ejs-accumulationchart'"
                  :key="item.id"
                  style="height: 200px;width: 100%"
                  :id="item.id"
                >
                  <e-accumulation-series-collection>
                    <e-accumulation-series :dataSource="seriesDataPie" xName="x" yName="y"></e-accumulation-series>
                  </e-accumulation-series-collection>
                </ejs-accumulationchart>
                <span
                  v-if="item.field.component == 'ejs-switch'"
                  class="mx-2"
                >{{ item.field.attributes.placeHolder.value }}</span
                >
              </div>
            </div>
          </div>
          <div
            class="col-md-2 rounded-3 shadow-lg h-100 col-12"
            style="

              min-height: 15rem;
              max-height: 45rem;
              overflow-y: scroll;
            "
          >
            <div>
              <div
                @click.prevent="settingShow(item)"
                @mousedown="onDragStart(index, $event)"
                @mouseup="onDragEnd(index, $event)"
                class="px-3 py-1 shadow-lg d-flex gap-2 align-items-center rounded-3 border my-2 cursor-pointer"
                style="cursor: grab"
                v-for="(item, index) in list"
                :key="index"
              >
                <div
                  class="bg-primary rounded-3 py-1 px-2 text-white text-start shadow-lg"
                >
                  Aa
                </div>

                <div class="col-md">
                  <p class="mt-2 mb-0">{{ dict[item.details.text][lang] }}</p>
                  <span
                    style="font-size: 12px; font-weight: lighter !important"
                  >{{ item.details[lang] }}</span
                  >
                </div>
                <span class="e-icons e-drag-and-drop"></span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </ejs-dialog>

    <b-modal
      id="modal-edit-field"
      size="xl"
      :title="dict.EditField[lang]"
      centered
      hide-header-close
      scrollable
      @hidden="selectedComponent = {}"
      :ok-title="dict.ok[lang]"
      :cancel-title="dict.cancel[lang]"
      :dir="dict.dir[lang]"
      no-close-on-backdrop
      header-border-variant="primary"
      header-class="my-modal-header "
    >
      <div v-if="Object.keys(selectedComponent).length > 0">
        <!--        <div>-->
        <!--          <component-->
        <!--            ref="theComponent"-->
        <!--            :type="selectedComponent.type!=null ?selectedComponent.type :'' "-->
        <!--            :enableRtl="lang == 'per'"-->
        <!--            :dataSource="selectedComponent.options"-->
        <!--            :fields="{ text:lang, value: 'value' }"-->
        <!--            :placeholder="selectedComponent.attributes.placeHolder"-->
        <!--            :label="selectedComponent.attributes.placeHolder"-->
        <!--            :is="selectedComponent.component"></component>-->
        <!--          <span v-if="selectedComponent.component=='ejs-switch'"-->
        <!--                class="mx-2">{{ selectedComponent.attributes.placeHolder }}</span>-->
        <!--        </div>-->
        <div class="my-3">
          <div dir="ltr" class="accordion col-12 my-3" role="tablist">
            <b-card no-body class="mb-1">
              <ejs-accordion
                :key="computeAccardions.length"
                :enableRtl="lang == 'per'"
                expandMode="Single"
              >
                <div
                  v-for="item in computeAccardions"
                  :key="'acc' + item"
                >
                  <div>
                    <div>{{ dict[item][lang] }}</div>
                  </div>
                  <div v-if="item == 'name'">
                    <ejs-textbox
                      :value="selectedComponent.name"
                      v-model="selectedComponent.name"
                      :enableRtl="lang === 'per'"
                      :required="true"
                      @input="validateInput"
                      floatLabelType="Auto"
                      :placeholder="dict.name[lang]"
                    ></ejs-textbox>
                  </div>
                  <div v-if="item == 'attributes'">
                    <div
                      class="col-md-12 col-12 my-2"
                      v-for="(
                              item, index
                            ) in selectedComponent.attributes"
                      :key="index"
                    >
                      {{ index }}
                      <ejs-textbox
                        v-if="
                                selectedComponent.attributes[index].type !=
                                'boolean'
                              "
                        floatLabelType="Auto"
                        :enableRtl="lang == 'per'"
                        :placeholder="dict[index][lang]"
                        v-model="
                                selectedComponent.attributes[index].value
                              "
                        :type="selectedComponent.attributes[index].type"
                      ></ejs-textbox>

                      <ejs-dropdownlist
                        v-if="
                                selectedComponent.attributes[index].type ==
                                'boolean'
                              "
                        floatLabelType="Auto"
                        :enableRtl="lang == 'per'"
                        :dataSource="booleanType"
                        v-model="
                                selectedComponent.attributes[index].value
                              "
                        :fields="{ text: lang, value: 'value' }"
                        :placeholder="dict[index][lang]"
                        @change="
                                (val) => {
                                  selectedComponent.attributes[index].value =
                                    val.value;
                                }
                              "
                      ></ejs-dropdownlist>

                    </div>
                  </div>
                  <div v-if="item == 'dataSource'">
                    <div class="d-flex justify-content-between pt-4">
                                <span
                                  @click="addSourceField"
                                  class="e-icons e-plus"
                                >
                              <span class="mx-2" style="font-size: 13px">{{
                                  dict.add[lang]
                                }}</span></span
                                >
                    </div>
                    <div
                      class="col-12 d-flex"
                      v-for="(item, index2) in selectedComponent.dataSource"
                      :key="index2"
                    >
                      <div class="col-md-11 row col-12 my-2">
                        <div class="col-md-3 col-12" v-for="(arg,i) in item" :key="i">
                          <ejs-textbox
                            :type="arg.type"
                            v-model="arg.value"
                            floatLabelType="Auto"
                            :enableRtl="lang == 'per'"
                            :placeholder="i"
                          ></ejs-textbox>
                        </div>

                      </div>
                      <span
                        @click="removeOption(item, index2)"
                        style="font-size: 18px"
                        class="e-icons col mt-4 mx-2 e-delete-1 text-danger"
                      ></span>
                    </div>
                  </div>
                  <div v-if="item == 'axis'">
                    <div
                      class="col-12 row"
                    >
                      <div class="col-md-3 col-12"
                           v-for="(item, index2) in selectedComponent.axis"
                           :key="index2"
                      >
                        <ejs-textbox
                          :type="item.type"
                          v-model="item.value"
                          floatLabelType="Auto"
                          :enableRtl="lang == 'per'"
                          :placeholder="index2"
                        ></ejs-textbox>
                      </div>
                    </div>
                  </div>

                  <div v-if="item == 'validation'">
                    <div
                      class="col-md-12 col-12 my-2"
                      v-for="(
                              item, index
                            ) in selectedComponent.validation"
                      :key="index"
                    >
                      <ejs-dropdownlist
                        v-if="index == 'required'"
                        floatLabelType="Auto"
                        :enableRtl="lang == 'per'"
                        :dataSource="booleanType"
                        v-model="selectedComponent.validation[index]"
                        :fields="{ text: lang, value: 'value' }"
                        :placeholder="dict[index][lang]"
                        @change="
                                (val) => {
                                  selectedComponent.validation[index] =
                                    val.value;
                                }
                              "
                      ></ejs-dropdownlist>
                      <ejs-numerictextbox
                        v-else
                        floatLabelType="Auto"
                        :enableRtl="lang == 'per'"
                        :placeholder="dict[index][lang]"
                        v-model="selectedComponent.validation[index]"
                      ></ejs-numerictextbox>
                    </div>
                  </div>
                  <div v-if="item == 'options'">
                    <div class="d-flex justify-content-between pt-4">
                      <p>{{ dict.addOptions[lang] }}</p>
                      <span
                        @click="addOptionsField"
                        class="e-icons e-plus"
                      >
                              <span class="mx-2" style="font-size: 13px">{{
                                  dict.add[lang]
                                }}</span></span
                      >
                    </div>
                    <div
                      class="row col-12"
                      v-for="(item, index) in selectedComponent.options"
                      :key="index"
                    >
                      <div class="col-md-3 col-12 my-2">
                        <ejs-textbox
                          floatLabelType="Auto"
                          v-model="item['per']"
                          :enableRtl="lang == 'per'"
                          :placeholder="dict.titles[lang]"
                        ></ejs-textbox>
                      </div>
                      <div class="col-md-3 col-12">
                        <ejs-textbox
                          floatLabelType="Auto"
                          v-model="item['eng']"
                          :enableRtl="lang == 'per'"
                          :placeholder="dict.titles[lang]"
                        ></ejs-textbox>
                      </div>
                      <div class="col-md-3 col-12">
                        <ejs-textbox
                          floatLabelType="Auto"
                          v-model="item['value']"
                          :enableRtl="lang == 'per'"
                          :placeholder="dict.value[lang]"
                        ></ejs-textbox>
                      </div>
                      <span
                        @click="removeOption(item, index)"
                        class="e-icons col mt-4 e-delete-1 text-danger"
                      ></span>
                    </div>
                  </div>
                  <div v-if="item == 'basic'">
                    <div
                      class="col-md-12 col-12 my-2"
                      v-for="(
                              item, index
                            ) in selectedComponent.basic"
                      :key="index"
                    >
                      <ejs-textbox
                        v-if="
                                selectedComponent.basic[index].type !=
                                'boolean'
                              "
                        floatLabelType="Auto"
                        :type="item.type"
                        :enableRtl="lang == 'per'"
                        :placeholder="dict[index][lang]"
                        v-model="selectedComponent.basic[index].default"
                      ></ejs-textbox>
                      <ejs-dropdownlist
                        v-if="
                                selectedComponent.basic[index].type ==
                                'boolean'
                              "
                        floatLabelType="Auto"
                        :enableRtl="lang == 'per'"
                        :dataSource="booleanType"
                        v-model="
                                selectedComponent.basic[index].value
                              "
                        :fields="{ text: lang, value: 'value' }"
                        :placeholder="dict[index][lang]"
                        @change="
                                (val) => {
                                  selectedComponent.basic[index].value =
                                    val.value;
                                }
                              "
                      ></ejs-dropdownlist>
                    </div>
                  </div>
                  <div v-if="item == 'title'">
                    <div
                      class="col-md-12 col-12 my-2"
                      v-for="(
                              item, index
                            ) in selectedComponent.title"
                      :key="index"
                    >
                      <ejs-textbox
                        v-if="
                                selectedComponent.title[index].type !=
                                'boolean'
                              "
                        floatLabelType="Auto"
                        :type="item.type"
                        :enableRtl="lang == 'per'"
                        :placeholder="dict[index][lang]"
                        v-model="selectedComponent.title[index].default"
                      ></ejs-textbox>
                      <ejs-dropdownlist
                        v-if="
                                selectedComponent.title[index].type ==
                                'boolean'
                              "
                        floatLabelType="Auto"
                        :enableRtl="lang == 'per'"
                        :dataSource="booleanType"
                        v-model="
                                selectedComponent.title[index].value
                              "
                        :fields="{ text: lang, value: 'value' }"
                        :placeholder="dict[index][lang]"
                        @change="
                                (val) => {
                                  selectedComponent.title[index].value =
                                    val.value;
                                }
                              "
                      ></ejs-dropdownlist>
                    </div>
                  </div>
                  <div v-if="item == 'value'">
                    <div
                      class="col-md-12 col-12 my-2"
                      v-for="(
                              item, index
                            ) in selectedComponent.value"
                      :key="index"
                    >
                      <ejs-textbox
                        v-if="
                                selectedComponent.value[index].type !=
                                'boolean'
                              "
                        floatLabelType="Auto"
                        :type="item.type"
                        :enableRtl="lang == 'per'"
                        :placeholder="dict[index][lang]"
                        v-model="selectedComponent.value[index].default"
                      ></ejs-textbox>
                      <ejs-dropdownlist
                        v-if="
                                selectedComponent.value[index].type ==
                                'boolean'
                              "
                        floatLabelType="Auto"
                        :enableRtl="lang == 'per'"
                        :dataSource="booleanType"
                        v-model="
                                selectedComponent.value[index].value
                              "
                        :fields="{ text: lang, value: 'value' }"
                        :placeholder="dict[index][lang]"
                        @change="
                                (val) => {
                                  selectedComponent.value[index].value =
                                    val.value;
                                }
                              "
                      ></ejs-dropdownlist>
                    </div>
                  </div>
                  <div v-if="item == 'units'">
                    <div
                      class="col-md-12 col-12 my-2"
                      v-for="(
                              item, index
                            ) in selectedComponent.units"
                      :key="index"
                    >
                      <ejs-textbox
                        v-if="
                                selectedComponent.units[index].type != 'boolean'
                              "
                        floatLabelType="Auto"
                        :type="item.type"
                        :enableRtl="lang == 'per'"
                        :placeholder="dict[index][lang]"
                        v-model="selectedComponent.units[index].default"
                      ></ejs-textbox>
                      <ejs-dropdownlist
                        v-if="
                                selectedComponent.units[index].type ==
                                'boolean'
                              "
                        floatLabelType="Auto"
                        :enableRtl="lang == 'per'"
                        :dataSource="booleanType"
                        v-model="
                                selectedComponent.units[index].value
                              "
                        :fields="{ text: lang, value: 'value' }"
                        :placeholder="dict[index][lang]"
                        @change="
                                (val) => {
                                  selectedComponent.units[index].value =
                                    val.value;
                                }
                              "
                      ></ejs-dropdownlist>
                    </div>
                  </div>
                  <div v-if="item == 'ticks'">
                    <div
                      class="col-md-12 col-12 my-2"
                      v-for="(
                              item, index
                            ) in selectedComponent.ticks"
                      :key="index"
                    >
                      <ejs-textbox
                        v-if="
                                selectedComponent.ticks[index].type !=
                                'boolean'
                              "
                        floatLabelType="Auto"
                        :type="item.type"
                        :enableRtl="lang == 'per'"
                        :placeholder="dict[index][lang]"
                        v-model="selectedComponent.ticks[index].default"
                      ></ejs-textbox>
                      <ejs-dropdownlist
                        v-if="
                                selectedComponent.ticks[index].type ==
                                'boolean'
                              "
                        floatLabelType="Auto"
                        :enableRtl="lang == 'per'"
                        :dataSource="booleanType"
                        v-model="
                                selectedComponent.ticks[index].value
                              "
                        :fields="{ text: lang, value: 'value' }"
                        :placeholder="dict[index][lang]"
                        @change="
                                (val) => {
                                  selectedComponent.ticks[index].value =
                                    val.value;
                                }
                              "
                      ></ejs-dropdownlist>
                    </div>
                  </div>
                </div>
              </ejs-accordion>
            </b-card>
          </div>
        </div>
      </div>

    </b-modal>
  </div>
</template>
<script>
import {
  Page,
  Sort,
  Toolbar,
  PdfExport,
  ExcelExport,
  Edit,
  Search
} from "@syncfusion/ej2-vue-grids";
import { Draggable } from "@syncfusion/ej2-base";
import { DropDownButtonComponent } from "@syncfusion/ej2-vue-splitbuttons";
import { NumericTextBoxComponent } from "@syncfusion/ej2-vue-inputs";
import {
  DashboardLayoutComponent,
  PanelDirective,
  PanelsDirective
} from "@syncfusion/ej2-vue-layouts";
import widgetPieChart from "@/components/widgetPieChart.vue";
import CustomPath from "@/components/widgetPieChart.vue";
import {
  AccordionComponent,
  AccordionItemDirective,
  AccordionItemsDirective
} from "@syncfusion/ej2-vue-navigations";
import { v4 } from "uuid";
import { DialogUtility } from "@syncfusion/ej2-popups";
import { createElement } from "@syncfusion/ej2-base";
import { FormValidator } from "@syncfusion/ej2-inputs";
import err from "@/lacale/errors.json";
import store from "@/store";
import { ToastUtility } from "@syncfusion/ej2-vue-notifications";
import LinearGaugeForm from "@/components/formWidgets/linearGauge.vue";
import {
  CircularGaugeComponent,
  AxesDirective,
  AxisDirective,
  PointerDirective,
  PointersDirective,
  GaugeTooltip,
  Annotations
} from "@syncfusion/ej2-vue-circulargauge";
import { LinearGaugeComponent } from "@syncfusion/ej2-vue-lineargauge";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Category,
  LineSeries,
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  PieSeries,
  AccumulationDataLabel,
  AccumulationLegend
} from "@syncfusion/ej2-vue-charts";
import { DatePickerComponent, DateTimePickerComponent, TimePickerComponent } from "@syncfusion/ej2-vue-calendars";

export default {
  name: "formGenerator",
  components: {
    "ejs-accumulationchart": AccumulationChartComponent,
    "e-accumulation-series-collection": AccumulationSeriesCollectionDirective,
    "e-accumulation-series": AccumulationSeriesDirective,
    "ejs-chart": ChartComponent,
    "ejs-datetimepicker": DateTimePickerComponent,
    "ejs-timepicker": TimePickerComponent,
    "ejs-datepicker": DatePickerComponent,
    "e-series-collection": SeriesCollectionDirective,
    "e-series": SeriesDirective,
    LinearGaugeForm,
    CustomPath,
    "linearGauge": LinearGaugeForm,
    "ejs-lineargauge": LinearGaugeComponent,
    "ejs-dropdownbutton": DropDownButtonComponent,
    "ejs-numerictextbox": NumericTextBoxComponent,
    "ejs-dashboardlayout": DashboardLayoutComponent,
    "ejs-accordion": AccordionComponent,
    "e-accordionitem": AccordionItemDirective,
    "e-accordionitems": AccordionItemsDirective,
    "e-panel": PanelDirective,
    "e-panels": PanelsDirective,
    "ejs-circulargauge": CircularGaugeComponent,
    "e-axes": AxesDirective,
    "e-axis": AxisDirective,
    "e-pointers": PointersDirective,
    "e-pointer": PointerDirective
  },
  props: {},
  data() {
    return {
      seriesData: [
        { month: "Jan", sales: 35 },
        { month: "Feb", sales: 28 },
        { month: "Mar", sales: 34 },
        { month: "Apr", sales: 32 },
        { month: "May", sales: 40 },
        { month: "Jun", sales: 32 },
        { month: "Jul", sales: 35 },
        { month: "Aug", sales: 55 },
        { month: "Sep", sales: 38 },
        { month: "Oct", sales: 30 },
        { month: "Nov", sales: 25 },
        { month: "Dec", sales: 32 }
      ],
      seriesDataPie: [
        { x: "Jan", y: 3 }, { x: "Feb", y: 3.5 }, { x: "Mar", y: 7 }, { x: "Apr", y: 13.5 }, {
          x: "May",
          y: 19
        }, { x: "Jun", y: 23.5 }, { x: "Jul", y: 26 }, { x: "Aug", y: 25 },
        { x: "Sep", y: 21 }, { x: "Oct", y: 15 }, { x: "Nov", y: 15 }
      ],
      primaryXAxis: {
        valueType: "Category",
        title: "Month"
      },
      primaryYAxis: {
        title: "Sales count"
      },
      title: "Monthly Sales Comparison",
      annotations: [{
        content: "CircularGauge", zIndex: "1", angle: 180
      }],
      gaugeradius: "90%",
      startAngle: 240,
      endAngle: 120,
      lineStyle: {
        width: 0
      },
      majorTicks: {
        color: "white",
        offset: -5,
        height: 12
      },
      minorTicks: {
        width: 20
      },
      labelStyle: {
        useRangeColor: true,
        font: {
          color: "#424242",
          size: "13px",
          fontFamily: "Roboto"
        }
      },
      value: 70,
      pointerRadius: "60%",
      color: "#bd33a1",
      cap: {
        radius: 10,
        border: {
          color: "#bd33a1",
          width: 5
        }
      },
      animation: {
        enable: true,
        duration: 1500
      },
      ranges: [
        {
          start: 0,
          end: 50,
          startWidth: 10,
          endWidth: 10,
          radius: "102%",
          color: "#3A5DC8"
        }, {
          start: 50,
          end: 100,
          radius: "102%",
          startWidth: 10,
          endWidth: 10,
          color: "#33BCBD"
        },
        {
          start: 100,
          end: 120,
          radius: "102%",
          startWidth: 10,
          endWidth: 10,
          color: "#9f33bd"
        }
      ],
      tooltip: {
        type: ["Pointer", "Range", "Annotation"],
        enable: true,
        enableAnimation: false,
        annotationSettings: { template: "<div>CircularGauge</div>" },
        rangeSettings: { fill: "red" }
      },
      enablePointerDrag: true,
      tabs: [
        { text: "input fields", value: "input_fields" },
        { text: "output fields", value: "output_fields" },
        { text: "elements", value: "elements" }
      ],
      accardions: ["name", "validation", "attributes", "options"],
      activeTab: "input_fields",
      isVisible: false,
      showSetting: false,
      position: { x: 0, y: 0 }, // Initial position
      dragging: false, // Drag state
      offset: { x: 0, y: 0 },
      dict: {
        axis: { eng: "axis", per: "اطلاعات برداری" },
        refresh: { eng: "refresh datas", per: "به روزرسانی اطلاعات" },
        valueInt: { eng: "valueInt", per: "مقدار اولیه" },
        valueDec: { eng: "valueDec", per: "مقدار کاهش" },
        colorValueText: { eng: "colorValueText", per: "رنگ متن" },
        createConfirm: {
          eng: "create class confirmation",
          per: "تاییدیه ساخت داده"
        },
        createConfirmContent: {
          eng: "Do you want to create a class for these elements ?",
          per: "آیا تمایل به ساخت داده نوع کلاس برای این فرم دارید؟"
        },
        deleteConfirm: { eng: "Confirmation Delete", per: "تاییدیه حذف" },
        errNoName: {
          eng: "set the name for every red elements",
          per: "لطفا برای المان های مشخص شده با رنگ قرمز نامی انتخاب کنید."
        },
        deleteConfirmContent: {
          eng: "Are you sure to remove the data ?",
          per: "آیا از حذف این رکورد اطمینان دارید ؟"
        },
        largeStep: { per: "مرحله بزرگ", eng: "large Step" },
        smallStep: { per: "مرحله کوچک", eng: "small Step" },
        showSmallTicks: { per: "نمایش تیک ها", eng: "showSmall Ticks" },
        rows: { per: "ردیف ها", eng: "rows" },
        options: { per: "مقادیر", eng: "options" },
        attributes: { per: "ویژگی ها", eng: "attributes" },
        name: { per: "نام المان", eng: "Element name" },
        validation: { per: "اعتبار سنجی", eng: "validation" },
        input_fields: { per: "کادرهای ورودی", eng: "input fields" },
        output_fields: { per: "کادرهای خروجی", eng: "output fields" },
        elements: { per: "المان های دیگر", eng: "elements" },
        textArea: { per: "کادر متنی", eng: "textArea" },
        switchInput: { per: "کلید تغییر حالت", eng: "switch Input" },
        radioButton: { per: "دکمه انتخاب دایره ای", eng: "radio Button" },
        checkBox: { per: "دکمه انتخاب مربعی", eng: "checkBox Button" },
        rangeSlider: { per: "اسلایدر بازه", eng: "range Slider" },
        add: { eng: "New option", per: "مقدار جدید" },
        addOptions: { eng: "Select box options", per: "لیست مقادیر" },
        selectBox: { eng: "New element", per: "المان جدید" },
        dir: { eng: "ltr", per: "rtl" },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        titleForm: { eng: "Forms manager", per: "مدیریت فرم ها" },
        formTitle: { eng: "form title", per: "عنوان فرم" },
        addData: { eng: "New form", per: "ایجاد فرم " },
        New: { eng: "New form", per: "فرم جدید" },
        EditField: { eng: "Edit field", per: "ویرایش فیلد" },
        colorInput: { eng: "input color", per: "کادر انتخاب رنگ" },
        textInput: { eng: "input text", per: "کادر متن" },
        dateTimeInput: { eng: "Input date time", per: "کادر تاریخ و ساعت" },
        dateInput: { eng: "input date", per: "کادر تاریخ" },
        timeInput: { eng: "input time", per: "کادر زمان" },
        numberInput: { eng: "input number", per: "کادر عدد" },
        selectInput: { eng: "Input select box", per: "کادر انتخاب مقدار" },
        multiselectInput: {
          eng: "Input multi select box",
          per: "کادر انتخاب چند مقداری"
        },
        required: { eng: "required", per: "مقدار آن خالی نباشد" },
        min: { eng: "min", per: "حداقل مقدار" },
        max: { eng: "max", per: "حداکثر مقدار" },
        minLength: { eng: "minLength", per: "حداقل تعداد کاراکتر" },
        maxLength: { eng: "maxLength", per: "حداکثر تعداد کاراکتر" },
        placeHolder: { eng: "placeHolder", per: "عنوان" },
        value: { eng: "value", per: "مقدار" },
        titles: { eng: "title", per: "عنوان" },
        linearGauge: { eng: "linear Gauge", per: "گیج خطی" },
        radialGauge: { eng: "radial Gauge", per: "گیج شعاعی" },
        pieChart: { eng: "pie Chart", per: "نمودار دایره ای" },
        lineChart: { eng: "line Chart", per: "نمودار خطی" },
        basic: { per: "تنظیمات پایه", eng: "basic" },
        units: { per: "تنظیمات واحدها", eng: "units" },
        ticks: { per: "تنظیمات تیک ها", eng: "ticks" },
        minValue: { eng: "minimum value", per: "مقدار کمینه" },
        maxValue: { eng: "maximum value", per: "مقدار بیشینه" },
        colorPlate: { eng: "color plate", per: "رنگ پس زمینه" },
        colorPlateEnd: { eng: "color plate end", per: "رنگ انتهای صفحه" },
        title: { eng: "title", per: "عنوان" },
        colorUnits: { eng: "colorUnits", per: "واحدهای رنگی" },
        fontUnits: { eng: "fontUnits", per: "واحدهای فونت" },
        fontUnitsSize: { eng: "fontUnitsSize", per: "سایز فونت واحد" },
        exactTicks: { eng: "exactTicks", per: "دقت تیک‌ها" },
        majorTicksInt: { eng: "majorTicksInt", per: "تعداد تیک‌های اصلی (عدد صحیح)" },
        majorTicksDec: { eng: "majorTicksDec", per: "تعداد تیک‌های اصلی (اعشار)" },
        colorMajorTicks: { eng: "colorMajorTicks", per: "رنگ تیک‌های اصلی" },
        colorMinorTicks: { eng: "colorMinorTicks", per: "رنگ تیک‌های فرعی" },
        colorStrokeTicks: { eng: "colorStrokeTicks", per: "رنگ خطوط تیک‌ها" },
        majorTicks: { eng: "major ticks", per: "تیک های اصلی" },
        minorTicks: { eng: "number of minor ticks", per: "تعداد تیک های فرعی" },
        strokeTicks: { eng: "stroke ticks", per: "نوار پشت تیک ها" },
        dataSource: { eng: "data series", per: "سری داده" },
        legend: { eng: "legend", per: "عنوان" },
        fields: { eng: "legend", per: "عنوان" }
      },
      chosenComponent: null,
      type: "New",
      pageMode: "New",
      fields: [
        { key: "title", per: "نام", eng: "Name", hasTemplate: false },
        { key: "type", per: "نوع", eng: "type", hasTemplate: false },
        { key: "modify", per: "", eng: "", hasTemplate: true }
      ],
      items: [],
      editSettings: {
        allowEditing: false,
        allowAdding: true,
        allowDeleting: true,
        mode: "Dialog"
      },
      selectionOptions: {
        type: "Multiple",
        persistSelection: false,
        checkboxOnly: true
      },
      drpItems: {
        inputs: [
          {
            text: "colorInput",
            value: "ejs-textbox",
            icon: "e-icons e-rename-3 mx-auto",
            details: {
              eng: "input field to enter only color",
              per: "کادر انتخاب رنگ"
            }
          },
          {
            text: "textInput",
            value: "ejs-textbox",
            icon: "e-icons e-rename-3 mx-auto",
            details: { eng: "input single line", per: "کادر متن تک خطی" }
          },
          {
            text: "numberInput",
            value: "ejs-textbox",
            icon: "e-icons e-rename-3 mx-auto",
            details: {
              eng: "input field to enter only number",
              per: "کادر وارد کردن اعداد"
            }
          },
          {
            text: "dateTimeInput",
            value: "ejs-datetimepicker",
            icon: "e-icons e-rename-3 mx-auto",
            details: {
              eng: "input field to enter only number",
              per: "کادر وارد کردن اعداد"
            }
          },
          {
            text: "dateInput",
            value: "ejs-datepicker",
            icon: "e-icons e-rename-3 mx-auto",
            details: {
              eng: "input field to enter only number",
              per: "کادر وارد کردن اعداد"
            }
          }, {
            text: "timeInput",
            value: "ejs-timepicker",
            icon: "e-icons e-rename-3 mx-auto",
            details: {
              eng: "input field to enter only number",
              per: "کادر وارد کردن اعداد"
            }
          },
          {
            text: "selectInput",
            value: "ejs-dropdownlist",
            icon: "e-icons e-rename-3 mx-auto",
            details: {
              eng: "input field to choose single value",
              per: "کادر انتخاب تک مقداری"
            }
          },
          {
            text: "multiselectInput",
            value: "ejs-textbox",
            icon: "e-icons e-rename-3 mx-auto",
            details: {
              eng: "input field to choose multi values",
              per: "کادر انتخاب چند مقداری"
            }
          },
          {
            text: "textArea",
            value: "ejs-textarea",
            icon: "e-icons e-rename-3 mx-auto",
            details: {
              eng: "input for long texts",
              per: "کادر وارد کردن متن های طولانی"
            }
          },
          {
            text: "switchInput",
            value: "ejs-switch",
            icon: "e-icons e-rename-3 mx-auto",
            details: { eng: "input to change status", per: "کادر تغییر وضعیت" }
          },
          {
            text: "radioButton",
            value: "ejs-radiobutton",
            icon: "e-icons e-rename-3 mx-auto",
            details: {
              eng: "input to choose value",
              per: "کادر دایره ای انتخاب مقدار"
            }
          },
          {
            text: "checkBox",
            value: "ejs-checkbox",
            icon: "e-icons e-rename-3 mx-auto",
            details: {
              eng: "input to choose value",
              per: "کادر مربعی انتخاب مقدار"
            }
          },
          {
            text: "rangeSlider",
            value: "ejs-slider",
            icon: "e-icons e-rename-3 mx-auto",
            details: {
              eng: "slider to choose range values",
              per: "اسلایدر انتخاب بازه"
            }
          }
        ],
        outputs: [
          {
            text: "linearGauge",
            value: "ejs-lineargauge",
            icon: "e-icons e-rename-3 mx-auto",
            details: {
              eng: "input field to enter only color",
              per: "کادر انتخاب رنگ"
            }
          },
          {
            text: "radialGauge",
            value: "ejs-circulargauge",
            icon: "e-icons e-rename-3 mx-auto",
            details: {
              eng: "input field to enter only color",
              per: "کادر انتخاب رنگ"
            }
          },
          {
            text: "pieChart",
            value: "ejs-accumulationchart",
            icon: "e-icons e-rename-3 mx-auto",
            details: {
              eng: "input field to enter only color",
              per: "کادر انتخاب رنگ"
            }
          },
          {
            text: "lineChart",
            value: "ejs-chart",
            icon: "e-icons e-rename-3 mx-auto",
            details: {
              eng: "input field to enter only color",
              per: "کادر انتخاب رنگ"
            }
          }
        ],
        elements: []
      },
      inputComponents: {
        inputs: {
          colorInput: {
            elementType: "input",
            name: "",
            component: "ejs-textbox",
            type: "color",
            fields: null,
            options: null,
            validation: {
              required: false
            },
            attributes: {
              placeHolder: { type: "text", value: "" }
            }
          },
          textArea: {
            elementType: "input",
            name: "",
            component: "ejs-textarea",
            type: null,
            fields: null,
            options: null,
            validation: {
              required: false,
              minLength: 1,
              maxLength: 100
            },
            attributes: {
              placeHolder: { type: "text", value: "" },
              rows: { type: "number", value: 3 }
            }
          },
          textInput: {
            elementType: "input",
            name: "",
            component: "ejs-textbox",
            type: "text",
            fields: null,
            options: null,
            validation: {
              required: false,
              minLength: 1,
              maxLength: 100
            },
            attributes: {
              placeHolder: { type: "text", value: "" }
            }
          },
          dateTimeInput: {
            elementType: "input",
            name: "",
            component: "ejs-datetimepicker",
            type: "text",
            fields: null,
            options: null,
            validation: {
              required: false,
              minLength: 1,
              maxLength: 100
            },
            attributes: {
              placeHolder: { type: "text", value: "" }
            }
          },
          dateInput: {
            elementType: "input",
            name: "",
            component: "ejs-datepicker",
            type: "text",
            fields: null,
            options: null,
            validation: {
              required: false,
              minLength: 1,
              maxLength: 100
            },
            attributes: {
              placeHolder: { type: "text", value: "" }
            }
          },
          timeInput: {
            elementType: "input",
            name: "",
            component: "ejs-timepicker",
            type: "text",
            fields: null,
            options: null,
            validation: {
              required: false,
              minLength: 1,
              maxLength: 100
            },
            attributes: {
              placeHolder: { type: "text", value: "" }
            }
          },
          numberInput: {
            elementType: "input",
            name: "",
            component: "ejs-numerictextbox",
            type: null,
            fields: null,
            options: null,
            validation: {
              required: false,
              min: 0,
              max: 100,
              minLength: 1,
              maxLength: 100
            },
            attributes: {
              placeHolder: { type: "text", value: "" }
            }
          },
          selectInput: {
            elementType: "input",
            name: "",
            component: "ejs-dropdownlist",
            type: "select",
            fields: [],
            options: [],
            validation: {
              required: false
            },
            attributes: {
              placeHolder: { type: "text", value: "" }
            }
          },
          multiselectInput: {
            elementType: "input",
            name: "",
            component: "ejs-dropdownlist",
            type: "multiselect",
            fields: [],
            options: [],
            validation: {
              required: false
            },
            attributes: {
              placeHolder: { type: "text", value: "" }
            }
          },
          switchInput: {
            elementType: "input",
            name: "",
            component: "ejs-switch",
            type: "switch",
            fields: null,
            options: null,
            validation: {
              required: false
            },
            attributes: {
              placeHolder: { type: "text", value: "" }
            }
          },
          radioButton: {
            elementType: "input",
            name: "",
            component: "ejs-radiobutton",
            type: null,
            fields: null,
            options: null,
            validation: {
              required: false
            },
            attributes: {
              placeHolder: { type: "text", value: "" }
            }
          },
          checkBox: {
            elementType: "input",
            name: "",
            component: "ejs-checkbox",
            type: null,
            fields: null,
            options: null,
            validation: {
              required: false
            },
            attributes: {
              placeHolder: { type: "text", value: "" }
            }
          },
          rangeSlider: {
            elementType: "input",
            name: "",
            component: "ejs-slider",
            type: null,
            fields: null,
            options: null,
            validation: {
              required: false,
              min: 0,
              max: 100,
              minLength: 1,
              maxLength: 100
            },
            attributes: {
              placeHolder: { type: "text", value: "" },
              largeStep: { type: "number", value: 20 },
              smallStep: { type: "number", value: 10 },
              showSmallTicks: { type: "boolean", value: true }
            }
          }
        },
        outputs: {
          linearGauge: {
            elementType: "output",
            name: "",
            component: "ejs-lineargauge",
            type: "gauge",
            fields: null,
            options: null,
            validation: {
              required: false
            },
            attributes: {
              placeHolder: { type: "text", value: "" }
            },
            basic: {
              minValue: { name: "minValue", type: "number", default: 0 },
              maxValue: { name: "maxValue", type: "number", default: 100 },
              colorPlate: { name: "colorPlate", type: "color", default: "#ffffff" },
              colorPlateEnd: { name: "colorPlateEnd", type: "color", default: "" }
            },
            title: {
              title: { name: "title", type: "boolean", default: false }
            },
            value: {
              valueInt: { name: "valueInt", type: "number", default: 3 },
              valueDec: { name: "valueDec", type: "number", default: 2 },
              colorValueText: { name: "colorValueText", type: "color", default: "#444" }
            },
            units: {
              units: { name: "units", type: "boolean", default: false },
              colorUnits: { name: "colorUnits", type: "color", default: "#888" },
              fontUnitsSize: { name: "fontUnitsSize", type: "number", default: 22 }
            },
            ticks: {
              majorTicks: { name: "majorTicks", type: "array", default: ["0", "50", "100"] },
              minorTicks: { name: "minorTicks", type: "number", default: 2 },
              majorTicksInt: { name: "majorTicksInt", type: "number", default: 1 },
              majorTicksDec: { name: "majorTicksDec", type: "number", default: 0 },
              colorMajorTicks: { name: "colorMajorTicks", type: "color", default: "#444" },
              colorMinorTicks: { name: "colorMinorTicks", type: "color", default: "#666" }
            }
          },
          radialGauge: {
            elementType: "output",
            name: "",
            component: "ejs-circulargauge",
            type: "gauge",
            fields: null,
            options: null,
            validation: {
              required: false
            },
            attributes: {
              placeHolder: { type: "text", value: "" }
            },
            basic: {
              minValue: { name: "minValue", type: "number", default: 0 },
              maxValue: { name: "maxValue", type: "number", default: 100 },
              colorPlate: { name: "colorPlate", type: "color", default: "#ffffff" },
              colorPlateEnd: { name: "colorPlateEnd", type: "color", default: "" }
            },
            title: {
              title: { name: "title", type: "boolean", default: false }
            },
            value: {
              valueInt: { name: "valueInt", type: "number", default: 3 },
              valueDec: { name: "valueDec", type: "number", default: 2 },
              colorValueText: { name: "colorValueText", type: "color", default: "#444" }
            },
            units: {
              units: { name: "units", type: "boolean", default: false },
              colorUnits: { name: "colorUnits", type: "color", default: "#888" },
              fontUnitsSize: { name: "fontUnitsSize", type: "number", default: 22 }
            },
            ticks: {
              majorTicks: { name: "majorTicks", type: "array", default: ["0", "50", "100"] },
              minorTicks: { name: "minorTicks", type: "number", default: 2 },
              majorTicksInt: { name: "majorTicksInt", type: "number", default: 1 },
              majorTicksDec: { name: "majorTicksDec", type: "number", default: 0 },
              colorMajorTicks: { name: "colorMajorTicks", type: "color", default: "#444" },
              colorMinorTicks: { name: "colorMinorTicks", type: "color", default: "#666" }
            }
          },
          lineChart: {
            elementType: "output",
            name: "",
            component: "ejs-chart",
            type: "gauge",
            fields: null,
            options: null,
            validation: {
              required: false
            },
            attributes: {
              title: { type: "text", value: "" }
            },
            axis: {
              x: { value: "", type: "number" },
              y: { value: "", type: "number" },
              xName: { value: "", type: "text" },
              yName: { value: "", type: "text" }
            },
            dataSource: []
          },
          pieChart: {
            elementType: "output",
            name: "",
            component: "ejs-accumulationchart",
            type: "gauge",
            fields: null,
            options: null,
            validation: {
              required: false
            },
            attributes: {
              title: { type: "text", value: "" },
              legend: { type: "boolean", value: true }
            },
            dataSource: [
              {
                x: { type: "number", value: "" },
                y: { type: "number", value: "" },
                fill: { type: "color", value: "" },
                text: { type: "text", value: "" }
              }
            ]
          }
        },
        elements: {}
      },
      selectedComponent: {},
      list: [],
      formTitle: "",
      selectedRowId: "",
      referedID: null,
      booleanType: [
        { per: "صحیح", eng: true, value: true },
        { per: "غلط", eng: false, value: false }
      ]
    };
  },
  methods: {
    validateInput(event) {
      const persianRegex = /[\u0600-\u06FF]/g;
      event.value = event.value.replace(persianRegex, "");
      this.selectedComponent.name = this.selectedComponent.name.replace(persianRegex, "");

    },
    moveUp(index) {
      if (index > 0) {
        this.swapItems(index, index - 1);
      }
    },
    moveDown(index) {
      if (index < this.items.length - 1) {
        this.swapItems(index, index + 1);
      }
    },
    swapItems(index1, index2) {
      const temp = this.list[index1];
      this.$set(this.list, index1, this.list[index2]);
      this.$set(this.list, index2, temp);
    },
    onDragStart(index, event) {
      this.draggedIndex = index;
      event.target.style.opacity = 0.5;
      event.target.style.cursor = "grabbing";
    },
    onDragEnd(index, event) {
      event.target.style.opacity = 1;
      event.target.style.cursor = "grab";
      const targetIndex = index;

      if (this.draggedIndex !== null && this.draggedIndex !== targetIndex) {
        this.swapItems(this.draggedIndex, targetIndex);
      }
      this.draggedIndex = null;
    },
    getList() {
      this.$store.dispatch("data/getAllForms").then((res) => {
        this.items = res;
        this.$refs.forms_list.ej2Instances.refresh();
      });
    },
    categoryToolbarClick(args) {
      if (args.item.id.includes("add_form")) {
        this.isVisible = true;
        this.pageMode = "New";
      } else if (args.item.id.includes("refresh_table")) {
        this.getList();
      }
    },
    updateClass(values) {
      let datas = [];
      values.forEach((item) => {
        datas.push({
          name: item,
          type: "String",
          id: v4(),
          value: "",
          required: false,
          enable: false,
          interval: null,
          uponChange: false
        });
      });


      this.$store
        .dispatch("data/updateClass", {
          id: this.referedID,
          name: this.formTitle,
          value: datas
        })
        .then(() => {
          this.$store
            .dispatch("data/editFormGeneration", {
              updates: {
                _id: this.selectedRowId,
                title: this.formTitle,
                details: {
                  elements: this.list,
                  referedId: this.referedID
                },
                type: "form"
              }
            })
            .then(() => {
            });
        });
    },
    onEditClick(args) {
      this.pageMode = "Edit";
      this.list = args.details.elements;
      this.formTitle = args.title;
      this.selectedRowId = args._id;
      this.referedID = args.details.referedId;
      this.isVisible = true;
    },
    remove(id) {
      const DialogObj = DialogUtility.confirm({
        title: this.dict.deleteConfirm[this.lang],
        locale: this.lang,
        position: "center center",
        Enabletrl: this.lang == "per" ? true : false,
        content: this.dict.deleteConfirmContent[this.lang],
        okButton: {
          text: this.dict.ok[this.lang],
          click: () => {
            this.$store
              .dispatch("data/removeFormGeneration", { _id: id }).then(() => {
                this.getList();
              }).catch((err)=>{
              console.log(err);
            });
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }
    },
    categoryActionBegin() {
    },
    categoryBound() {
    },
    createForm() {
      const isValid = new FormValidator("#titleSection").validate();
      if (isValid) {
        const DialogObj = DialogUtility.confirm({
          title: this.dict.createConfirm[this.lang],
          locale: this.lang,
          position: "center center",
          Enabletrl: this.lang == "per" ? true : false,
          content: this.dict.createConfirmContent[this.lang],
          okButton: {
            text: this.dict.ok[this.lang],
            click: () => {
              let classObj = [];
              this.list.forEach((item, index) => {
                let withName = [document.getElementById(item.id)];
                if (item.details.value != "ejs-slider") {
                  withName[0].childNodes[1].classList.remove("e-error");
                }


                withName[0].classList.remove("e-error");
                if (item.field.name != "" && item.field.name != null) {
                  classObj.push(item.field.name);
                } else {
                  let noName = [document.getElementById(item.id)];
                  if (item.details.value != "ejs-slider") {
                    noName[0].childNodes[1].classList.add("e-error");
                  }
                  noName[0].classList.add("e-error");

                }
              });
              if (this.list.length > classObj.length) {
                ToastUtility.show({
                  content: this.dict.errNoName[this.lang],
                  timeOut: 3000,
                  cssClass: "e-toast-danger",
                  position: { X: "Center", Y: "Center" },
                  showCloseButton: true,
                  enableRtl: this.lang == "per" ? true : false
                });
              } else if (this.list.length == classObj.length) {
                if (this.pageMode == "New")
                  this.createNewClass(classObj);
                else this.updateClass(classObj);

                this.isVisible = false;
                this.getList();
              }
              DialogObj.hide();
            }
          },
          cancelButton: { text: this.dict.cancel[this.lang] },
          showCloseIcon: false,
          closeOnEscape: false,
          animationSettings: { effect: "Zoom" }
        });
        if (this.lang == "per") {
          DialogObj.element.classList.add("e-control-rtl");
        } else {
          DialogObj.element.classList.remove("e-control-rtl");
          DialogObj.element.classList.add("e-control-ltr");
        }
      }
    },
    createNewClass(args) {
      let data = {
        value: [],
        name: this.formTitle,
        dataType: "Object"
      };

      args.forEach((item) => {
        data.value.push({
          name: item,
          type: "String",
          id: v4(),
          value: "",
          required: false,
          enable: false,
          interval: null,
          uponChange: false
        });
      });

      this.$store.dispatch("data/createNewClass", data).then((res) => {
        this.$store.dispatch("data/newFormGeneration", {
          title: this.formTitle,
          details: {
            elements: this.list,
            referedId: res
          },
          type: "form"
        });
      });
    },
    dragElement(uuId) {
      var draggableElement = document.getElementById(uuId);

      // new Draggable(draggableElement, {
      //   clone: false,
      //   dragArea: "#dragArea",
      // });
      // this.makeResizable(document.getElementById(uuId));
    },
    onSelect(args, type) {
      let uuId = v4();

      this.chosenComponent = args.text;
      let Item = Object.entries(this.inputComponents[type]).find(
        (item) => item[0] == this.chosenComponent
      );
      this.list.push({ id: uuId, field: Item[1], details: args });
      //
      // setTimeout(() => {
      //   new Draggable(document.getElementById(uuId), {
      //     clone: false,
      //     dragArea: "#dragArea",
      //     dragStop: this.onDragStop
      //   });
      //   // this.makeResizable(document.getElementById(uuId));
      // }, 100);
    },
    settingShow(args) {
      this.selectedComponent = args.field;
      this.showSetting = true;
      this.$bvModal.show("modal-edit-field");
    },
    getElementPosition(element) {
      const rect = element.getBoundingClientRect();
      const x = rect.left + window.scrollX;
      const y = rect.top + window.scrollY;
      return { x, y };
    },
    onDragStop(args) {
      this.getElementPosition(args.element);
    },
    resizeMouseDown(e, handle, element) {
      e.preventDefault();

      const initialWidth = element.offsetWidth;
      const initialHeight = element.offsetHeight;
      const initialX = e.clientX;
      const initialY = e.clientY;

      const initialLeft = element.offsetLeft;
      const initialTop = element.offsetTop;

      function onMouseMove(e) {
        const dx = e.clientX - initialX;
        const dy = e.clientY - initialY;

        if (handle.includes("right")) {
          element.style.width = `${initialWidth + dx}px`;
        }
        if (handle.includes("bottom")) {
          element.style.height = `${initialHeight + dy}px`;
        }
        if (handle.includes("left")) {
          element.style.width = `${initialWidth - dx}px`;
          element.style.left = `${initialLeft + dx}px`;
        }
        if (handle.includes("top")) {
          element.style.height = `${initialHeight - dy}px`;
          element.style.top = `${initialTop + dy}px`;
        }
        // element.offsetWidth, element.offsetHeight
      }

      function onMouseUp() {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
      }

      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    },
    makeResizable(element) {
      const handles = ["bottom-right"];

      // Create and append resize handles
      handles.forEach((handle) => {
        const handleDiv = createElement("span", {
          className: `e-icons e-resizer resize-handle ${handle}`
        });
        element.appendChild(handleDiv);

        // Attach event listeners for resizing
        handleDiv.addEventListener("mousedown", (e) =>
          this.resizeMouseDown(e, handle, element)
        );
      });
    },
    editField(item, id) {
      this.selectedComponent = {};
      this.selectedComponent = this.list.find((item) => item.id == id).field;
      // this.$bvModal.show("modal-edit-field");
    },
    removeField(item, id) {
      let theItem = this.list.find((item) => item.id == id);
      this.list.splice(this.list.indexOf(theItem), 1);
      this.selectedComponent = {};
    },
    resetPage() {
      this.getList();
      this.items = [];
      this.selectedComponent = {};
      this.list = [];
      this.isVisible = false;
      this.formTitle = "";
      this.selectedRowId = "";
      this.referedID = "";
    },
    addOptionsField() {
      this.selectedComponent.options.push({
        per: "",
        value: "",
        eng: ""
      });
    },
    addSourceField() {
      this.selectedComponent.dataSource.push({
        x: { type: "number", value: "" },
        y: { type: "number", value: "" },
        fill: { type: "color", value: "" },
        text: { type: "text", value: "" }
      });
    },
    removeOption(item) {
      this.selectedComponent.options.splice(
        this.selectedComponent.options.indexOf(item),
        1
      );
      this.selectedComponent = {};
    }
  },
  computed: {
    computeAccardions() {
      let list = [];
      Object.entries(this.selectedComponent).forEach((item, index) => {
        if (typeof item[1] != "string" && item[1] != null || item[0] == "name") {
          list.push(item[0]);
        }
      });
      return list;
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    toolbarGenerator() {
      return [
        "Search",
        {
          text: this.dict.addData[this.lang],
          id: "add_form",
          prefixIcon: "e-icons e-add"
        },
        { text: this.dict.refresh[this.lang], id: "refresh_table", prefixIcon: "e-icons e-refresh-2" }
      ];
      // if (!this.userPemission.includes("dataManager_add")) {
      //   return ["Search"];
      // } else {
      //
      // }
    }
  },
  provide: {
    grid: [Page, Sort, Toolbar, PdfExport, ExcelExport, Edit, Search, GaugeTooltip, Annotations],
    chart: [Category, LineSeries],
    accumulationchart: [PieSeries, AccumulationDataLabel, AccumulationLegend]
  },
  mounted() {
    this.getList();
  }
};
</script>
<style>
.resize-handle {
  position: absolute;
  background: #fff;
  z-index: 10;
  cursor: nwse-resize !important;
}

.bottom-right {
  bottom: -5px;
  right: -5px;
  cursor: nwse-resize;
}

</style>
<style scoped>
@import "../../node_modules/@syncfusion/ej2-vue-layouts/styles/material.css";
</style>
