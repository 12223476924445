<template>
  <div>
    <dataPickerModal></dataPickerModal>
    <b-modal
      :dir="dict.dir[lang]"
      id="create-marker"
      size="lg"
      centered
      no-close-on-backdrop
      header-border-variant="primary"
      header-class="my-modal-header "
      scrollable
      @ok="saveData"
      @shown="initModal"
      :ok-title="dict.ok[lang]"
      :cancel-title="dict.cancel[lang]"
      @hidden="resetAll"
    >
      <template #modal-header>
        <div :dir="dict.dir[lang]">
          <h5>{{ dict.title[lang] }}</h5>
        </div>
      </template>
      <div id="markerValid">
        <div class="p-3">
          <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
            <li
              v-for="(item, index) in tabs"
              class="mga-tab-nav-item"
              @click="activeTab = item.value"
              :key="index"
            >
              <a
                class="mga-tab-nav-link"
                :class="{ active: activeTab == item.value }"
                data-toggle="tab"
                role="tab"
                aria-controls="tab1"
                aria-selected="true"
              >
                {{ item[lang] }}
              </a>
            </li>
          </ul>
        </div>
        <div class="px-2" v-show="activeTab=='general'">
          <div class="col-md-12">
            <!--            <b-form-group-->
            <!--              :label="dict.status[lang]"-->
            <!--              v-slot="{ ariaDescribedby }"-->
            <!--            >-->
            <!--              <b-form-radio-group-->
            <!--                v-model="data.general.situation"-->
            <!--                id="radio-group-2"-->
            <!--                class="d-flex"-->
            <!--                :aria-describedby="ariaDescribedby"-->
            <!--                :name="dict.situation[lang]"-->
            <!--              >-->
            <!--                <b-form-radio-->
            <!--                  :aria-describedby="ariaDescribedby"-->
            <!--                  value="mobile"-->
            <!--                ><span class="px-2">{{-->
            <!--                    dict.mobile[lang]-->
            <!--                  }}</span></b-form-radio-->
            <!--                >-->
            <!--                <b-form-radio-->
            <!--                  :aria-describedby="ariaDescribedby"-->
            <!--                  value="stationary"-->
            <!--                ><span class="px-2">{{-->
            <!--                    dict.stationary[lang]-->
            <!--                  }}</span></b-form-radio-->
            <!--                >-->
            <!--              </b-form-radio-group>-->
            <!--            </b-form-group>-->
            <div class="col-md-6 col-12 px-3">
              <ejs-textbox
                :autocomplete="'off'"
                floatLabelType="Auto"
                v-model="schema.name"
                :placeholder="dict.pop[lang]"
                :enableRtl="lang=='per'"
              ></ejs-textbox>
            </div>
            <div class="row mx-auto mt-4">
              <div class="col-12 col-md-6 my-2">
                <ejs-textbox
                  :autocomplete="'off'"
                  floatLabelType="Auto"
                  v-model="schema.properties.width"
                  :placeholder="dict.width[lang]"
                  :enableRtl="lang=='per'"
                  type="number"
                />
              </div>
              <div class="col-12 col-md-6 my-2">
                <ejs-textbox
                  :autocomplete="'off'"
                  floatLabelType="Auto"
                  v-model="schema.properties.height"
                  :placeholder="dict.height[lang]"
                  :enableRtl="lang =='per'"
                  type="number"
                />
              </div>
              <div class="col-12 col-md-6 my-2">
                <ejs-textbox
                  :autocomplete="'off'"
                  :readOnly="true"
                  floatLabelType="Auto"
                  v-model="schema.properties.lat"
                  :placeholder="dict.latitude[lang]"
                  :enableRtl="lang=='per'"
                  type="number"
                />
              </div>
              <div class="col-12 col-md-6 my-2">
                <ejs-textbox
                  :autocomplete="'off'"
                  :readOnly="true"
                  :floatLabelType="'Auto'"
                  v-model="schema.properties.lng"
                  :placeholder="dict.longtitude[lang]"
                  :enableRtl="lang =='per'"
                  type="number"
                />
              </div>
              <div class="col-12 col-md-6 mt-4 d-flex">
                <ejs-switch
                  @change="(val)=>{schema.movable.enable=val.checked}"
                  :value="schema.movable.enable"
                  :checked="schema.movable.enable"
                />
                <span class="mx-2" style="font-size: 14px">{{ dict.draggable[lang] }}</span>
              </div>
              <div class="d-flex justify-content-around" v-if="schema.movable.enable">
                <div class="d-flex gap-2">
                  <ejs-textbox
                    :autocomplete="'off'"
                    floatLabelType="Auto"
                    :readOnly="true"
                    v-model="schema.movable.lat.name"
                    :placeholder="dict.latitude[lang]"
                    :enableRtl="lang=='per'"
                  ></ejs-textbox>
                  <span class="prefix-icon pt-2 e-icons e-edit-6 cursor-pointer"
                        @click="addNewLatLng('draggingLat')"></span>

                </div>
                <div class="d-flex gap-2 ">
                  <ejs-textbox
                    :autocomplete="'off'"
                    floatLabelType="Auto"
                    :readOnly="true"
                    v-model="schema.movable.lng.name"
                    :placeholder="dict.longtitude[lang]"
                    :enableRtl="lang=='per'"
                  ></ejs-textbox>
                  <span class="prefix-icon pt-2 e-icons e-edit-6 cursor-pointer"
                        @click="addNewLatLng('draggingLng')"></span>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="px-3" v-show="activeTab=='shape'">
          <b-form-group
            :label="dict.shapeType[lang]"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              v-model="shapeType"
              id="radio-group-2"
              class="d-flex my-2"
              :aria-describedby="ariaDescribedby"
              :name="dict.shapeType[lang]"
            >
              <b-form-radio
                :aria-describedby="ariaDescribedby"
                value="constant icon"
              ><span class="px-2">{{
                  dict.constant[lang]
                }}</span></b-form-radio
              >
              <b-form-radio
                :aria-describedby="ariaDescribedby"
                value="variable icon"
              ><span class="px-2">{{
                  dict.variable[lang]
                }}</span></b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <div
            v-if="shapeType == 'constant icon'"
            class="row col-md-12  mx-auto mt-4"
            :class="{'justify-content-center':true,'justify-content-md-end':lang=='per','justify-content-md-start':lang=='eng'}"
          >
            <div
              :class="{
                    'col-md-1 col-3 mx-2 border-2 border-red border m-2 p-2 cursor-pointer': true,
                    'border-success border-2 border': item.name == selected,
                  }"
              @click="chooseIcon(item)"
              v-for="(item, index) in iconList"
              :key="index"
            >
              <component v-if="item.component" :is="item.component" color="black"></component>
              <img v-else :src="item.address" alt="" class="img-fluid" />
            </div>
<!--            <div class="row">-->
            <!--              <div-->
            <!--                :class="{-->
            <!--                    'col-md-1 col-3 border border-2 border-red border-2 m-2 p-2 cursor-pointer': true,-->
            <!--                    'border-success border-2 border': item.name == selected,-->
            <!--                  }"-->
            <!--                @click="chooseIcon(item)"-->
            <!--                v-for="(item, index) in fireMarkers"-->
            <!--                :key="index"-->
            <!--              >-->
            <!--                <component v-if="item.component" :is="item.component" color="black"></component>-->
            <!--                <img v-else :src="item.address" alt="" class="img-fluid" />-->
            <!--              </div>-->
            <!--            </div>-->
          </div>

          <div v-if="shapeType != 'constant icon'">
            <div
              style="
                    display: flex;
                    justify-content: right;
                    align-items: center;
                    font-size: 24px;
                  "
            >
              <div class="d-flex col-md-6 col-12 gap-2">
                <ejs-textbox
                  :autocomplete="'off'"
                  floatLabelType="Auto"
                  :readOnly="true"
                  :required="shapeType != 'constant icon'"
                  v-model="schema.variableIcon.assignedData.name"
                  :placeholder="dict.label[lang]"
                  :enableRtl="lang=='per'"
                ></ejs-textbox>
                <span class="prefix-icon pt-2 e-icons e-edit-6 cursor-pointer" @click="addNewLatLng('markers')"></span>

              </div>

            </div>

            <b-card class="mt-4">
              <div class="row">
                <div class="col-md-6">
                  <ejs-textbox
                    :autocomplete="'off'"
                    floatLabelType="Auto"
                    placeholder="value"
                    :type="schema.variableIcon.assignedData.dataType"
                    v-model="schema.variableIcon.defaultValue.value"
                  ></ejs-textbox>
                </div>
                <div class="col-md-6 d-flex justify-content-end gap-3 align-items-center">
                  <img style="width: 30px" v-if="schema.variableIcon.defaultValue.icon!=''"
                       :src="schema.variableIcon.defaultValue.icon.address" alt=""
                       class="img-fluid" />
                  <span @click="openShapes(schema.variableIcon.defaultValue,true)"
                        class="e-icons e-image-2 mt-2"></span>

                </div>

              </div>
              <b-table
                class="mt-5"
                hover
                stacked="md"
                small
                :items="schema.variableIcon.shapes"
                :fields="dataFields"
                ref="alertTable"
              >
                <template v-slot:cell(icon)="row">
                  <img style="width: 30px" v-if="row.item.icon!=''" :src="row.item.icon.address" alt=""
                       class="img-fluid" />
                </template>
                <template v-slot:cell(value)="row">
                  <!--                  <div class="d-flex gap-5" v-if="schema.variableIcon.assignedData.dataType=='Number'">-->
                  <!--                    <ejs-textbox-->
                  <!--                      :placeholder="lang=='per'?'کمترین مقدار':'min'"-->
                  <!--                      :autocomplete="'off'"-->
                  <!--                      floatLabelType="Auto"-->
                  <!--                      :type="schema.variableIcon.assignedData.dataType"-->
                  <!--                      v-model="row.item.value[0]"-->
                  <!--                    ></ejs-textbox>-->
                  <!--                    <ejs-textbox-->
                  <!--                      :autocomplete="'off'"-->
                  <!--                      floatLabelType="Auto"-->
                  <!--                      :placeholder="lang=='per'?'بیشترین مقدار':'max'"-->
                  <!--                      :type="schema.variableIcon.assignedData.dataType"-->
                  <!--                      v-model="row.item.value[1]"-->
                  <!--                    ></ejs-textbox>-->

                  <!--                  </div>-->
                  <div>
                    <ejs-textbox
                      :autocomplete="'off'"
                      floatLabelType="Auto"
                      :type="schema.variableIcon.assignedData.dataType"
                      v-model="row.item.value"
                    ></ejs-textbox>
                  </div>
                </template>
                <template v-slot:cell(action)="row">
                  <div style="display: flex; justify-content: flex-end">
                    <span @click="openShapes(row,false)" class="e-icons e-image-2 mt-2"></span>

                    <b-button
                      pill
                      size="sm"
                      @click="deleteData(row.item)"
                      variant="outline-danger"
                      class="mr-2"
                      style="
                            margin-inline: 10px;
                            outline: none !important;
                            border: none;
                            box-shadow: none;
                          "
                    >
                      <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                    </b-button>

                  </div>
                </template>
              </b-table>
              <i class="reset" @click="newRowTable">
                <font-awesome-icon
                  icon="fas fa-plus-square"
                  class="arrow-down"
                />
              </i>
            </b-card>

          </div>
        </div>
        <div class="px-3" v-show="activeTab=='action'">
          <div class="p-3">
            <ejs-switch
              @change="EnableClick"
              :value="schema.onClick.enable"
              :checked="schema.onClick.enable"
            />
            <span class="mx-2" style="font-size: 14px">{{ dict.onClick[lang] }}</span>
          </div>
          <div class="row mx-auto" v-if="schema.onClick.enable ==true">
            <div class="col-md-6 col-12">
              <ejs-dropdownlist
                :dataSource="typeList"
                :value="schema.onClick.type"
                :fields="{ text: lang, value: 'value' }"
                :placeholder="dict.clickType[lang]"
                :enableRtl="lang === 'per'"
                @change="(val) => {schema.onClick.type = val.value}"
              ></ejs-dropdownlist>
            </div>
            <div class="col-md-6 col-12" v-if="schema.onClick.type=='page'">
              <ejs-textbox
                :value="schema.onClick.value"
                v-model="schema.onClick.value"
                :placeholder="dict.page[lang]"
                :enableRtl="lang === 'per'"
                type="number"
                :min="1"
                :required="true"
              ></ejs-textbox>
            </div>
            <!--            <div class="row col-12 my-3"-->
            <!--                 :key="schema.onClick.type"-->
            <!--                 v-show="schema.onClick.type=='form'"-->
            <!--            >-->
            <!--              <div class="col-md-6 col-12 mt-4">-->
            <!--                <ejs-dropdownlist-->
            <!--                  :dataSource="formsList"-->
            <!--                  :fields="{ text: 'text', value: 'value' }"-->
            <!--                  :placeholder="dict.clickType[lang]"-->
            <!--                  :enableRtl="lang === 'per'"-->
            <!--                  @select="getDatasForForm"-->
            <!--                ></ejs-dropdownlist>-->
            <!--              </div>-->
            <!--              <div class="d-flex col-md-6 col-12 gap-2">-->
            <!--                <ejs-textbox-->
            <!--                  :autocomplete="'off'"-->
            <!--                  floatLabelType="Auto"-->
            <!--                  :readOnly="true"-->
            <!--                  :required="shapeType != 'constant icon'"-->
            <!--                  v-model="schema.onClick.form.value.name"-->
            <!--                  :placeholder="dict.label[lang]"-->
            <!--                  :enableRtl="lang=='per'"-->
            <!--                ></ejs-textbox>-->
            <!--                <span class="prefix-icon pt-4 e-icons e-edit-6 cursor-pointer"-->
            <!--                      @click="chooseFormClass"></span>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>


        <!--        <b-tabs card v-model="activeTab">-->
        <!--          <b-tab title="tooltip">-->
        <!--            <div>-->
        <!--              <p>tooltip</p>-->
        <!--            </div>-->
        <!--          </b-tab>-->
        <!--          <b-tab :disabled="data.general.situation != 'mobile'" title="path">-->
        <!--            <div>-->
        <!--              <div>-->
        <!--                <input id="stroke" v-model="hasStroke" type="checkbox" />-->
        <!--                <label class="mx-2" for="stroke">stroke</label>-->
        <!--              </div>-->
        <!--              <div class="row" v-if="hasStroke">-->
        <!--                <div-->
        <!--                  class="col-12 col-md-6 my-2"-->
        <!--                  v-for="(item, index) in pathFields.stroke"-->
        <!--                  :key="index"-->
        <!--                >-->
        <!--                  <label class="m-2" :for="item.name">{{ item.name }}</label>-->
        <!--                  <b-form-select-->
        <!--                    v-if="item.type == 'select' && item.name == 'lineCap'"-->
        <!--                    :id="item.name"-->
        <!--                    v-model="data.path.stroke[item.name]"-->
        <!--                    class="form-control"-->
        <!--                    :options="lineCap"-->
        <!--                    style="width: 100%; font-size: small"-->
        <!--                  ></b-form-select>-->
        <!--                  <b-form-select-->
        <!--                    v-if="item.type == 'select' && item.name == 'lineJoin'"-->
        <!--                    :id="item.name"-->
        <!--                    v-model="data.path.stroke[item.name]"-->
        <!--                    class="form-control"-->
        <!--                    :options="lineJoin"-->
        <!--                    style="width: 100%; font-size: small"-->
        <!--                  ></b-form-select>-->
        <!--                  <input-->
        <!--                    v-if="item.type != 'select'"-->
        <!--                    :id="item.name"-->
        <!--                    v-model="data.path.stroke[item.name]"-->
        <!--                    :class="{ 'form-control': item.type != 'checkbox' }"-->
        <!--                    :type="item.type"-->
        <!--                  />-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <div>-->
        <!--                <input id="stroke" v-model="hasFill" type="checkbox" />-->
        <!--                <label class="mx-2" for="stroke">fill</label>-->
        <!--              </div>-->
        <!--              <div v-if="hasFill" class="row">-->
        <!--                <div-->
        <!--                  class="col-12 col-md-6 my-2"-->
        <!--                  v-for="(item, index) in pathFields.fill"-->
        <!--                  :key="index"-->
        <!--                >-->
        <!--                  <label class="mx-2" :for="item.name">{{ item.name }}</label>-->
        <!--                  <b-form-select-->
        <!--                    v-if="item.type == 'select' && item.name == 'fillRule'"-->
        <!--                    :id="item.name"-->
        <!--                    v-model="data.path.fill[item.name]"-->
        <!--                    class="form-control"-->
        <!--                    :options="fillRule"-->
        <!--                    style="width: 100%; font-size: small"-->
        <!--                  ></b-form-select>-->
        <!--                  <input-->
        <!--                    v-if="item.type != 'select'"-->
        <!--                    :id="item.name"-->
        <!--                    v-model="data.path.fill[item.name]"-->
        <!--                    :class="{ 'form-control': item.type != 'checkbox' }"-->
        <!--                    :type="item.type"-->
        <!--                  />-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </b-tab>-->
        <!--        </b-tabs>-->
      </div>
    </b-modal>
    <b-modal
      id="shape-selector"
      size="lg"
      :dir="dict.dir[lang]"
      centered
      hide-header-close
      :title="dict.markerIcon[lang]"
      header-border-variant="primary"
      header-class="my-modal-header"
      hide-footer
    >
      <div
        class="row col-md-12  mx-auto mt-4"
        :class="{'justify-content-center':true,'justify-content-md-end':lang=='per','justify-content-md-start':lang=='eng'}"
      >
        <div
          :class="{
                    'col-md-1 col-3 mx-2 border-2 border-red border m-2 p-2 cursor-pointer': true,
                    'border-success border-2 border': item.name == file,
                  }"
          @click="submitIcon(item)"
          v-for="(item, index) in iconList"
          :key="index"
        >
          <component v-if="item.component" :is="item.component" color="black"></component>
          <img v-else :src="item.address" alt="" class="img-fluid" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DataPickerModal from "@/components/dataManagerModalDataPicker.vue";
import L from "leaflet";
import { v4 } from "uuid";
import { FormValidator } from "@syncfusion/ej2-inputs";

export default {
  name: "markerCreator",
  components: { DataPickerModal },
  props: {
    information: {
      default: {}
    },
    latLng: {
      default: {}
    },
    value: {
      default: false
    },
    type: {
      default: ""
    }
  },
  data() {
    return {
      formsList: [],
      tabs: [
        { per: "تنظیمات عمومی", eng: "general settings", value: "general" },
        { per: "شکل مارکر", eng: "marker shapes", value: "shape" },
        { per: "عملیات مارکر", eng: "marker click", value: "action" }
      ],
      selectedId: "",
      typeList: [
        { per: "یک مورد انتخاب کنید", eng: "Select One", value: null },
        { per: "تغییر صفحه", eng: "change page", value: "page" }
        // { per: "افزودن فرم", eng: "assign form", value: "form" }
      ],
      activeTab: "",
      dict: {
        markerIcon: { eng: "marker icon", per: "آیکن مارکر" },
        page: { eng: "page number", per: "شماره صفحه" },
        clickType: { eng: "action type", per: "نوع عملیات" },
        label: { eng: "data", per: "داده" },
        pop: { eng: "marker title", per: "عنوان مارکر" },
        dir: { eng: "ltr", per: "rtl" },
        title: { per: "ایجاد مارکر", eng: "create marker" },
        titleInput: { per: "عنوان", eng: "title" },
        width: { per: "عرض", eng: "width" },
        height: { per: "طول", eng: "height" },
        latitude: { per: "عرض جغرافیایی", eng: "latitude" },
        longtitude: { per: "طول جغرافیایی", eng: "longtitude" },
        draggable: { per: "قابلیت حرکت", eng: "draggable" },
        onClick: { per: "فعال کردن عملیات مارکر", eng: "enable action" },
        shapeTab: { per: "آیکن مارکر", eng: "shape" },
        tooltipTab: { per: "متن نمایش", eng: "tooltip" },
        pathTab: { per: "مسیرحرکت", eng: "path" },
        generalTab: { per: "تنظیمات", eng: "general" },
        mobile: { per: "متحرک", eng: "mobile" },
        stationary: { per: "ثابت", eng: "stationary" },
        status: { per: "وضعیت مارکر", eng: "status" },
        shapeType: { per: "نوع ایکن", eng: "icon type" },
        variable: { per: "ایکن متفیر", eng: "variable icon" },
        constant: { per: "ایکن ثابت", eng: "constant icon" },
        modalTitle: { per: "تخصیص داده", eng: "set data" },
        ok: { per: "تایید", eng: "Ok" },
        cancel: { per: "لغو", eng: "Cancel" }
      },
      curData: { name: "" },
      popups: "",
      iconPic: "",
      selected: "",
      hasStroke: false,
      iconForDefValue: false,
      schema: {
        name: "",
        properties: {
          lat: "",
          lng: "",
          width: "",
          height: ""
        },
        movable: {
          enable: false,
          lat: { name: "" },
          lng: { name: "" }
        },
        constantIcon: {
          enable: true,
          properties: {}
        },
        variableIcon: {
          enable: false,
          shapes: [],
          defaultValue: { value: "", icon: "", id: v4() },
          assignedData: { name: "" }
        },
        tooltip: {
          enable: false,
          type: "",
          properties: {}
        },
        onClick: {
          enable: false,
          value: "",
          type: "",
          form: {
            name: "",
            id: "",
            value: {
              dataType: null,
              id: "",
              name: "",
              type: ""
            }
          }
        }
      },
      currentMarkerData: {
        range: {
          min: "",
          max: "",
          state: null,
          term: ""
        },
        type: ""
      },
      iconList: [
        {
          name: "fire_sensor1.png",
          address: require("@/assets/images/fire_sensor1.png"),
          iconSize: [30, 45]
        },
        {
          name: "fire_sensor2.png",
          address: require("@/assets/images/fire_sensor2.png"),
          iconSize: [30, 45]
        },
        {
          name: "fire_sensor3.png",
          address: require("@/assets/images/fire_sensor3.png"),
          iconSize: [30, 45]
        },
        {
          name: "fire_sensor4.png",
          address: require("@/assets/images/fire_sensor4.png"),
          iconSize: [30, 45]
        },
        {
          name: "fire station01.png",
          address: require("@/assets/images/traffic/fire station01.png"),
          iconSize: [30, 45]
        },
        {
          name: "fire_station00.png",
          address: require("@/assets/images/traffic/fire_station00.png"),
          iconSize: [30, 45]
        },
        {
          name: "traffic_light07.png",
          address: require("@/assets/images/traffic/traffic_light07.png"),
          iconSize: [30, 45]
        }, {
          name: "traffic_light06.png",
          address: require("@/assets/images/traffic/traffic_light06.png"),
          iconSize: [30, 45]
        }, {
          name: "traffic_light05.png",
          address: require("@/assets/images/traffic/traffic_light05.png"),
          iconSize: [30, 45]
        },
        {
          name: "traffic_light04.png",
          address: require("@/assets/images/traffic/traffic_light04.png"),
          iconSize: [30, 45]
        },
        {
          name: "gray.png",
          address: require("@/assets/images/mapIcons/gray.png"),
          iconSize: [30, 30]
        },
        {
          name: "green.png",
          address: require("@/assets/images/mapIcons/green.png"),
          iconSize: [30, 30]
        },
        {
          name: "fire_marker_fire.png",
          address: require("@/assets/images/photo_4.gif"),
          iconSize: [30, 45]
        },
        {
          name: "fire_marker_blue.png",
          address: require("@/assets/images/mapIcons/fire_marker_blue.png"),
          iconSize: [30, 45]
        },
        {
          name: "fire_marker_yellow.png",
          address: require("@/assets/images/mapIcons/fire_marker_yellow.png"),
          iconSize: [30, 45]
        },
        {
          name: "fire_marker_green.png",
          address: require("@/assets/images/mapIcons/fire_marker_green.png"),
          iconSize: [30, 45]
        },
        {
          name: "fire_marker_gray.png",
          address: require("@/assets/images/mapIcons/fire_marker_gray.png"),
          iconSize: [30, 45]
        },
        {
          name: "fire_marker_red.png",
          address: require("@/assets/images/mapIcons/fire_marker_red.png"),
          iconSize: [30, 45]
        }
      ],
      fireMarkers: [
        {
          name: "fireTrcuk3.png",
          address: require("@/assets/images/cars/fireTrcuk3.png"),
          iconSize: [30, 45]
        },
        {
          name: "fireTrcuk2.png",
          address: require("@/assets/images/cars/fireTrcuk2.png"),
          iconSize: [30, 45]
        },
        {
          name: "fireTrcuk1.png",
          address: require("@/assets/images/cars/fireTrcuk1.png"),
          iconSize: [30, 45]
        },
        {
          name: "fireTrcuk4.png",
          address: require("@/assets/images/cars/fireTrcuk4.png"),
          iconSize: [30, 45]
        },
        {
          name: "van.png",
          address: require("@/assets/images/cars/van.png"),
          iconSize: [30, 45]
        },
        {
          name: "kamiunet.png",
          address: require("@/assets/images/cars/kamiunet.png"),
          iconSize: [30, 45]
        },
        {
          name: "neisan.png",
          address: require("@/assets/images/cars/neisan.png"),
          iconSize: [30, 45]
        },
        {
          name: "ambulanc.png",
          address: require("@/assets/images/cars/ambulanc.png"),
          iconSize: [30, 45]
        },
        {
          name: "jaro_kesh.png",
          address: require("@/assets/images/cars/jaro_kesh.png"),
          iconSize: [30, 45]
        }
      ],
      pathFields: {
        stroke: [
          { value: "", name: "color", type: "color" },
          { value: "", name: "weight", type: "number" },
          { value: "", name: "opacity", type: "number" },
          { value: "", name: "lineCap", type: "select" },
          { value: "", name: "lineJoin", type: "select" }
        ],
        fill: [
          { value: "", name: "fillColor", type: "color" },
          { value: "", name: "fillOpacity", type: "number" },
          { value: "", name: "fillRule", type: "select" }
        ]
      },
      lineCap: [
        { value: "butt", text: "butt" },
        { value: "square", text: "square" },
        { value: "round", text: "round" }
      ],
      fillRule: [
        { value: "evenodd", text: "evenodd" },
        { value: "nonzero ", text: "nonzero " }
      ],
      lineJoin: [
        { value: "arcs", text: "arcs" },
        { value: "bevel", text: "bevel" },
        { value: "miter", text: "miter" },
        { value: "miter-clip", text: "miter-clip" },
        { value: "round", text: "round" }
      ],
      shapeType: "constant icon",
      dataFields: [
        { key: "value", label: "value" },
        { key: "icon", label: "icon" },
        { key: "action", label: "" }
      ],
      triggerList: [
        { text: "Select One", value: null },
        { text: "True", value: true },
        { text: "False", value: false }
      ],
      selectedRow: {},
      list: [],
      chosenIconUrl: {},
      file: ""
    };
  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    },
    dataType() {
      return this.$store.state.chpge.dataTypeForMap;
    },
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    child() {
      return this.$store.state.chpge.child2child;
    },
    marker() {
      return this.$store.state.data.currentMarker;
    }
  },
  methods: {
    EnableClick(val) {
      this.schema.onClick.enable = val.checked;
    },
    getForms() {
      this.$store.dispatch("data/getAllForms").then(res => {
        res.forEach(item => {
          this.formsList.push({ text: item.title, value: item.title, id: item._id });
        });
      });
    },
    chooseFormClass() {
      this.$store.dispatch("chpge/parent2child", {
        task: "pickedData", from: "markers_form",
        filter: {
          dataType: [this.schema.onClick.form.name],
          usedBy: [],
          parents: [],
          archive: null,
          perPage: 5000,
          page: 1,
          withChild: false
        }
      });
      this.$root.$emit("bv::show::modal", "modal-data-picker");
    },
    getDatasForForm(args) {
      this.schema.onClick.form.name = args.itemData.value;
      this.schema.onClick.form.id = args.itemData.id;
    },
    initModal() {
      if (this.type != "edit") {
        this.schema.properties.lat = this.latLng[0];
        this.schema.properties.lng = this.latLng[1];
        this.schema.properties.width = this.marker.iconSize[0];
        this.schema.properties.height = this.marker.iconSize[1];
      }
      this.activeTab = "general";

    },
    chooseIcon(item) {
      this.selected = item.name;
      var myIcon = L.icon({
        iconUrl: item.address,
        iconSize: [this.schema.properties.width, this.schema.properties.height],
        iconAnchor: [this.schema.properties.width / 2, this.schema.properties.height],
        popupAnchor: [-15, -40]
      });
      this.chosenIconUrl = myIcon;
    },
    submitIcon(item) {
      if (!this.iconForDefValue) {
        if (item["component"]) {
          this.schema.variableIcon.shapes.find(val => val.id == this.selectedRow.id).icon = {
            address: item.address,
            name: item.name
          };
        } else {
          this.schema.variableIcon.shapes.find(val => val.id == this.selectedRow.id).icon = item;
        }
      } else {
        this.schema.variableIcon.defaultValue.icon = {
          address: item.address,
          name: item.name
        };
      }

      this.$bvModal.hide("shape-selector");
    },
    saveData($bv) {
      $bv.preventDefault();
      const isValid = new FormValidator("#markerValid").validate();

      if (isValid) {
        if (this.type == "edit") {
          this.$store.dispatch("chpge/child2parent", {
            task: "editMarker",
            id: this.selectedId,
            item: {
              name: this.schema.name,
              properties: {
                lat: this.schema.properties.lat,
                lng: this.schema.properties.lng,
                width: this.schema.properties.width,
                height: this.schema.properties.height
              },
              movable: {
                enable: this.schema.movable.enable,
                lat: this.schema.movable.lat,
                lng: this.schema.movable.lng
              },
              constantIcon: {
                enable: this.schema.constantIcon.enable,
                properties: {
                  icon: this.schema.constantIcon.properties.icon,
                  newIcon: this.chosenIconUrl
                }
              },
              variableIcon: {
                enable: this.schema.variableIcon.enable,
                shapes: this.schema.variableIcon.shapes,
                defaultValue: this.schema.variableIcon.defaultValue,
                assignedData: this.schema.variableIcon.assignedData || { name: "" }
              },
              tooltip: this.schema.tooltip,
              onClick: this.schema.onClick
            }
          });
        } else {
          this.$store.dispatch("chpge/child2parent", {
            task: "newMarker",
            item: {
              id: v4(),
              name: this.schema.name,
              properties: {
                lat: this.latLng[0],
                lng: this.latLng[1],
                width: this.schema.properties.width,
                height: this.schema.properties.height
              },
              movable: {
                enable: this.schema.movable.enable,
                lat: this.schema.movable.lat,
                lng: this.schema.movable.lng
              },
              constantIcon: {
                enable: this.schema.constantIcon.enable,
                properties: {
                  icon: this.marker,
                  newIcon: this.chosenIconUrl
                }
              },
              variableIcon: {
                enable: this.schema.variableIcon.enable,
                shapes: this.schema.variableIcon.shapes,
                defaultValue: this.schema.variableIcon.defaultValue,
                assignedData: this.shapeType != "constant icon" ? this.schema.variableIcon.assignedData : { name: "" }
              },
              tooltip: this.schema.tooltip,
              onClick: this.schema.onClick
            }
          });
        }
        this.selected = "";
        this.$nextTick(() => {
          this.$bvModal.hide("create-marker");
        });
        this.resetForSub();
      }

    },
    addNewLatLng(value) {
      this.$store.dispatch("chpge/parent2child", {
        task: "pickedData",
        from: value,
        filter: {
          dataType: ["!complex"],
          usedBy: [],
          parents: [],
          archive: null,
          perPage: 5000,
          page: 1,
          withChild: false
        }
      });
      this.$root.$emit("bv::show::modal", "modal-data-picker");
    },
    openShapes(row, isDefault) {
      this.iconForDefValue = isDefault;
      if (!isDefault)
        this.selectedRow = row.item;
      this.$bvModal.show("shape-selector");
    },
    resetAll() {
      if (this.type != "edit") {
        this.iconPic = "";
        this.shapeType = "constant icon";
        this.schema.variableIcon.shapes = [];
        this.selectedRow = {};
        this.schema = {
          name: "",
          properties: {
            lat: "",
            lng: "",
            width: "",
            height: ""
          },
          movable: {
            enable: false,
            lat: { name: "" },
            lng: { name: "" }
          },
          constantIcon: {
            enable: true,
            properties: {}
          },
          variableIcon: {
            enable: false,
            shapes: [],
            defaultValue: { value: "", icon: "", id: v4() },
            assignedData: { name: "" }
          },
          tooltip: {
            enable: this.schema.tooltip.enable,
            type: this.schema.tooltip.type,
            properties: this.schema.tooltip.properties
          },
          onClick: {
            enable: false,
            value: "",
            type: ""
          }
        };
      }
    },
    resetForSub() {
      this.iconPic = "";
      this.shapeType = "constant icon";
      this.schema.variableIcon.shapes = [];
      this.selectedRow = {};
      this.selected = "";
      this.chosenIconUrl = {};
      this.schema = {
        name: "",
        properties: {
          lat: "",
          lng: "",
          width: "",
          height: ""
        },
        movable: {
          enable: false,
          lat: { name: "" },
          lng: { name: "" }
        },
        constantIcon: {
          enable: true,
          properties: {}
        },
        variableIcon: {
          enable: false,
          shapes: [],
          defaultValue: { value: "", icon: "", id: v4() },
          assignedData: { name: "" }
        },
        tooltip: {
          enable: this.schema.tooltip.enable,
          type: this.schema.tooltip.type,
          properties: this.schema.tooltip.properties
        },
        onClick: {
          enable: false,
          value: "",
          type: ""
        }
      };
    },
    deleteData(item) {
      this.schema.variableIcon.shapes.splice(this.schema.variableIcon.shapes.indexOf(item), 1);
    },
    newRowTable() {
      this.schema.variableIcon.shapes.push({
        value: "",
        icon: "",
        id: v4()
      });
      // if (this.schema.variableIcon.assignedData.dataType != "Number") {
      //   this.schema.variableIcon.shapes.push({
      //     value: "",
      //     icon: "",
      //     id: v4()
      //   });
      // } else {
      //   this.schema.variableIcon.shapes.push({
      //     value: [0, 0],
      //     icon: "",
      //     id: v4()
      //   });
      // }
    }
  },
  watch: {
    dataType(val) {
      this.currentMarkerData.type = val;
    },
    shapeType(val) {
      if (val == "variable icon" && this.type != "edit" && this.type != "") {
        this.schema.constantIcon.enable = false;
        this.schema.variableIcon.enable = true;
        this.schema.variableIcon.shapes = [];
        this.schema.variableIcon.assignedData = { name: "" };
        this.schema.variableIcon.defaultValue = { value: "", icon: "", id: v4() };
        this.selected = "";
      } else if (val != "variable icon" && this.type != "edit") {
        this.schema.variableIcon.enable = false;
        this.schema.constantIcon.enable = true;
        this.schema.constantIcon.properties = {};
      }
    },
    child(newValue) {
      if (newValue.task == "pickedData") {

        if (newValue.to == "draggingLat")
          this.schema.movable.lat = newValue.item;

        else if (newValue.to == "draggingLng")
          this.schema.movable.lng = newValue.item;

        else if (newValue.to == "markers_form") {
          this.schema.onClick.form.value = newValue.item;
        } else
          this.schema.variableIcon.assignedData = newValue.item;

      } else if (newValue.task == "chosenMarker") {
        this.selectedId = newValue.item.id;
        this.schema.constantIcon = newValue.item.constantIcon;
        this.schema.properties.lat = newValue.item.properties.lat;
        this.schema.properties.lng = newValue.item.properties.lng;
        this.schema.properties.width = newValue.item.properties.width;
        this.schema.properties.height = newValue.item.properties.height;
        this.schema.variableIcon.shapes = newValue.item.variableIcon.shapes;
        this.schema.variableIcon.defaultValue = newValue.item.variableIcon.defaultValue;
        this.shapeType = newValue.item.variableIcon.enable == true ? "variable icon" : "constant icon";
        this.schema.variableIcon.assignedData = newValue.item.variableIcon.assignedData;
        this.marker = newValue.item;
        this.schema.movable = newValue.item.movable || {
          enable: false,
          lat: { name: "" },
          lng: { name: "" }
        };
        this.schema.tooltip = newValue.item.tooltip;
        this.schema.onClick = newValue.item.onClick || {
          enable: false,
          value: "",
          type: "",
          form: {
            name: "",
            value: {
              dataType: null,
              id: "",
              name: "",
              type: ""
            }
          }
        };
        this.schema.name = newValue.item.name || "";
        this.activeTab = "general";
      }
    }
  },
  mounted() {
    this.getForms();
  }
};
</script>


<style>

/* Icon styling */
.prefix-icon {
  color: #474bff; /* Color of the icon */
  font-size: 18px; /* Adjust size */
  cursor: pointer !important;
}

</style>
